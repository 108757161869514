import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import AvatarGroup from '@material-ui/lab/AvatarGroup';
import SmallCardWrapper from '../../components/SmallCardWrapper';
import ColoredAvatar from '../../../../components/ColoredAvatar';
import ProjectStatusLabel from '../../../projects/components/ProjectStatusLabel';

import Paths from '../../../../utils/route-paths';
import { Project } from '../../../../utils/types';

interface ProjectBlockCardProps {
  data: Project;
}

const useStyles = makeStyles((theme) => ({
  additionalContent: {
    marginTop: 'auto',
  },
  statusBox: {
    display: 'flex',
    marginTop: 8,
  },
}));

function ProjectCard(props: ProjectBlockCardProps): JSX.Element {
  const { data } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleCardClick = () => history.push(`${Paths.PROJECT_WORKSPACE}/${data.id}`);

  return (
    <SmallCardWrapper title={data.name} text={data.goal} onClick={handleCardClick}>
      <div className={classes.additionalContent}>
        <AvatarGroup max={5}>
          {data.team.map((teamMember) => {
            return <ColoredAvatar key={teamMember.id} user={teamMember} />;
          })}
        </AvatarGroup>
      </div>
      <div className={classes.statusBox}>
        <ProjectStatusLabel
          status={data.status}
          organizationOnly={data.organizationOnly}
          startDate={data.startDate}
        />
      </div>
    </SmallCardWrapper>
  );
}

export default ProjectCard;
