import React, { PropsWithChildren } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

interface TilesGridWraperProps {
  isLastPage: boolean;
  loadMore: () => void;
  title?: string;
}

interface StyleProps {
  isTitle: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: (props: StyleProps) => (props.isTitle ? 15 : 0),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: 345,
    height: 560,
  },
  control: {
    padding: theme.spacing(2),
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  action: {
    marginTop: 'auto',
  },
  top: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingBottom: '15px',
  },
}));

function TilesGridWrapper(props: PropsWithChildren<TilesGridWraperProps>) {
  const { loadMore, isLastPage, children, title } = props;
  const classes = useStyles({ isTitle: !!title });

  return (
    <div>
      {!!title && <Typography variant="h3">{title}</Typography>}
      <Grid container spacing={2} className={classes.grid}>
        <Grid item>
          <Grid container spacing={2}>
            {children}
          </Grid>
        </Grid>
        <Grid item>
          {!isLastPage && (
            <Button color="primary" onClick={loadMore}>
              загрузить ещё
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default TilesGridWrapper;
