import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Formik, FieldArray } from 'formik';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import Switch from '@material-ui/core/Switch';
import TextFieldWithCounter from '../../../components/TextFieldWithCounter';
import StandardDialog from '../../../components/StandardDialog';
import QuestionsSkeleton from '../../../components/Skeletons/Questions';

import StartProjectSchema from './validationScema';
import { COMPONENT_STATES } from '../../../utils/componentState';
import { fetchDefaultQuestions, recruitingStart } from './domain';
import { Template } from '../../../utils/types';
import { MAX_TEMPLATE_QUESTIONS_NUMBER, GLOBAL_ORGANIZATION_ID } from '../../../utils/constants';
import { getFontSizeForTitle } from '../../../utils/utils';
import { TOUR_PAGES, TOURS_CLASS_NAMES } from '../../../components/tour/tourSteps';
import { setTourPage } from '../../globalDomain';

interface StartRecruitingFormProps {
  templateData: Template;
  open: boolean;
  onClose: () => void;
  isTemplateEdited?: boolean;
}

interface StyleProps {
  title: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 750,
  },
  titleBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 20,
  },
  title: {
    fontWeight: 500,
    fontSize: (props: StyleProps) => getFontSizeForTitle(props.title, 64, 128),
    textAlign: 'center',
    lineHeight: '125%',
    marginTop: 8,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  settingsBox: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
  },
  settingsFields: {
    display: 'flex',
  },
  questions: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      width: 160,
    },
    marginBottom: 25,
  },
  warning: {
    marginTop: 'auto',
    width: '90%',
    paddingBottom: 24,
    paddingTop: 24,
  },
  onlyOrgSwitch: {
    display: 'flex',
    paddingTop: 8,
  },
}));

function StartRecruitingForm(props: StartRecruitingFormProps) {
  const { templateData, open, onClose, isTemplateEdited } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles({ title: templateData.name });

  const { user } = useSelector((state: any) => state.app);
  const {
    componentState,
    formData,
    requesting,
    quotaData: { totalProjects, quota },
  } = useSelector((state: any) => state.defaultQuestions);

  const availableProjects = quota - totalProjects > 0 ? quota - totalProjects : 0;
  const showAvailableProjectsWarning = availableProjects <= Math.round(totalProjects * 0.1);

  useEffect(
    () => {
      if (open) {
        dispatch(setTourPage({ pages: [TOUR_PAGES.startRecruitingForm] }));
      }
      return function cleanUp() {
        dispatch(setTourPage({ pages: [] }));
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, open],
  );

  useEffect(() => {
    if (open) {
      dispatch(fetchDefaultQuestions({ orgId: user?.organization?.id }));
    }
  }, [dispatch, user, open]);

  const submitForm = (values) => {
    const newValues = isTemplateEdited ? { ...values, ...templateData } : { ...values };
    dispatch(
      recruitingStart({
        values: newValues,
        info: { templateId: templateData.id, mentorId: user.id },
        onClose,
        history,
      }),
    );
  };

  return (
    <StandardDialog className={classes.root} open={open} onClose={onClose} showTour>
      <div className={classes.titleBox}>
        <Typography variant="h3" color="textSecondary">
          Объявление рекрутинга
        </Typography>
        <Typography className={classes.title}>{templateData.name}</Typography>
        {user?.organization?.id !== GLOBAL_ORGANIZATION_ID &&
          componentState === COMPONENT_STATES.CONTENT && (
            <Typography
              variant="subtitle2"
              color={showAvailableProjectsWarning ? 'secondary' : 'primary'}
            >
              {showAvailableProjectsWarning && 'Внимание! '}В вашей организации возможно запустить{' '}
              {availableProjects} из {quota} проектов
            </Typography>
          )}
      </div>
      <Formik
        validationSchema={StartProjectSchema}
        initialValues={formData}
        onSubmit={submitForm}
        enableReinitialize
      >
        {({ values, handleChange, setFieldValue, isValid, errors }) => {
          return (
            <Form className={classes.form}>
              {/* блок настроек */}
              <div className={classes.settingsBox}>
                <Typography variant="subtitle2" color="textSecondary">
                  Параметры рекрутинга
                </Typography>
                <div
                  className={clsx(
                    classes.settingsFields,
                    TOURS_CLASS_NAMES.startRecruitingForm.settings,
                  )}
                >
                  <TextField
                    variant="outlined"
                    error={!!errors.teamSize}
                    margin="normal"
                    id="teamSize"
                    label="Ожидаемый размер команды"
                    name="teamSize"
                    autoFocus
                    value={values.teamSize}
                    onChange={handleChange}
                    helperText={`${errors.teamSize}`}
                    size="small"
                  />
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                    <DatePicker
                      error={!!errors.endDate}
                      style={{ marginLeft: 64 }}
                      name="endDate"
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="dd.MM.yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Дата завершения набора"
                      value={values.endDate}
                      onChange={(v) => setFieldValue('endDate', v)}
                      helperText={`${errors.endDate}`}
                      size="small"
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div
                  className={clsx(
                    classes.onlyOrgSwitch,
                    TOURS_CLASS_NAMES.startRecruitingForm.organizationOnly,
                  )}
                >
                  <Switch
                    disabled={requesting}
                    onChange={(e) => setFieldValue('organizationOnly', e.target.checked)}
                    checked={values.organizationOnly}
                    color="primary"
                  />
                  <div>
                    <Typography>Объявить только для участников организации</Typography>
                    <Typography color="textSecondary" variant="subtitle2" style={{ width: '90%' }}>
                      Если настройка не активирована, то любой пользователь платформы может
                      посмотреть и отправить заявку на участие.
                    </Typography>
                  </div>
                </div>
              </div>

              {/* блок вопросов */}
              <div
                className={clsx(classes.questions, TOURS_CLASS_NAMES.startRecruitingForm.questions)}
              >
                <Typography variant="subtitle2" color="textSecondary">
                  Вопросы к анкете кандидата на участие в проект
                </Typography>
                {componentState === COMPONENT_STATES.LOADING ? (
                  <QuestionsSkeleton />
                ) : (
                  <div>
                    <FieldArray
                      name="questions"
                      render={(arrayHelpers) => (
                        <div>
                          <div id="question-list">
                            {values.questions.map((question, index) => {
                              return (
                                <div key={question.id} id={`question-box-${index}`}>
                                  <TextFieldWithCounter
                                    id={`question-input-${index}`}
                                    style={{ width: 600 }}
                                    size="small"
                                    name={`questions.${index}`}
                                    error={errors.questions && errors.questions[index]}
                                    value={values.questions[index]}
                                    onChange={handleChange}
                                    rowsMax={3}
                                  />
                                  <IconButton
                                    id={`question-remove-button-${index}`}
                                    style={{ marginTop: 10 }}
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </div>
                              );
                            })}
                          </div>
                          {values.questions.length < MAX_TEMPLATE_QUESTIONS_NUMBER && (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <Link
                              component="button"
                              variant="button"
                              color={isValid ? 'primary' : 'textSecondary'}
                              disabled={!isValid}
                              underline={isValid ? 'hover' : 'none'}
                              onClick={() => arrayHelpers.push('')}
                            >
                              ДОБАВИТЬ ВОПРОС
                            </Link>
                          )}
                        </div>
                      )}
                    />
                  </div>
                )}
              </div>

              {/* предупреждение */}
              <div className={classes.warning}>
                <Typography variant="subtitle2" color="secondary" style={{ fontWeight: 'bold' }}>
                  Рекрутинг может быть отправлен на модерацию. Если рекрутинг не пройдёт модерацию,
                  то будет отменён. Редактировать рекрутинг после запуска невозможно.
                </Typography>
              </div>

              {/* блок кнопок */}
              <div className={classes.buttons}>
                <Button
                  className={TOURS_CLASS_NAMES.startRecruitingForm.startButton}
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={
                    requesting ||
                    !isValid ||
                    componentState === COMPONENT_STATES.LOADING ||
                    !values.questions.length
                  }
                >
                  Объявить
                </Button>
                <Button
                  style={{ marginLeft: 26 }}
                  variant="outlined"
                  color="primary"
                  disabled={componentState === COMPONENT_STATES.LOADING}
                  onClick={onClose}
                  size="large"
                >
                  Отмена
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </StandardDialog>
  );
}

export default StartRecruitingForm;
