import React, { PropsWithChildren } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { isLoggedIn } from './utils/auth';
import AppContainer from './app/App';
import RememberPathPage from './app/auth/RememberPathPage';

// auth
import Login from './app/auth';
import PasswordReset from './app/auth/password-reset/ResetRequest';
import PasswordResetConfirm from './app/auth/confirm-reset/ResetConfirm';
import NewUserRegistration from './app/auth/registrations';
import SocialLogin from './app/auth/SocialLogin';
// ideas
import Templates from './app/project-templates/list';
import ProjectTemplate from './app/project-templates/view-n-form';
import PersonalProjectsTemplatesTiles from './app/project-templates/personal-templates-list/Tiles';
// recruiting
import RecruitingTemplateForm from './app/project-templates/start-recruiting-form/edit-template/RecruitingTemplateForm';
import CommonRecruiting from './app/recruiting/list';
import PersonalRecruiting from './app/recruiting/personal-list';
import RecruitingProjectView from './app/recruiting/view';
import StartProjectPage from './app/recruiting/project-start';
// projects
import PersonalProjectsTiles from './app/projects/list';
import ProjectWorkspace from './app/workspace';
import JoinNewMember from './app/workspace/join-new-member';
import ProjectSettings from './app/workspace/project-settings';
// organization
import OrganizationAddUser from './app/organization/user-forms/AddUser';
import OrganizationManagement from './app/organization';
import StatIdeas from './app/organization/statistics/ideas';
import StatProjects from './app/organization/statistics/projects';
import StatRecruitings from './app/organization/statistics/recruitings';
import UserInfoAndStatistics from './app/organization/user-statistics';
import OrgInfoForm from './app/organization/common-info/OrgInfoForm';
// common
import UserProfile from './app/user/UserProfileForm';
import Dashboard from './app/dashboard';
import UserManagement from './app/user';
// public
import PublicUserProfile from './app/user/public-profile';
import PublicUserIdeas from './app/user/public-profile/ideas';
import PublicUserRecruiting from './app/user/public-profile/recruiting';
import PublicUserProjects from './app/user/public-profile/projects';
import OrgPublicPage from './app/organization/public-page';
import OrgPublicIdeas from './app/organization/public-page/ideas';
import OrgPublicProjects from './app/organization/public-page/projects';
import OrgPublicRecruiting from './app/organization/public-page/recruiting';

import Paths from './utils/route-paths';
import Discord from './app/community/Discord';

const AppRoutes = () => {
  return (
    <AppContainer>
      {/* шаблоны */}
      <Route exact path="/" component={Dashboard} />
      <Route exact path={Paths.PERSONAL_IDEAS} component={PersonalProjectsTemplatesTiles} />
      <Route exact path={`${Paths.COMMON_IDEAS}/:id`} component={ProjectTemplate} />
      <Route exact path={Paths.COMMON_IDEAS} component={Templates} />
      <Route exact path={Paths.EDIT_IDEA_BEFORE_RECRUITING} component={RecruitingTemplateForm} />

      {/*  рекрутинг */}
      <Route exact path={Paths.COMMON_RECRUITING_PROJECTS} component={CommonRecruiting} />
      <Route exact path={Paths.PERSONAL_RECRUITING_PROJECTS} component={PersonalRecruiting} />
      <Route
        exact
        path={`${Paths.COMMON_RECRUITING_PROJECTS}/:id`}
        component={RecruitingProjectView}
      />
      <Route exact path={Paths.START_NEW_PROJECT} component={StartProjectPage} />

      {/* проекты */}
      <Route exact path={Paths.PERSONAL_PROJECTS} component={PersonalProjectsTiles} />
      <Route exact path={`${Paths.PROJECT_WORKSPACE}/:id`} component={ProjectWorkspace} />
      <Route exact path={`${Paths.JOIN_PROJECT_PATH}/:token`} component={JoinNewMember} />
      <Route exact path={`${Paths.PROJECT_SETTINGS}/:projectId`} component={ProjectSettings} />

      {/* организация */}
      <Route exact path={Paths.ORGANIZATION} component={OrganizationManagement} />
      <Route exact path={`${Paths.ORGANIZATION_USER}`} component={OrganizationAddUser} />
      <Route exact path={`${Paths.ORGANIZATION_USER}/:id`} component={UserInfoAndStatistics} />
      <Route exact path={`${Paths.ORGANIZATION}/:id`} component={OrgInfoForm} />
      <Route exact path={`${Paths.PUBLIC_ORGANIZATION}/:id`} component={OrgPublicPage} />
      <Route exact path={`${Paths.PUBLIC_ORGANIZATION_IDEAS}/:id`} component={OrgPublicIdeas} />
      <Route
        exact
        path={`${Paths.PUBLIC_ORGANIZATION_PROJECTS}/:id`}
        component={OrgPublicProjects}
      />
      <Route
        exact
        path={`${Paths.PUBLIC_ORGANIZATION_RECRUITINGS}/:id`}
        component={OrgPublicRecruiting}
      />

      {/* statistics */}
      <Route exact path={Paths.ORGANIZATION_STATISTICS_IDEAS} component={StatIdeas} />
      <Route exact path={Paths.ORGANIZATION_STATISTICS_PROJECTS} component={StatProjects} />
      <Route exact path={Paths.ORGANIZATION_STATISTICS_RECRUITINGS} component={StatRecruitings} />

      {/* разное */}
      <Route exact path={`${Paths.USER_PROFILE}`} component={UserManagement} />
      <Route exact path={`${Paths.USER_PROFILE_FORM}`} component={UserProfile} />
      <Route exact path={`${Paths.COMMUNITY}`} component={Discord} />

      {/* public */}
      <Route exact path={`${Paths.PUBLIC_USER_PROFILE}/:id`} component={PublicUserProfile} />
      <Route exact path={`${Paths.PUBLIC_USER_IDEAS}/:id`} component={PublicUserIdeas} />
      <Route exact path={`${Paths.PUBLIC_USER_RECRUITING}/:id`} component={PublicUserRecruiting} />
      <Route exact path={`${Paths.PUBLIC_USER_PROJECTS}/:id`} component={PublicUserProjects} />
    </AppContainer>
  );
};

function PrivateRoute({ children, ...rest }: PropsWithChildren<any>) {
  return <Route {...rest} render={() => (isLoggedIn() ? children : <RememberPathPage />)} />;
}

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path={Paths.LOGIN} component={Login} />
        <Route path={Paths.RESET_PASSWORD} component={PasswordReset} />
        <Route path={Paths.RESET_PASSWORD_CONFIRM} component={PasswordResetConfirm} />
        <Route path={Paths.NEW_USER_REGISTRATION} component={NewUserRegistration} />
        <Route path={Paths.SOCIAL_LOGIN} component={SocialLogin} />
        <PrivateRoute path="/">
          <AppRoutes />
        </PrivateRoute>
      </Switch>
    </Router>
  );
};

export default Routes;
