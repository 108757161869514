import axios from 'axios';
import Urls from '../../../utils/endpoints';

export const updateProject = (projectId, payload) =>
  axios.patch(`${Urls.PROJECTS}/${projectId}`, payload);
export const getProject = (projectId) => axios.get(`${Urls.PROJECTS}/${projectId}`);
export const getProjectJoinToken = (projectId) =>
  axios.get(`${Urls.PROJECTS}/${projectId}/get-token`);
export const kickProjectMember = (projectId, payload) =>
  axios.post(`${Urls.PROJECTS}/${projectId}/kick-user`, payload);
export const sendAppToJoinProject = (projectId, payload) =>
  axios.post(`${Urls.PROJECTS}/${projectId}/applications`, payload);
export const getHasApplication = (projectId) =>
  axios.get(`${Urls.PROJECTS}/${projectId}/applications/check`);
export const getApplicationData = (projectId) =>
  axios.get(`${Urls.PROJECTS}/${projectId}/applications`);
export const downloadProjectPassport = (projectId) =>
  axios.get(`${Urls.PROJECT_OFFICE}/${projectId}/passport`, { responseType: 'blob' });
export const downloadProjectReport = (projectId) =>
  axios.get(`${Urls.PROJECT_OFFICE}/${projectId}/report`, { responseType: 'blob' });
