import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HowToRegOutlinedIcon from '@material-ui/icons/HowToRegOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';

import OrgMenuItem from '../../../components/OrgMenuItem';
import UpdateUser from '../user-forms/UpdateUser';
import UserIdeas from './ideas';
import UserStatRecruitings from './recruitings';
import UserStatProjects from './projects';

import { PALETTE } from '../../../utils/palette';
import {
  OrganizationCommonInfo,
  setUserStatisticsMenuItem,
  fetchInfo,
} from '../common-info/domain/reducer';
import { AppState, setTourPage } from '../../globalDomain';
import PageWrapper from '../../../components/Wrapper';
import { TOUR_PAGES, TOURS_CLASS_NAMES } from '../../../components/tour/tourSteps';

const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: 'solid',
  },
  divider: {
    width: '100%',
  },
  main: {
    height: '100%',
    display: 'flex',
  },
  menuList: {
    height: '100%',
    borderRight: 'solid',
    borderRightColor: PALETTE(0.2).gray,
    borderWidth: 1,
  },
  content: {
    paddingTop: 40,
    paddingLeft: 40,
    width: '80%',
  },
  icon: {
    color: PALETTE().gray,
  },
  ideaIcon: {
    transform: 'rotate(180deg)',
  },
  list: {
    background: '#fff',
  },
}));

function UserInfoAndStatistics() {
  const classes = useStyles();
  // @ts-ignore
  const { id } = useParams();
  const { user }: AppState = useSelector((state: any) => state.app);
  const { userStatMenuItem, hasAccessToStatistics, request }: OrganizationCommonInfo = useSelector(
    (state: any) => state.organizationCommonInfo,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && !hasAccessToStatistics) {
      dispatch(fetchInfo({ orgId: user.organization.id }));
      dispatch(setTourPage({ pages: [TOUR_PAGES.userInfoStat] }));
    }
    return function cleanUp() {
      dispatch(setTourPage({ pages: [] }));
    };
  }, [dispatch, user, hasAccessToStatistics]);

  // сбрасываем пункт меню на первый. Переделывать не стал,
  // на случай возврата к предыдущему варианту с запоминанием выбранного пункта.
  useEffect(() => {
    return function cleanup() {
      dispatch(setUserStatisticsMenuItem(0));
      dispatch(setTourPage({ pages: [] }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickMenuItem = (item) => {
    dispatch(setUserStatisticsMenuItem(item));
  };

  return (
    <PageWrapper header="Информация и статистика пользователя">
      <Divider className={clsx(classes.divider, TOURS_CLASS_NAMES.userInfoStat.center)} />
      <div className={classes.main}>
        <div className={classes.menuList}>
          <List className={classes.list}>
            <OrgMenuItem
              onClick={() => handleClickMenuItem(0)}
              Icon={<InfoOutlinedIcon className={classes.icon} />}
              text="Информация"
              isSelected={userStatMenuItem === 0}
            />
            <OrgMenuItem
              className={TOURS_CLASS_NAMES.userInfoStat.ideasMenuItem}
              onClick={() => handleClickMenuItem(1)}
              Icon={<WbIncandescentOutlinedIcon className={clsx(classes.icon, classes.ideaIcon)} />}
              text="Идеи"
              isSelected={userStatMenuItem === 1}
            />
            <OrgMenuItem
              className={TOURS_CLASS_NAMES.userInfoStat.recruitingsMenuItem}
              onClick={() => handleClickMenuItem(2)}
              Icon={<HowToRegOutlinedIcon className={classes.icon} />}
              text="Рекрутинг"
              isSelected={userStatMenuItem === 2}
            />
            <OrgMenuItem
              className={TOURS_CLASS_NAMES.userInfoStat.projectsMenuItem}
              onClick={() => handleClickMenuItem(3)}
              Icon={<SchoolOutlinedIcon className={classes.icon} />}
              text="Проекты"
              isSelected={userStatMenuItem === 3}
            />
          </List>
        </div>
        {!request && (
          <div className={classes.content}>
            {userStatMenuItem === 0 && <UpdateUser id={id} />}
            {userStatMenuItem === 1 && <UserIdeas id={id} />}
            {userStatMenuItem === 2 && <UserStatRecruitings id={id} />}
            {userStatMenuItem === 3 && <UserStatProjects id={id} />}
          </div>
        )}
      </div>
    </PageWrapper>
  );
}

export default UserInfoAndStatistics;
