import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ViewSelectState from '../../../components/select-state/ViewSelectState';
import ProfileAvatar from '../../../components/ProfileAvatar';
import InfoString from './compoments/InfoString';
import RichTextEditor from '../../../components/RichTextEditor';
import CustomButton from '../../../components/CustomButton';
import OrgHeadInfoBlock from './compoments/OrgHeadInfoBlock';

import { OrganizationCommonInfo } from './domain/reducer';
import Paths from '../../../utils/route-paths';
import { TOURS_CLASS_NAMES, TOUR_PAGES } from '../../../components/tour/tourSteps';
import { setTourPage } from '../../globalDomain';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 800,
    display: 'flex',
  },
  orgAvatarInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 34,
  },
  avatarBox: {
    paddingBottom: 40,
  },
  orgInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  addressBox: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  orgHead: {
    marginBottom: 24,
  },
}));

function OrgCommonInfo(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { organization, componentState }: OrganizationCommonInfo = useSelector(
    (state: any) => state.organizationCommonInfo,
  );

  useEffect(() => {
    dispatch(setTourPage({ pages: [TOUR_PAGES.orgManageInfo] }));
    return function cleanUp() {
      dispatch(setTourPage({ pages: [] }));
    };
  }, [dispatch]);

  const content = () => (
    <div className={clsx(classes.root, TOURS_CLASS_NAMES.orgManageInfo.center)}>
      <div className={classes.orgAvatarInfo}>
        <div className={classes.orgInfo}>
          <ProfileAvatar
            className={classes.avatarBox}
            avatarUrl={organization.avatar}
            variant="rounded"
            orgName={organization.name}
          />
          <CustomButton
            variant="outlined"
            color="primary"
            onClick={() => history.push(`${Paths.ORGANIZATION}/${organization.id}`)}
          >
            Редактировать
          </CustomButton>
        </div>
      </div>

      <div className={classes.orgInfo}>
        <Typography variant="h2">{`${organization.name} (${organization.shortName})`}</Typography>
        <OrgHeadInfoBlock className={classes.orgHead} user={organization.owner} />
        <InfoString
          label="Сайт"
          titleProps={{ variant: 'body1' }}
          show={!!organization.siteUrl}
          title={
            <Link href={organization.siteUrl} target="_blank" rel="noreferrer">
              {organization.siteUrl}
            </Link>
          }
        />
        <InfoString label="Адрес" title={organization.location} titleProps={{ variant: 'body1' }} />
        <RichTextEditor toolbarHidden readOnly value={organization.description} />
      </div>
    </div>
  );

  return <ViewSelectState state={componentState} content={content} />;
}

export default OrgCommonInfo;
