import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { PALETTE } from '../../../../utils/palette';

interface InfoCell {
  className?: string;
  label?: string;
  firstParam: number | string;
  secondParam?: number | string;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 210,
  },
}));

function InfoCell({ className, label, firstParam, secondParam }: InfoCell) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Typography align="center" color="textSecondary">
        {label}
      </Typography>
      <div>
        <Typography component="span" style={{ color: PALETTE().green }} variant="h2">
          {firstParam}
        </Typography>
        {secondParam && (
          <>
            <Typography component="span" color="textSecondary" variant="h2">
              /
            </Typography>
            <Typography component="span" color="primary" variant="h2">
              {secondParam}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
}

export default InfoCell;
