import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { mdToDraftjs } from 'draftjs-md-converter';
import PageWrapper from '../../../components/Wrapper';
import ViewSelectState from '../../../components/select-state/ViewSelectState';
import ProfileAvatar from '../../../components/ProfileAvatar';
import OrgHeadInfoBlock from '../common-info/compoments/OrgHeadInfoBlock';
import PublicIdeasBlock from '../../user/public-profile/components/PublicIdeasBlock';
import PublicRecruitingBlock from '../../user/public-profile/components/PublicRecruitingsBlock';
import PublicProjectsBlock from '../../user/public-profile/components/PublicProjectsBlock';
import RichTextEditor from '../../../components/RichTextEditor';
import TypographyWithTooltipAndEllipsis from '../../../components/TypographyWithTooltipAndEllipsis';

import { makeTextEllipsis } from '../../../utils/utils';
import { fetchPublicOrganization, PublicOrganizationState } from './domain';
import Paths from '../../../utils/route-paths';
import { OrganizationFull } from '../../../utils/types';
import NoData from '../../../components/NoData';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  info: {
    paddingRight: 24,
    width: 350,
  },
  infoItem: {
    paddingTop: 24,
  },
  projectsList: {},
  avatar: {
    width: 300,
    height: 300,
  },
}));

function OrgPublicPage() {
  const classes = useStyles();
  // @ts-ignore
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    componentState,
    organization,
    projects,
    projectsTotal,
    recruitings,
    recruitingTotal,
    ideas,
    ideasTotal,
  }: PublicOrganizationState = useSelector((state: any) => state.publicOrganization);

  const pureDescriptionText = mdToDraftjs(organization?.description);

  useEffect(() => {
    dispatch(fetchPublicOrganization({ orgId: id }));
  }, [id, dispatch]);

  const Contacts = (org: OrganizationFull) => {
    const { siteUrl, location } = org;

    const siteExist = !!siteUrl && siteUrl.length > 0;
    const locationExist = !!location && location.length > 0;

    return (siteExist || locationExist) && <Typography color="textSecondary">Контакты</Typography>;
  };

  const content = () => {
    return (
      <div className={classes.root}>
        <div className={classes.info}>
          <ProfileAvatar
            variant="rounded"
            avatarUrl={organization.avatar}
            avatarClassName={classes.avatar}
            orgName={organization.name}
          />
          <div className={classes.infoItem}>
            <OrgHeadInfoBlock user={organization.owner} />
          </div>
          <div className={classes.infoItem}>
            {Contacts(organization)}
            <TypographyWithTooltipAndEllipsis
              title={organization.location}
              symbolsNumber={60}
              typographyProps={{ gutterBottom: true }}
            />
            <Typography>
              {!!organization.siteUrl && (
                <Link href={organization.siteUrl} target="_blank" rel="noreferrer">
                  {makeTextEllipsis(30, organization.siteUrl)}
                </Link>
              )}
            </Typography>
          </div>
          <div className={classes.infoItem}>
            {!!pureDescriptionText && pureDescriptionText.legth > 0 && (
              <Typography style={{ marginBottom: -10 }} color="textSecondary">
                Описание
              </Typography>
            )}
            <RichTextEditor toolbarHidden readOnly value={organization.description} />
          </div>
        </div>
        <div className={classes.projectsList}>
          <PublicProjectsBlock
            data={projects}
            counter={projectsTotal}
            onClickMore={() => history.push(`${Paths.PUBLIC_ORGANIZATION_PROJECTS}/${id}`)}
          />
          <PublicRecruitingBlock
            data={recruitings}
            counter={recruitingTotal}
            onClickMore={() => history.push(`${Paths.PUBLIC_ORGANIZATION_RECRUITINGS}/${id}`)}
          />
          <PublicIdeasBlock
            data={ideas}
            counter={ideasTotal}
            onClickMore={() => history.push(`${Paths.PUBLIC_ORGANIZATION_IDEAS}/${id}`)}
          />
          {projectsTotal === 0 && recruitingTotal === 0 && ideasTotal === 0 && (
            <NoData text={'У организации нет публичных идей, рекрутингов и проектов'} />
          )}
        </div>
      </div>
    );
  };

  return (
    <PageWrapper header={organization.name}>
      <ViewSelectState state={componentState} content={content} />
    </PageWrapper>
  );
}

export default OrgPublicPage;
