import * as React from 'react';
import { TaskNodeModel } from './TaskNodeModel';
import { TaskNodeWidget } from './TaskNodeWidget';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';

export class TaskNodeFactory extends AbstractReactFactory {
  constructor() {
    super('js-custom-node');
  }

  generateModel(event) {
    return new TaskNodeModel();
  }

  generateReactWidget(event) {
    return <TaskNodeWidget engine={this.engine} node={event.model} />;
  }
}
