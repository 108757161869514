import React from 'react';
import { useHistory } from 'react-router-dom';

import TemplateCard from './TemplateCard';
import CardBlockWrapper from '../../components/CardBlockWrapper';

import Paths from '../../../../utils/route-paths';
import { Template } from '../../../../utils/types';

interface TemplatesBlockProps {
  data: Template[];
  counter?: number;
  counterEnable?: boolean;
}

function TemplatesBlock(props: TemplatesBlockProps): JSX.Element | null {
  const history = useHistory();
  const { data, counter, counterEnable } = props;

  const finalCounter = counter || data.length;
  const finalCounterEnable = typeof counterEnable === 'undefined' ? false : counterEnable;

  if (!data?.length) {
    return null;
  }

  return (
    <CardBlockWrapper
      title="Мои идеи проектов"
      counter={finalCounter}
      addButtonText="Создать новую идею проекта"
      titleLinkOnClick={() => history.push(Paths.PERSONAL_IDEAS)}
      addButtonOnClick={() => history.push({ pathname: `${Paths.COMMON_IDEAS}/new`, state: {} })}
      counterEnable={finalCounterEnable}
    >
      {data.slice(0, 2).map((item) => (
        <TemplateCard key={item.id} data={item} />
      ))}
    </CardBlockWrapper>
  );
}

export default TemplatesBlock;
