import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { COMPONENT_STATES } from '../../../utils/componentState';
import Urls from '../../../utils/endpoints';
import { errorHandler } from '../../../utils/fetchUtils';
import {
  Recruiting,
  Image,
  Attachment,
  RecruitingApplication,
  User,
  Question,
} from '../../../utils/types';
import Paths from '../../../utils/route-paths';
import { getOrganization } from '../../organization/common-info/domain/dal';

// dal
export const getPersonalRecruitingProject = (userId, projectId) =>
  axios.get(Urls.PERSONAL_RECRUITING_PROJECT(userId, projectId));
export const getCommonRecruitingProject = (projectId) =>
  axios.get(`${Urls.COMMON_RECRUITING}${projectId}`);
export const cancelRecruiting = (projectId, payload) =>
  axios.post(Urls.CANCEL_RECRUITING(projectId), payload);

// saga
function* recruitingProjectRequest(action) {
  try {
    const { userId, projectId } = action.payload;
    const fetchRecruitingProject = userId
      ? () => getPersonalRecruitingProject(userId, projectId)
      : () => getCommonRecruitingProject(projectId);
    const { data: projectData } = yield call(fetchRecruitingProject);
    const { data: organization } = yield call(getOrganization, projectData.organizationId);
    yield put(fetchRecruitingProjectSuccess({ projectData, organization }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchRecruitingProjectFail(e.response));
  }
}

function* requestingCancelRecruiting(action) {
  try {
    const { projectId, values, history } = action.payload;
    yield call(cancelRecruiting, projectId, values);
    history.push(Paths.COMMON_IDEAS);
    toast.success('Рекрутинг отменен');
    yield put(cancelRecruitingEnd());
  } catch (e) {
    errorHandler(e);
    yield put(cancelRecruitingEnd());
  }
}

export function* watchLoadingRecruitingProject() {
  yield takeEvery(fetchRecruitingProject.type, recruitingProjectRequest);
  yield takeEvery(fetchCancelRecruiting.type, requestingCancelRecruiting);
}

// reducer
interface InitialState {
  componentState: string;
  recruitingProjectData: Recruiting;
  organization: any;
  requesting: boolean;
  errorData: any;
}

const initialState: InitialState = {
  componentState: COMPONENT_STATES.LOADING,
  recruitingProjectData: {
    id: '',
    name: '',
    goal: '',
    about: '',
    duration: 1,
    teamSize: 1,
    applications: [] as RecruitingApplication[],
    endDate: '',
    isMentor: true,
    images: [] as Image[],
    attachments: [] as Attachment[],
    mentor: {} as User,
    questions: [] as Question[],
    canSendApplication: false,
    status: '',
    applicationSent: false,
  },
  organization: null,
  requesting: false,
  errorData: {},
};

const recruitingProjectSlice = createSlice({
  name: 'recruitingProject',
  initialState,
  reducers: {
    fetchRecruitingProject(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
    },
    fetchRecruitingProjectSuccess(state, action) {
      const { projectData, organization } = action.payload;
      state.recruitingProjectData = { ...initialState.recruitingProjectData, ...projectData };
      state.organization = organization;
      state.componentState = COMPONENT_STATES.CONTENT;
    },
    fetchRecruitingProjectFail(state, action) {
      state.componentState = COMPONENT_STATES.ERROR;
      state.errorData = action.payload;
    },
    fetchCancelRecruiting(state, action) {
      state.requesting = true;
    },
    cancelRecruitingEnd(state) {
      state.requesting = false;
    },
  },
});

export const {
  fetchRecruitingProject,
  fetchRecruitingProjectSuccess,
  fetchRecruitingProjectFail,
  fetchCancelRecruiting,
  cancelRecruitingEnd,
} = recruitingProjectSlice.actions;

export default recruitingProjectSlice.reducer;
