import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { acceptApp, rejectApp } from './dal';
import {
  fetchAcceptApp,
  fetchRejectApp,
  finishProcessApp,
  FetchAcceptAppActionPayload,
  FetchRejectAppActionPayload,
  fetchApplicationForProject,
  fetchApplicationForProjectSuccess,
  fetchApplicationForProjectFail,
} from './reducer';
import { fetchWorkspaceProject, setApplicationList } from '../../domain/reducer';
import { errorHandler } from '../../../../utils/fetchUtils';
import { getApplicationData } from '../../domain/dal';

function* acceptAppRequest(action) {
  try {
    const { projectId, appId, userId }: FetchAcceptAppActionPayload = action.payload;
    yield call(acceptApp, projectId, appId);
    toast.success('Заявка принята');
    yield put(fetchWorkspaceProject({ projectId, userId }));
    yield put(finishProcessApp());
  } catch (e) {
    errorHandler(e);
    yield put(finishProcessApp());
  }
}

function* rejectAppRequest(action) {
  try {
    const { projectId, appId, rejectMessage, userId }: FetchRejectAppActionPayload = action.payload;
    yield call(rejectApp, projectId, appId, { rejectMessage });
    toast.info('Заявка отклонена');
    yield put(fetchWorkspaceProject({ projectId, userId }));
    yield put(finishProcessApp());
  } catch (e) {
    errorHandler(e);
    yield put(finishProcessApp());
  }
}

function* requestApplications(action) {
  try {
    const { projectId } = action.payload;
    const { data } = yield call(getApplicationData, projectId);
    yield put(setApplicationList({ data }));
    yield put(fetchApplicationForProjectSuccess());
  } catch (e) {
    errorHandler(e);
    yield put(fetchApplicationForProjectFail());
  }
}

export function* watchProjectAppProcessing() {
  yield takeEvery(fetchAcceptApp.type, acceptAppRequest);
  yield takeEvery(fetchRejectApp.type, rejectAppRequest);
  yield takeEvery(fetchApplicationForProject.type, requestApplications);
}
