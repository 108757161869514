import { createAction } from '@reduxjs/toolkit';
import { all, call, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { errorHandler } from '../../utils/fetchUtils';
import Urls from '../../utils/endpoints';
import { delay } from '../../app/workspace/plan/utils';

// reducer
export const uploadFilesAction = createAction<any>('uploadFiles/fetchUpload');

// dal
export const uploadFile = (file, fileName = '') => {
  return axios.post(Urls.ATTACHMENTS(fileName), file);
};

// saga
function* uploadFilesRequest(action) {
  try {
    const { files, onSave, onClose } = action.payload;
    const filesObject = yield all(
      files.map(function*(file, index) {
        yield call(delay, 300 * index);
        const { data } = yield call(uploadFile, file, file.name);
        return data;
      }),
    );
    onSave(filesObject);
    onClose();
  } catch (e) {
    errorHandler(e);
  }
}

export function* watchFileUploading() {
  yield takeLatest(uploadFilesAction.type, uploadFilesRequest);
}
