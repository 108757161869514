import { put, takeEvery, call } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { COMPONENT_STATES } from '../../../../utils/componentState';
import { errorHandler } from '../../../../utils/fetchUtils';
import { ComponentState, Project, User } from '../../../../utils/types';
import { EMPTY_USER, LIST_PAGE_SIZE } from '../../../../utils/constants';
import { getPersonalProjectsList } from '../../../projects/list/domain';
import { getUser } from '../domain';

// dal

// saga
function* publicProjectsRequest(action) {
  try {
    const { userId, currentPage } = action.payload;
    const { data } = yield call(() =>
      getPersonalProjectsList(userId, { page: currentPage, pageSize: LIST_PAGE_SIZE }),
    );
    const { data: userData } = yield call(getUser, userId);
    yield put(fetchPublicUserProjectsSuccess({ data, userData }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchPublicUserProjectsFail());
  }
}

export function* watchLoadingPublicUserProjects() {
  yield takeEvery(fetchPublicUserProjects.type, publicProjectsRequest);
}

export interface PublicUserProjectsState {
  componentState: ComponentState;
  projects: Project[];
  user: User;
  currentPage: number;
  isLastPage: boolean;
}

// reducer
const initialState: PublicUserProjectsState = {
  componentState: COMPONENT_STATES.LOADING,
  projects: [],
  user: EMPTY_USER,
  currentPage: 1,
  isLastPage: false,
};

const publicUserProjectsSlice = createSlice({
  name: 'publicUserProjects',
  initialState,
  reducers: {
    fetchPublicUserProjects(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
      if (action.payload.isFirstLoading) {
        state.currentPage = 1;
        state.projects = [];
      }
      action.payload.currentPage = state.currentPage;
    },
    fetchPublicUserProjectsSuccess(state, action) {
      const { data, userData } = action.payload;
      state.user = userData;
      if (data.content?.length === 0) {
        state.componentState = COMPONENT_STATES.EMPTY;
      } else {
        state.componentState = COMPONENT_STATES.CONTENT;
        state.projects = [...state.projects, ...data.content];
        state.currentPage += 1;
        state.isLastPage = data.last;
      }
    },
    fetchPublicUserProjectsFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const {
  fetchPublicUserProjects,
  fetchPublicUserProjectsSuccess,
  fetchPublicUserProjectsFail,
} = publicUserProjectsSlice.actions;

export default publicUserProjectsSlice.reducer;
