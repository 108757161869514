import React from 'react';
import { useHistory } from 'react-router-dom';

import RecruitingCard from './RecruitingCard';
import CardBlockWrapper from '../../components/CardBlockWrapper';

import Paths from '../../../../utils/route-paths';
import { Recruiting } from '../../../../utils/types';

interface ProjectsBlockProps {
  data: Recruiting[];
  counter?: number;
  counterEnable?: boolean;
}

function RecruitingBlock(props: ProjectsBlockProps): JSX.Element | null {
  const history = useHistory();
  const { data, counter, counterEnable } = props;
  const finalCounter = counter || data.length;

  if (!data?.length) {
    return null;
  }

  const finalCounterEnable = typeof counterEnable === 'undefined' ? false : counterEnable;

  return (
    <CardBlockWrapper
      title="Мой рекрутинг"
      counter={finalCounter}
      titleLinkOnClick={() => history.push(Paths.PERSONAL_RECRUITING_PROJECTS)}
      addButtonText="Объявить рекрутинг"
      addButtonOnClick={() => history.push(Paths.COMMON_IDEAS)}
      counterEnable={finalCounterEnable}
    >
      {data.slice(0, 2).map((item) => (
        <RecruitingCard key={item.id} data={item} />
      ))}
    </CardBlockWrapper>
  );
}

export default RecruitingBlock;
