import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button, { ButtonProps } from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);

export interface PopoverProps {
  mainHandle?: (() => void) | ((e) => void);
  cancelHandle?: () => void;
  onClose: () => void;
  anchorEl: any;
  mainText?: string;
  secondaryText?: string;
  okButtonText?: string;
  cancelButtonText?: string;
  okButtonId?: string;
  cancelButtonId?: string;
  bodyComponent?: JSX.Element;
  okButtonProps?: ButtonProps;
}

export type OuterPopoverProps = Omit<PopoverProps, 'anchorEl' | 'onClose'>;

function SimplePopover(props: PopoverProps) {
  const classes = useStyles();
  const {
    mainHandle,
    cancelHandle,
    onClose,
    anchorEl,
    mainText = 'Вы уверены?',
    okButtonText = 'Да',
    cancelButtonText = 'Отмена',
    okButtonId,
    cancelButtonId,
    secondaryText,
    bodyComponent,
    okButtonProps,
  } = props;

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onConfirm = (e) => {
    if (mainHandle) {
      mainHandle(e);
    }
    onClose();
  };

  const onCancel = () => {
    if (cancelHandle) {
      cancelHandle();
    }
    onClose();
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <div className={classes.popover}>
        <Typography>{mainText}</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {secondaryText}
        </Typography>
        {bodyComponent}
        <div style={{ paddingTop: 10 }}>
          <Button
            id={okButtonId}
            size="small"
            variant="outlined"
            color="primary"
            onClick={onConfirm}
            {...okButtonProps}
          >
            {okButtonText}
          </Button>
          <Button
            id={cancelButtonId}
            size="small"
            style={{ marginLeft: 10 }}
            variant="outlined"
            color="primary"
            onClick={onCancel}
          >
            {cancelButtonText}
          </Button>
        </div>
      </div>
    </Popover>
  );
}

export default SimplePopover;
