import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface InfoString {
  className?: string;
  label?: string;
  title?: string | React.ReactNode;
  titleProps?: TypographyProps;
  show?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: 16,
    display: 'flex',
  },
  label: {
    marginRight: 10,
  },
}));

function InfoString({
  className,
  label,
  title,
  titleProps,
  show,
  children,
}: PropsWithChildren<InfoString>) {
  const classes = useStyles();

  return title || show ? (
    <div className={clsx(classes.root, className)}>
      {label && (
        <Typography className={classes.label} color="textSecondary">
          {label}:
        </Typography>
      )}
      {title ? (
        <Typography variant="h4" {...titleProps}>
          {title}
        </Typography>
      ) : (
        children
      )}
    </div>
  ) : null;
}

export default InfoString;
