import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PageWrapper from '../../components/Wrapper';
import DashboardFirst from './screens/FirstScreen';
import DashboardTemplatesOnly from './screens/ideas';
import DashboardRecruiting from './screens/recruiting';
import DashboardProjects from './screens/projects';
import ViewSelectState from '../../components/select-state/ViewSelectState';

import { DashboardComponentState } from '../../utils/componentState';
import { fetchDashboard, DashboardState } from './domain';
import { setTourPage } from '../globalDomain';
import { TOUR_PAGES } from '../../components/tour/tourSteps';
import { RootState } from '../../store/rootReducer';

const Dashboard = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.app);
  const {
    componentState,
    dashboardState,
    templates,
    recruiting,
    projects,
    errorData,
  }: DashboardState = useSelector((state: any) => state.dashboard);

  const fetchData = () => {
    if (user) {
      dispatch(fetchDashboard({ userId: user.id }));
    }
  };

  useEffect(fetchData, [user]);

  useEffect(() => {
    if (user) {
      dispatch(setTourPage({ pages: [TOUR_PAGES.sideMenu] }));
    }
    return function cleanUp() {
      dispatch(setTourPage({ pages: [] }));
    };
  }, [dispatch, user]);

  const content = () => {
    switch (dashboardState) {
      case DashboardComponentState.EMPTY:
        return <DashboardFirst />;
      case DashboardComponentState.TEMPLATES:
        return <DashboardTemplatesOnly templates={templates} />;
      case DashboardComponentState.RECRUITING:
        return <DashboardRecruiting templates={templates} recruiting={recruiting} />;
      case DashboardComponentState.PROJECTS:
        return (
          <DashboardProjects templates={templates} recruiting={recruiting} projects={projects} />
        );
      default:
        return null;
    }
  };

  return (
    <PageWrapper dontShowGoBack header="Обзор">
      <ViewSelectState
        state={componentState}
        content={content}
        errorData={errorData}
        errorAction={fetchData}
      />
    </PageWrapper>
  );
};

export default Dashboard;
