import React, { useEffect, useState } from 'react';
import createEngine, { DiagramModel } from '@projectstorm/react-diagrams';
import { CanvasWidget } from '@projectstorm/react-canvas-core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { TaskNodeFactory } from './node/TaskNodeFactory';
import { ArrowLinkFactory } from './link/ArrowLinkFactory';
import { PALETTE } from '../../../../utils/palette';

interface StyleProps {
  dimensions: { height: number; width: number };
}

const useStyles = makeStyles(() => ({
  root: {
    height: (props: StyleProps) => props.dimensions.height,
    width: (props: StyleProps) => props.dimensions.width,
    backgroundColor: PALETTE(0.1).gray,
  },
}));

function PlanDiagram() {
  const { graphTree, dimensions } = useSelector((state: any) => state.plan);
  const classes = useStyles({ dimensions });
  const [engine, setEngine] = useState();

  useEffect(() => {
    const engine = createEngine();
    engine.getNodeFactories().registerFactory(new TaskNodeFactory() as any);
    engine.getLinkFactories().registerFactory(new ArrowLinkFactory() as any);

    const model = new DiagramModel();
    model.addAll(...graphTree);
    engine.setModel(model);
    // @ts-ignore
    setEngine(engine);
  }, [graphTree]);

  return (
    <div>
      {!!engine && (
        <CanvasWidget
          className={classes.root}
          // @ts-ignore
          engine={engine}
        />
      )}
    </div>
  );
}

export default PlanDiagram;
