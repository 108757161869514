import { TourPages } from '../components/tour/types';

export const isLoggedIn = () => localStorage.getItem('access_token');
export const setLogIn = (token: string) => localStorage.setItem('access_token', token);
export const clearToken = () => localStorage.removeItem('access_token');

export const isNeedToShowFirstTour = (tourPage: TourPages | TourPages[] | null) => {
  if (!tourPage) {
    return false;
  }
  const shownToursString = localStorage.getItem('shown_tours');
  if (!shownToursString) {
    return true;
  }
  const shownTours = JSON.parse(shownToursString);

  if (Array.isArray(tourPage)) {
    const intersection = shownTours.filter((x) => tourPage.includes(x));
    if (!intersection.length) {
      return true;
    }
    return !(intersection.length === tourPage.length);
  }
  return !shownTours.includes(tourPage);
};

export const setTourShown = (tourPage: TourPages) => {
  const shownToursString = localStorage.getItem('shown_tours');
  const shownTours = shownToursString ? JSON.parse(shownToursString) : [];
  if (!shownTours.includes(tourPage)) {
    shownTours.push(tourPage);
    localStorage.setItem('shown_tours', JSON.stringify(shownTours));
  }
};
