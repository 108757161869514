import { call, put, takeEvery } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { COMPONENT_STATES } from '../../../../utils/componentState';
import { errorHandler } from '../../../../utils/fetchUtils';
import { ComponentState, TablePaginationType } from '../../../../utils/types';
import axios from 'axios';
import Urls from '../../../../utils/endpoints';

// dal
export const getOrganizationRecruitings = (
  orgId: string,
  pagination: TablePaginationType,
  filters = {},
) => {
  const params = { ...pagination, ...filters };
  return axios.get(`${Urls.ORGANIZATION_ORGANIZATION(orgId)}/recruitings`, { params });
};

// saga
function* requestStatRecruitings(action) {
  try {
    const { orgId, pagination, filters } = action.payload;
    const { data } = yield call(getOrganizationRecruitings, orgId, pagination, filters);
    yield put(fetchStatRecruitingsSuccess({ data }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchStatRecruitingsFail());
  }
}

export function* watchLoadingStatRecruitings() {
  yield takeEvery(fetchStatRecruitings.type, requestStatRecruitings);
}

export interface StatRecruitingsType {
  componentState: ComponentState;
  recruitings: any[];
  totalElements: number;
  request: boolean;
}

// reducer
const initialState: StatRecruitingsType = {
  componentState: COMPONENT_STATES.LOADING,
  recruitings: [],
  totalElements: 0,
  request: true,
};

const statRecruitingsSlice = createSlice({
  name: 'statRecruitings',
  initialState,
  reducers: {
    fetchStatRecruitings(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
    },
    fetchStatRecruitingsSuccess(state, action) {
      const { data } = action.payload;
      state.recruitings = data.content;
      state.totalElements = data.totalElements;
      state.componentState = COMPONENT_STATES.CONTENT;
    },
    fetchStatRecruitingsFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const {
  fetchStatRecruitings,
  fetchStatRecruitingsSuccess,
  fetchStatRecruitingsFail,
} = statRecruitingsSlice.actions;

export default statRecruitingsSlice.reducer;
