import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { createSlice } from '@reduxjs/toolkit';

import { errorHandler } from '../../../utils/fetchUtils';
import Urls from '../../../utils/endpoints';
import Paths from '../../../utils/route-paths';

// dal
export const passwordReset = (payload) => axios.post(Urls.PASSWORD_RESET_REQUEST, payload);

// saga
function* passwordResetRequest(action) {
  try {
    const { values, history } = action.payload;
    yield call(() => passwordReset(values));
    toast.success('Ссылка на смену пароля отправлена');
    history.push(Paths.LOGIN);
    yield put(passwordResetRequestingSuccess());
  } catch (e) {
    errorHandler(e);
    yield put(passwordResetRequestingFail());
  }
}

export function* watchPasswordResetRequest() {
  yield takeEvery(passwordResetRequesting.type, passwordResetRequest);
}

// reducer
const initialState = {
  request: false,
};

const PasswordResetSlice = createSlice({
  name: 'passwordReset',
  initialState,
  reducers: {
    passwordResetRequesting(state, action) {
      state.request = true;
    },
    passwordResetRequestingSuccess(state) {
      state.request = false;
    },
    passwordResetRequestingFail(state) {
      state.request = false;
    },
  },
});

export const {
  passwordResetRequesting,
  passwordResetRequestingSuccess,
  passwordResetRequestingFail,
} = PasswordResetSlice.actions;

export default PasswordResetSlice.reducer;
