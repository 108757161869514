import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ViewSelectState from '../../../components/select-state/ViewSelectState';
import ApplicationList from './components/ApplicationsList';
import SimplePopover from '../../../components/Popover';
import PageWrapper from '../../../components/Wrapper';
import InfoBlock from '../../../components/InfoBlock';
import UserInfoBlock from '../../../components/UserInfoBlock';
import AcceptedUsers from './components/AcceptedUsers';
import QuotaOverrunDialog from './components/QuotaOverrunDialog';
import TeamRecruitingTipDialog from './components/TeamRecruitingTipDialog';

import { fetchApplications, projectStarting, setQuotaOverrun } from './domain';
import Dates from '../../../utils/dates';

const useStyles = makeStyles(() => ({
  root: {},
  main: {
    width: 1016,
    height: 634,
    display: 'flex',
  },
  contentLeft: {
    minWidth: 420,
    marginRight: 78,
  },
  contentRight: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  blockTitle: {
    marginBottom: 16,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 'auto',
    marginBottom: 28,
    '& button': {
      marginRight: 18,
      width: 256,
    },
  },
}));

function StartProjectPage(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { recruitingProjectData } = useSelector((state: any) => state.recruitingProject);
  const {
    componentState,
    applications,
    acceptedApplications,
    acceptedUsers,
    requesting,
    quotaOverrun,
  } = useSelector((state: any) => state.applications);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [recruitingTipOpen, setRecruitingTipOpen] = useState<boolean>(true);

  useEffect(() => {
    if (recruitingProjectData) {
      dispatch(fetchApplications({ projectId: recruitingProjectData.id }));
    }
  }, [recruitingProjectData, dispatch]);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleStartProject = () => {
    dispatch(
      projectStarting({ acceptedApplications, projectId: recruitingProjectData.id, history }),
    );
    handleClosePopover();
  };

  const handleCloseQuotaOverrunDialog = () => {
    dispatch(setQuotaOverrun(false));
  };

  const handleCancel = () => history.goBack();

  const handleCloseRecruitingTip = () => {
    setRecruitingTipOpen(false);
  };

  const content = () => {
    const { mentor, name, goal, duration, endDate } = recruitingProjectData;
    const supposedProjectEndDate = Dates.addWeeksToStringDate(endDate, duration);

    return (
      <div className={classes.root}>
        <div className={classes.main}>
          {/* заявки */}
          <div className={classes.contentLeft}>
            <Typography className={classes.blockTitle} variant="h4">
              Заявки:
            </Typography>
            <ApplicationList
              applications={applications}
              project={{ id: recruitingProjectData.id, name }}
            />
          </div>

          {/* проект и команда */}
          <div className={classes.contentRight}>
            <Typography className={classes.blockTitle} variant="h4">
              Проект и команда:
            </Typography>
            <InfoBlock label="Название проекта">
              <Typography variant="h3">{name}</Typography>
            </InfoBlock>
            <InfoBlock label="Цель" body={goal} />
            <InfoBlock label="Лидер проекта">
              <UserInfoBlock user={mentor} />
            </InfoBlock>
            <InfoBlock label="Команда проекта">
              <AcceptedUsers users={acceptedUsers} />
            </InfoBlock>
            <InfoBlock label="Ожидаемая дата завершения проекта" body={supposedProjectEndDate} />

            {/*  кнопки */}
            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                disabled={requesting || !acceptedApplications.length}
                onClick={handleOpenPopover}
                size="large"
              >
                Запустить проект
              </Button>
              <Button
                variant="outlined"
                color="primary"
                disabled={requesting}
                onClick={handleCancel}
                size="large"
              >
                Отмена
              </Button>
            </div>
          </div>

          {/* Всплывающее окно подтверждения начала проекта */}
          <SimplePopover
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            mainHandle={handleStartProject}
            mainText="Вы уверены, что хотите начать этот проект?"
          />
          <QuotaOverrunDialog open={quotaOverrun} onClose={handleCloseQuotaOverrunDialog} />
          <TeamRecruitingTipDialog open={recruitingTipOpen} onClose={handleCloseRecruitingTip} />
        </div>
      </div>
    );
  };

  return (
    <PageWrapper header="Запуск проекта">
      <ViewSelectState
        state={componentState}
        content={content}
        requiredData={{ state: recruitingProjectData }}
      />
    </PageWrapper>
  );
}

export default StartProjectPage;
