import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Paths from '../../../utils/route-paths';
import TemplateStatusLabel from './TemplateSatatusLable';
import CommonCardFull from '../../../components/CommonCardFull';
import { Template } from '../../../utils/types';

interface TemplateCardProps {
  template: Template;
  isPersonal?: boolean;
}

function TemplateCardFull(props: TemplateCardProps): JSX.Element {
  const { template, isPersonal = false } = props;
  const history = useHistory();

  const handleMoreInfoAction = useCallback(
    (e) => {
      const { id } = e.currentTarget.dataset;
      history.push({
        pathname: `${Paths.COMMON_IDEAS}/${id}`,
        state: { isPersonal },
      });
    },
    [history, isPersonal],
  );

  return (
    <CommonCardFull
      data={template}
      user={template.owner}
      moreAction={handleMoreInfoAction}
      isPersonal={isPersonal}
    >
      <TemplateStatusLabel status={template.status} organizationOnly={template.organizationOnly} />
    </CommonCardFull>
  );
}

export default TemplateCardFull;
