import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { Notification } from '../../utils/types';
import Urls from '../../utils/endpoints';
import Dates from '../../utils/dates';

import { fetchNotificationRead, goNotificationPath } from './domain/reducer';
import { toggleRightPanelView } from '../globalDomain';

interface NotificationCard {
  notification: Notification;
  index: number;
}

const useStyles = makeStyles(() => ({
  main: {
    padding: 12,
    display: 'flex',
  },
  imgBox: {
    width: '10%',
  },
  img: {
    width: '100%',
  },
  textBox: {
    width: '90%',
    paddingLeft: 8,
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 4,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

function NotificationCard({ notification, index }: NotificationCard): JSX.Element {
  const { title, text, read, data, createdAt, id } = notification;
  const { user } = useSelector((state: any) => state.app);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleNotificationRead = () => {
    if (!read) {
      dispatch(fetchNotificationRead({ index, id }));
    }
  };

  const handleGoPath = () => {
    if (data?.ids.length) {
      dispatch(toggleRightPanelView({ rightPanelName: 'notifications' }));
      dispatch(goNotificationPath({ data, history, userId: user.id }));
    }
  };

  return (
    <div style={{ cursor: read ? 'default' : 'pointer' }} onClick={handleNotificationRead}>
      <div className={classes.main}>
        <div className={classes.imgBox}>
          <img
            className={classes.img}
            src={data.image || Urls.ASSET_IMAGE('logo_aw3bb5')}
            alt="img"
          />
        </div>
        <div className={classes.textBox}>
          <div className={classes.title}>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              style={{ fontWeight: read ? 'normal' : 'bold', width: 305 }}
            >
              {title}
            </Typography>
            <Typography
              style={{ fontWeight: read ? 300 : 'bold', width: 130 }}
              align="right"
              variant="subtitle2"
              color="textSecondary"
            >
              {Dates.dateToLocalDateAndTime(new Date(createdAt))}
            </Typography>
          </div>

          <Typography
            variant="subtitle2"
            style={{
              fontWeight: read ? 'normal' : 'bold',
              whiteSpace: 'break-spaces',
              overflowWrap: 'break-word',
            }}
          >
            {text}
          </Typography>
          {data?.ids?.length && (
            <div className={classes.buttons}>
              <Button color="primary" onClick={handleGoPath}>
                Перейти
              </Button>
            </div>
          )}
        </div>
      </div>
      <Divider />
    </div>
  );
}

export default NotificationCard;
