import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { errorHandler } from '../../../utils/fetchUtils';
import { getOrganization } from '../../organization/common-info/domain/dal';

import {
  fetchWorkspaceProject,
  fetchProjectJoinToken,
  fetchKickMember,
  fetchSendAppToJoinProject,
  fetchWorkspaceProjectSuccess,
  fetchWorkspaceProjectFail,
  fetchProjectJoinTokenSuccess,
  fetchProjectJoinTokenFail,
  fetchKickMemberSuccess,
  fetchKickMemberFail,
  fetchSendAppToJoinProjectSuccess,
  fetchSendAppToJoinProjectFail,
  fetchUpdateProject,
  fetchUpdateProjectSuccess,
  fetchUpdateProjectFail,
} from './reducer';

import {
  getProject,
  getHasApplication,
  sendAppToJoinProject,
  kickProjectMember,
  getProjectJoinToken,
  getApplicationData,
  updateProject,
} from './dal';

// saga
function* projectInfoRequest(action) {
  try {
    const { userId, projectId, fromPush } = action.payload;
    const { data } = yield call(() => getProject(projectId));
    const { data: organization } = yield call(getOrganization, data.organizationId);

    const teamIds = data.team.reduce((ids, member) => [...ids, member.id], []);
    const isProjectMember = data.isMentor || teamIds.includes(userId);

    const payload = {
      data,
      organization,
      hasApplication: undefined,
      applicationsData: undefined,
      isProjectMember,
      fromPush,
    };

    if (!isProjectMember) {
      const {
        data: { hasApplication },
      } = yield call(getHasApplication, projectId);
      payload.hasApplication = hasApplication;
    } else if (data.isMentor) {
      const { data: appData } = yield call(getApplicationData, projectId);
      payload.applicationsData = appData;
    }

    yield put(fetchWorkspaceProjectSuccess(payload));
  } catch (e) {
    errorHandler(e);
    yield put(fetchWorkspaceProjectFail(e.response));
  }
}

function* projectJoinTokenRequest(action) {
  try {
    const { projectId } = action.payload;
    const {
      data: { token },
    } = yield call(getProjectJoinToken, projectId);
    yield put(fetchProjectJoinTokenSuccess({ token }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchProjectJoinTokenFail());
  }
}

function* kickMemberRequest(action) {
  try {
    const { projectId, payload } = action.payload;
    yield call(kickProjectMember, projectId, payload);
    const { data } = yield call(getProject, projectId);
    toast.info('Пользователь исключен из проекта');
    yield put(fetchKickMemberSuccess({ data }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchKickMemberFail());
  }
}

function* sendAppToJoinProjectRequest(action) {
  try {
    const { projectId, message } = action.payload;
    yield call(sendAppToJoinProject, projectId, { message });
    toast.info('Заявка отправлена');
    yield put(fetchSendAppToJoinProjectSuccess());
  } catch (e) {
    errorHandler(e);
    yield put(fetchSendAppToJoinProjectFail());
  }
}

function* updateProjectRequest(action) {
  try {
    const { projectId, values } = action.payload;
    const { data } = yield call(updateProject, projectId, values);
    yield put(fetchUpdateProjectSuccess({ data }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchUpdateProjectFail());
  }
}

export function* watchLoadingRequestingWorkspaceProject() {
  yield takeLatest(fetchWorkspaceProject.type, projectInfoRequest);
  yield takeEvery(fetchProjectJoinToken.type, projectJoinTokenRequest);
  yield takeEvery(fetchKickMember.type, kickMemberRequest);
  yield takeLatest(fetchSendAppToJoinProject.type, sendAppToJoinProjectRequest);
  yield takeLatest(fetchUpdateProject.type, updateProjectRequest);
}
