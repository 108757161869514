import axios from 'axios';
import { put, takeEvery, takeLatest, all, call } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { COMPONENT_STATES } from '../../utils/componentState';
import { errorHandler } from '../../utils/fetchUtils';
import Urls from '../../utils/endpoints';
import { setAvatar, setNweNotificationSettings } from '../globalDomain';
import { canvasToBlob } from '../../utils/utils';
import { EMPTY_USER } from '../../utils/constants';
import UrlsV2 from '../../utils/endpointsV2';

interface User {
  lastName: string;
  firstName: string;
  middleName?: string;
}

const initialUser: User = {
  lastName: '',
  firstName: '',
  middleName: '',
};

// dal
export const getUser = (userId) => axios.get(Urls.USER_PROFILE(userId));
export const updateUser = (userId, payload) => axios.patch(Urls.USER_PROFILE(userId), payload);
export const uploadAvatar = (userId, image, imageName) =>
  axios.post(`${Urls.USER_PROFILE(userId)}/avatar`, image, {
    headers: {
      'Content-Disposition': `attachment;filename=${imageName}`,
    },
  });
export const removeAvatar = (userId) => axios.delete(`${Urls.USER_PROFILE(userId)}/avatar`);
export const updateNotificationSettings = (payload) =>
  axios.patch(UrlsV2.NOTIFICATION_SETTINGS, payload);

// saga
function* getUserRequest(action) {
  try {
    const { userId } = action.payload;
    let values = initialUser;
    if (userId) {
      const { data } = yield call(getUser, userId);
      values = data;
    }
    yield put(fetchUserSuccess(values));
  } catch (e) {
    errorHandler(e);
    yield put(fetchUserFail());
  }
}

function* updateUserRequest(action) {
  try {
    const { userId, values, resetForm } = action.payload;
    const { data } = yield call(updateUser, userId, values);
    toast.success('Данные сохранены');
    resetForm({ values: data });
    yield put(finishUserProcessing());
  } catch (e) {
    errorHandler(e);
    yield put(finishUserProcessing());
  }
}

function* updateAvatarRequest(action) {
  try {
    const { userId, image, imageName } = action.payload;
    const { blobImage, blobImageName } = yield canvasToBlob(image, imageName, 400);
    const {
      data: { url },
    } = yield call(uploadAvatar, userId, blobImage, blobImageName);
    yield put(setAvatar(url));
    yield put(finishUserProcessing());
  } catch (e) {
    errorHandler(e);
    yield put(finishUserProcessing());
  }
}

function* removeAvatarRequest(action) {
  try {
    const { userId } = action.payload;
    yield call(removeAvatar, userId);
    yield put(setAvatar(null));
    toast.success('Аватар успешно удален');
    yield put(finishUserProcessing());
  } catch (e) {
    errorHandler(e);
    yield put(finishUserProcessing());
  }
}

function* updateSettingsRequest(action) {
  try {
    const { newSettings } = action.payload;
    const { data } = yield call(updateNotificationSettings, newSettings);
    yield put(setNweNotificationSettings({ newSettings: data }));
    yield put(finishUpdateNotificationSettings());
  } catch (e) {
    errorHandler(e);
    yield put(finishUpdateNotificationSettings());
  }
}

export function* watchProcessingSelfUserProfile() {
  yield all([
    yield takeEvery(fetchUser.type, getUserRequest),
    yield takeLatest(startUpdatingUser.type, updateUserRequest),
    yield takeLatest(startUpdatingAvatar.type, updateAvatarRequest),
    yield takeLatest(startRemovingAvatar.type, removeAvatarRequest),
    yield takeLatest(fetchUpdateNotificationSettings.type, updateSettingsRequest),
  ]);
}

// reducer
const initialState = {
  componentState: COMPONENT_STATES.LOADING,
  user: EMPTY_USER,
  requesting: false,
  avatarUploading: false,
  menuItem: 0,
};

const organizationUserSlice = createSlice({
  name: 'selfUserProfile',
  initialState,
  reducers: {
    fetchUser(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
    },
    fetchUserSuccess(state, action) {
      state.componentState = COMPONENT_STATES.CONTENT;
      state.user = action.payload;
    },
    fetchUserFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
    startUpdatingUser(state, action) {
      state.requesting = true;
    },
    startUpdatingAvatar(state, action) {
      state.avatarUploading = true;
    },
    startRemovingAvatar(state, action) {
      state.avatarUploading = true;
    },
    finishUserProcessing(state) {
      state.requesting = false;
      state.avatarUploading = false;
    },
    setUserMenuItem(state, action) {
      state.menuItem = action.payload;
    },
    fetchUpdateNotificationSettings(state, action) {
      state.requesting = true;
    },
    finishUpdateNotificationSettings(state) {
      state.requesting = false;
    },
  },
});

export const {
  fetchUser,
  fetchUserSuccess,
  fetchUserFail,
  startUpdatingUser,
  startUpdatingAvatar,
  startRemovingAvatar,
  finishUserProcessing,
  setUserMenuItem,
  fetchUpdateNotificationSettings,
  finishUpdateNotificationSettings,
} = organizationUserSlice.actions;

export default organizationUserSlice.reducer;
