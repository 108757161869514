import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import ColoredAvatar from './ColoredAvatar';

import { makeFullName } from '../utils/utils';
import Dates from '../utils/dates';
import { User } from '../utils/types';

interface UserInfoTooltipProps {
  user: User;
  onClick?: () => void;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    display: 'flex',
    padding: 16,
    paddingRight: 24,
  },
  avatar: {
    marginRight: theme.spacing(3),
    width: 128,
    height: 128,
  },
  textInfoBlock: {
    // width: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  typeText: {
    fontWeight: 600,
    fontSize: 14,
    color: '#78909C',
  },
  nameText: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1.2,
  },
  orgText: {
    fontSize: 20,
    fontWeight: 300,
    lineHeight: 2.8,
  },
  activity: {
    whiteSpace: 'nowrap',
  },
}));

function UserInfoTooltipContent(props: UserInfoTooltipProps) {
  const { user } = props;
  const classes = useStyles();

  return (
    <div className={classes.info}>
      <ColoredAvatar className={classes.avatar} user={user} size="large" />
      <div className={classes.textInfoBlock}>
        <Typography className={classes.nameText}>{makeFullName(user)}</Typography>
        {user?.organization && (
          <Typography className={classes.orgText}>
            {user.organization.shortName.toUpperCase()}
          </Typography>
        )}
        {user?.lastActivity && (
          <Typography className={classes.activity} variant="subtitle2" color="textSecondary">
            Последняя активность {Dates.dateToLocalDateAndTime(new Date(user.lastActivity))}
          </Typography>
        )}
      </div>
    </div>
  );
}

export default UserInfoTooltipContent;
