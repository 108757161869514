import React from 'react';
import { Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import TextFieldWithCounter from '../../../../components/TextFieldWithCounter';
import RemakeCharterButton from '../components/RamakeCharterButton';
import CustomButton from '../../../../components/CustomButton';

import { MAX_SYMBOLS_NUMBER_FOR_LONG_TEXT } from '../../../../utils/constants';
import { fetchUpdateCharter, setCharterState } from '../domain';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: '34px 28px',
    maxWidth: 800,
  },
  radio: {
    display: 'flex',
    marginBottom: 15,
  },
  buttonsBox: {
    marginTop: 10,
  },
  acceptors: {
    marginTop: 30,
  },
}));

function Edit() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    charterData: { text: charter, acceptors, id },
    previousState,
  } = useSelector((state: any) => state.workspaceProjectCharter);
  const {
    projectData: { id: projectId },
  } = useSelector((state: any) => state.workspaceProjectInfo);

  const handleSubmitDraft = ({ charter }) => {
    dispatch(fetchUpdateCharter({ projectId, text: charter, id }));
  };

  const handleReturn = () => {
    dispatch(setCharterState({ current: previousState }));
  };

  return (
    <Formik initialValues={{ charter }} onSubmit={handleSubmitDraft} enableReinitialize>
      {({ values, setFieldValue, isValid, errors, dirty, handleChange }) => (
        <Form>
          <TextFieldWithCounter
            name="charter"
            error={errors.charter}
            value={values.charter}
            onChange={handleChange}
            multiline
            fullWidth
            maxSymbols={MAX_SYMBOLS_NUMBER_FOR_LONG_TEXT}
          />
          {previousState === 'approved' && (
            <Typography variant="subtitle2" color="textSecondary">
              При сохранении изменений потребуется повторное одобрение устава всей командой
            </Typography>
          )}
          {previousState === 'draft' && !!acceptors?.length && (
            <Typography variant="subtitle2" color="textSecondary">
              При сохранении изменений потребуется повторное одобрение устава
            </Typography>
          )}
          <div className={classes.buttonsBox}>
            <CustomButton type="submit" variant="contained" color="primary">
              Сохранить текст
            </CustomButton>
            {previousState !== 'steps' && (
              <CustomButton
                withConfirm={dirty}
                popoverProps={{ secondaryText: 'Все изменения будут утеряны' }}
                style={{ marginLeft: 10 }}
                variant="outlined"
                color="primary"
                onClick={handleReturn}
              >
                Отмена
              </CustomButton>
            )}
            <RemakeCharterButton style={{ marginLeft: 10 }} />
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Edit;
