import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';

import { Copyright } from '../../../components/Copyright';
import { ConfirmSchema } from './validationSchema';
import { passwordResetConfirmRequesting } from './domain';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface FormValues {
  password: string;
  passwordRepeat: string;
}

interface ParamTypes {
  token: string;
  uid: string;
}

function PasswordResetConfirm(): JSX.Element {
  const classes = useStyles();
  const { token, uid } = useParams<ParamTypes>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
  const { request } = useSelector((state: any) => state.passwordResetRequest);

  const initialValues: FormValues = {
    password: '',
    passwordRepeat: '',
  };

  const onSubmit = (values) => {
    values.uid = uid;
    values.token = token;
    dispatch(passwordResetConfirmRequesting({ values, history }));
  };

  const handleClickShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Подтверждение сброса пароля
        </Typography>
        <Formik initialValues={initialValues} validationSchema={ConfirmSchema} onSubmit={onSubmit}>
          {({ values, handleChange, isValid, errors }) => (
            <Form>
              <TextField
                error={!!errors.password}
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Пароль"
                type={isPasswordShown ? 'text' : 'password'}
                id="password"
                value={values.password}
                onChange={handleChange}
                helperText={errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                error={!!errors.passwordRepeat}
                variant="outlined"
                margin="normal"
                fullWidth
                name="passwordRepeat"
                label="Повтор пароля"
                type={isPasswordShown ? 'text' : 'password'}
                id="passwordRepeat"
                value={values.passwordRepeat}
                onChange={handleChange}
                helperText={errors.passwordRepeat}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={request || !isValid}
              >
                Установить новый пароль
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default PasswordResetConfirm;
