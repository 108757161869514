import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleRightPanelView } from '../app/globalDomain';
import { RootState } from '../store/rootReducer';
import { PushNotification } from '../utils/types';
import { makePathFromNotification, makeTextEllipsis } from '../utils/utils';
import { setSelectedTab } from '../app/workspace/domain/reducer';
import { WORKSPACE_TABS } from '../utils/constants';
import { PALETTE } from '../utils/palette';

interface NotificationToast {
  payload: PushNotification;
  history: any;
}

function NotificationToast({ payload: { notification, data }, history }: NotificationToast) {
  const { body, title } = notification;
  const entities = JSON.parse(data.entities);
  const ids = JSON.parse(data.ids);
  const dispatch = useDispatch();
  const { rightPanelsState } = useSelector((state: RootState) => state.app);
  const { path, pathKey } = makePathFromNotification(ids, entities);

  const handlePushClick = () => {
    if (pathKey === 'PROJECT' && entities.includes('MESSAGE')) {
      dispatch(setSelectedTab(WORKSPACE_TABS.chat));
      history.push({ pathname: path, state: { fromPush: true } });
      return;
    }
    if (!rightPanelsState.notifications) {
      dispatch(toggleRightPanelView({ rightPanelName: 'notifications' }));
    }
  };

  return (
    <div style={{ paddingLeft: 8 }} onClick={handlePushClick}>
      <p
        style={{
          color: PALETTE().black,
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-word',
        }}
      >
        {title}
      </p>
      <p
        style={{
          color: PALETTE().black,
          whiteSpace: 'break-spaces',
          wordBreak: 'break-word',
          // maxWidth: '90%',
        }}
      >
        {makeTextEllipsis(180, body)}
      </p>
    </div>
  );
}

export default NotificationToast;
