import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Badge, { BadgeProps } from '@material-ui/core/Badge';

// @ts-ignore
import { FontWeightProperty } from 'csstype';

interface MenuItemProps {
  onClick: () => void;
  Icon: JSX.Element;
  text: string;
  fontWeight?: FontWeightProperty;
  isSelected?: boolean;
  className?: string;
  withBadge?: boolean;
  badgeProps?: BadgeProps;
}

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: 8,
    marginRight: 8,
    width: 'inherit',
    borderRadius: 4,
    paddingLeft: 8,
  },
  icon: {
    minWidth: 48,
  },
}));

function MenuItem(props: MenuItemProps): JSX.Element {
  const {
    onClick,
    Icon,
    text,
    fontWeight = 'normal',
    isSelected = false,
    className = '',
    withBadge = false,
    badgeProps = {},
  } = props;
  const classes = useStyles();

  return (
    <ListItem
      className={clsx(classes.root, className)}
      selected={isSelected}
      button
      onClick={onClick}
    >
      <ListItemIcon
        classes={{
          root: classes.icon,
        }}
      >
        {withBadge ? (
          <Badge color="secondary" badgeContent=" " {...badgeProps}>
            {Icon}
          </Badge>
        ) : (
          Icon
        )}
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ style: { fontWeight, whiteSpace: 'nowrap', marginTop: 1 } }}
        primary={text}
      />
    </ListItem>
  );
}

export default MenuItem;
