import { all } from 'redux-saga/effects';

// auth
import { watchLogin } from '../app/auth/domain';
import { watchPasswordResetRequest } from '../app/auth/password-reset/domain';
import { watchPasswordResetConfirmRequest } from '../app/auth/confirm-reset/domain';
import { watchRegisterNewUserRequest } from '../app/auth/registrations/domain';

// ideas
import { watchProjectTemplate } from '../app/project-templates/view-n-form/domain/saga';
import { watchStartRecruiting } from '../app/project-templates/start-recruiting-form/domain';
import { watchLoadingPersonalProjectTemplatesList } from '../app/project-templates/personal-templates-list/domain';
import { watchLoadingCommonProjectTemplateList } from '../app/project-templates/list/domain';

// recruiting
import { watchLoadingRecruitingProjectsLis } from '../app/recruiting/list/domain';
import { watchAddingNewApplication } from '../app/recruiting/view/application-form/domain';
import { watchLoadingPersonalRecruitingList } from '../app/recruiting/personal-list/domain';
import { watchLoadingRecruitingProject } from '../app/recruiting/view/domain';
import { watchProjectStarting } from '../app/recruiting/project-start/domain';
import { watchRecruitingTemplateEditing } from '../app/project-templates/start-recruiting-form/edit-template/domain';

// project
import { watchLoadingRequestingWorkspaceProject } from '../app/workspace/domain/saga';
import { watchLoadingPersonalProjectsList } from '../app/projects/list/domain';
import { watchChatWorking } from '../app/workspace/chat/domain';
import { watchLoadingKanbanBoard, watchWorkingWithCards } from '../app/workspace/kanban/domain';
import { watchLoadingCard } from '../app/workspace/kanban/components/kanban-card/domain';
import { watchRequestingProjectChapter } from '../app/workspace/project-charter/domain';
import { watchPlan } from '../app/workspace/plan/domain';
import { watchFetchingGraphTooltipTask } from '../app/workspace/plan/components/graph-task-tooltip/domain';
import { watchNewUserJoinToProject } from '../app/workspace/join-new-member/domain';
import { watchProjectAppProcessing } from '../app/workspace/project-settings/domain/saga';

// organization
import { watchProcessingOrganizationUser } from '../app/organization/user-forms/domain';
import { watchLoadingOrganizationUserList } from '../app/organization/user-list/domain';
import { watchLoadingOrganizationInfo } from '../app/organization/common-info/domain/saga';
import { watchLoadingStatIdeas } from '../app/organization/statistics/ideas/domain';
import { watchLoadingStatProjects } from '../app/organization/statistics/projects/domain';
import { watchLoadingStatRecruitings } from '../app/organization/statistics/recruitings/domain';
import { watchLoadingUserStatIdeas } from '../app/organization/user-statistics/ideas/domain';
import { watchLoadingUserStatRecruitings } from '../app/organization/user-statistics/recruitings/domain';
import { watchLoadingUserStatProjects } from '../app/organization/user-statistics/projects/domain';

// other
import { watchGetSelfUser } from '../app/globalDomain';
import { watchProcessingSelfUserProfile } from '../app/user/domain';
import { watchDashboardRequest } from '../app/dashboard/domain';
import { watchLoadingComments } from '../components/comments/domain';
import { watchFileUploading } from '../components/files-uploader/domain';
import { watchLoadingNotificationsList } from '../app/notifications/domain/saga';

// public
import { watchProcessingPublicUserProfile } from '../app/user/public-profile/domain';
import { watchLoadingPublicUserIdeas } from '../app/user/public-profile/ideas/domain';
import { watchLoadingPublicUserProjects } from '../app/user/public-profile/projects/domain';
import { watchLoadingPublicUserRecruitings } from '../app/user/public-profile/recruiting/domain';
import { watchPublicOrganization } from '../app/organization/public-page/domain';
import { watchLoadingOrgPublicIdeas } from '../app/organization/public-page/ideas/domain';
import { watchLoadingOrgPublicProjects } from '../app/organization/public-page/projects/domain';
import { watchLoadingOrgPublicRecruiting } from '../app/organization/public-page/recruiting/domain';
import { watchProjectOffice } from '../app/workspace/project-office/domain';

export default function* rootSaga() {
  yield all([
    watchLogin(),
    watchGetSelfUser(),
    watchStartRecruiting(),
    watchLoadingRecruitingProjectsLis(),
    watchLoadingPersonalProjectTemplatesList(),
    watchLoadingCommonProjectTemplateList(),
    watchAddingNewApplication(),
    watchLoadingPersonalProjectsList(),
    watchLoadingRecruitingProject(),
    watchPasswordResetRequest(),
    watchPasswordResetConfirmRequest(),
    watchLoadingRequestingWorkspaceProject(),
    watchChatWorking(),
    watchLoadingKanbanBoard(),
    watchWorkingWithCards(),
    watchLoadingCard(),
    watchProcessingOrganizationUser(),
    watchLoadingOrganizationUserList(),
    watchProcessingSelfUserProfile(),
    watchDashboardRequest(),
    watchProjectTemplate(),
    watchLoadingPersonalRecruitingList(),
    watchProjectStarting(),
    watchRecruitingTemplateEditing(),
    watchRegisterNewUserRequest(),
    watchLoadingComments(),
    watchRequestingProjectChapter(),
    watchPlan(),
    watchFetchingGraphTooltipTask(),
    watchLoadingOrganizationInfo(),
    watchLoadingStatIdeas(),
    watchLoadingStatProjects(),
    watchLoadingStatRecruitings(),
    watchLoadingUserStatIdeas(),
    watchLoadingUserStatRecruitings(),
    watchLoadingUserStatProjects(),
    watchProcessingPublicUserProfile(),
    watchLoadingPublicUserIdeas(),
    watchLoadingPublicUserProjects(),
    watchLoadingPublicUserRecruitings(),
    watchPublicOrganization(),
    watchLoadingOrgPublicIdeas(),
    watchLoadingOrgPublicProjects(),
    watchLoadingOrgPublicRecruiting(),
    watchNewUserJoinToProject(),
    watchProjectAppProcessing(),
    watchFileUploading(),
    watchLoadingNotificationsList(),
    watchProjectOffice(),
  ]);
}
