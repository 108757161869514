import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import ViewSelectState from '../../../../components/select-state/ViewSelectState';
import PageWrapper from '../../../../components/Wrapper';
import RecruitingCardFull from '../../../recruiting/components/RecruitingFullCard';
import TilesGridWrapper from '../../../../components/TilesGridWrapper';

import { fetchOrgPublicRecruiting, OrgPublicRecruitingState } from './domain';
import { PublicOrganizationState } from '../domain';

interface OrgPublicRecruiting {}

function OrgPublicRecruiting(props: OrgPublicRecruiting) {
  const dispatch = useDispatch();
  // @ts-ignore
  const { id } = useParams();
  const { componentState, recruiting, isLastPage }: OrgPublicRecruitingState = useSelector(
    (state: any) => state.orgPublicRecruiting,
  );

  const { organization }: PublicOrganizationState = useSelector(
    (state: any) => state.publicOrganization,
  );

  useEffect(() => {
    dispatch(fetchOrgPublicRecruiting({ isFirstLoading: true, orgId: id }));
  }, [dispatch, id]);

  const handleLoadMore = () => {
    dispatch(fetchOrgPublicRecruiting({ orgId: id }));
  };

  const content = () => {
    return (
      <div>
        <TilesGridWrapper isLastPage={isLastPage} loadMore={handleLoadMore}>
          {recruiting.map((r: any) => (
            <Grid key={r.id} item>
              <RecruitingCardFull recruiting={r} isPersonal={true} />
            </Grid>
          ))}
        </TilesGridWrapper>
      </div>
    );
  };

  return (
    <PageWrapper header={`Рекрутинг организации ${organization.shortName}`}>
      <ViewSelectState state={componentState} content={content} />
    </PageWrapper>
  );
}

export default OrgPublicRecruiting;
