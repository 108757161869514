import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import PageWrapper from '../../../components/Wrapper';
import ViewSelectState from '../../../components/select-state/ViewSelectState';
import CustomButton from '../../../components/CustomButton';
import ProfileAvatar from '../../../components/ProfileAvatar';
import ImageLoader from '../../../components/ImageLoader';
import OrgHeadInfoBlock from './compoments/OrgHeadInfoBlock';
import TextFieldWithCounter from '../../../components/TextFieldWithCounter';
import RichTextEditor from '../../../components/RichTextEditor';

import {
  fetchUpdateOrgInfo,
  fetchChangeAvatar,
  fetchInfo,
  OrganizationCommonInfo,
} from './domain/reducer';
import Paths from '../../../utils/route-paths';
import TemplateSchema from './validationSchema';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    maxWidth: 800,
  },
  avatarAndButtons: {
    paddingRight: 34,
  },
  avatar: {
    paddingBottom: 40,
  },
  form: {
    minWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  fields: {
    display: 'flex',
  },
  siteField: {
    width: 280,
    marginRight: 20,
  },
  addressField: {
    width: 500,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  orgHead: {
    marginBottom: 24,
  },
}));

function OrgInfoForm(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const {
    organization,
    organization: { siteUrl, description, location, avatar },
    componentState,
    request,
    avatarRequest,
  }: OrganizationCommonInfo = useSelector((state: any) => state.organizationCommonInfo);

  const [openImageUpload, setOpenImageUpload] = useState(false);

  useEffect(() => {
    dispatch(fetchInfo({ orgId: id }));
  }, [dispatch, id]);

  const handleSubmit = (values) => {
    dispatch(fetchUpdateOrgInfo({ orgId: id, values }));
  };

  const handleUploadImage = () => {
    setOpenImageUpload(true);
  };

  const handleCancelUploadImage = () => {
    setOpenImageUpload(false);
  };

  const handleUploadAvatar = (croppedImage, imageName) => {
    dispatch(fetchChangeAvatar({ orgId: id, image: croppedImage, imageName }));
  };

  const handleRemoveAvatar = () => {
    dispatch(fetchChangeAvatar({ orgId: id }));
  };

  const handleCancelEditing = () => {
    history.push(Paths.ORGANIZATION);
  };

  const content = () => (
    <Formik
      validationSchema={TemplateSchema}
      initialValues={{ siteUrl, description, location }}
      validateOnBlur
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, handleChange, isValid, errors, dirty }) => {
        return (
          <div className={classes.root}>
            <div className={classes.avatarAndButtons}>
              <ProfileAvatar
                className={classes.avatar}
                variant="rounded"
                avatarUrl={avatar}
                upload={handleUploadImage}
                remove={handleRemoveAvatar}
                avatarUploading={avatarRequest}
                square
                orgName={organization.name}
              />
              <div className={classes.buttons}>
                <CustomButton
                  type="submit"
                  form="orgInfoForm"
                  variant="contained"
                  color="primary"
                  size="large"
                  length="short"
                  disabled={request || !(dirty && isValid)}
                >
                  Сохранить
                </CustomButton>
                <CustomButton
                  style={{ marginTop: 20 }}
                  variant="outlined"
                  color="primary"
                  size="large"
                  length="short"
                  disabled={request}
                  onClick={handleCancelEditing}
                  withConfirm={dirty}
                  popoverProps={{ secondaryText: 'Внесенные изменения не сохранятся' }}
                >
                  Отмена
                </CustomButton>
              </div>
            </div>

            <div>
              <Typography variant="h2">
                {`${organization.name} (${organization.shortName})`}
              </Typography>
              <OrgHeadInfoBlock className={classes.orgHead} user={organization.owner} />
              <Form id="orgInfoForm">
                <div className={classes.form}>
                  <div className={classes.fields}>
                    <TextFieldWithCounter
                      className={classes.siteField}
                      name="siteUrl"
                      label="Сайт"
                      fullWidth
                      value={values.siteUrl}
                      onChange={handleChange}
                      error={errors.siteUrl}
                      multiline={false}
                      maxSymbols={100}
                    />
                    <TextFieldWithCounter
                      className={classes.addressField}
                      name="location"
                      label="Адрес"
                      fullWidth
                      value={values.location}
                      onChange={handleChange}
                      error={errors.location}
                      multiline={false}
                      maxSymbols={100}
                    />
                  </div>
                  <RichTextEditor
                    label="Описание"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    error={errors.description}
                    options={['inline', 'list', 'textAlign', 'history']}
                  />
                </div>
              </Form>
            </div>
            <ImageLoader
              onSave={handleUploadAvatar}
              onClose={handleCancelUploadImage}
              open={openImageUpload}
            />
          </div>
        );
      }}
    </Formik>
  );

  return (
    <PageWrapper header={'Редактирование организации'}>
      <ViewSelectState state={componentState} content={content} />
    </PageWrapper>
  );
}

export default OrgInfoForm;
