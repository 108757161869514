import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';
import { COMPONENT_STATES } from '../../../utils/componentState';
import { errorHandler } from '../../../utils/fetchUtils';
import Urls from '../../../utils/endpoints';

// dal
const getProjectResourcesRequest = (projectId) => axios.get(Urls.PROJECT_RESOURCES(projectId));
const patchProjectResourceRequest = (projectId, resourceId, payload) =>
  axios.patch(Urls.PROJECT_RESOURCE_ITEM(projectId, resourceId), payload);
const createProjectResourceRequest = (projectId, payload) =>
  axios.post(Urls.PROJECT_RESOURCES(projectId), payload);
const deleteProjectResourceRequest = (projectId, resourceId) =>
  axios.delete(Urls.PROJECT_RESOURCE_ITEM(projectId, resourceId));

const getProjectRiskRequest = (projectId) => axios.get(Urls.PROJECT_RISKS(projectId));
const patchProjectRiskRequest = (projectId, riskId, payload) =>
  axios.patch(Urls.PROJECT_RISKS_ITEM(projectId, riskId), payload);
const createProjectRiskRequest = (projectId, payload) =>
  axios.post(Urls.PROJECT_RISKS(projectId), payload);
const deleteProjectRiskRequest = (projectId, riskId) =>
  axios.delete(Urls.PROJECT_RISKS_ITEM(projectId, riskId));

const getProjectStakeholderRequest = (projectId) => axios.get(Urls.PROJECT_STAKEHOLDERS(projectId));
const patchProjectStakeholderRequest = (projectId, stakeholderId, payload) =>
  axios.patch(Urls.PROJECT_STAKEHOLDERS_ITEM(projectId, stakeholderId), payload);
const createProjectStakeholderRequest = (projectId, payload) =>
  axios.post(Urls.PROJECT_STAKEHOLDERS(projectId), payload);
const deleteProjectStakeholderRequest = (projectId, stakeholderId) =>
  axios.delete(Urls.PROJECT_STAKEHOLDERS_ITEM(projectId, stakeholderId));

// saga
function* getProjectOfficeDataSaga(action) {
  try {
    const { projectId } = action.payload;

    const resourcesResponse = yield call(getProjectResourcesRequest, projectId);
    const risksResponse = yield call(getProjectRiskRequest, projectId);
    const stakeholdersResponse = yield call(getProjectStakeholderRequest, projectId);

    const data = {
      resources: resourcesResponse.data,
      risks: risksResponse.data,
      stakeholders: stakeholdersResponse.data,
    };

    yield put(fetchProjectOfficeDataSuccess(data));
  } catch (e) {
    errorHandler(e);
    yield put(fetchProjectOfficeDataFail());
  }
}

function* createProjectResourcesSaga(action) {
  try {
    const { projectId, resources } = action.payload;
    yield all(resources.map((resource) => call(createProjectResourceRequest, projectId, resource)));

    yield put(fetchProjectOfficeData({ projectId }));
    toast.success('Данные сохранены');
  } catch (e) {
    errorHandler(e);
    toast.error('Не удалось сохранить данные. Попробуйте повторить');
  }
}

function* createProjectRiskSaga(action) {
  try {
    const { projectId, risks } = action.payload;
    yield all(risks.map((risk) => call(createProjectRiskRequest, projectId, risk)));

    yield put(fetchProjectOfficeData({ projectId }));
    toast.success('Данные сохранены');
  } catch (e) {
    errorHandler(e);
    toast.error('Не удалось сохранить данные. Попробуйте повторить');
  }
}

function* createProjectStakeholderSaga(action) {
  try {
    const { projectId, stakeholders } = action.payload;
    yield all(
      stakeholders.map((stakeholder) =>
        call(createProjectStakeholderRequest, projectId, stakeholder),
      ),
    );

    yield put(fetchProjectOfficeData({ projectId }));
    toast.success('Данные сохранены');
  } catch (e) {
    errorHandler(e);
    toast.error('Не удалось сохранить данные. Попробуйте повторить');
  }
}

function* updateProjectResourceSaga(action) {
  try {
    const { projectId, resourceId, resource } = action.payload;
    yield call(patchProjectResourceRequest, projectId, resourceId, resource);

    yield put(fetchProjectOfficeData({ projectId }));
    toast.success('Данные сохранены');
  } catch (e) {
    errorHandler(e);
    toast.error('Не удалось обновить данные. Попробуйте повторить');
  }
}

function* updateProjectRiskSaga(action) {
  try {
    const { projectId, riskId, risk } = action.payload;
    yield call(patchProjectRiskRequest, projectId, riskId, risk);

    yield put(fetchProjectOfficeData({ projectId }));
    toast.success('Данные сохранены');
  } catch (e) {
    errorHandler(e);
    toast.error('Не удалось обновить данные. Попробуйте повторить');
  }
}

function* updateProjectStakeholderSaga(action) {
  try {
    const { projectId, stakeholderId, stakeholder } = action.payload;
    yield call(patchProjectStakeholderRequest, projectId, stakeholderId, stakeholder);

    yield put(fetchProjectOfficeData({ projectId }));
    toast.success('Данные сохранены');
  } catch (e) {
    errorHandler(e);
    toast.error('Не удалось обновить данные. Попробуйте повторить');
  }
}

function* deleteProjectResourceSaga(action) {
  try {
    const { projectId, resourceId } = action.payload;
    yield call(deleteProjectResourceRequest, projectId, resourceId);

    yield put(fetchProjectOfficeData({ projectId }));
    toast.success('Данные удалены успешно');
  } catch (e) {
    errorHandler(e);
    yield put(fetchProjectOfficeDataFail());
  }
}

function* deleteProjectRiskSaga(action) {
  try {
    const { projectId, riskId } = action.payload;
    yield call(deleteProjectRiskRequest, projectId, riskId);

    yield put(fetchProjectOfficeData({ projectId }));
    toast.success('Данные удалены успешно');
  } catch (e) {
    errorHandler(e);
    yield put(fetchProjectOfficeDataFail());
  }
}

function* deleteProjectStakeholderSaga(action) {
  try {
    const { projectId, stakeholderId } = action.payload;
    yield call(deleteProjectStakeholderRequest, projectId, stakeholderId);

    yield put(fetchProjectOfficeData({ projectId }));
    toast.success('Данные удалены успешно');
  } catch (e) {
    errorHandler(e);
    yield put(fetchProjectOfficeDataFail());
  }
}

export function* watchProjectOffice() {
  yield takeLatest(fetchProjectOfficeData.type, getProjectOfficeDataSaga);
  yield takeLatest(createProjectResource.type, createProjectResourcesSaga);
  yield takeLatest(updateProjectResource.type, updateProjectResourceSaga);
  yield takeLatest(deleteProjectResource.type, deleteProjectResourceSaga);
  yield takeLatest(createProjectRisk.type, createProjectRiskSaga);
  yield takeLatest(updateProjectRisk.type, updateProjectRiskSaga);
  yield takeLatest(deleteProjectRisk.type, deleteProjectRiskSaga);
  yield takeLatest(createProjectStakeholder.type, createProjectStakeholderSaga);
  yield takeLatest(updateProjectStakeholder.type, updateProjectStakeholderSaga);
  yield takeLatest(deleteProjectStakeholder.type, deleteProjectStakeholderSaga);
}

// reducer
const initialState = {
  componentState: COMPONENT_STATES.LOADING,
  resources: [],
  risks: [],
  stakeholders: [],
};

const projectOfficeSlice = createSlice({
  name: 'projectOffice',
  initialState,
  reducers: {
    fetchProjectOfficeData(state, action) {},
    fetchProjectOfficeDataSuccess(state, action) {
      state.componentState = COMPONENT_STATES.CONTENT;
      state.resources = action.payload.resources;
      state.risks = action.payload.risks;
      state.stakeholders = action.payload.stakeholders;
    },
    fetchProjectOfficeDataFail(state) {},
    createProjectResource(state, action) {},
    updateProjectResource(state, action) {},
    deleteProjectResource(state, action) {},
    createProjectRisk(state, action) {},
    updateProjectRisk(state, action) {},
    deleteProjectRisk(state, action) {},
    createProjectStakeholder(state, action) {},
    updateProjectStakeholder(state, action) {},
    deleteProjectStakeholder(state, action) {},
  },
});

export const {
  fetchProjectOfficeData,
  fetchProjectOfficeDataSuccess,
  fetchProjectOfficeDataFail,
  createProjectResource,
  updateProjectResource,
  deleteProjectResource,
  createProjectRisk,
  updateProjectRisk,
  deleteProjectRisk,
  createProjectStakeholder,
  updateProjectStakeholder,
  deleteProjectStakeholder,
} = projectOfficeSlice.actions;

export default projectOfficeSlice.reducer;
