import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import ruLocale from 'date-fns/locale/ru';
import DateFnsUtils from '@date-io/date-fns';
import clsx from 'clsx';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import CustomButton from '../../../../../components/CustomButton';

import StandardDialog from '../../../../../components/StandardDialog';
import TextFieldWithCounter from '../../../../../components/TextFieldWithCounter';
import RichTextEditor from '../../../../../components/RichTextEditor';
import ColoredAvatar from '../../../../../components/ColoredAvatar';
import Comments from '../../../../../components/comments';
import FieldWrapper from '../../../../../components/FieldWrapper';
import FileUploader from '../../../../../components/files-uploader/FileLoader';
import AttachmentsView from '../../../../../components/AttachmentsView';

import CardSchema from './cardSchema';
import { COMPONENT_STATES } from '../../../../../utils/componentState';
import Dates from '../../../../../utils/dates';
import { User } from '../../../../../utils/types';
import { TOURS_CLASS_NAMES } from '../../../../../components/tour/tourSteps';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Urls from '../../../../../utils/endpoints';
import { useCardStyles } from './style';
import { RootState } from '../../../../../store/rootReducer';
import { makeTextEllipsis } from '../../../../../utils/utils';

const { kanbanCard } = TOURS_CLASS_NAMES;

export interface CardProps {
  onClose: () => void;
  onSave: (card) => void;
  onDelete: (cardId: string) => void;
  canDelete: boolean;
}

function Card(props: CardProps) {
  const classes = useCardStyles();
  const { onSave, onClose, onDelete, canDelete } = props;
  const { componentState, openedCard: card, currentColumn, isCardOpen } = useSelector(
    (state: RootState) => state.kanbanCard,
  );
  const { projectData } = useSelector((state: any) => state.workspaceProjectInfo);
  const [isFileUploaderOpened, setIsFileUploaderOpened] = useState(false);
  const commentsConfig = {
    url: `${Urls.KANBAN_BOARD_CARDS(projectData.id, card.id)}/comments`,
    pageSize: 20,
  };

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [attachmentWarning, setAttachmentWarning] = useState('');
  const teamWithMentor = [projectData.mentor, ...projectData.team];

  const onSubmit = (values) => {
    const { attachments, ...finalValues } = values;
    if (values.taskEndDate !== null && values.taskEndDate !== card.taskEndDate) {
      finalValues.taskEndDate = Dates.toDateString(values.taskEndDate);
    }
    finalValues.attachmentIds = values.attachments.map((att) => att.id);

    let columnId = currentColumn.id;
    if (columnId.length === 0) {
      columnId = card.columnId;
    }
    onSave({ ...finalValues, columnId });
    setAttachmentWarning('');
  };

  const handleRemoveCard = () => {
    onDelete(card.id);
  };

  const handleRemoveAttachment = (setFieldValue, field) => (ids, index) => {
    const newValue = [...field.value];
    newValue.splice(index, 1);
    setFieldValue(field.name, newValue);
    if (!isFormDirty) {
      setAttachmentWarning('Вложение удалится после сохранения изменений');
    }
  };

  const handleCloseCard = () => {
    setAttachmentWarning('');
    onClose();
  };

  const handleBackdropClick = () => {
    if (isFormDirty) {
      toast.info('Вы изменили данные карточки. Нажмите "Cохранить" или "Отмена", чтобы выйти.');
    } else {
      handleCloseCard();
    }
  };

  const onCancelPopoverProps = {
    secondaryText: 'Измененные данные не сохранятся',
  };

  return (
    <StandardDialog
      className={kanbanCard.center}
      withConfirmOnClose={isFormDirty}
      onClosePopoverProps={onCancelPopoverProps}
      open={isCardOpen}
      onClose={handleCloseCard}
      showTour
      onBackdropClick={handleBackdropClick}
    >
      <div className={classes.title}>
        <Typography
          style={{ whiteSpace: 'break-spaces', overflowWrap: 'break-word', maxWidth: '80%' }}
          align="center"
          variant="h4"
        >
          {card.id ? makeTextEllipsis(100, card.name) : 'Новая карточка'}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          в колонке &quot;{card.columnName}&quot;
        </Typography>
      </div>
      <Formik
        initialValues={card}
        validationSchema={CardSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, handleChange, isValid, errors, dirty, setFieldValue }) => {
          if (isFormDirty !== dirty) {
            setIsFormDirty(dirty);
          }
          return (
            <Form className={classes.formRoot}>
              {/* форма */}
              <div>
                <FieldWrapper className={kanbanCard.needTodoField} label={'Что нужно сделать?'}>
                  <TextFieldWithCounter
                    name="name"
                    error={errors.name}
                    value={values.name}
                    onChange={handleChange}
                    margin="none"
                    size="small"
                    fullWidth
                    placeholder="Напишите, что нужно сделать"
                  />
                </FieldWrapper>
                <div className={classes.dateAndExecutorBox}>
                  <FieldWrapper
                    className={clsx(classes.executorWrapper, kanbanCard.responsiblePerson)}
                    label={'Ответственный за исполнение'}
                  >
                    <div className={classes.executorField}>
                      <FormControl fullWidth error={!!errors.executorId} variant="outlined">
                        <Field name="executorId">
                          {({ field }) => (
                            <Select
                              id="executorId-select"
                              value={values.executorId}
                              onChange={field.onChange(field.name)}
                              classes={{ outlined: classes.executorSelectBox }}
                            >
                              {teamWithMentor.map((member: User) => (
                                <MenuItem key={member.id} value={member.id}>
                                  <div className={classes.executorItem}>
                                    <ColoredAvatar
                                      className={classes.executorAvatar}
                                      user={member}
                                      size="small"
                                    />
                                    <div style={{ overflow: 'hidden' }}>
                                      <Typography style={{ marginLeft: 10 }}>
                                        {member.firstName} {member.lastName}
                                      </Typography>
                                    </div>
                                  </div>
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </Field>
                        <FormHelperText>{errors.executorId}</FormHelperText>
                      </FormControl>
                      <div className={classes.executorClearIcon}>
                        <IconButton size="small" onClick={() => setFieldValue('executorId', '')}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>
                  </FieldWrapper>
                  <FieldWrapper className={kanbanCard.taskEndDate} label={'Дата завершения'}>
                    <div className={classes.dateBox}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                        <DatePicker
                          error={!!errors.taskEndDate}
                          name="taskEndDate"
                          disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format="dd.MM.yyyy"
                          id="date-picker-inline"
                          value={values.taskEndDate}
                          onChange={(v) => setFieldValue('taskEndDate', v)}
                          helperText={errors.taskEndDate}
                          size="small"
                          disablePast
                          autoOk
                        />
                      </MuiPickersUtilsProvider>
                      <div className={classes.dateClearIcon}>
                        <IconButton size="small" onClick={() => setFieldValue('taskEndDate', null)}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>
                  </FieldWrapper>
                </div>
                <FieldWrapper className={kanbanCard.expectedResult} label={'Ожидаемый результат'}>
                  <TextFieldWithCounter
                    name="expectedResult"
                    error={errors.expectedResult}
                    value={values.expectedResult}
                    onChange={handleChange}
                    margin="none"
                    size="small"
                    fullWidth
                    placeholder="Опишите ожидаемый результат выполнения задачи"
                  />
                </FieldWrapper>
                <FieldWrapper className={kanbanCard.description} label="Описание">
                  <RichTextEditor
                    value={values.description}
                    name="description"
                    onChange={handleChange}
                    error={errors.description}
                  />
                </FieldWrapper>
                <FieldWrapper label="Вложения">
                  <Field name="attachments">
                    {({ field }) => (
                      <div>
                        <AttachmentsView
                          files={field.value}
                          onRemoveFile={handleRemoveAttachment(setFieldValue, field)}
                        />
                        <Typography component="div" color="secondary" variant="caption">
                          {attachmentWarning}
                        </Typography>
                        <Button
                          className={clsx(classes.addAttachmentBtn, kanbanCard.addAttachment)}
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => setIsFileUploaderOpened(true)}
                        >
                          Добавить вложение
                        </Button>
                        {/* Диалог загрузки вложения */}
                        <FileUploader
                          open={isFileUploaderOpened}
                          onSave={(data) => setFieldValue(field.name, [...field.value, ...data])}
                          onClose={() => setIsFileUploaderOpened(false)}
                          filesLimit={5}
                        />
                      </div>
                    )}
                  </Field>
                </FieldWrapper>

                <Divider className={classes.divider} />
              </div>

              {/* кнопки */}
              <div className={classes.buttons}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={!isValid || componentState !== COMPONENT_STATES.CONTENT || !dirty}
                >
                  {card.id ? 'Сохранить' : 'Создать'}
                </Button>
                {!!card.id && (canDelete || card.planStepId == null) && (
                  <CustomButton
                    withConfirm
                    variant="outlined"
                    color="secondary"
                    popoverProps={{ mainText: 'Удалить карточку задания?' }}
                    onClick={handleRemoveCard}
                  >
                    Удалить
                  </CustomButton>
                )}
                <CustomButton
                  withConfirm={dirty}
                  variant="outlined"
                  color="primary"
                  popoverProps={onCancelPopoverProps}
                  onClick={handleCloseCard}
                >
                  {isFormDirty ? 'Отмена' : 'Закрыть'}
                </CustomButton>
                {!!card.id && !canDelete && card.planStepId != null && (
                  <Typography component="div" color="primary" variant="caption">
                    {'Карточку можно удалить только из плана работ'}
                  </Typography>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>

      {/* комментарии */}
      {!!card.id && (
        <Comments
          className={clsx(classes.comments, kanbanCard.comments)}
          commentsConfig={commentsConfig}
        />
      )}
    </StandardDialog>
  );
}

export default Card;
