import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import Badge from '@material-ui/core/Badge';

import ViewSelectState from '../../../components/select-state/ViewSelectState';
import CommonProjectView from '../../../components/CommonProjectView';
import ProjectInfoActionBlock from './ProjectInfoActionBlock';
import ProjectStatusLabel from '../../projects/components/ProjectStatusLabel';
import { PALETTE } from '../../../utils/palette';
import Paths from '../../../utils/route-paths';
import { setTourPage } from '../../globalDomain';
import { TOUR_PAGES } from '../../../components/tour/tourSteps';

interface ProjectInfo {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'relative',
  },
  setting: {
    position: 'absolute',
    top: -8,
    left: 502,
    color: PALETTE().gray,
  },
}));

function ProjectInfo({ className }: ProjectInfo) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    projectInfoComponentState,
    projectData,
    organization,
    applicationsData,
    isProjectMember,
  } = useSelector((state: any) => state.workspaceProjectInfo);

  useEffect(() => {
    if (isProjectMember) {
      dispatch(setTourPage({ pages: [TOUR_PAGES.projectInfo] }));
    }
    return function cleanUp() {
      dispatch(setTourPage({ pages: [] }));
    };
  }, [dispatch, isProjectMember]);

  const content = () => {
    return (
      <CommonProjectView
        data={projectData}
        organization={organization}
        organizationLabel="Место выполнения проекта"
        statusComponent={
          <ProjectStatusLabel
            status={projectData.status}
            organizationOnly={projectData.organizationOnly}
            startDate={projectData.startDate}
          />
        }
      >
        <ProjectInfoActionBlock />
        {projectData.isMentor && (
          <IconButton
            className={classes.setting}
            onClick={() => history.push(`${Paths.PROJECT_SETTINGS}/${projectData.id}`)}
          >
            <Badge badgeContent={applicationsData.totalElements} color="secondary">
              <SettingsIcon />
            </Badge>
          </IconButton>
        )}
      </CommonProjectView>
    );
  };

  return (
    <div className={clsx(classes.paper, className)}>
      <ViewSelectState state={projectInfoComponentState} content={content} />
    </div>
  );
}

export default ProjectInfo;
