import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import Typography from '@material-ui/core/Typography';

interface TableErrorDataLoadingProps {
  columnsNumber?: number;
}

function TableErrorDataLoading({ columnsNumber = 1 }: TableErrorDataLoadingProps) {
  return (
    <>
      <TableRow component="div">
        <TableCell colSpan={columnsNumber} component="div" align="center">
          <NotInterestedIcon color="secondary" />
          <Typography color="textSecondary">Ошибка загрузки данных</Typography>
        </TableCell>
      </TableRow>
    </>
  );
}

export default TableErrorDataLoading;
