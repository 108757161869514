import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import ColoredAvatar from '../../../../components/ColoredAvatar';

import { PALETTE } from '../../../../utils/palette';
import { SmallKanbanCard } from '../../../../utils/types';
import Dates from '../../../../utils/dates';
import { COMPONENT_STATES } from '../../../../utils/componentState';
import { makeTextEllipsis } from '../../../../utils/utils';

interface KanbanSmallCardProps {
  card: SmallKanbanCard;
  index: number;
  onClickCard: () => void;
  className?: string;
}

interface StylesProps {
  isFullHeight: boolean;
}

const useStyles = makeStyles(() => ({
  avatar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  circularProgress: {
    position: 'absolute',
    top: (props: StylesProps) => (props.isFullHeight ? 30 : 10),
    left: 116,
  },
  cardName: {
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
  },
  endDate: {
    fontWeight: 'bold',
  },
  planStep: {
    marginTop: 4,
  },
}));

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 8,
  marginBottom: 8,
  cursor: 'pointer',
  borderRadius: 8,
  position: 'relative',

  // change background colour if dragging
  background: isDragging ? PALETTE().white : PALETTE().white,

  // styles we need to apply on draggables
  ...draggableStyle,
});

function KanbanSmallCard(props: KanbanSmallCardProps) {
  const { card, index, onClickCard, className = '' } = props;
  const classes = useStyles({ isFullHeight: !!card.taskEndDate || !!card.executorId });
  const { projectData } = useSelector((state: any) => state.workspaceProjectInfo);
  const { componentState, openedCardId } = useSelector((state: any) => state.kanbanCard);

  const teamData = projectData?.team.reduce((team, member) => ({ ...team, [member.id]: member }), {
    [projectData.mentor.id]: projectData.mentor,
  });

  const handleClickCard = () => {
    onClickCard();
  };

  return (
    <Draggable draggableId={card.id} index={index}>
      {(provided, snapshot) => (
        <Paper
          className={className}
          elevation={3}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          onClick={handleClickCard}
        >
          <div>
            <Typography className={classes.cardName} variant="subtitle2" gutterBottom>
              {makeTextEllipsis(100, card.name)}
            </Typography>
            {card.planStepId != null && (
              <Tooltip
                className={classes.planStep}
                style={{ color: PALETTE().gray }}
                title="Задача создана из плана работ"
                arrow
                placement="right-end"
              >
                <AccountTreeOutlinedIcon />
              </Tooltip>
            )}
          </div>
          <div className={classes.avatar}>
            <Typography className={classes.endDate} variant="caption" color="textSecondary">
              {card.taskEndDate && Dates.dateStringToLocalString(card.taskEndDate)}
            </Typography>
            <div>
              {card.executorId && teamData[card.executorId] && (
                <ColoredAvatar user={teamData[card.executorId]} />
              )}
            </div>
          </div>
          {componentState === COMPONENT_STATES.LOADING && openedCardId === card.id && (
            <CircularProgress size={20} className={classes.circularProgress} />
          )}
        </Paper>
      )}
    </Draggable>
  );
}

export default KanbanSmallCard;
