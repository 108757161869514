import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { ChangeSet, EditingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableEditColumn,
  TableEditRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import { Tooltip, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  createProjectResource,
  createProjectRisk,
  createProjectStakeholder,
  deleteProjectResource,
  deleteProjectRisk,
  deleteProjectStakeholder,
  fetchProjectOfficeData,
  updateProjectResource,
  updateProjectRisk,
  updateProjectStakeholder,
} from './domain';
import ViewSelectState from '../../../components/select-state/ViewSelectState';

interface ProjectOfficeProps {
  projectId: string;
  className?: string;
}

const resourceColumnsSet = [
  { title: 'Ресурс', name: 'name' },
  { title: 'Цена за единицу', name: 'price' },
  { title: 'Количество', name: 'quantity' },
  { title: 'Стоимость', name: 'cost' },
];

const riskColumnsSet = [
  { title: 'Риск', name: 'name' },
  { title: 'Причины', name: 'reason' },
  { title: 'Последствие', name: 'consequence' },
  { title: 'Вероятность', name: 'probability' },
  { title: 'Владелец риска', name: 'owner' },
  { title: 'План А', name: 'planA' },
  { title: 'План Б', name: 'planB' },
  { title: 'Переодичность мониторинга', name: 'frequencyMonitoring' },
];

const stakeholderColumnsSet = [
  { title: 'Наименование стороны', name: 'name' },
  { title: 'Представитель стороны', name: 'representative' },
  { title: 'Проектная роль', name: 'role' },
  { title: 'Контактные данные', name: 'contacts' },
  { title: 'Влияние на проект', name: 'influenceLevel' },
  { title: 'Интерес к проекту', name: 'interestLevel' },
  { title: 'Примечания', name: 'comments' },
];

const editColumnMessages = {
  addCommand: 'Добавить',
  editCommand: 'Изменить',
  deleteCommand: 'Удалить',
  commitCommand: 'Сохранить',
  cancelCommand: 'Отменить',
};

const tableMessages = {
  noData: 'Нет данных. Нажмите на "+", чтобы добавить новую строку',
};

const getRowId = (row) => row.id;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginLeft: 24,
    marginRight: 24,
    paddingBottom: 60,
  },
  header: {
    marginTop: 32,
    marginBottom: 16,
  },
}));

function ProjectOffice({ projectId, className }: ProjectOfficeProps) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [resourceColumns] = useState(resourceColumnsSet);
  const [riskColumns] = useState(riskColumnsSet);
  const [stakeholderColumns] = useState(stakeholderColumnsSet);

  const { componentState, resources, risks, stakeholders } = useSelector(
    (state: any) => state.workspaceProjectOffice,
  );

  const commitResourcesChanges = ({ added, changed, deleted }: ChangeSet) => {
    if (added) {
      dispatch(createProjectResource({ projectId, resources: added }));
    }
    if (changed) {
      const resourceId = Object.keys(changed)[0];
      const resource = {
        ...changed[resourceId],
      };

      dispatch(updateProjectResource({ projectId, resourceId, resource }));
    }
    if (deleted) {
      dispatch(deleteProjectResource({ projectId, resourceId: deleted[0] }));
    }
  };

  const commitRisksChanges = ({ added, changed, deleted }: ChangeSet) => {
    if (added) {
      dispatch(createProjectRisk({ projectId, risks: added }));
    }
    if (changed) {
      const riskId = Object.keys(changed)[0];
      const risk = {
        ...changed[riskId],
      };

      dispatch(updateProjectRisk({ projectId, riskId, risk }));
    }
    if (deleted) {
      dispatch(deleteProjectRisk({ projectId, riskId: deleted[0] }));
    }
  };

  const commitStakeholderChanges = ({ added, changed, deleted }: ChangeSet) => {
    if (added) {
      dispatch(createProjectStakeholder({ projectId, stakeholders: added }));
    }
    if (changed) {
      const stakeholderId = Object.keys(changed)[0];
      const stakeholder = {
        ...changed[stakeholderId],
      };

      dispatch(updateProjectStakeholder({ projectId, stakeholderId, stakeholder }));
    }
    if (deleted) {
      dispatch(deleteProjectStakeholder({ projectId, stakeholderId: deleted[0] }));
    }
  };

  const Command = ({ id, onExecute, text }) => {
    switch (id) {
      case 'add': {
        return (
          <Tooltip arrow title="Добавить строку">
            <IconButton size="small" onClick={onExecute}>
              <AddIcon color="primary" />
            </IconButton>
          </Tooltip>
        );
      }

      case 'edit':
        return (
          <Tooltip arrow title="Редактировать строку">
            <IconButton size="small" onClick={onExecute}>
              <EditIcon color="primary" />
            </IconButton>
          </Tooltip>
        );

      case 'delete':
        return (
          <Tooltip arrow title="Удалить строку">
            <IconButton size="small" onClick={onExecute}>
              <ClearIcon color="primary" />
            </IconButton>
          </Tooltip>
        );

      case 'commit':
        return (
          <Tooltip arrow title="Сохранить">
            <IconButton size="small" onClick={onExecute}>
              <SaveIcon color="primary" />
            </IconButton>
          </Tooltip>
        );

      case 'cancel':
        return (
          <Tooltip arrow title="Отменить">
            <IconButton size="small" onClick={onExecute}>
              <CancelIcon color="primary" />
            </IconButton>
          </Tooltip>
        );

      default:
        return <Button onClick={onExecute}>{text}</Button>;
    }
  };

  const [tableColumnExtensions] = useState([
    { columnName: 'name', wordWrapEnabled: true },
    { columnName: 'price', wordWrapEnabled: true },
    { columnName: 'quantity', wordWrapEnabled: true },
    { columnName: 'cost', wordWrapEnabled: true },
    { columnName: 'reason', wordWrapEnabled: true },
    { columnName: 'consequence', wordWrapEnabled: true },
    { columnName: 'probability', wordWrapEnabled: true },
    { columnName: 'impact', wordWrapEnabled: true },
    { columnName: 'owner', wordWrapEnabled: true },
    { columnName: 'trigger', wordWrapEnabled: true },
    { columnName: 'plan_a', wordWrapEnabled: true },
    { columnName: 'plan_b', wordWrapEnabled: true },
    { columnName: 'frequency_monitoring', wordWrapEnabled: true },
    { columnName: 'representative', wordWrapEnabled: true },
    { columnName: 'role', wordWrapEnabled: true },
    { columnName: 'contacts', wordWrapEnabled: true },
    { columnName: 'influence_level', wordWrapEnabled: true },
    { columnName: 'interest_level', wordWrapEnabled: true },
    { columnName: 'comments', wordWrapEnabled: true },
  ]);

  useEffect(() => {
    dispatch(fetchProjectOfficeData({ projectId }));
  }, [dispatch, projectId]);

  const content = () => {
    return (
      <>
        <Typography className={classes.header} variant="h2">
          Ресурсы
        </Typography>
        <Paper style={{ width: '100%' }}>
          {/*
          // @ts-ignore */}
          <Grid rows={resources} columns={resourceColumns} getRowId={getRowId}>
            <EditingState onCommitChanges={commitResourcesChanges} />
            <Table messages={tableMessages} columnExtensions={tableColumnExtensions} />
            <TableHeaderRow />
            <TableEditRow />
            <TableEditColumn
              commandComponent={Command}
              messages={editColumnMessages}
              showAddCommand
              showEditCommand
              showDeleteCommand
            />
          </Grid>
        </Paper>

        <Typography className={classes.header} variant="h2">
          Риски
        </Typography>
        <Paper style={{ width: '100%' }}>
          {/*
          // @ts-ignore */}
          <Grid rows={risks} columns={riskColumns} getRowId={getRowId}>
            <EditingState onCommitChanges={commitRisksChanges} />
            <Table messages={tableMessages} columnExtensions={tableColumnExtensions} />
            <TableHeaderRow />
            <TableEditRow />
            <TableEditColumn
              commandComponent={Command}
              messages={editColumnMessages}
              showAddCommand
              showEditCommand
              showDeleteCommand
            />
          </Grid>
        </Paper>

        <Typography className={classes.header} variant="h2">
          Заинтересованные стороны
        </Typography>
        <Paper style={{ width: '100%' }}>
          {/*
          // @ts-ignore */}
          <Grid rows={stakeholders} columns={stakeholderColumns} getRowId={getRowId}>
            <EditingState onCommitChanges={commitStakeholderChanges} />
            <Table messages={tableMessages} columnExtensions={tableColumnExtensions} />
            <TableHeaderRow />
            <TableEditRow />
            <TableEditColumn
              commandComponent={Command}
              messages={editColumnMessages}
              showAddCommand
              showEditCommand
              showDeleteCommand
            />
          </Grid>
        </Paper>
      </>
    );
  };

  return (
    <div className={classes.root}>
      <ViewSelectState state={componentState} content={content} />
    </div>
  );
}

export default ProjectOffice;
