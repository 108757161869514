import * as Yup from 'yup';

export const maxAnswerSymbols = 1023;

const ApplicationSchema = Yup.object().shape({
  answers: Yup.lazy((obj) =>
    Yup.object(
      Object.keys(obj).reduce(
        (schema, key) => ({
          ...schema,
          [key]: Yup.string()
            .trim()
            .max(maxAnswerSymbols, `Не более ${maxAnswerSymbols} знаков`)
            .required('Обязательное поле'),
        }),
        {},
      ),
    ),
  ),
});

export default ApplicationSchema;
