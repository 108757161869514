import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import TemplateCardFull from '../components/TemplateFullCard';
import TilesGridWrapper from '../../../components/TilesGridWrapper';

import { Template } from '../../../utils/types';
import { fetchCommonProjectTemplatesList } from './domain';

function CommonTemplatesTiles() {
  const dispatch = useDispatch();
  const { commonTemplates, isLastPage } = useSelector(
    (state: any) => state.commonProjectTemplateList,
  );

  const handleLoadMore = () => {
    dispatch(fetchCommonProjectTemplatesList({ isFirstLoading: false }));
  };

  return (
    <TilesGridWrapper isLastPage={isLastPage} loadMore={handleLoadMore} title="Все идеи проектов">
      {commonTemplates.map((pt: Template) => (
        <Grid key={pt.id} item>
          <TemplateCardFull template={pt} />
        </Grid>
      ))}
    </TilesGridWrapper>
  );
}

export default CommonTemplatesTiles;
