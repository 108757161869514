import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ColoredAvatar from './ColoredAvatar';

import { makeShortName, makeTextEllipsis } from '../utils/utils';
import Urls from '../utils/endpoints';
import { DEFAULT_IMAGE } from '../utils/constants';
import { CUSTOM_SHADOWS } from '../utils/palette';
import { User } from '../utils/types';

interface CommonCardFullProps {
  data: {
    images: any[];
    id: string;
    name: string;
    goal: string;
  };
  user: User;
  moreAction: (e: any) => void;
  isPersonal: boolean;
  isMentor?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: 320,
    height: 420,
    cursor: 'pointer',
    borderRadius: 16,
  },
  cardGreenShadow: {
    boxShadow: CUSTOM_SHADOWS.green.elevation10,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  content1: {
    paddingBottom: 10,
  },
  name: {
    fontWeight: 500,
    marginBottom: 5,
  },
  divider: {
    marginTop: 'auto',
  },
  mentor: {
    paddingTop: 0,
    paddingBottom: 10,
  },
  content2: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

function CommonCardFull(props: PropsWithChildren<CommonCardFullProps>) {
  const { data, user, children, moreAction, isPersonal, isMentor = false } = props;
  const classes = useStyles();
  const { images, id, name, goal } = data;

  const imageUrl = images.length ? images[0].url : Urls.ASSET_IMAGE(DEFAULT_IMAGE);

  return (
    <Card
      className={clsx(classes.card, isMentor && classes.cardGreenShadow)}
      data-id={id}
      onClick={moreAction}
      elevation={10}
    >
      <CardMedia className={classes.media} image={imageUrl} title="Project" />
      <CardContent className={classes.content1}>
        <Typography className={classes.name} variant="body1">
          {makeTextEllipsis(54, name)}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {makeTextEllipsis(95, goal)}
        </Typography>
      </CardContent>
      <div className={classes.divider} />
      {!isPersonal && (
        <CardHeader
          className={classes.mentor}
          avatar={<ColoredAvatar user={user} />}
          title={makeShortName(user)}
          subheader={user.organization?.shortName.toUpperCase()}
        />
      )}
      <CardContent className={classes.content2}>{children}</CardContent>
    </Card>
  );
}

export default CommonCardFull;
