import * as Yup from 'yup';
import { MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT } from '../../../utils/constants';

const dateNow = new Date();
const required = 'Обязательное поле';
export const teamSize = 999;

const StartProjectSchema = Yup.object().shape({
  teamSize: Yup.number()
    .typeError('Должно быть число')
    .integer('Должно быть целое число')
    .min(1, 'Не меньше 1 человека')
    .max(teamSize, `Не больше ${teamSize} человек`)
    .required(required),
  endDate: Yup.date()
    .min(dateNow, 'Не раньше завтрашней даты')
    .required(required),
  questions: Yup.array().of(
    Yup.string()
      .trim()
      .min(8, 'Не менее 8 знаков')
      .max(
        MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT,
        `Не более ${MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT} знаков`,
      )
      .required('Вопрос не может быть пустым'),
  ),
});

export default StartProjectSchema;
