import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextFieldWithCounter from '../../../components/TextFieldWithCounter';
import SelectField from '../../../components/SelectField';
import ViewSelectState from '../../../components/select-state/ViewSelectState';
import PageWrapper from '../../../components/Wrapper';
import MainPaper from '../../../components/MainPaper';

import UserSchema from './validationSchemaForAdd';
import { fetchUser, startAddingUser } from './domain';
import Urls from '../../../utils/endpoints';
import { USER_TYPE_TITLES, USER_TYPES } from '../../../utils/constants';
import { TOURS_CLASS_NAMES, TOUR_PAGES } from '../../../components/tour/tourSteps';
import { setTourPage } from '../../globalDomain';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: 280,
    height: 550,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(5),
    width: 354,
    '& button': {
      width: 160,
      height: 48,
    },
  },
  avatar: {
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(10),
    width: 250,
    height: 250,
  },
  image: {
    width: 400,
  },
  cancelButton: {
    color: theme.palette.text.disabled,
  },
  formControl: {
    margin: theme.spacing(1),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  text: {
    fontSize: 26,
    fontWeight: 300,
    textAlign: 'center',
    lineHeight: 1.2,
    width: 400,
    marginTop: 40,
  },
}));

function OrganizationUser() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { componentState, user } = useSelector((state: any) => state.organizationUser);
  const { user: appUser } = useSelector((state: any) => state.app);

  useEffect(() => {
    dispatch(fetchUser({}));
    dispatch(setTourPage({ pages: [TOUR_PAGES.addUserPage] }));
    return function cleanUp() {
      dispatch(setTourPage({ pages: [] }));
    };
  }, [dispatch]);

  const handleSubmit = (values) => {
    dispatch(startAddingUser({ orgId: appUser.organization.id, values, history }));
  };

  const content = () => (
    <div className={classes.root}>
      <Formik
        validationSchema={UserSchema}
        initialValues={user}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, handleChange, handleBlur, isValid, errors, dirty }) => {
          return (
            <div>
              <Form className={classes.form}>
                <SelectField
                  name="type"
                  error={errors.type}
                  value={values.type}
                  items={[
                    { value: USER_TYPES.STUDENT, name: USER_TYPE_TITLES.STUDENT },
                    { value: USER_TYPES.EMPLOYEE, name: USER_TYPE_TITLES.EMPLOYEE },
                  ]}
                  label="Тип пользователя"
                />
                <TextFieldWithCounter
                  className={TOURS_CLASS_NAMES.addUserPage.emailField}
                  margin="none"
                  fullWidth
                  name="email"
                  error={errors.email}
                  value={values.email}
                  onChange={handleChange}
                  label="E-mail"
                />
                <TextFieldWithCounter
                  margin="none"
                  fullWidth
                  name="lastName"
                  error={errors.lastName}
                  value={values.lastName}
                  onChange={handleChange}
                  label="Фамилия"
                />
                <TextFieldWithCounter
                  margin="none"
                  fullWidth
                  name="firstName"
                  error={errors.firstName}
                  value={values.firstName}
                  onChange={handleChange}
                  label="Имя"
                />
                <TextFieldWithCounter
                  margin="none"
                  fullWidth
                  name="middleName"
                  error={errors.middleName}
                  value={values.middleName}
                  onChange={handleChange}
                  label="Отчество"
                />
                <div className={classes.buttons}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={!isValid}
                    onClick={() => history.goBack()}
                  >
                    Отмена
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!(isValid && dirty)}
                  >
                    Добавить
                  </Button>
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
      <div className={classes.content}>
        <img className={classes.image} src={Urls.ASSET_IMAGE('users_qqbuzq')} alt="users" />
        <Typography variant="body2" className={classes.text}>
          Новый пользователь получит доступ к созданию и участию в проектах в рамках организации{' '}
        </Typography>
      </div>
    </div>
  );

  return (
    <PageWrapper header={'Добавить пользователя'}>
      <MainPaper>
        <ViewSelectState state={componentState} content={content} />
      </MainPaper>
    </PageWrapper>
  );
}

export default OrganizationUser;
