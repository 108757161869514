import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import RecruitingStatusLabel from '../../../recruiting/components/RecruitingStatusLabel';
import RecruitingApplicationsView from '../../../recruiting/components/RecruitingApplicationsView';
import SmallCardWrapper from '../../components/SmallCardWrapper';

import Paths from '../../../../utils/route-paths';
import { Recruiting } from '../../../../utils/types';
import { CUSTOM_SHADOWS } from '../../../../utils/palette';

interface RecruitingCardCardProps {
  data: Recruiting;
  isPersonal?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  cardGreenShadow: {
    boxShadow: CUSTOM_SHADOWS.green.elevation10,
  },
  divider: {
    marginTop: 'auto',
  },
  applications: {
    marginBottom: 8,
  },
}));

function RecruitingCard(props: RecruitingCardCardProps): JSX.Element {
  const { data, isPersonal } = props;
  const history = useHistory();

  const classes = useStyles();

  const handleCardClick = () =>
    history.push({
      pathname: `${Paths.COMMON_RECRUITING_PROJECTS}/${data.id}`,
      state: { isPersonal },
    });

  return (
    <SmallCardWrapper
      className={clsx(classes.root, data.isMentor && classes.cardGreenShadow)}
      title={data.name}
      text={data.goal}
      onClick={handleCardClick}
    >
      <div className={classes.divider} />
      {data?.applications && (
        <RecruitingApplicationsView
          className={classes.applications}
          applications={data.applications}
          isMentor={data.isMentor}
        />
      )}
      <RecruitingStatusLabel endDate={data.endDate} status={data.status} />
    </SmallCardWrapper>
  );
}

export default RecruitingCard;
