import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import { IDEA_STATUSES_TITLES, IDEA_STATUSES } from '../../../utils/constants';
import { PALETTE, PROJECT_TEMPLATE_STATUSES_BACK_PALETTE } from '../../../utils/palette';
import { TOURS_CLASS_NAMES } from '../../../components/tour/tourSteps';

interface TemplateStatusLabelProps {
  status: string;
  organizationOnly?: boolean;
  className?: string;
}

interface StylesProps {
  backgroundColor: string;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  label: {
    width: 120,
    height: 24,
    color: '#fff',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  status: {
    backgroundColor: (props: StylesProps) => props.backgroundColor,
    marginRight: 8,
  },
  organization: {
    backgroundColor: PALETTE().greenBlue,
  },
}));

function TemplateStatusLabel({
  status,
  organizationOnly,
  className,
}: TemplateStatusLabelProps): JSX.Element {
  const styleProps = { backgroundColor: PROJECT_TEMPLATE_STATUSES_BACK_PALETTE[status] };
  const classes = useStyles(styleProps);

  return (
    <div className={clsx(classes.root, className)}>
      {!(status === IDEA_STATUSES.OPEN && organizationOnly) && (
        <div
          className={clsx(
            classes.label,
            classes.status,
            TOURS_CLASS_NAMES.ideaViewDraft.templateStatusLabel,
          )}
        >
          <Typography id="template-status-label" variant="caption">
            {IDEA_STATUSES_TITLES[status]}
          </Typography>
        </div>
      )}
      {organizationOnly && (
        <div className={clsx(classes.label, classes.organization)}>
          <Typography variant="caption">ОРГАНИЗАЦИЯ</Typography>
        </div>
      )}
    </div>
  );
}

export default TemplateStatusLabel;
