/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import WidgetBot from '@widgetbot/react-embed';
import Typography from '@material-ui/core/Typography';
import PageWrapper from '../../components/Wrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 1200,
    height: 680,
    display: 'flex',
  },
  contentLeft: {
    minWidth: 420,
  },
  contentRight: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginLeft: 16,
    maxWidth: 280,
  },
}));

function Discord() {
  const classes = useStyles();

  return (
    <PageWrapper header="Cообщество EDUTEAM" dontShowGoBack>
      <div className={classes.root}>
        <div className={classes.contentLeft}>
          <WidgetBot
            server="571836304619470870"
            channel="571836305051353090"
            defer={false}
            height={680}
            width={900}
          />
        </div>

        <div className={classes.contentRight}>
          <Typography variant="h6">Как пользоваться чатом:</Typography>
          <Typography variant="body1">
            1. Нажмите на кнопку <b>Login</b> в левом нижнем углу чата
          </Typography>
          <Typography style={{ marginTop: '.5rem' }} variant="body1">
            2. Вам будет предложено зарегистрироваться или авторизоваться в системе Discord
          </Typography>
          <Typography style={{ marginTop: '.5rem' }} variant="body1">
            3. После успешной авторизации, вы можете выбирать каналы для общения (слева) и
            отправлять сообщения
          </Typography>
          <Typography style={{ marginTop: '2rem' }} variant="body2">
            <a href="https://discord.com/" target="_blank" rel="noopener noreferrer">
              Установите Discord на компьютер или смартфон, чтобы быть всегда на связи с сообществом
              EDUTEAM!
            </a>
          </Typography>
          <Typography style={{ marginTop: '2rem' }} variant="body2">
            <a href="https://discord.gg/gk3TPBD2E9" target="_blank" rel="noopener noreferrer">
              Кликните сюда, чтобы присоединиться к серверу сообщества EDUTEAM
            </a>
          </Typography>
        </div>
      </div>
    </PageWrapper>
  );
}

export default Discord;
