import { put, takeEvery, all, call } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { COMPONENT_STATES } from '../../../../../utils/componentState';
import { errorHandler } from '../../../../../utils/fetchUtils';
import { getCard } from '../../../kanban/components/kanban-card/domain';

// saga
function* getTaskRequest(action) {
  try {
    const { projectId, cardId } = action.payload;
    const { data } = yield call(getCard, projectId, cardId);
    yield put(fetchTaskSuccess({ data }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchTaskFail());
  }
}

export function* watchFetchingGraphTooltipTask() {
  yield all([yield takeEvery(fetchTask.type, getTaskRequest)]);
}

// reducer
const initialState = {
  componentState: COMPONENT_STATES.LOADING,
  task: {
    name: '',
    taskEndDate: null,
    executorId: '',
    expectedResult: '',
  },
};

const graphTaskTooltipSlice = createSlice({
  name: 'graphTaskTooltip',
  initialState,
  reducers: {
    fetchTask(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
    },
    fetchTaskSuccess(state, action) {
      state.componentState = COMPONENT_STATES.CONTENT;
      state.task = action.payload.data;
    },
    fetchTaskFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const { fetchTask, fetchTaskSuccess, fetchTaskFail } = graphTaskTooltipSlice.actions;

export default graphTaskTooltipSlice.reducer;
