import { call, put, takeEvery } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { COMPONENT_STATES } from '../../../../utils/componentState';
import { errorHandler } from '../../../../utils/fetchUtils';
import { ComponentState, TablePaginationType } from '../../../../utils/types';
import axios from 'axios';
import Urls from '../../../../utils/endpoints';

// dal
const getUserProjects = (userId: string, pagination: TablePaginationType, filters) => {
  const params = { ...pagination, ...filters };
  return axios.get(Urls.PERSONAL_PROJECTS_LIST(userId), { params });
};

// saga
function* requestUserStatProjects(action) {
  try {
    const { userId, pagination, filters } = action.payload;
    const { data } = yield call(getUserProjects, userId, pagination, filters);
    yield put(fetchUserStatProjectsSuccess({ data }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchUserStatProjectsFail());
  }
}

export function* watchLoadingUserStatProjects() {
  yield takeEvery(fetchUserStatProjects.type, requestUserStatProjects);
}

export interface UserStatProjectsType {
  componentState: ComponentState;
  projects: any[];
  totalElements: number;
  request: boolean;
}

// reducer
const initialState: UserStatProjectsType = {
  componentState: COMPONENT_STATES.LOADING,
  projects: [],
  totalElements: 0,
  request: true,
};

const userStatProjectsSlice = createSlice({
  name: 'userStatProjects',
  initialState,
  reducers: {
    fetchUserStatProjects(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
    },
    fetchUserStatProjectsSuccess(state, action) {
      const { data } = action.payload;
      if (!data.content.length) {
        state.componentState = COMPONENT_STATES.EMPTY;
      } else {
        state.projects = data.content;
        state.totalElements = data.totalElements;
        state.componentState = COMPONENT_STATES.CONTENT;
      }
    },
    fetchUserStatProjectsFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const {
  fetchUserStatProjects,
  fetchUserStatProjectsSuccess,
  fetchUserStatProjectsFail,
} = userStatProjectsSlice.actions;

export default userStatProjectsSlice.reducer;
