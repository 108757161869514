import React, { useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import Chat from './Chat';
import ChatMembers from './ChatMembers';

import { initConnection } from './domain';
import { PALETTE } from '../../../utils/palette';

interface ChatRoot {
  projectId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  teamPaper: {
    padding: 12,
  },
  mentorBlock: {
    display: 'flex',
  },
  mentorIcon: {
    marginLeft: 12,
    color: PALETTE().gray,
  },
  user: {
    marginBottom: 20,
  },
}));

function ChatRoot({ projectId }: ChatRoot) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { projectId: chatProjectId } = useSelector((state: any) => state.chatInfo);

  useEffect(() => {
    if (projectId === chatProjectId) {
      // не соединяемся если чат уже открытого проекта
      return;
    }
    dispatch(initConnection({ projectId }));
  }, [dispatch, projectId, chatProjectId]);

  return (
    <div className={classes.root}>
      <Chat />
      <ChatMembers />
    </div>
  );
}

export default ChatRoot;
