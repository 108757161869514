import axios from 'axios';
import addDays from 'date-fns/addDays';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { call, put, takeEvery, all, takeLatest } from 'redux-saga/effects';

import Urls from '../../../utils/endpoints';
import { COMPONENT_STATES } from '../../../utils/componentState';
import { errorHandler } from '../../../utils/fetchUtils';
import Dates from '../../../utils/dates';
import Paths from '../../../utils/route-paths';

// dal
export const getDefaultQuestions = () => axios.get(Urls.QUESTIONS);
export const startRecruiting = (payload) => axios.post(Urls.COMMON_RECRUITING, payload);
export const getQuota = (id) => axios.get(`${Urls.ROOTS.organizations}/${id}/quota`);

// saga
function* defaultQuestionsRequest(action) {
  try {
    const { orgId } = action.payload;
    const { data } = yield call(() => getDefaultQuestions());
    let quotaData;
    if (orgId) {
      const quota = yield call(getQuota, orgId);
      quotaData = quota.data;
    }
    const questions = data.sort((a, b) => a.number - b.number).map((q) => q.text);
    yield put(fetchDefaultQuestionsSuccess({ questions, quotaData }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchDefaultQuestionsFail());
  }
}

function* startRecruitingRequest(action) {
  const {
    values,
    onClose,
    info: { mentorId, templateId },
    history,
  } = action.payload;
  try {
    const newRecruiting = { ...values, mentorId, templateId };
    newRecruiting.endDate = Dates.toDateString(values.endDate);
    const { data } = yield call(startRecruiting, newRecruiting);
    onClose();
    toast.success('Рекрутинг начат');
    yield put(finishRecruitingStart({ values }));
    history.push({
      pathname: `${Paths.COMMON_RECRUITING_PROJECTS}/${data.id}`,
      state: { isPersonal: true },
    });
  } catch (e) {
    errorHandler(e);
    yield put(finishRecruitingStart({ values }));
  }
}

export function* watchStartRecruiting() {
  yield all([
    yield takeEvery(fetchDefaultQuestions.type, defaultQuestionsRequest),
    yield takeLatest(recruitingStart.type, startRecruitingRequest),
  ]);
}

// reducer
const initialState = {
  componentState: COMPONENT_STATES.LOADING,
  formData: {
    teamSize: 1,
    endDate: addDays(new Date(), 7),
    questions: [] as any[],
    organizationOnly: true,
  },
  quotaData: {
    totalProjects: 0,
    quota: 0,
  },
  requesting: false,
};

const defaultQuestionsSlice = createSlice({
  name: 'defaultQuestions',
  initialState,
  reducers: {
    fetchDefaultQuestions(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
    },
    fetchDefaultQuestionsSuccess(state, action) {
      const { questions, quotaData } = action.payload;
      state.componentState = COMPONENT_STATES.CONTENT;
      state.formData.questions = questions;
      if (quotaData) {
        state.quotaData = quotaData;
      }
    },
    fetchDefaultQuestionsFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
    recruitingStart(state, action) {
      state.requesting = true;
    },
    finishRecruitingStart(state, action) {
      const { values } = action.payload;
      state.formData = values;
      state.requesting = false;
    },
  },
});

export const {
  fetchDefaultQuestions,
  fetchDefaultQuestionsSuccess,
  fetchDefaultQuestionsFail,
  recruitingStart,
  finishRecruitingStart,
} = defaultQuestionsSlice.actions;

export default defaultQuestionsSlice.reducer;
