import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';

import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import ClearIcon from '@material-ui/icons/Clear';
import CustomIconButton from './CustomIconButton';
import { OuterPopoverProps } from './Popover';
import pulseIcon from './icons/PulseIcon';
import { setTourStart } from '../app/globalDomain';

interface StandardDialogProps extends DialogProps {
  open: boolean;
  onClose: () => void;
  className?: any;
  withConfirmOnClose?: boolean;
  onClosePopoverProps?: OuterPopoverProps;
  showTour?: boolean;
}

const TourIcon = pulseIcon();

const useStyles = makeStyles((theme) => ({
  root: {
    width: 846,
    maxHeight: '100vh',
    paddingTop: 26,
    paddingLeft: 48,
    display: 'flex',
    flexDirection: 'column',
  },
  closeIcon: {
    position: 'absolute',
    right: 2,
    top: 2,
  },
  tourIconBtn: {
    position: 'absolute',
    left: 8,
    top: 8,
  },
}));

function StandardDialog(props: PropsWithChildren<StandardDialogProps>) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    withConfirmOnClose,
    onClosePopoverProps,
    open,
    onClose,
    children,
    className,
    showTour = false,
    ...otherProps
  } = props;

  const handleRunTour = () => {
    dispatch(setTourStart());
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" disableBackdropClick {...otherProps}>
      <div style={{ overflow: 'auto' }} className={clsx(classes.root, className)}>
        {children}
      </div>
      {showTour && (
        <CustomIconButton size="small" className={classes.tourIconBtn} onClick={handleRunTour}>
          <TourIcon />
        </CustomIconButton>
      )}
      <CustomIconButton
        withConfirm={withConfirmOnClose}
        className={classes.closeIcon}
        onClick={onClose}
        popoverProps={onClosePopoverProps}
      >
        <ClearIcon />
      </CustomIconButton>
    </Dialog>
  );
}

export default StandardDialog;
