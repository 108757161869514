/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable camelcase */
import stc from 'string-to-color';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { slugify } from 'transliteration';
import { toast } from 'react-toastify';
import { DEFAULT_IMAGE, LOCALE_DIC, LocalDicKeys, EVENTS_STORAGE } from './constants';
import Urls from './endpoints';
import Paths from './route-paths';
import { NotificationEntities } from './types';

export interface HumanName {
  firstName?: string;
  lastName?: string;
  middleName?: string;
}

export const makeFullName = (nameObj: HumanName) => {
  const { firstName = '', lastName = '', middleName } = nameObj;
  return middleName ? `${lastName} ${firstName} ${middleName}` : `${firstName} ${lastName}`;
};

export const makeNameWithInitials = (nameObj: HumanName) => {
  const { firstName = '', lastName = '', middleName } = nameObj;
  return middleName
    ? `${lastName} ${firstName[0]}. ${middleName[0]}.`
    : `${lastName} ${firstName[0]}.`;
};

export const makeShortName = (nameObj: HumanName) => {
  const { firstName, lastName } = nameObj;
  return `${firstName} ${lastName}`;
};

export const makeTextEllipsis = (visiblePartLength: number, text = ''): string =>
  text.length > visiblePartLength ? `${text.slice(0, visiblePartLength)}...` : text;

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

function getResizedCanvas(canvas, newWidth) {
  const tmpCanvas = document.createElement('canvas');
  const scale = newWidth / canvas.width;
  tmpCanvas.width = newWidth;
  tmpCanvas.height = canvas.height * scale;

  const ctx = tmpCanvas.getContext('2d');
  if (ctx) {
    ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newWidth, canvas.height * scale);
  }
  return tmpCanvas;
}

export const getExt = (fileName: string) =>
  fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);

export const canvasToBlob = (imageCanvas, imageName: string, width = 600) => {
  // все изображения из кроппера сохраняем в png.
  // imageCanvas.toBlob по умолчанию ставит mime type image/png
  const extractedImageName = imageName.substring(0, imageName.lastIndexOf('.'));
  // axios не любит кирилицу в именах файлов
  const transliteratedName = slugify(extractedImageName);
  return new Promise((resolve, reject) => {
    const newCanvas = getResizedCanvas(imageCanvas, width);
    newCanvas.toBlob((blob) => {
      if (blob) {
        resolve({ blobImage: blob, blobImageName: `${transliteratedName}.png` });
      } else {
        reject();
      }
    });
  });
};

const IMAGE_UPLOAD_PRESET = `${process.env.REACT_APP_IMAGE_UPLOAD_PRESET}`;

export const makeImageFormData = (tag: string, imageFile: Blob) => {
  const data = new FormData();
  data.append('upload_preset', IMAGE_UPLOAD_PRESET);
  data.append('tags', tag);
  data.append('file', imageFile);
  return data;
};

export const makeImageFileFormData = (file: Blob) => {
  const data = new FormData();
  data.append('image', file);
  return data;
};

export const makeAvatarColor = (firstName = '', lastName = ''): string =>
  stc(`${firstName}${lastName}`);

export type AvatarSizes = 'small' | 'normal' | 'medium' | 'large';

export const makeAvatarStyle = (
  firstName: string,
  lastName: string,
  clickable?: boolean,
  size: AvatarSizes = 'normal',
) => {
  const avatarColor = makeAvatarColor(firstName, lastName);

  const styles = makeStyles((theme: Theme) => ({
    avatar: {
      color: theme.palette.getContrastText(avatarColor),
      backgroundColor: avatarColor,
      cursor: clickable ? 'pointer' : 'default',
    },
    text: {
      fontSize: () => {
        switch (size) {
          case 'small':
            return theme.typography.caption.fontSize;
          case 'normal':
            return theme.typography.body1.fontSize;
          case 'medium':
            return theme.typography.h5.fontSize;
          case 'large':
            return theme.typography.h2.fontSize;
        }
      },
    },
  }));
  return styles();
};

// выбор слов с верным окончанием
export const numToStr = (num: number, arrText: string[]): string => {
  if (num % 10 === 1 && num % 100 !== 11) {
    return arrText[0];
  }
  if (num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)) {
    return arrText[1];
  }
  return arrText[2];
};

export const localNaming = (number = 0, key: LocalDicKeys): string => {
  const names = LOCALE_DIC[key];
  if (names) {
    return numToStr(number, names);
  }
  return '';
};

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const create_UUID = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    // eslint-disable-next-line no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

export const symbolsLeft = (text: string, maxTextSize: number, showOffsetFromEnd = 0) =>
  text && text.length > maxTextSize - showOffsetFromEnd ? `${maxTextSize - text.length}` : '';

export const getFontSizeForTitle = (string, firstLimit, secondLimit) => {
  const stringLength = string.length;
  switch (true) {
    case stringLength < firstLimit:
      return 36;
    case stringLength < secondLimit:
      return 28;
    default:
      return 20;
  }
};

export const makeGalleryImages = (images: any[]) => {
  if (images.length) {
    return images.map((image) => ({
      original: image.url,
      thumbnail: image.url, // TODO
    }));
  }
  return [{ original: Urls.ASSET_IMAGE(DEFAULT_IMAGE) }];
};

export const setTextToClipboard = (text = '') => {
  navigator.clipboard.writeText(text).then(
    function() {
      toast.success('Скопировано');
    },
    function() {
      toast.error('Ошибка копирования');
    },
  );
};

export const isEventStorageHasEvent = (event: string): boolean => {
  const eventsString = localStorage.getItem(EVENTS_STORAGE.name);
  if (!eventsString) {
    return false;
  }
  const events = JSON.parse(eventsString);
  return events.includes(event);
};

export const setIEventToStorage = (event: string): void => {
  const eventsString = localStorage.getItem(EVENTS_STORAGE.name);
  const events = eventsString ? JSON.parse(eventsString) : [];
  if (!events.includes(event)) {
    events.push(event);
    localStorage.setItem(EVENTS_STORAGE.name, JSON.stringify(events));
  }
};

export const removeIEventFromStorage = (event: string): void => {
  const eventsString = localStorage.getItem(EVENTS_STORAGE.name);
  if (eventsString) {
    const events: string[] = JSON.parse(eventsString);
    const newEvents = events.filter((storageEvent) => storageEvent !== event);
    localStorage.setItem(EVENTS_STORAGE.name, JSON.stringify(newEvents));
  }
};

export const makePathFromNotification = (
  rawIds: string[] | string,
  rawEntities: NotificationEntities[] | string,
): {
  path: string;
  pathKey: NotificationEntities;
  pathId: string;
  entities: NotificationEntities[];
  ids: string[];
} => {
  const ids = typeof rawIds === 'string' && isJsonString(rawIds) ? JSON.parse(rawIds) : rawIds;
  const entities =
    typeof rawEntities === 'string' && isJsonString(rawEntities)
      ? JSON.parse(rawEntities)
      : rawEntities;

  if (!ids.length) {
    return { path: '/', pathKey: 'IDEA', pathId: '', entities: [], ids: [] };
  }
  const appPaths = {
    IDEA: Paths.COMMON_IDEAS,
    RECRUITING: Paths.COMMON_RECRUITING_PROJECTS,
    PROJECT: Paths.PROJECT_WORKSPACE,
    APPLICATION: Paths.PROJECT_SETTINGS,
  };
  const pathId = ids[0];
  let pathKey = entities[0];
  if (pathKey === 'PROJECT' && entities[1] === 'APPLICATION') {
    pathKey = 'APPLICATION';
  }

  return { path: `${appPaths[pathKey]}/${pathId}`, pathKey, pathId, entities, ids };
};
