import { DefaultLinkModel } from '@projectstorm/react-diagrams';

import { TreeItem } from './components/PlanList';
import { TaskNodeModel } from './components/node/TaskNodeModel';
import { ArrowPortModel } from './components/link/ArrowLinkModel';
import { PLAN_TASK_STATUSES_COLORS, PlanTaskStatuses } from '../../../utils/constants';
import { PALETTE } from '../../../utils/palette';

export const NODE_DIMENSIONS = {
  height: 50,
  width: 100,
};

export interface PlainTask {
  id: string;
  parentStepId: string | null;
  projectId: string;
  name: string;
  estimation: number;
  cardId: string;
  status: PlanTaskStatuses;
  position: number;
  taskEndDate: string;
}

export const getNodeKey = ({ treeIndex }) => treeIndex;
export const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

const getLayers = (taskTree) => {
  const layers = {};
  let maxDeeps = 0;
  let columns = 0;
  const getLayersNumber = (tree, layersCount): number => {
    if (!tree.length) {
      // дошли до конца ветки смотрим глубину и если больше чем была - фиксируем
      // при свертывании глубина будут уменьшаться до первого узла с детьми
      // далее снова нарастать до конца ветки
      maxDeeps = maxDeeps < columns ? columns : maxDeeps;
      return layersCount;
    }
    // при входе в узел считаем колонку
    columns += 1;
    const newLayersCount = layersCount + (tree.length - 1);

    const layersNum = tree.reduce((acc, node) => {
      const newAcc = getLayersNumber(node.children, acc);
      layers[node.task.id] = getLayersNumber(node.children, 1);
      return newAcc;
    }, newLayersCount);
    // при выходе из узла отнимаем колонку
    columns -= 1;
    return layersNum;
  };
  const fullLayersNumber = getLayersNumber(taskTree, 1);
  return { layers, fullLayersNumber, maxDeeps };
};

const makeParents = (plainTree: PlainTask[], rootId) =>
  plainTree.reduce(
    (parents: any, item) => {
      const iterationParents = parents;
      if (!item.parentStepId) {
        iterationParents.root.push(item);
        return iterationParents;
      }
      if (parents[item.parentStepId]) {
        iterationParents[item.parentStepId].push(item);
        return iterationParents;
      }
      return { ...iterationParents, [item.parentStepId]: [item] };
    },
    { [rootId]: [] },
  );

export const makeTree = (plainTree: PlainTask[], rootId, expandedNodes) => {
  const parents = makeParents(plainTree, rootId);
  const makeTaskTree = (children) => {
    return children.map(
      (child: PlainTask): TreeItem => {
        return {
          expanded: expandedNodes[child.id] || false,
          task: {
            id: child.id,
            name: child.name,
            daysToComplete: child.estimation,
            cardId: child.cardId,
            projectId: child.projectId,
            status: child.status,
            position: child.position,
            taskEndDate: child.taskEndDate,
          },
          children: parents[child.id] ? makeTaskTree(parents[child.id]) : [],
        };
      },
    );
  };
  return makeTaskTree(parents[rootId]);
};

export const makeGraphPlaneTree = (taskTree) => {
  const xStep = NODE_DIMENSIONS.width * 1.5;
  const yStep = NODE_DIMENSIONS.height * 1.5;
  const { layers, fullLayersNumber, maxDeeps } = getLayers(taskTree);
  const fullHeight = fullLayersNumber * yStep;
  const fullWidth = (maxDeeps + 1) * xStep + NODE_DIMENSIONS.width / 2;

  const rootNode = new TaskNodeModel({
    name: 'Завершение проекта',
    color: PALETTE().greenBlue,
  });
  rootNode.setPosition(fullWidth, fullHeight / 2 + yStep);
  // @ts-ignore
  const rootOutPort = rootNode.addPort(new ArrowPortModel(true, 'in'));
  // @ts-ignore
  rootNode.addPort(new ArrowPortModel(false, 'out'));

  const makePlaneTree = (children, nodes, xOffset, yOffset, height, parentPort) => {
    let currentYOffset = yOffset;

    return children.reduce((nodes, child, index) => {
      const childrenHeight = layers[child.task.id] * yStep;

      const node = new TaskNodeModel({
        name: child.task.name,
        color: PLAN_TASK_STATUSES_COLORS[child.task.status],
        cardId: child.task.cardId,
        projectId: child.task.projectId,
      });
      node.setPosition(fullWidth - xOffset, childrenHeight / 2 + currentYOffset);
      // @ts-ignore
      const nodeOutPort = node.addPort(new ArrowPortModel(true, 'in'));
      // @ts-ignore
      const nodeInPort = node.addPort(new ArrowPortModel(false, 'out'));
      // @ts-ignore
      const link = nodeInPort.link<DefaultLinkModel>(parentPort);
      const newNodes = [...nodes, node, link];
      const childrenOffset = currentYOffset;

      currentYOffset += childrenHeight;
      if (child?.children.length) {
        return makePlaneTree(
          child.children,
          newNodes,
          xOffset + xStep,
          childrenOffset,
          childrenHeight,
          nodeOutPort,
        );
      }
      return newNodes;
    }, nodes);
  };
  const graphTree = makePlaneTree(taskTree, [rootNode], xStep, yStep, fullHeight, rootOutPort);
  return {
    graphTree,
    dimensions: {
      height: fullHeight + NODE_DIMENSIONS.height * 3,
      width: fullWidth + NODE_DIMENSIONS.width * 3,
    },
  };
};

export const getMaxPosition = (plainTree) => {
  const position =
    plainTree.length > 0
      ? plainTree.reduce((max, node) => (max < node.position ? node.position : max), 0)
      : 512;
  return position || 512;
};
