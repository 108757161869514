import { call, put, takeEvery } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { COMPONENT_STATES } from '../../../../utils/componentState';
import { errorHandler } from '../../../../utils/fetchUtils';
import { ComponentState, TablePaginationType } from '../../../../utils/types';
import UrlsV2 from '../../../../utils/endpointsV2';

// dal
export const getUserIdeas = (userId: string, pagination: TablePaginationType, filters) => {
  const params = { ...pagination, ...filters };
  return axios.get(UrlsV2.PERSONAL_PROJECTS_IDEAS(userId), { params });
};

// saga
function* requestUserStatIdeas(action) {
  try {
    const { userId, pagination, filters } = action.payload;
    const { data } = yield call(getUserIdeas, userId, pagination, filters);
    yield put(fetchUserIdeasSuccess({ data }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchUserIdeasFail());
  }
}

export function* watchLoadingUserStatIdeas() {
  yield takeEvery(fetchUserIdeas.type, requestUserStatIdeas);
}

export interface UserStatIdeasType {
  componentState: ComponentState;
  ideas: any[];
  totalElements: number;
  request: boolean;
}

// reducer
const initialState: UserStatIdeasType = {
  componentState: COMPONENT_STATES.LOADING,
  ideas: [],
  totalElements: 0,
  request: true,
};

const userStatIdeasSlice = createSlice({
  name: 'userStatIdeas',
  initialState,
  reducers: {
    fetchUserIdeas(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
    },
    fetchUserIdeasSuccess(state, action) {
      const { data } = action.payload;
      if (!data.items.length) {
        state.componentState = COMPONENT_STATES.EMPTY;
      } else {
        state.ideas = data.items;
        state.totalElements = data.totalElements;
        state.componentState = COMPONENT_STATES.CONTENT;
      }
    },
    fetchUserIdeasFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const {
  fetchUserIdeas,
  fetchUserIdeasSuccess,
  fetchUserIdeasFail,
} = userStatIdeasSlice.actions;

export default userStatIdeasSlice.reducer;
