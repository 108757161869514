import React from 'react';
import { FormikErrors } from 'formik';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw } from 'draft-js';
import { makeStyles } from '@material-ui/core/styles';

import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import { draftjsToMd, mdToDraftjs } from 'draftjs-md-converter';
import { PALETTE } from '../utils/palette';
import { localNaming } from '../utils/utils';
import { MAX_SYMBOLS_NUMBER_FOR_LONG_TEXT } from '../utils/constants';

type option =
  | 'inline'
  | 'blockType'
  | 'fontSize'
  | 'fontFamily'
  | 'list'
  | 'textAlign'
  | 'colorPicker'
  | 'link'
  | 'embedded'
  | 'emoji'
  | 'image'
  | 'remove'
  | 'history';

interface EditorFieldProps {
  name?: string;
  onChange?: any;
  maxSymbols?: number;
  value: string;
  label?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  options?: option[];
  toolbarHidden?: boolean;
  readOnly?: boolean;
}

interface StyleProps {
  readOnly: boolean | undefined;
}

const useStyles = makeStyles((theme) => ({
  about: {
    padding: ({ readOnly }: StyleProps) => (readOnly ? 0 : 10),
    borderWidth: 1,
    borderStyle: ({ readOnly }: StyleProps) => (readOnly ? 'none' : 'solid'),
    borderColor: PALETTE(0.1).gray,
  },
  blockType: {
    width: 180,
  },
}));

const defaultOptions = ['inline', 'blockType', 'list', 'history'];

function RichTextEditor(props: EditorFieldProps) {
  const {
    options = defaultOptions,
    value,
    onChange,
    name,
    label,
    error,
    maxSymbols = MAX_SYMBOLS_NUMBER_FOR_LONG_TEXT,
    toolbarHidden,
    readOnly,
  } = props;
  const classes = useStyles({ readOnly });

  const improvedValue = mdToDraftjs(value);

  const handleOnChange = (v) => {
    onChange(name)(draftjsToMd(v));
  };

  const pureText = convertFromRaw(improvedValue).getPlainText();

  const isTextLengthLessOrEqualThenMax = pureText.length <= maxSymbols;
  const symbolsLeft = maxSymbols - pureText.length;
  const aboutSymbolsCounter = isTextLengthLessOrEqualThenMax
    ? `Еще можно ввести: ${symbolsLeft} ${localNaming(symbolsLeft, 'symbols')}`
    : `Вам нужно удалить: ${Math.abs(symbolsLeft)} ${localNaming(
        Math.abs(symbolsLeft),
        'symbols',
      )}`;

  return (
    <div>
      {!!label && <FormLabel style={{ fontSize: 14 }}>{label}</FormLabel>}
      <Editor
        toolbarHidden={toolbarHidden}
        readOnly={readOnly}
        webDriverTestID="foo"
        initialContentState={improvedValue}
        onContentStateChange={handleOnChange}
        editorClassName={classes.about}
        stripPastedStyles
        spellCheck
        toolbar={{
          options,
          inline: {
            options: ['bold', 'italic'],
          },
          list: {
            options: ['unordered', 'ordered'],
          },
          blockType: {
            className: classes.blockType,
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
          },
        }}
        localization={{
          locale: 'ru',
        }}
      />
      {!!pureText && !readOnly && (
        <FormHelperText error={!isTextLengthLessOrEqualThenMax}>
          {aboutSymbolsCounter}
        </FormHelperText>
      )}
      {/*
      // @ts-ignore */}
      <FormHelperText error>{error}</FormHelperText>
    </div>
  );
}

export default RichTextEditor;
