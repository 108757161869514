import React, { useState, PropsWithChildren } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';

import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import UserInfoBlock from '../../../components/UserInfoBlock';

import TextFieldWithCounter from '../../../components/TextFieldWithCounter';
import { KickReasonSchema, maxSymbols } from './validationSchema';
import { User } from '../../../utils/types';

interface RemoveMemberButton extends IconButtonProps {
  user: User;
  isEdit: boolean;
  submitRemoving: (v: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    width: 400,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function RemoveMemberButton(props: PropsWithChildren<RemoveMemberButton>) {
  const classes = useStyles();
  const { onClick, children, submitRemoving, user, isEdit, ...otherButtonProps } = props;

  const { request } = useSelector((state: any) => state.workspaceProjectInfo);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    if (isEdit) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const onSubmit = ({ kickReason }) => {
    submitRemoving(kickReason);
    handleClosePopover();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton
        style={{ cursor: isEdit ? 'pointer' : 'default' }}
        onClick={handleOpenPopover}
        {...otherButtonProps}
      >
        {children}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className={classes.popover}>
          <Typography variant="h3" color="textSecondary" gutterBottom>
            Исключить из проекта
          </Typography>
          <UserInfoBlock user={user} />
          <Formik
            initialValues={{ kickReason: '' }}
            validationSchema={KickReasonSchema}
            onSubmit={onSubmit}
            validateOnMount
          >
            {({ values, handleChange, isValid, errors }) => (
              <Form id="removeProjectMember" className={classes.form}>
                <TextFieldWithCounter
                  name="kickReason"
                  fullWidth
                  value={values.kickReason}
                  maxSymbols={maxSymbols}
                  onChange={handleChange}
                  placeholder={'Укажите причину исключения'}
                />
                <div style={{ paddingTop: 10 }}>
                  <Button
                    form="removeProjectMember"
                    type="submit"
                    size="small"
                    variant="outlined"
                    color="primary"
                    disabled={request || !isValid}
                  >
                    Исключить
                  </Button>
                  <Button
                    size="small"
                    style={{ marginLeft: 10 }}
                    variant="outlined"
                    color="primary"
                    onClick={handleClosePopover}
                  >
                    Отменить
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Popover>
    </>
  );
}

export default RemoveMemberButton;
