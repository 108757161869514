import * as Yup from 'yup';

const required = 'Обязательное поле';

export const maxSymbols = 1000;

export const KickReasonSchema = Yup.object().shape({
  kickReason: Yup.string()
    .max(maxSymbols, `Не более ${maxSymbols} символов`)
    .required(required),
});
