import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createMuiTheme,
  makeStyles,
  Theme,
  ThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';

import Chat from './chat';
import Kanban from './kanban';
import ProjectInfo from './project-info';
import Plan from './plan';
import TabPanel from './components/TabPanel';
import ProjectCharter from './project-charter';
import ViewSelectState from '../../components/select-state/ViewSelectState';

import { fetchWorkspaceProject, resetIsProjectMember, setSelectedTab } from './domain/reducer';
import { CUSTOM_SHADOWS, PALETTE } from '../../utils/palette';
import { CharterReduxState } from './project-charter/utils/types';
import { TOURS_CLASS_NAMES } from '../../components/tour/tourSteps';
import { drawerWidth } from '../app-components/MainMenu';
import { WorkspaceTabsIndexes } from '../../utils/types';
import { WORKSPACE_TABS } from '../../utils/constants';
import { setCurrentPlace } from '../globalDomain';
import { closeChatChannel } from './chat/domain';
import ProjectOffice from './project-office/ProjectOffice';

const { workspace } = TOURS_CLASS_NAMES;
const tabPanelTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: PALETTE().gray,
    },
    secondary: {
      main: PALETTE().gray,
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    text: {
      primary: PALETTE().gray,
      secondary: PALETTE().gray,
    },
    divider: 'rgba(120,144,156,0.25)',
  },
  typography: {
    body1: {
      fontSize: 14,
    },
    h4: {
      fontSize: 24,
      fontWeight: 500,
    },
  },
  zIndex: {
    appBar: 1201,
  },
  overrides: {
    MuiPaper: {
      elevation5: {
        boxShadow: CUSTOM_SHADOWS.gray.elevation5,
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  tabs: {
    top: 0,
    left: 'auto',
    position: 'fixed',
    width: '100%',
    zIndex: theme.zIndex.drawer + 1,
  },
  tabPanels: {
    flexGrow: 1,
    paddingTop: 50,
  },
  tab: {
    textTransform: 'none',
    fontSize: 18,
    fontWeight: 400,
    // width: 150,
  },
  selectedTab: {
    color: PALETTE().black,
    fontWeight: 500,
  },
  badge: {
    top: 2,
    right: 0,
  },
  tourMenu: {
    width: 1030,
    height: 46,
    position: 'fixed',
    top: 0,
    left: drawerWidth,
  },
  tabPanel: {
    margin: '34px 28px',
  },
}));

const MenuTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    fontSize: 18,
    fontWeight: 400,
  },
  selected: {
    color: PALETTE().black,
    fontWeight: 500,
  },
}))(Tab);

function ProjectWorkspace() {
  const classes = useStyles();
  // @ts-ignore
  const { id: projectId } = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const {
    projectInfoComponentState,
    isProjectMember,
    errorData,
    selectedTab,
    projectData,
  } = useSelector((state: any) => state.workspaceProjectInfo);

  const { user } = useSelector((state: any) => state.app);
  const { approvedByMe }: CharterReduxState = useSelector(
    (state: any) => state.workspaceProjectCharter,
  );
  const isChat = selectedTab === WORKSPACE_TABS.chat;

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: WorkspaceTabsIndexes) => {
    dispatch(setSelectedTab(newValue));
  };

  useEffect(() => {
    dispatch(
      setCurrentPlace({
        place: isChat
          ? { placeName: 'workspaceChat', data: { projectId: projectData?.id } }
          : { placeName: null },
      }),
    );
  }, [dispatch, isChat, projectData]);

  const fetchWorkspaceProjectData = () => {
    if (user) {
      dispatch(
        fetchWorkspaceProject({
          projectId,
          userId: user.id,
          // @ts-ignore
          fromPush: state?.fromPush,
        }),
      );
    }
  };

  useEffect(fetchWorkspaceProjectData, [projectId, user]);

  useEffect(
    () => {
      return () => {
        dispatch(resetIsProjectMember());
        dispatch(closeChatChannel());
      };
    },
    // eslint-disable-next-line
    [],
  );

  const content = () => (
    <div className={clsx(classes.root, workspace.center)}>
      <ThemeProvider theme={tabPanelTheme}>
        <Paper className={classes.tabs} elevation={5} square>
          <Tabs TabIndicatorProps={{ hidden: true }} value={selectedTab} onChange={handleChange}>
            <MenuTab className={workspace.menuProjectInfo} label="О проекте" />
            <MenuTab
              label={
                <Badge
                  invisible={approvedByMe || !isProjectMember}
                  variant="dot"
                  classes={{ anchorOriginTopRightRectangle: classes.badge }}
                  color="error"
                >
                  <Typography className={clsx(classes.tab, workspace.menuCharter)}>
                    Устав проекта
                  </Typography>
                </Badge>
              }
            />
            <MenuTab className={workspace.menuPlan} label="План работ" />
            {isProjectMember && <MenuTab label="Проектный офис" />}
            {isProjectMember && <MenuTab className={workspace.menuChat} label="Сообщения" />}
            {isProjectMember && <MenuTab className={workspace.menuKanban} label="Доска задач" />}
          </Tabs>
        </Paper>
      </ThemeProvider>
      <div className={classes.tabPanels}>
        <TabPanel value={selectedTab} index={WORKSPACE_TABS.description}>
          <ProjectInfo className={classes.tabPanel} />
        </TabPanel>
        <TabPanel value={selectedTab} index={WORKSPACE_TABS.project_office}>
          <ProjectOffice className={classes.tabPanel} projectId={projectId} />
        </TabPanel>
        <TabPanel value={selectedTab} index={WORKSPACE_TABS.charter}>
          <ProjectCharter className={classes.tabPanel} />
        </TabPanel>
        <TabPanel value={selectedTab} index={WORKSPACE_TABS.plan}>
          <Plan className={classes.tabPanel} />
        </TabPanel>
        {isProjectMember && (
          <>
            <TabPanel value={selectedTab} index={WORKSPACE_TABS.chat}>
              <Chat projectId={projectId} />
            </TabPanel>
            <TabPanel value={selectedTab} index={WORKSPACE_TABS.kanban}>
              <Kanban className={classes.tabPanel} projectId={projectId} />
            </TabPanel>
          </>
        )}
      </div>
      <div className={clsx(classes.tourMenu, workspace.menu)} />
    </div>
  );

  return (
    <ViewSelectState
      state={projectInfoComponentState}
      content={content}
      errorData={errorData}
      errorAction={fetchWorkspaceProjectData}
    />
  );
}

export default ProjectWorkspace;
