import React from 'react';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { PALETTE } from '../../../utils/palette';

interface SettingSwitch {
  gridRow: number;
  title: string;
  description: string;
  onChange: () => void;
  checked: boolean | undefined;
  disabled: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    gridColumn: '1 / end',
    justifySelf: 'center',
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
    padding: 8,
    borderRadius: 4,
    '&:hover': {
      backgroundColor: PALETTE(0.1).gray,
    },
  },
}));

function SettingSwitch(props: SettingSwitch) {
  const { gridRow, title, description, onChange, checked, disabled } = props;
  const classes = useStyles();
  return (
    <div style={{ gridRow }} className={classes.root}>
      <div>
        <Typography>{title}</Typography>
        <Typography color="textSecondary" variant="subtitle2">
          {description}
        </Typography>
      </div>
      <Switch disabled={disabled} onChange={onChange} checked={checked} color="primary" />
    </div>
  );
}

export default SettingSwitch;
