import axios from 'axios';
import Urls from '../../../../utils/endpoints';

export const getOrganization = (id) => axios.get(`${Urls.ROOTS.organizations}/${id}`);
export const getOrganizationStatistic = (id) =>
  axios.get(`${Urls.ROOTS.organizations}/${id}/statistics`);
export const toggleModeration = (id) =>
  axios.post(`${Urls.ROOTS.organizations}/${id}/toggle-moderation`);
export const updateOrgInfo = (id, payload) =>
  axios.patch(`${Urls.ROOTS.organizations}/${id}`, payload);

export const updateOrgAvatar = (orgId, image, imageName) =>
  axios.post(`${Urls.ROOTS.organizations}/${orgId}/avatar/`, image, {
    headers: {
      'Content-Disposition': `attachment;filename=${imageName}`,
    },
  });

export const removeOrgAvatar = (id) => axios.delete(`${Urls.ROOTS.organizations}/${id}/avatar/`);
