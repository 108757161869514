import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import { PROJECT_STATUSES_TITLES } from '../../../utils/constants';
import { PALETTE, PROJECT_STATUSES_PALETTE } from '../../../utils/palette';
import Dates from '../../../utils/dates';
import { TOURS_CLASS_NAMES } from '../../../components/tour/classNames';

interface ProjectStatusLabelProps {
  status: string;
  organizationOnly?: boolean;
  startDate: string;
  className?: string;
}

interface StylesProps {
  color: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  label: {
    height: 26,
    color: '#fff',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  status: {
    width: 120,
    backgroundColor: PALETTE().green,
    marginRight: 8,
  },
  organization: {
    width: 120,
    backgroundColor: PALETTE().greenBlue,
    marginRight: 8,
  },
  date: {
    width: 90,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: PALETTE().gray,
  },
  completed: {
    width: 90,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: (props: StylesProps) => props.color,
    color: (props: StylesProps) => props.color,
    marginRight: 8,
  },
}));

function ProjectStatusLabel({
  status,
  organizationOnly,
  startDate,
  className,
}: ProjectStatusLabelProps) {
  const styleProps = { color: PROJECT_STATUSES_PALETTE[status] };
  const classes = useStyles(styleProps);

  let timeCounter = Dates.currentWeekNumber(startDate);
  let timePeriod = 'неделя';
  if (timeCounter > 52) {
    timeCounter = Math.floor(timeCounter / 52) + 1;
    timePeriod = 'год';
  } else if (timeCounter > 4) {
    timeCounter = Math.floor(timeCounter / 4) + 1;
    timePeriod = 'месяц';
  }
  timePeriod = `${timeCounter} ${timePeriod}`;

  return (
    <div className={clsx(classes.root, className)}>
      <div className={TOURS_CLASS_NAMES.projectInfo.visibilityLabel}>
        {organizationOnly ? (
          <div className={clsx(classes.label, classes.organization)}>
            <Typography variant="caption">ОРГАНИЗАЦИЯ</Typography>
          </div>
        ) : (
          <div className={clsx(classes.label, classes.status)}>
            <Typography variant="caption">ПУБЛИЧНЫЙ</Typography>
          </div>
        )}
      </div>
      <div className={clsx(classes.label, classes.completed)}>
        <Typography variant="caption">{PROJECT_STATUSES_TITLES[status]}</Typography>
      </div>
      <div className={clsx(classes.label, classes.date, TOURS_CLASS_NAMES.projectInfo.timeCounter)}>
        <Typography variant="subtitle2" color="textSecondary" display="block">
          {timePeriod}
        </Typography>
      </div>
    </div>
  );
}

export default ProjectStatusLabel;
