import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ColoredAvatar from '../../../components/ColoredAvatar';

import { MessageData } from './domain';
import { User } from '../../../utils/types';
import { PALETTE } from '../../../utils/palette';
import Dates from '../../../utils/dates';
import Avatar from '@material-ui/core/Avatar';

interface StyleProps {
  isSelf: boolean;
  hasImage: boolean;
}

interface TeamData {
  [key: string]: User;
}

interface MessageViewProps {
  messageData: MessageData;
  teamData: TeamData;
  isMentor: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '80%',
    display: 'flex',
    marginTop: 20,
    alignSelf: (props: StyleProps) => (props.isSelf ? 'flex-end' : 'flex-start'),
  },
  messageBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  message: {
    padding: '14px 16px',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  commonMessage: {
    backgroundColor: PALETTE().white,
    borderRadius: ({ hasImage }: StyleProps) => `16px 16px ${hasImage ? '0' : '16px'} 0`,
  },
  selfMessage: {
    backgroundColor: PALETTE().blue,
    color: PALETTE().white,
    borderRadius: ({ hasImage }: StyleProps) => `16px 16px 0 ${hasImage ? '0' : '16px'}`,
    minWidth: 100,
  },
  timeStamp: {
    alignSelf: (props: StyleProps) => (props.isSelf ? 'flex-end' : 'flex-start'),
  },
  avatar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: (props: StyleProps) => (props.isSelf ? '0 0 0 10px' : '0 10px 0 0'),
  },
  imageBox: {
    width: 400,
  },
}));

function MessageView({ messageData, teamData }: MessageViewProps) {
  const { message, timestamp, author_id: authorId, id, images } = messageData;
  const { user } = useSelector((state: any) => state.app);
  const isSelf = user.id === authorId;
  const classes = useStyles({ isSelf, hasImage: !!images.length });
  const timeString = !!timestamp && Dates.dateToLocalDateAndTime(new Date(timestamp * 1000));
  const messageAuthor = teamData[authorId];

  const messageBox = useMemo(
    () => (
      <div id={messageData.id} className={classes.root}>
        {!isSelf && (
          <div className={classes.avatar}>
            {messageAuthor ? <ColoredAvatar user={messageAuthor} /> : <Avatar />}
          </div>
        )}
        <div className={classes.messageBox}>
          <Typography
            className={clsx(classes.message, isSelf ? classes.selfMessage : classes.commonMessage)}
            variant="body1"
          >
            {message}
          </Typography>
          {!!images[0] &&
            (images[0].url ? (
              <img className={classes.imageBox} src={images[0].url} alt="" />
            ) : (
              <Typography variant="caption" color="textSecondary">
                Изображение загружается...
              </Typography>
            ))}
          <Typography variant="caption" color="textSecondary" className={classes.timeStamp}>
            {timeString || <AccessTimeIcon style={{ fontSize: 12 }} />}
          </Typography>
        </div>
        {isSelf && (
          <div className={classes.avatar}>
            <ColoredAvatar user={user} />
          </div>
        )}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id],
  );

  return <>{messageBox}</>;
}

export default MessageView;
