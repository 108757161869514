import * as Yup from 'yup';
import { MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT } from '../../utils/constants';

const MIN_SYMBOLS_NUMBER = 3;

export const CommentSchema = Yup.object().shape({
  text: Yup.string()
    .trim()
    .min(MIN_SYMBOLS_NUMBER, `Причина не может быть короче ${MIN_SYMBOLS_NUMBER} символов`)
    .max(
      MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT,
      `Комментарий не может быть длиннее ${MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT} символов`,
    ),
});
