import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import UserInfoBlock from '../../../../../components/UserInfoBlock';

import { fetchTask } from './domain';
import Dates from '../../../../../utils/dates';
import { COMPONENT_STATES } from '../../../../../utils/componentState';

interface GraphTaskTooltip {
  data: {
    cardId: string;
    projectId: string;
    estimate: string;
    name: string;
  };
}

interface RowType {
  title: string;
  value: string | number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
  },
  boardTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 10,
  },
  planTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  planTitleText: {
    fontWeight: 600,
  },
  row: {
    display: 'flex',
    paddingBottom: 6,
  },
  rowTitle: {
    marginRight: 10,
  },
}));

function GraphTaskTooltip({ data }: GraphTaskTooltip) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { cardId, projectId, estimate, name } = data;
  const {
    task: { name: boardCardName, taskEndDate, executorId, expectedResult },
    componentState,
  } = useSelector((state: any) => state.graphTaskTooltip);
  const { projectData, isProjectMember } = useSelector((state: any) => state.workspaceProjectInfo);

  const teamData = projectData?.team.reduce(
    (team, member) => ({ ...team, [member.id]: member }),
    {},
  );

  const isContent = componentState === COMPONENT_STATES.CONTENT;

  useEffect(() => {
    if (isProjectMember && cardId) {
      dispatch(fetchTask({ cardId, projectId }));
    }
  }, [dispatch, cardId, projectId, isProjectMember]);

  const Row = ({ title, value }: RowType) => (
    <div className={classes.row}>
      <Typography className={classes.rowTitle} variant="subtitle2" color="textSecondary">
        {title}:
      </Typography>
      <Typography variant="subtitle2">
        {isContent ? value : <Skeleton width={70} animation="wave" />}
      </Typography>
    </div>
  );

  return (
    <div className={classes.root}>
      {cardId && isProjectMember ? (
        <>
          <div className={classes.boardTitle}>
            <Typography variant="subtitle2" color="textSecondary">
              Связанная задача на доске
            </Typography>
            <Typography className={classes.planTitleText} align="center" variant="body1">
              {isContent ? boardCardName : <Skeleton width={200} height={30} animation="wave" />}
            </Typography>
          </div>
          <Row
            title="Дата завершения"
            value={taskEndDate ? Dates.dateStringToLocalString(taskEndDate) : 'не указана'}
          />
          <Row title="Ожидаемый результат" value={expectedResult || 'не указан'} />
          <Row title="Исполнитель" value={!executorId ? 'не указан' : ''} />
          {isContent && executorId && (
            <div style={{ paddingTop: 5 }}>
              <UserInfoBlock user={teamData[executorId]} />
            </div>
          )}
        </>
      ) : (
        <div className={classes.planTitle}>
          <Typography className={classes.planTitleText} align="center" variant="body1">
            {name}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {estimate}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default GraphTaskTooltip;
