import React from 'react';
import { useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import Tooltip from '@material-ui/core/Tooltip';
import UserInfoTooltipContent from './UserInfoTooltipContent';

import { makeAvatarStyle, AvatarSizes } from '../utils/utils';
import Urls from '../utils/endpoints';
import { User, NoOrgUser } from '../utils/types';
import { CUSTOM_SHADOWS, PALETTE } from '../utils/palette';
import Paths from '../utils/route-paths';

interface ColoredAvatarProps {
  user?: User | NoOrgUser | null;
  onClick?: () => void;
  clickable?: boolean;
  className?: string;
  size?: AvatarSizes;
  disableTooltip?: boolean;
}

interface StyleProps {
  disableTooltip: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    display: (props: StyleProps) => (props.disableTooltip ? 'none' : 'flex'),
    backgroundColor: PALETTE().white,
    color: PALETTE().black,
    maxWidth: 466,
    borderRadius: 16,
    boxShadow: CUSTOM_SHADOWS.gray.elevation10,
  },
}));

function ColoredAvatar(props: ColoredAvatarProps) {
  const { user: appUser, avatarUrl } = useSelector((state: any) => state.app);
  const { onClick, clickable = true, className, user, size, disableTooltip = false } = props;
  let finalUrl = avatarUrl || '';
  let finalUser = appUser;
  const history = useHistory();

  if (user) {
    finalUrl = Urls.AVATAR(user.avatar);
    finalUser = user;
  }
  const classes = useStyles({ disableTooltip });
  const avatarClasses = makeAvatarStyle(finalUser.firstName, finalUser.lastName, clickable, size);

  const handleClick = (e) => {
    if (e) {
      e.stopPropagation();
    }
    if (onClick && clickable) {
      onClick();
      return;
    }
    if (clickable && user) {
      history.push(`${Paths.PUBLIC_USER_PROFILE}/${user.id}`);
    }
  };

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      placement="top"
      title={<UserInfoTooltipContent user={finalUser} />}
    >
      <Avatar
        onClick={handleClick}
        src={finalUrl}
        className={clsx(avatarClasses.avatar, className)}
      >
        <span className={avatarClasses.text}>
          {finalUser.firstName[0]}
          {finalUser.lastName[0]}
        </span>
      </Avatar>
    </Tooltip>
  );
}

export default ColoredAvatar;
