import axios from 'axios';
import UrlsV2 from '../../../../utils/endpointsV2';

export const getProjectTemplate = (profileId: string, templateId: string) =>
  axios.get(UrlsV2.PERSONAL_PROJECTS_IDEAS(profileId, templateId));

export const getCommonProjectTemplate = (templateId: string) =>
  axios.get(`${UrlsV2.COMMON_PROJECTS_IDEAS}${templateId}`);

export const addProjectTemplate = (payload) => axios.post(UrlsV2.COMMON_PROJECTS_IDEAS, payload);

export const updateProjectTemplate = (templateId: string, payload) =>
  axios.patch(`${UrlsV2.COMMON_PROJECTS_IDEAS}${templateId}`, payload);

export const updateProjectTemplateIfOpen = (templateId: string) =>
  axios.post(`${UrlsV2.COMMON_PROJECTS_IDEAS}${templateId}}/edit/`);

export const removeProjectTemplate = (templateId: string) =>
  axios.delete(`${UrlsV2.COMMON_PROJECTS_IDEAS}${templateId}`);

export const uploadForModeration = (templateId: string) =>
  axios.post(`${UrlsV2.COMMON_PROJECTS_IDEAS}${templateId}/moderate/`);

export const startProjectFromIdea = (ideaId: string, payload) =>
  axios.post(UrlsV2.START_PROJECT_FROM_IDEA(ideaId), payload);
