import { TourClassNamesAndContent } from './types';

export const TOURS_CLASS_NAMES: TourClassNamesAndContent = {
  sideMenu: {
    profile: 'side-menu-tour-profile',
    center: 'center-tour',
    dashboard: 'side-menu-tour-dashboard',
    projects: 'side-menu-tour-projects',
    ideas: 'side-menu-tour-ideas',
    recruiting: 'side-menu-tour-recruiting',
    community: `community`,
    help: `tour-help`,
    support: 'tour-support-chat-invisible',
    tour: `tour`,
  },
  ideaView: {
    startRecruitingButton: 'tour-idea-view-recruiting-button',
  },
  ideaViewDraft: {
    templateStatusLabel: 'tour-template-status',
    sendToModerationButton: 'tour-idea-view-draft-send-to-moderation',
    editIdea: 'tour-idea-view-draft-edit-idea',
  },
  ideaForm: {
    form: 'tour-idea-form',
    images: 'tour-idea-images',
    saveButton: 'tour-idea-save-button',
  },
  startRecruitingForm: {
    settings: 'settings-class',
    organizationOnly: 'tour-start-recruiting-organization-only',
    questions: 'tour-start-recruiting-questions',
    startButton: 'tour-start-recruiting-start-button',
  },
  workspace: {
    center: 'ws-center-tour',
    menu: 'ws-menu-tour',
    menuProjectInfo: 'ws-menu-project-info',
    menuCharter: 'ws-menu-charter',
    menuPlan: 'ws-menu-plan',
    menuChat: 'ws-menu-chat',
    menuKanban: 'ws-menu-kanban',
  },
  kanbanBoard: {
    center: 'kanbanBoard-center-tour',
    firstCard: 'kanbanBoard-tour-firstCard',
    toDoList: 'kanbanBoard-tour-toDoList',
    inProgressList: 'kanbanBoard-tour-inProgressList',
    toCheckList: 'kanbanBoard-tour-toCheckList',
    doneList: 'kanbanBoard-tour-doneList',
    fineTuningList: 'kanbanBoard-tour-fineTuningList',
    center2: 'kanbanBoard-tour-center2',
  },
  projectInfo: {
    visibilityLabel: 'projectInfo-tour-visibilityLabel',
    timeCounter: 'projectInfo-tour-timeCounter',
    organizationBanner: 'projectInfo-tour-organizationBanner',
    welcomeLincButton: 'projectInfo-tour-welcomeLincButton',
    kickOffButton: 'projectInfo-tour-kickOffButton',
  },
  charter: {
    center: 'charter-center-tour',
    startMasterButton: 'charter-tour-startMasterButton',
    templateList: 'charter-tour-templateList',
    approveCharterButton: 'charter-tour-approveCharterButton',
    center2: 'charter-tour-center2',
  },
  plan: {
    visibilityToggle: 'plan-center-tour',
    addToBoardButton: 'plan-tour-addToBoardButton',
  },
  kanbanCard: {
    center: 'kanbanCard-tour-center',
    needTodoField: 'kanbanCard-tour-needTodoField',
    responsiblePerson: 'kanbanCard-tour-responsiblePerson',
    taskEndDate: 'kanbanCard-tour-taskEndDate',
    expectedResult: 'kanbanCard-tour-expectedResult',
    description: 'kanbanCard-tour-description',
    addAttachment: 'kanbanCard-tour-addAttachment',
    comments: 'kanbanCard-tour-comments',
  },
  orgManageInfo: {
    center: 'orgManageInfo-center',
  },
  orgManageUsers: {
    addUserBtn: 'orgManageUsers-addUserBtn',
    copyLink: 'orgManageUsers-copyLink',
    userTableFirsRow: 'orgManageUsers-userTableFirsRow',
  },
  addUserPage: {
    emailField: 'addUserPage-emailField',
  },
  userInfoStat: {
    center: 'userInfoStat-center',
    lastActivity: 'userInfoStat-lastActivity',
    ideasMenuItem: 'userInfoStat-ideasMenuItem',
    recruitingsMenuItem: 'userInfoStat-recruitingsMenuItem',
    projectsMenuItem: 'userInfoStat-projectsMenuItem',
    kickOffMenuItem: 'userInfoStat-kickOffMenuItem',
  },
  orgManageStat: {
    center: 'orgManageStat-center',
  },
  orgManageSetting: {
    moderation: 'orgManageSetting-moderation',
  },
};
