import React from 'react';
import { DropzoneDialog } from 'material-ui-dropzone';
import { useDispatch } from 'react-redux';

import { uploadFilesAction } from './domain';
import { Attachment } from '../../utils/types';

interface FileLoaderProps {
  open: boolean;
  onSave: (data: Attachment[]) => void;
  onClose: () => void;
  filesLimit?: number;
}

function FileUploader(props: FileLoaderProps) {
  const { onClose, open, onSave, filesLimit = 10 } = props;
  const dispatch = useDispatch();
  const handleCloseUploader = () => {
    onClose();
  };

  const handleSave = (files) => {
    dispatch(uploadFilesAction({ files, onSave, onClose }));
  };

  return (
    <DropzoneDialog
      fileObjects={[]}
      acceptedFiles={[]}
      filesLimit={filesLimit}
      open={open}
      showPreviews
      showPreviewsInDropzone={false}
      previewGridProps={{ container: { spacing: 1 } }}
      previewText="Выбранные файлы"
      maxFileSize={20000000}
      onClose={handleCloseUploader}
      onSave={handleSave}
      dialogTitle="Загрузить файлы"
      cancelButtonText="Отмена"
      submitButtonText="Ok"
      dropzoneText="Перетащите файл на эту область или кликните на неё."
    />
  );
}

export default FileUploader;
