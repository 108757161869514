import * as Yup from 'yup';
import { MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT } from '../../../utils/constants';

const required = 'Обязательное поле';

export const RegistrationSchema = Yup.object().shape({
  email: Yup.string()
    .email('введите корректный email')
    .required(required),
  firstName: Yup.string()
    .trim()
    .max(
      MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT,
      `Имя не может быть длиннее ${MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT} символов`,
    )
    .required(required),
  lastName: Yup.string()
    .trim()
    .max(
      MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT,
      `Фамилия не может быть длиннее ${MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT} символов`,
    )
    .required(required),
  middleName: Yup.string()
    .trim()
    .max(
      MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT,
      `Отчество не может быть длиннее ${MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT} символов`,
    ),
});
