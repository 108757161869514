import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import PageWrapper from '../../../components/Wrapper';
import ViewSelectState from '../../../components/select-state/ViewSelectState';
import PublicIdeasBlock from './components/PublicIdeasBlock';
import PublicRecruitingBlock from './components/PublicRecruitingsBlock';
import PublicProjectsBlock from './components/PublicProjectsBlock';
import ProfileAvatar from '../../../components/ProfileAvatar';
import OrganizationBanner from '../../../components/OrganizationBanner';

import { USER_TYPE_TITLES } from '../../../utils/constants';
import { makeFullName } from '../../../utils/utils';
import { fetchPublicUser, PublicUserProfileState } from './domain';
import Dates from '../../../utils/dates';
import { usePublicProfileStyles } from './styles';

function PublicUserProfile(): JSX.Element {
  const classes = usePublicProfileStyles();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const {
    componentState,
    user,
    dashboard: { templates, recruitings, projects },
  }: PublicUserProfileState = useSelector((state: any) => state.publicUserProfile);

  useEffect(() => {
    dispatch(fetchPublicUser({ userId: id }));
  }, [dispatch, id]);

  const content = (): JSX.Element => (
    <div className={classes.root}>
      <div className={classes.info}>
        <ProfileAvatar
          className={classes.avatar}
          avatarUrl={user.avatar}
          userFirstName={user.firstName}
          userLastName={user.lastName}
        />
        <div className={classes.textInfoBlock}>
          <Typography className={classes.typeText}>{USER_TYPE_TITLES[user.type]}</Typography>
          <Typography className={classes.nameText}>{makeFullName(user)}</Typography>
          {user.organization && (
            <OrganizationBanner
              className={classes.orgBanner}
              id={user.organization.id}
              name={user.organization.name}
              avatarUrl={user.organization.avatar}
            />
          )}
          {!!user.lastActivity && (
            <Typography className={classes.lastActivityText}>
              Последняя активность: {Dates.dateToLocalDateAndTime(new Date(user.lastActivity))}
            </Typography>
          )}
        </div>
      </div>
      <div className={classes.dashboard}>
        {!!templates?.length && <PublicIdeasBlock data={templates} userId={id} />}
        {!!recruitings?.length && <PublicRecruitingBlock data={recruitings} userId={id} />}
        {!!projects?.length && <PublicProjectsBlock data={projects} userId={id} />}
      </div>
    </div>
  );

  return (
    <PageWrapper header={'Профиль пользователя'}>
      <ViewSelectState state={componentState} content={content} />
    </PageWrapper>
  );
}

export default PublicUserProfile;
