import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import StandardDialog from '../../../../components/StandardDialog';
import InfoBlock from '../../../../components/InfoBlock';
import UserInfoBlock from '../../../../components/UserInfoBlock';
import CustomButton from '../../../../components/CustomButton';
import RejectAppButton from './RejectAppButton';

import { fetchAcceptApp, fetchRejectApp } from '../domain/reducer';

interface ApplicationViewProps {
  application: any;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'hidden',
  },
  userInfo: {
    marginBottom: 20,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 34,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 25,
    marginTop: 'auto',
    '& button': {
      width: 160,
    },
    '& button:first-child': {
      marginRight: 24,
    },
  },
}));

function ProjectApplicationView(props: ApplicationViewProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { application, open, onClose } = props;
  const { projectData } = useSelector((state: any) => state.workspaceProjectInfo);
  const { user } = useSelector((state: any) => state.app);

  const handleAcceptApp = () => {
    dispatch(fetchAcceptApp({ projectId: projectData.id, appId: application.id, userId: user.id }));
    onClose();
  };

  const handleRejectApp = (rejectMessage: string) => {
    dispatch(
      fetchRejectApp({
        projectId: projectData.id,
        appId: application.id,
        userId: user.id,
        rejectMessage,
      }),
    );
    onClose();
  };

  return (
    <StandardDialog open={open} onClose={onClose}>
      <div className={classes.main}>
        <Typography variant="h3" color="textSecondary" paragraph>
          Заявка участника
        </Typography>
        <UserInfoBlock className={classes.userInfo} user={application.applicant} />
        <div className={classes.content}>
          <InfoBlock
            label="Почему вы хотите вступить в проект?"
            body={application.applicationMessage}
          />
        </div>
      </div>
      <div className={classes.buttons}>
        <CustomButton
          size="large"
          variant="contained"
          color="primary"
          horizontalMargin="right"
          onClick={handleAcceptApp}
        >
          Принять
        </CustomButton>
        <RejectAppButton
          size="large"
          variant="outlined"
          color="secondary"
          submitRejecting={handleRejectApp}
        >
          Отклонить
        </RejectAppButton>
        <CustomButton
          size="large"
          variant="outlined"
          color="primary"
          horizontalMargin="left"
          onClick={onClose}
        >
          Отмена
        </CustomButton>
      </div>
    </StandardDialog>
  );
}

export default ProjectApplicationView;
