import { createSlice } from '@reduxjs/toolkit';
import { call, put, takeEvery, all } from 'redux-saga/effects';

import { COMPONENT_STATES } from '../../../../utils/componentState';
import { errorHandler } from '../../../../utils/fetchUtils';
import { Image, Attachment } from '../../../../utils/types';
import { uploadCanvasToBlobImage } from '../../../../utils/dal';

// dal

// saga
function* updateImageRequest(action) {
  try {
    const { image, setFieldValue, images, imageName } = action.payload;
    const data = yield call(uploadCanvasToBlobImage, image, imageName);
    const newImages = [data, ...images];
    setFieldValue('images', newImages, false);
    yield put(fetchingEnd());
  } catch (e) {
    errorHandler(e);
    yield put(fetchingEnd());
  }
}

function* uploadRecruitingTemplateRequest(action) {
  try {
    const { values, handleOpenDialog } = action.payload;
    const { ...newValues } = values;
    newValues.imageIds = values.images.map((i) => i.id);
    newValues.attachmentIds = values.attachments.map((i) => i.id);
    yield put(uploadRecruitingTemplateSuccess({ values: newValues }));
    yield call(handleOpenDialog);
  } catch (e) {
    errorHandler(e);
    yield put(fetchingEnd());
  }
}

export function* watchRecruitingTemplateEditing() {
  yield all([
    yield takeEvery(startUploadingRecruitingTemplateImage.type, updateImageRequest),
    yield takeEvery(startUploadRecruitingTemplate.type, uploadRecruitingTemplateRequest),
  ]);
}

// reducer
const initialState = {
  componentState: COMPONENT_STATES.LOADING,
  recruitingTemplateData: {
    name: '',
    goal: '',
    about: '{"blocks": [], "entityMap": {}}',
    duration: 1,
    attachments: [] as Attachment[],
    images: [] as Image[],
  },
  requesting: false,
};

const recruitingTemplateSlice = createSlice({
  name: 'recruitingTemplate',
  initialState,
  reducers: {
    startUploadingRecruitingTemplateImage(state, action) {
      state.requesting = true;
    },
    startUploadRecruitingTemplate(state, action) {
      state.requesting = true;
    },
    uploadRecruitingTemplateSuccess(state, action) {
      const { values } = action.payload;
      state.recruitingTemplateData = values;
      state.requesting = false;
    },
    fetchingEnd(state) {
      state.requesting = false;
    },
  },
});

export const {
  startUploadingRecruitingTemplateImage,
  startUploadRecruitingTemplate,
  uploadRecruitingTemplateSuccess,
  fetchingEnd,
} = recruitingTemplateSlice.actions;

export default recruitingTemplateSlice.reducer;
