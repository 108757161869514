import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SlimBoxTip from '../../../components/tips/SlimBoxTip';
import ViewSelectState from '../../../components/select-state/ViewSelectState';
import InfoCell from '../common-info/compoments/InfoCell';
import StatCard from './components/StatCard';

import { OrganizationCommonInfo } from '../common-info/domain/reducer';
import Paths from '../../../utils/route-paths';
import { PAID_FEATURES_LINK } from '../../../utils/constants';
import { TOUR_PAGES, TOURS_CLASS_NAMES } from '../../../components/tour/tourSteps';
import { setTourPage } from '../../globalDomain';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: 30,
  },
  statistics: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  tip: {
    width: 850,
    height: 250,
  },
  infoProjectsUsers: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingBottom: 40,
    flexWrap: 'wrap',
  },
  divider: {
    marginTop: 20,
    marginBottom: 30,
  },
}));

function OrgStat() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { hasAccessToStatistics, statistics, organization }: OrganizationCommonInfo = useSelector(
    (state: any) => state.organizationCommonInfo,
  );

  useEffect(() => {
    dispatch(setTourPage({ pages: [TOUR_PAGES.orgManageStat] }));
    return function cleanUp() {
      dispatch(setTourPage({ pages: [] }));
    };
  }, [dispatch]);

  const handleToKnowClick = () => {
    window.open(PAID_FEATURES_LINK, '_blank', 'noopener, noreferrer');
  };

  const content = () => (
    <div className={clsx(classes.root, TOURS_CLASS_NAMES.orgManageStat.center)}>
      <div className={classes.infoProjectsUsers}>
        <InfoCell
          label="Количество пользователей текущее/максимальное"
          firstParam={organization.publicStats.usersCount}
          secondParam={organization.settings.maxOrganizationUsers}
        />
        <InfoCell
          label="Максимальный размер команды"
          firstParam={organization.settings.maxProjectTeamMembers}
        />
        <InfoCell
          label="Количество проектов текущее/максимальное"
          firstParam={organization.publicStats.activeProjectsCount}
          secondParam={organization.settings.maxOrganizationUsers}
        />
      </div>
      {hasAccessToStatistics ? (
        <div className={classes.statistics}>
          <StatCard
            title="Идеи"
            subtitle="всего"
            value={statistics.ideasCount}
            onClick={() => history.push(Paths.ORGANIZATION_STATISTICS_IDEAS)}
          />
          <StatCard
            title="Рекрутинги"
            subtitle="всего"
            value={statistics.activeRecruitingsCount}
            onClick={() => history.push(Paths.ORGANIZATION_STATISTICS_RECRUITINGS)}
          />
          <StatCard
            title="Проекты"
            subtitle="активные"
            value={statistics.activeProjectsCount}
            onClick={() => history.push(Paths.ORGANIZATION_STATISTICS_PROJECTS)}
          />
        </div>
      ) : (
        <div>
          <SlimBoxTip
            className={classes.tip}
            title="Нет доступа"
            mainText="Полная статистика и мониторинг недоступны в бесплатной версии. Нажмите на кнопку, что узнать о преимуществах платной версии"
            buttonText="Узнать"
            buttonAction={handleToKnowClick}
          />
        </div>
      )}
    </div>
  );

  return <ViewSelectState state={'CONTENT'} content={content} />;
}

export default OrgStat;
