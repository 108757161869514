import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { ru } from 'date-fns/locale';
import addWeeks from 'date-fns/addWeeks';
import differenceInWeeks from 'date-fns/differenceInWeeks';

const toDateString = (date: Date) => format(date, 'yyyy-MM-dd');

const dateStringToDate = (dateStr: string) => parse(dateStr, 'yyyy-MM-dd', new Date());

const dateStringToLocalString = (dateStr: string) =>
  format(dateStringToDate(dateStr), 'd MMMM yyyy', { locale: ru });

const dateToLocalDate = (date: Date) => format(date, 'd MMMM yyyy', { locale: ru });
const dateToLocalDateAndTime = (date: Date) => format(date, 'd MMMM, HH:mm', { locale: ru });

const addWeeksToStringDate = (date: string, weeks: number) => {
  const startDate = dateStringToDate(date);
  const finalDate = addWeeks(startDate, weeks);
  return dateToLocalDate(finalDate);
};

const currentWeekNumber = (startDate) => differenceInWeeks(new Date(), new Date(startDate)) + 1;

const Dates = {
  toDateString,
  dateStringToDate,
  dateStringToLocalString,
  dateToLocalDate,
  addWeeksToStringDate,
  dateToLocalDateAndTime,
  currentWeekNumber,
};

export default Dates;
