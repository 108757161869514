import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ProjectTemplatesForm from './components/Form';
import ViewSelectState from '../../../components/select-state/ViewSelectState';
import PageWrapper from '../../../components/Wrapper';
import ProjectTemplateView from './components/View';

import { fetchProjectTemplate, setLoadingState } from './domain/reducer';

function ProjectTemplate() {
  const { user } = useSelector((state: any) => state.app);
  const dispatch = useDispatch();
  // @ts-ignore
  const { id } = useParams();
  const { state } = useLocation();
  const { componentState, isEditForm, projectTemplateData, errorData } = useSelector(
    (state: any) => state.personalProjectTemplate,
  );

  const fetchData = () => {
    if (user) {
      const payload = { templateId: id, userId: null };
      // @ts-ignore
      if (state?.isPersonal) {
        payload.userId = user.id;
      }
      // @ts-ignore
      if (state?.userId) {
        // @ts-ignore
        payload.userId = state.userId;
      }
      dispatch(fetchProjectTemplate(payload));
    }
  };

  useEffect(fetchData, [id, user, state, dispatch]);

  useEffect(
    () => {
      return function cleanup() {
        dispatch(setLoadingState());
      };
    },
    // eslint-disable-next-line
    [],
  );

  const content = () => {
    if (isEditForm) {
      return <ProjectTemplatesForm userId={user && user.id} />;
    }
    if (id !== 'new') {
      return <ProjectTemplateView template={projectTemplateData} />;
    }
    return null;
  };

  return (
    <PageWrapper dontShowGoBack={isEditForm} header={'Идея проекта'}>
      <ViewSelectState
        state={componentState}
        content={content}
        errorData={errorData}
        errorAction={fetchData}
      />
    </PageWrapper>
  );
}

export default ProjectTemplate;
