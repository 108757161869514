import React from 'react';
import { Field, FormikErrors } from 'formik';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

interface SelectFieldProps {
  name: string;
  label?: string;
  error: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  value: any;
  items: { value: any; name: string }[];
  [someProps: string]: any;
}

function SelectField(props: SelectFieldProps) {
  const { error, name, value, label, items, ...otherProps } = props;
  const labelId = label ? `${name}-select-label` : undefined;

  return (
    <FormControl margin="normal" error={!!error} fullWidth variant="outlined" {...otherProps}>
      {!!label && <InputLabel id={labelId}>{label}</InputLabel>}
      <Field name={name}>
        {({ field }) => (
          <Select
            labelId={labelId}
            label={label}
            id={`${name}-select`}
            value={value}
            onChange={field.onChange(field.name)}
          >
            {items.map((item, index) => (
              <MenuItem key={`${value}-${index}`} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </Field>
      {/*
          // @ts-ignore */}
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}

export default SelectField;
