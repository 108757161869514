import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import Urls from '../../../utils/endpoints';
import { COMPONENT_STATES } from '../../../utils/componentState';
import { errorHandler } from '../../../utils/fetchUtils';
import { LIST_PAGE_SIZE } from '../../../utils/constants';

// dal
export const getPersonalRecruitingList = (
  profileId: string,
  params: { page: number; pageSize: number },
) => axios.get(Urls.PERSONAL_RECRUITING_LIST(profileId), { params });

// saga
function* personalRecruitingRequest(action) {
  try {
    const { userId, currentPage } = action.payload;
    const { data } = yield call(getPersonalRecruitingList, userId, {
      page: currentPage,
      pageSize: LIST_PAGE_SIZE,
    });
    yield put(fetchPersonalRecruitingListSuccess(data));
  } catch (e) {
    errorHandler(e);
    yield put(fetchPersonalRecruitingListFail());
  }
}

export function* watchLoadingPersonalRecruitingList() {
  yield takeEvery(fetchPersonalRecruitingList.type, personalRecruitingRequest);
}

// reducer
const initialState = {
  componentState: COMPONENT_STATES.LOADING,
  recruitingProjects: [] as any[],
  currentPage: 1,
  isLastPage: false,
};

const personalRecruitingListSlice = createSlice({
  name: 'personalRecruitingList',
  initialState,
  reducers: {
    fetchPersonalRecruitingList(state, action) {
      if (action.payload.isFirstLoading) {
        state.componentState = COMPONENT_STATES.LOADING;
        state.currentPage = 1;
        state.recruitingProjects = [];
      }
      action.payload.currentPage = state.currentPage;
    },
    fetchPersonalRecruitingListSuccess(state, action) {
      if (action.payload.content.length === 0) {
        state.componentState = COMPONENT_STATES.EMPTY;
      } else {
        state.componentState = COMPONENT_STATES.CONTENT;
        state.recruitingProjects = [...state.recruitingProjects, ...action.payload.content];
        state.currentPage += 1;
        state.isLastPage = action.payload.last;
      }
    },
    fetchPersonalRecruitingListFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const {
  fetchPersonalRecruitingList,
  fetchPersonalRecruitingListSuccess,
  fetchPersonalRecruitingListFail,
} = personalRecruitingListSlice.actions;

export default personalRecruitingListSlice.reducer;
