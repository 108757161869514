import * as Yup from 'yup';

import { MAX_SYMBOLS_NUMBER_FOR_LONG_TEXT } from '../../../utils/constants';

const MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT = 1000;

const TemplateSchema = Yup.object().shape({
  siteUrl: Yup.string()
    .trim()
    .matches(
      /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
      'Некорректный адрес url',
    )
    .max(
      MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT,
      `Адрес сайта не может быть длиннее ${MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT} символов`,
    ),
  location: Yup.string()
    .trim()
    .max(
      MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT,
      `Цель не может быть длиннее ${MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT} символов`,
    ),
  description: Yup.string()
    .transform((value) => {
      return value;
    })
    .max(
      MAX_SYMBOLS_NUMBER_FOR_LONG_TEXT,
      `Описание не может быть длиннее ${MAX_SYMBOLS_NUMBER_FOR_LONG_TEXT} символов`,
    ),
});

export default TemplateSchema;
