import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import ViewSelectState from '../../components/select-state/ViewSelectState';
import ProfileAvatar from '../../components/ProfileAvatar';
import CustomButton from '../../components/CustomButton';
import OrganizationBanner from '../../components/OrganizationBanner';

import { TOURS_CLASS_NAMES } from '../../components/tour/tourSteps';
import Paths from '../../utils/route-paths';
import { USER_TYPE_TITLES } from '../../utils/constants';
import { makeFullName } from '../../utils/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 800,
    display: 'flex',
  },
  orgAvatarInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 34,
  },
  avatarBox: {
    paddingBottom: 40,
  },
  orgInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  typeText: {
    fontWeight: 600,
    fontSize: 18,
    color: '#78909C',
  },
  nameText: {
    fontSize: 36,
    fontWeight: 500,
    lineHeight: 1.2,
  },
  orgText: {
    fontSize: 24,
    fontWeight: 300,
    lineHeight: 2.8,
  },
  emailText: {
    fontSize: 18,
    fontWeight: 500,
    color: '#78909C',
  },
  orgBanner: {
    marginTop: 16,
  },
}));

function OrgCommonInfo() {
  const classes = useStyles();
  const history = useHistory();
  const { user, avatarUrl, componentState } = useSelector((state: any) => state.app);

  const content = () => (
    <div className={clsx(classes.root, TOURS_CLASS_NAMES.orgManageInfo.center)}>
      <div className={classes.orgAvatarInfo}>
        <div className={classes.orgInfo}>
          <ProfileAvatar
            className={classes.avatarBox}
            avatarUrl={avatarUrl}
            userFirstName={user.firstName}
            userLastName={user.lastName}
          />
          <CustomButton
            variant="outlined"
            color="primary"
            onClick={() => history.push(`${Paths.USER_PROFILE_FORM}`)}
          >
            Редактировать
          </CustomButton>
        </div>
      </div>

      <div className={classes.orgInfo}>
        <Typography className={classes.typeText}>{USER_TYPE_TITLES[user.type]}</Typography>
        <Typography className={classes.nameText}>{makeFullName(user)}</Typography>
        <Typography className={classes.emailText}>{user.email}</Typography>
        {!!user.organization && (
          <OrganizationBanner
            className={classes.orgBanner}
            id={user.organization.id}
            name={user.organization.shortName}
            avatarUrl={user.organization.avatar}
          />
        )}
      </div>
    </div>
  );

  return <ViewSelectState state={componentState} content={content} />;
}

export default OrgCommonInfo;
