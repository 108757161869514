import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import SimplePopover from '../Popover';
import ColoredAvatar from '../ColoredAvatar';

import { CommentSchema } from './validationSchema';
import { PALETTE } from '../../utils/palette';
import { Comment as CommentType } from '../../utils/types';
import { COMPONENT_STATES } from '../../utils/componentState';

const ruLocale = require('date-fns/locale/ru/index.js');

interface CommentProps {
  comment: CommentType;
  isFirstInput?: boolean;
  onSave: (v) => void;
  onRemove?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    paddingBottom: 20,
  },
  commentBox: {
    width: '65%',
    marginLeft: 10,
  },
  authorName: {
    marginRight: 16,
    color: PALETTE().greenBlue,
    fontWeight: 700,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  comment: {
    marginBottom: 20,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: PALETTE(0.25).gray,
    borderRadius: 4,
    paddingLeft: 8,
    paddingRight: 8,
    overflow: 'hidden',
  },
  editCommentOpen: {
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  editCommentClose: {
    height: 36,
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  input: {
    marginBottom: 10,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  avatarBox: {
    paddingTop: 4,
  },
  avatar: {
    width: 30,
    height: 30,
  },
  saveButton: {
    color: PALETTE().green,
  },
  cancelButton: {
    color: PALETTE().gray,
  },
  commentText: {
    maxWidth: 'fit-content',
    backgroundColor: PALETTE(0.15).gray,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 8,
    paddingTop: 6,
    borderRadius: 8,
  },
  nameAndTime: {
    display: 'flex',
  },
}));

function Comment(props: CommentProps) {
  const {
    comment,
    comment: { author, text, updatedAt, edited },
    isFirstInput = false,
    onSave,
    onRemove,
  } = props;
  const initialValues = comment;
  const classes = useStyles();
  const { componentState, commentsLoading } = useSelector((state: any) => state.comments);
  const [editCommentOpen, setEditCommentOpen] = useState(false);
  const distanceToNow = updatedAt
    ? formatDistanceToNow(new Date(updatedAt), { locale: ruLocale })
    : '';
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const onSubmit = (values, { resetForm }) => {
    onSave(values);
    setEditCommentOpen(false);
    resetForm();
  };

  const handleOpenEdit = (values) => {
    setEditCommentOpen(true);
  };

  return (
    <div className={classes.root}>
      <div className={classes.avatarBox}>
        <ColoredAvatar className={classes.avatar} user={author} size="small" />
      </div>
      <div className={classes.commentBox}>
        {!isFirstInput && (
          <div className={classes.nameAndTime}>
            <Typography className={classes.authorName} variant="subtitle2" gutterBottom>
              {author.firstName} {author.lastName}
            </Typography>
            {edited && (
              <Typography style={{ marginRight: 16 }} variant="caption" color="textSecondary">
                Изменен
              </Typography>
            )}
            <Typography variant="caption" color="textSecondary">
              {distanceToNow}
            </Typography>
          </div>
        )}
        {editCommentOpen || isFirstInput ? (
          <Formik
            initialValues={initialValues}
            validationSchema={CommentSchema}
            onSubmit={onSubmit}
          >
            {({ values, handleChange, isValid, errors, dirty }) => {
              return (
                <Form className={classes.form}>
                  <div
                    className={clsx(classes.comment, {
                      [classes.editCommentOpen]: editCommentOpen,
                      [classes.editCommentClose]: !editCommentOpen,
                    })}
                  >
                    <div className={classes.input}>
                      <InputBase
                        multiline
                        name="text"
                        value={values.text}
                        onChange={handleChange}
                        margin="none"
                        fullWidth
                        placeholder="Напишите комментарий"
                        onFocus={() => setEditCommentOpen(true)}
                      />
                    </div>
                    {
                      <div className={classes.buttons}>
                        <IconButton
                          className={classes.saveButton}
                          type="submit"
                          size="small"
                          disabled={
                            !isValid ||
                            !dirty ||
                            commentsLoading ||
                            componentState === COMPONENT_STATES.LOADING
                          }
                        >
                          <CheckCircleIcon />
                        </IconButton>
                        <IconButton
                          className={classes.cancelButton}
                          size="small"
                          onClick={() => setEditCommentOpen(false)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    }
                  </div>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <div>
            <div className={classes.commentText}>
              <Typography variant="subtitle2">{text}</Typography>
            </div>
            <div>
              <Link
                component="button"
                variant="caption"
                color="textSecondary"
                onClick={handleOpenEdit}
              >
                Редактировать
              </Link>
              <Link
                component="button"
                variant="caption"
                color="textSecondary"
                style={{ marginLeft: 10 }}
                onClick={handleOpenPopover}
              >
                Удалить
              </Link>
            </div>
          </div>
        )}
      </div>
      {/* Всплывающее окно удаления комментария */}
      <SimplePopover
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        mainHandle={onRemove}
        mainText="Удаление комментария необратимо"
        okButtonText="Удалить"
        cancelButtonText="Отменить"
      />
    </div>
  );
}

export default Comment;
