import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// @ts-ignore
import { FontWeightProperty } from 'csstype';
import { PALETTE } from '../utils/palette';

interface MenuItemProps {
  onClick: () => void;
  Icon: JSX.Element;
  text: string;
  fontWeight?: FontWeightProperty;
  isSelected?: boolean;
  className?: string;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 8,
    marginRight: 8,
    width: 'inherit',
    borderRadius: 4,
    paddingLeft: 8,
  },
  icon: {
    minWidth: 48,
  },
}));

function OrgMenuItem(props: MenuItemProps): JSX.Element {
  const {
    onClick,
    Icon,
    text,
    fontWeight = 'normal',
    isSelected = false,
    className = '',
    disabled = false,
  } = props;
  const classes = useStyles();

  return (
    <ListItem
      className={clsx(classes.root, className)}
      selected={isSelected}
      button
      onClick={onClick}
      disabled={disabled}
    >
      <ListItemIcon
        classes={{
          root: classes.icon,
        }}
      >
        {Icon}
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{
          style: { fontWeight, whiteSpace: 'nowrap', color: PALETTE().gray },
        }}
        primary={text}
      />
    </ListItem>
  );
}

export default OrgMenuItem;
