import * as Yup from 'yup';

const required = 'Обязательное поле';

export const ConfirmSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^[A-Za-z0-9-'.,&@:?!()$#%_+*^/\\]+$/,
      'Пароль может содержать только цифры, латинские буквы и символы: \'".,&@:?!()$#%_+*^/\\+$',
    )
    .min(8, 'Пароль не может быть короче 8 символов')
    .required(required),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Пароли не совпадают')
    .required(required),
});
