import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CommonProjectView, { CommonProjectData } from '../../../../components/CommonProjectView';
import TemplateActionBlock from './TemplateActionBlock';
import TemplateStatusLabel from '../../components/TemplateSatatusLable';

import { Template } from '../../../../utils/types';
import { setTourPage } from '../../../globalDomain';
import { IDEA_STATUSES } from '../../../../utils/constants';
import { TOUR_PAGES } from '../../../../components/tour/tourSteps';
import { COMPONENT_STATES } from '../../../../utils/componentState';

interface ProjectTemplateViewProps {
  template: Template;
}

const ideaTours = {
  [IDEA_STATUSES.DRAFT]: TOUR_PAGES.ideaViewDraft,
  [IDEA_STATUSES.OPEN]: TOUR_PAGES.ideaView,
};

function ProjectTemplateView({ template }: ProjectTemplateViewProps) {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.app);
  const { componentState } = useSelector((state: any) => state.personalProjectTemplate);
  const {
    name,
    status,
    goal,
    about,
    duration,
    images,
    attachments,
    owner,
    organizationOnly,
    organization,
  } = template;

  const currentIdeaTour = ideaTours[status];

  useEffect(
    () => {
      if (currentIdeaTour && componentState === COMPONENT_STATES.CONTENT) {
        dispatch(setTourPage({ pages: [currentIdeaTour] }));
      }
      return function cleanUp() {
        dispatch(setTourPage({ pages: [] }));
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, status],
  );

  const data: CommonProjectData = {
    name,
    status,
    goal,
    about,
    duration,
    images,
    attachments,
    user: owner,
    isOwner: user?.id === owner.id,
    ownerTitle: 'Вы автор идеи проекта',
  };

  return (
    <CommonProjectView
      data={data}
      userLabel={!data.isOwner ? 'Автор идеи' : ''}
      organization={organization}
      organizationLabel="Место появления идеи"
      statusComponent={<TemplateStatusLabel status={status} organizationOnly={organizationOnly} />}
    >
      <TemplateActionBlock />
    </CommonProjectView>
  );
}

export default ProjectTemplateView;
