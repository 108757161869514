import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import MuiButton from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CharterTextView from '../components/CharterTextView';

import { setCharterState, setWorkingTemplateVersion } from '../domain';
import { CharterReduxState, CharterTemplate } from '../utils/types';
import { TOURS_CLASS_NAMES } from '../../../../components/tour/classNames';

const useStyles = makeStyles(() => ({
  select: {
    width: 400,
    marginBottom: 30,
  },
  buttonsBox: {
    marginTop: 10,
  },
  acceptors: {
    marginTop: 30,
  },
}));

function Variants() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { charterTemplates, workingTemplateVersion }: CharterReduxState = useSelector(
    (state: any) => state.workspaceProjectCharter,
  );
  const [currentTemplateIndex, setCurrentTemplateIndex] = useState(0);

  const handleSelectVariant = (e) => {
    const index = e.target.value;
    setCurrentTemplateIndex(index);
    dispatch(setWorkingTemplateVersion(charterTemplates[index]));
  };

  const handleReStartCharterMaster = () => {
    dispatch(setCharterState({ current: 'steps' }));
  };

  return (
    <div>
      <div className={clsx(classes.select, TOURS_CLASS_NAMES.charter.templateList)}>
        <Select
          id="charter-select"
          value={currentTemplateIndex}
          onChange={handleSelectVariant}
          fullWidth
        >
          {charterTemplates.map((template: CharterTemplate, index) => (
            <MenuItem key={template.id} value={index}>
              <div style={{ overflow: 'hidden' }}>
                <Typography style={{ marginLeft: 10 }}>{template?.name}</Typography>
              </div>
            </MenuItem>
          ))}
        </Select>
      </div>
      <Typography color="textSecondary" gutterBottom>
        Пример устава по шаблону
      </Typography>
      <CharterTextView charter={workingTemplateVersion?.example} />
      <MuiButton
        className={TOURS_CLASS_NAMES.charter.startMasterButton}
        variant="contained"
        color="primary"
        size="large"
        onClick={handleReStartCharterMaster}
      >
        Запустить мастер устава
      </MuiButton>
    </div>
  );
}

export default Variants;
