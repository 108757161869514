import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { errorHandler } from '../../../../utils/fetchUtils';
import { canvasToBlob } from '../../../../utils/utils';
import {
  fetchChangeAvatar,
  fetchChangeAvatarFail,
  fetchChangeAvatarSuccess,
  fetchInfo,
  fetchInfoFail,
  fetchInfoSuccess,
  fetchToggleModeration,
  fetchToggleModerationFail,
  fetchToggleModerationSuccess,
  fetchUpdateOrgInfo,
  fetchUpdateOrgInfoFail,
  fetchUpdateOrgInfoSuccess,
} from './reducer';

import {
  getOrganization,
  getOrganizationStatistic,
  removeOrgAvatar,
  toggleModeration,
  updateOrgAvatar,
  updateOrgInfo,
} from './dal';

function* requestInfo(action) {
  try {
    const { orgId } = action.payload;
    const { data: organization } = yield call(getOrganization, orgId);
    let statistics;
    if (organization?.settings?.hasAccessToStatistics) {
      const { data } = yield call(getOrganizationStatistic, orgId);
      statistics = data;
    }
    yield put(fetchInfoSuccess({ organization, statistics }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchInfoFail());
  }
}

function* requestToggleModeration(action) {
  try {
    const { orgId } = action.payload;
    const { data } = yield call(toggleModeration, orgId);
    yield put(fetchToggleModerationSuccess({ data }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchToggleModerationFail());
  }
}

function* requestUpdateOrgInfo(action) {
  try {
    const { orgId, values } = action.payload;
    const { data } = yield call(updateOrgInfo, orgId, values);
    yield put(fetchUpdateOrgInfoSuccess({ data }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchUpdateOrgInfoFail());
  }
}

function* requestUpdateOrgAvatar(action) {
  try {
    const { orgId, image, imageName } = action.payload;
    if (image) {
      const { blobImage, blobImageName } = yield canvasToBlob(image, imageName, 400);
      const { data } = yield call(updateOrgAvatar, orgId, blobImage, blobImageName);
      yield put(fetchChangeAvatarSuccess(data));
    } else {
      yield call(removeOrgAvatar, orgId);
      yield put(fetchChangeAvatarSuccess({}));
    }
  } catch (e) {
    errorHandler(e);
    yield put(fetchChangeAvatarFail());
  }
}

export function* watchLoadingOrganizationInfo() {
  yield takeEvery(fetchInfo.type, requestInfo);
  yield takeLatest(fetchToggleModeration.type, requestToggleModeration);
  yield takeLatest(fetchUpdateOrgInfo.type, requestUpdateOrgInfo);
  yield takeLatest(fetchChangeAvatar.type, requestUpdateOrgAvatar);
}
