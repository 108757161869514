import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import ColoredAvatar from '../../../../components/ColoredAvatar';

import { NoOrgUser } from '../../../../utils/types';

interface UserInfoBlockProps {
  user: NoOrgUser;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 10,
    display: 'flex',
  },
  textBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 10,
  },
  avatar: {
    width: 60,
    height: 60,
  },
}));

function UserInfoBlock(props: UserInfoBlockProps) {
  const classes = useStyles();
  const { user, className } = props;

  return (
    <div className={clsx(classes.root, !!className && className)}>
      <ColoredAvatar className={classes.avatar} user={user} />
      <div className={classes.textBlock}>
        <Typography variant="h3">
          {user?.firstName} {user?.lastName}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Представитель организации
        </Typography>
      </div>
    </div>
  );
}

export default UserInfoBlock;
