import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { getExt } from '../utils/utils';
import { UPLOAD_FILE_TYPES } from '../utils/constants';
import Urls from '../utils/endpoints';
import { PALETTE } from '../utils/palette';
import { Attachment } from '../utils/types';

interface AttachmentsView {
  files: Attachment[];
  onRemoveFile?: (filesIds: string[], index: number) => void;
  className?: string;
}

const useStyles = makeStyles(() => ({
  attachmentsBox: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  addAttachmentBtn: {
    marginTop: 16,
  },
  fileIcon: {
    height: 40,
    marginRight: 4,
  },
  attachmentItem: {
    maxWidth: 'inherit',
    display: 'flex',
    alignItems: 'center',
    marginRight: 4,
    marginBottom: 4,
    marginLeft: 4,
    marginTop: 4,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 8,
    paddingTop: 8,
    borderRadius: 16,
    backgroundColor: PALETTE(0.05).gray,
  },
}));

function AttachmentsView({ files, onRemoveFile, className = '' }: AttachmentsView) {
  const classes = useStyles();

  const handleOnRemove = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    const filesIds = newFiles.map((att) => att.id);
    if (onRemoveFile) {
      onRemoveFile(filesIds, index);
    }
  };

  return (
    <div className={clsx(classes.attachmentsBox, className)}>
      {files.map((att, index) => {
        const fileExt = getExt(att.filename);
        const fileType = UPLOAD_FILE_TYPES.includes(fileExt) ? fileExt : 'file';
        return (
          <div key={att.id} className={classes.attachmentItem}>
            <img className={classes.fileIcon} src={Urls.ASSET_IMAGE(fileType)} alt="file_icon" />
            <Link
              href={att.url}
              style={{ fontWeight: 600, overflow: 'hidden', textOverflow: 'ellipsis' }}
              variant="subtitle2"
              color="textSecondary"
            >
              {att.filename}
            </Link>
            {!!onRemoveFile && (
              <IconButton size="small" onClick={() => handleOnRemove(index)}>
                <CloseIcon fontSize="small" style={{ color: PALETTE().greenBlue }} />
              </IconButton>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default AttachmentsView;
