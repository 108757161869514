import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';

import TableSkeleton from '../Skeletons/TableSkeleton';
import TableErrorDataLoading from './TableErrorDataLoading';
import TableEmptyData from './TableEmtyData';
import { COMPONENT_STATES } from '../../utils/componentState';
import { ComponentState, TablePaginationType } from '../../utils/types';

interface Row {
  [key: string]: any;
}

export interface Column {
  title: string;
  handler?: (v, row) => string;
  props?: TableCellProps;
  fieldName: string;
  style?: ((v) => { [key: string]: string }) | { [key: string]: string };
}

interface CustomTableProps {
  columns: Column[];
  tableData: Row[];
  containerClassName?: string;
  tableClassName?: string;
  componentState: ComponentState;
  showColumnNumber?: boolean;
  onRowClick?: (e, index) => void;
  totalElements: number;
  pagination: TablePaginationType;
  setPagination: (pagination: TablePaginationType) => void;
  firstRowClassName?: string;
  rowClassname?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: '1px solid #A6A6A6',
    borderRadius: 5,
  },
  main: {
    width: 800,
    height: 'min-content',
    minHeight: '60%',
    maxHeight: '100%',
  },
  table: {
    minWidth: 500,
  },
  hover: {
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(3),
  },
  headerText: {
    fontSize: 26,
    fontWeight: 300,
  },
}));

function CustomTable(props: CustomTableProps) {
  const classes = useStyles();
  const {
    columns,
    tableData,
    containerClassName,
    tableClassName,
    componentState,
    showColumnNumber = true,
    onRowClick,
    totalElements,
    // для корректной работы параметр должен быть добавлен в массив useEffect
    pagination: { page, pageSize },
    setPagination,
    firstRowClassName,
    rowClassname,
  } = props;
  const isContentState = componentState === COMPONENT_STATES.CONTENT;
  const offset = (page - 1) * pageSize;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({ page: newPage + 1, pageSize });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({ page: 1, pageSize: parseInt(event.target.value, 10) });
  };

  const handleClickRow = (e, index) => {
    if (onRowClick) {
      onRowClick(e, index);
    }
  };

  const tableBody = () => (
    <>
      {tableData.map((row, index) => (
        <TableRow
          hover
          className={clsx(classes.hover, index === 0 && firstRowClassName, rowClassname)}
          key={row.id}
          data-id={row.id}
          data-row={JSON.stringify(row)}
          onClick={(e) => handleClickRow(e, index)}
        >
          {showColumnNumber && (
            <TableCell align="center" size="small">
              {index + 1 + offset}
            </TableCell>
          )}
          {columns.map(({ fieldName, handler, props = {}, style }, index) => (
            <TableCell
              key={index}
              style={typeof style === 'function' ? style(row[fieldName]) : style}
              {...props}
            >
              {handler ? handler(row[fieldName], row) : row[fieldName]}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );

  const mainContent = () => {
    const columnsNumber = showColumnNumber ? columns.length + 1 : columns.length;
    switch (componentState) {
      case 'LOADING':
        return (
          <TableBody>
            <TableSkeleton
              columns={columns}
              rows={pageSize}
              cells={columns.length}
              showColumnNumber
            />
          </TableBody>
        );
      case 'CONTENT':
        return <TableBody>{tableBody()}</TableBody>;
      case 'EMPTY':
        return (
          <TableFooter>
            <TableEmptyData columnsNumber={columnsNumber} />
          </TableFooter>
        );
      case 'ERROR':
        return (
          <TableFooter>
            <TableErrorDataLoading columnsNumber={columnsNumber} />
          </TableFooter>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <TableContainer className={clsx(classes.root, containerClassName)}>
        <Table className={clsx(classes.table, tableClassName)}>
          <TableHead>
            <TableRow>
              {showColumnNumber && (
                <TableCell align="center" size="small">
                  №
                </TableCell>
              )}
              {columns.map(({ title, props = {} }, index) => (
                <TableCell key={index} {...props}>
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {mainContent()}
        </Table>
      </TableContainer>
      {isContentState && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalElements}
          rowsPerPage={pageSize}
          page={page - 1}
          onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
}

export default CustomTable;
