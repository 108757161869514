import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { createSlice } from '@reduxjs/toolkit';

import { errorHandler } from '../../../utils/fetchUtils';
import Urls from '../../../utils/endpoints';
import Paths from '../../../utils/route-paths';

// dal
export const registerNewUser = (payload) => axios.post(Urls.NEW_USER_REGISTRATION, payload);

// saga
function* registerNewUserRequest(action) {
  try {
    const { values, history } = action.payload;
    yield call(() => registerNewUser(values));
    toast.success('Вы успешно зарегистрированы. Данные для входа высланы на указанную вами почту');
    history.push(Paths.LOGIN);
    yield put(userRegistrationEnd());
  } catch (e) {
    errorHandler(e);
    yield put(userRegistrationEnd());
  }
}

export function* watchRegisterNewUserRequest() {
  yield takeEvery(userRegistrationRequesting.type, registerNewUserRequest);
}

// reducer
const initialState = {
  request: false,
};

const PasswordResetSlice = createSlice({
  name: 'newUserRegistration',
  initialState,
  reducers: {
    userRegistrationRequesting(state, action) {
      state.request = true;
    },
    userRegistrationEnd(state) {
      state.request = false;
    },
  },
});

export const { userRegistrationRequesting, userRegistrationEnd } = PasswordResetSlice.actions;

export default PasswordResetSlice.reducer;
