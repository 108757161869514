import { put, takeEvery, call } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { COMPONENT_STATES } from '../../../../utils/componentState';
import { errorHandler } from '../../../../utils/fetchUtils';
import { ComponentState, Recruiting } from '../../../../utils/types';
import { LIST_PAGE_SIZE } from '../../../../utils/constants';
import { getOrganizationRecruitings } from '../../statistics/recruitings/domain';

// dal

// saga
function* orgPublicRecruitingRequest(action) {
  try {
    const { orgId, currentPage } = action.payload;
    const { data } = yield call(getOrganizationRecruitings, orgId, {
      page: currentPage,
      pageSize: LIST_PAGE_SIZE,
    });
    yield put(fetchOrgPublicRecruitingSuccess({ data }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchOrgPublicRecruitingFail());
  }
}

export function* watchLoadingOrgPublicRecruiting() {
  yield takeEvery(fetchOrgPublicRecruiting.type, orgPublicRecruitingRequest);
}

export interface OrgPublicRecruitingState {
  componentState: ComponentState;
  recruiting: Recruiting[];
  currentPage: number;
  isLastPage: boolean;
}

// reducer
const initialState: OrgPublicRecruitingState = {
  componentState: COMPONENT_STATES.LOADING,
  recruiting: [],
  currentPage: 1,
  isLastPage: false,
};

const orgPublicRecruitingSlice = createSlice({
  name: 'orgPublicRecruiting',
  initialState,
  reducers: {
    fetchOrgPublicRecruiting(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
      if (action.payload.isFirstLoading) {
        state.currentPage = 1;
        state.recruiting = [];
      }
      action.payload.currentPage = state.currentPage;
    },
    fetchOrgPublicRecruitingSuccess(state, action) {
      const { data } = action.payload;
      if (data.content?.length === 0) {
        state.componentState = COMPONENT_STATES.EMPTY;
      } else {
        state.componentState = COMPONENT_STATES.CONTENT;
        state.recruiting = [...state.recruiting, ...data.content];
        state.currentPage += 1;
        state.isLastPage = data.last;
      }
    },
    fetchOrgPublicRecruitingFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const {
  fetchOrgPublicRecruiting,
  fetchOrgPublicRecruitingSuccess,
  fetchOrgPublicRecruitingFail,
} = orgPublicRecruitingSlice.actions;

export default orgPublicRecruitingSlice.reducer;
