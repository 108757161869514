import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Urls from '../../../../../utils/endpoints';
import { COMPONENT_STATES } from '../../../../../utils/componentState';
import { errorHandler } from '../../../../../utils/fetchUtils';
import { KANBAN_CARD_START_WEIGHT } from '../../../../../utils/constants';
import { addCard, updateCard } from '../../domain';

// dal
export const getCard = (projectId, cardId) => axios.get(Urls.KANBAN_BOARD_CARDS(projectId, cardId));

// saga
function* requestGetCard(action) {
  try {
    const {
      projectId,
      cardId,
      column: { cards },
    } = action.payload;
    if (cardId === 'new') {
      const weight = cards.length ? cards[cards.length - 1].weight * 2 : KANBAN_CARD_START_WEIGHT;
      yield put(setKanbanCardInitialForNewCard({ weight }));
    } else {
      const { data: cardData } = yield call(getCard, projectId, cardId);
      if (cardData.executorId === null) {
        cardData.executorId = '';
      }
      yield put(fetchKanbanCardSuccess({ cardData }));
    }
    yield put(setKanbanCardOpen(true));
  } catch (e) {
    errorHandler(e);
    yield put(fetchKanbanCardFail());
  }
}

function* requestExistedCard(action) {
  try {
    const { projectId, cardId } = action.payload;

    const { data: cardData } = yield call(getCard, projectId, cardId);
    if (cardData.executorId === null) {
      cardData.executorId = '';
    }
    yield put(fetchKanbanCardSuccess({ cardData }));
    yield put(setKanbanCardOpen(true));
  } catch (e) {
    errorHandler(e);
    yield put(fetchKanbanCardFail());
  }
}

function* requestUploadTask(action) {
  try {
    const { projectId, card } = action.payload;

    if (card.executorId.length === 0) {
      card.executorId = null;
    }

    if (card.id) {
      const { data: cardData } = yield call(updateCard, projectId, card.id, card);
      if (cardData.executorId === null) {
        cardData.executorId = '';
      }
      yield put(fetchKanbanCardSuccess({ cardData }));
    } else {
      const { data: cardData } = yield call(addCard, projectId, card);
      if (cardData.executorId === null) {
        cardData.executorId = '';
      }
      yield put(fetchKanbanCardSuccess({ cardData }));
    }
  } catch (e) {
    errorHandler(e);
    yield put(fetchKanbanCardFail());
  }
}

export function* watchLoadingCard() {
  yield takeLatest(fetchKanbanCard.type, requestGetCard);
  yield takeLatest(fetchUploadCard.type, requestUploadTask);
  yield takeLatest(fetchExistedKanbanCard.type, requestExistedCard);
}

// reducer
const initialState = {
  componentState: COMPONENT_STATES.CONTENT,
  openedCardId: '',
  openedCard: {
    id: '',
    name: '',
    description: '',
    taskEndDate: null,
    executorId: '',
    expectedResult: '',
    attachmentIds: [] as string[],
    attachments: [] as any[],
    weight: 0,
    columnId: '',
    columnName: '',
    planStepId: null,
  },
  currentColumn: {
    id: '',
    name: '',
  },
  isCardOpen: false,
};

const kanbanCardSlice = createSlice({
  name: 'kanbanCard',
  initialState,
  reducers: {
    fetchKanbanCard(state, action) {
      const {
        column: { id, name },
        cardId,
      } = action.payload;
      state.componentState = COMPONENT_STATES.LOADING;
      state.openedCardId = cardId;
      state.currentColumn.id = id;
      state.currentColumn.name = name;
    },
    fetchExistedKanbanCard(state, action: PayloadAction<{ projectId: string; cardId: string }>) {},
    setKanbanCardInitialForNewCard(state, action) {
      const { weight } = action.payload;
      state.componentState = COMPONENT_STATES.CONTENT;
      state.openedCard = { ...initialState.openedCard, weight };
    },
    fetchKanbanCardSuccess(state, action) {
      const { cardData } = action.payload;
      state.componentState = COMPONENT_STATES.CONTENT;
      state.openedCard = cardData;
    },
    fetchUploadCard(state, action) {},
    fetchKanbanCardFail(state) {
      state.componentState = COMPONENT_STATES.CONTENT;
    },
    setKanbanCardOpen(state, action: PayloadAction<boolean>) {
      state.isCardOpen = action.payload;
    },
  },
});

export const {
  fetchKanbanCard,
  fetchKanbanCardSuccess,
  fetchKanbanCardFail,
  setKanbanCardInitialForNewCard,
  fetchUploadCard,
  fetchExistedKanbanCard,
  setKanbanCardOpen,
} = kanbanCardSlice.actions;

export default kanbanCardSlice.reducer;
