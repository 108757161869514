import React from 'react';
import { FormikErrors } from 'formik';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

import { symbolsLeft } from '../utils/utils';
import { MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT } from '../utils/constants';

interface TextFieldWithCounterProps {
  name: string;
  label?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  value: any;
  onChange: any;
  maxSymbols?: number;
  hasCounter?: boolean;
  multiline?: boolean;
  className?: string;
  [someProps: string]: any;
}

function TextFieldWithCounter(props: TextFieldWithCounterProps) {
  const {
    name,
    label,
    value,
    onChange,
    maxSymbols = MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT,
    error,
    hasCounter = true,
    multiline = true,
    className,
    ...otherProps
  } = props;
  const symbolsCounter = hasCounter ? symbolsLeft(value, maxSymbols, 15) : 0;
  return (
    <TextField
      className={className}
      variant="outlined"
      // type="number"
      error={!!error}
      margin="normal"
      id={name}
      label={label}
      name={name}
      multiline={multiline}
      value={value}
      onChange={onChange}
      // @ts-ignore
      helperText={error}
      InputProps={{
        endAdornment: hasCounter ? (
          <InputAdornment position="end">
            <Typography variant="caption" color={symbolsCounter < 0 ? 'error' : 'primary'}>
              {symbolsCounter}
            </Typography>
          </InputAdornment>
        ) : null,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        maxLength: maxSymbols,
      }}
      {...otherProps}
    />
  );
}

export default TextFieldWithCounter;
