import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import PageWrapper from '../../../../components/Wrapper';
import CustomTable, { Column } from '../../../../components/table/CustomTable';

import { AppState } from '../../../globalDomain';
import { fetchStatRecruitings, StatRecruitingsType } from './domain';
import {
  RECRUITING_STATUSES_FOR_STATISTICS_FILTER,
  RECRUITING_STATUSES_FOR_STATISTICS_TABLE,
} from '../../../../utils/constants';
import { makeShortName } from '../../../../utils/utils';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paths from '../../../../utils/route-paths';

interface StatRecruitingsProps {}

const columns: Column[] = [
  { title: 'Название', fieldName: 'name' },
  {
    title: 'Статус',
    fieldName: 'status',
    handler: (v) => RECRUITING_STATUSES_FOR_STATISTICS_TABLE[v].titleLow,
    props: { align: 'right' },
    style: (v) => ({ color: RECRUITING_STATUSES_FOR_STATISTICS_TABLE[v].color }),
  },
  {
    title: 'Ментор',
    fieldName: 'mentor',
    handler: (v) => makeShortName(v),
    props: { align: 'right' },
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 800,
  },
  filtersBox: {
    marginBottom: 10,
    display: 'flex',
  },
  filter: {
    marginLeft: 10,
    width: 250,
  },
  input: {
    color: (props: { filterStatus: string }) =>
      props.filterStatus
        ? RECRUITING_STATUSES_FOR_STATISTICS_TABLE[props.filterStatus].color
        : undefined,
  },
}));

function StatRecruitings(props: StatRecruitingsProps) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user }: AppState = useSelector((state: any) => state.app);
  const { componentState, recruitings }: StatRecruitingsType = useSelector(
    (state: any) => state.statRecruitings,
  );
  const [filters, setFilters] = useState({
    status: '',
  });
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const classes = useStyles({ filterStatus: filters.status });

  useEffect(() => {
    if (user) {
      dispatch(fetchStatRecruitings({ orgId: user?.organization.id, pagination, filters }));
    }
  }, [dispatch, user, pagination, filters]);

  const handleChangeFilter = (event) => {
    setFilters((prevState) => ({ ...prevState, status: event.target.value }));
  };

  const handleOnRowClick = useCallback(
    (e) => {
      const { id, row } = e.currentTarget.dataset;
      const mentorId = JSON.parse(row).mentorId;
      history.push(`${Paths.COMMON_RECRUITING_PROJECTS}/${id}`, { userId: mentorId });
    },
    [history],
  );

  const content = () => (
    <div className={classes.root}>
      <div className={classes.filtersBox}>
        <TextField
          className={classes.filter}
          select
          variant="outlined"
          value={filters.status}
          label="Статус"
          helperText="Отфильтруйте рекрутинг по статусу"
          onChange={handleChangeFilter}
          InputProps={{
            className: classes.input,
          }}
        >
          <MenuItem value="">
            <em>Статус не выбран</em>
          </MenuItem>
          {Object.keys(RECRUITING_STATUSES_FOR_STATISTICS_FILTER).map((status) => (
            <MenuItem
              style={{ color: RECRUITING_STATUSES_FOR_STATISTICS_TABLE[status].color }}
              key={status}
              value={status}
            >
              {RECRUITING_STATUSES_FOR_STATISTICS_TABLE[status].titleLow}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <CustomTable
        columns={columns}
        tableData={recruitings}
        componentState={componentState}
        totalElements={1}
        pagination={pagination}
        setPagination={setPagination}
        onRowClick={handleOnRowClick}
      />
    </div>
  );

  return <PageWrapper header="Статистика организации по Рекрутингам">{content()}</PageWrapper>;
}

export default StatRecruitings;
