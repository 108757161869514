import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as Tips } from '../../resources/tips.svg';
import { PALETTE } from '../../utils/palette';

interface PulseIcon {
  className?: string;
}

const useStyles = makeStyles(() => ({
  icon: {
    width: 30,
    height: 30,
    color: PALETTE().red,
    borderRadius: '50%',
    animation: '$pulse 10s infinite',
  },
  '@keyframes pulse': {
    '0%': {
      'box-shadow': '0 0 0 0 rgba(234,78,27, 0.4)',
    },
    '10%': {
      'box-shadow': '0 0 0 10px rgba(234,78,27, 0)',
    },
    '20%': {
      'box-shadow': '0 0 0 0 rgba(234,78,27, 0)',
    },
  },
}));

const pulseIcon = () => ({ className, ...props }: PulseIcon) => {
  const classes = useStyles();

  return (
    <SvgIcon className={clsx(classes.icon, className)} {...props}>
      <Tips />
    </SvgIcon>
  );
};

export default pulseIcon;
