import {
  PALETTE,
  PROJECT_TEMPLATE_STATUSES_BACK_PALETTE,
  RECRUITING_STATUSES_PALETTE,
} from './palette';
import { User, Project } from './types';

export const IDEA_STATUSES_TITLES = {
  DRAFT: 'ЧЕРНОВИК',
  MODERATION: 'МОДЕРАЦИЯ',
  OPEN: 'ПУБЛИЧНЫЙ',
  HIDDEN: 'СКРЫТ',
};

export const IDEA_STATUSES = {
  DRAFT: 'DRAFT',
  MODERATION: 'MODERATION',
  OPEN: 'OPEN',
  HIDDEN: 'HIDDEN',
};

export const IDEA_STATUSES_FOR_STATISTICS_FILTER = {
  MODERATION: 'MODERATION',
  OPEN: 'OPEN',
};

export const IDEA_STATUSES_FOR_STATISTICS_TABLE = {
  MODERATION: {
    type: 'MODERATION',
    titleUpper: 'МОДЕРАЦИЯ',
    titleLow: 'Модерация',
    color: PROJECT_TEMPLATE_STATUSES_BACK_PALETTE.MODERATION,
  },
  OPEN: {
    type: 'OPEN',
    titleUpper: 'ПУБЛИЧНЫЙ',
    titleLow: 'Публичный',
    color: PROJECT_TEMPLATE_STATUSES_BACK_PALETTE.OPEN,
  },
  HIDDEN: {
    type: 'HIDDEN',
    titleUpper: 'СКРЫТ',
    titleLow: 'Скрыт',
    color: PROJECT_TEMPLATE_STATUSES_BACK_PALETTE.HIDDEN,
  },
  DRAFT: {
    type: 'DRAFT',
    titleUpper: 'ЧЕРНОВИК',
    titleLow: 'Черновик',
    color: PROJECT_TEMPLATE_STATUSES_BACK_PALETTE.DRAFT,
  },
};

export const RECRUITING_MODERATION_STATUSES = {
  ACTIVE: 'ACTIVE',
  WAITING: 'WAITING',
  MODERATION: 'MODERATION',
  DECLINED: 'DECLINED',
};

export const RECRUITING_MODERATION_STATUSES_TITLES = {
  ACTIVE: 'Набор команды',
  MODERATION: 'Модерация',
  DECLINED: 'Отклонен',
  WAITING: 'Ожидает запуска',
};

export const RECRUITING_TIME_STATUSES = {
  END: 'END',
  LESS_THEN_WEEK: 'LESS_THEN_WEEK',
  MORE_THEN_WEEK: 'MORE_THEN_WEEK',
};

export const RECRUITING_STATUSES_FOR_STATISTICS_FILTER = {
  ACTIVE: 'ACTIVE',
  MODERATION: 'MODERATION',
  WAITING: 'WAITING',
};

export const RECRUITING_STATUSES_FOR_STATISTICS_TABLE = {
  ACTIVE: {
    type: 'ACTIVE',
    titleLow: 'Набор команды',
    color: RECRUITING_STATUSES_PALETTE.ACTIVE,
  },
  MODERATION: {
    type: 'MODERATION',
    titleLow: 'Модерация',
    color: RECRUITING_STATUSES_PALETTE.MODERATION,
  },
  WAITING: {
    type: 'WAITING',
    titleLow: 'Ожидает запуска',
    color: RECRUITING_STATUSES_PALETTE.WAITING,
  },
  COMPLETED: {
    type: 'COMPLETED',
    titleLow: 'Завершён',
    color: PALETTE().gray,
  },
  FAILED: {
    type: 'FAILED',
    titleLow: 'Не удался',
    color: PALETTE().greenBlue,
  },
  CANCELED: {
    type: 'CANCELED',
    titleLow: 'Отменён',
    color: PALETTE().gray,
  },
  DECLINED: {
    type: 'DECLINED',
    titleLow: 'Отклонён',
    color: PALETTE().red,
  },
};

// projects
export const PROJECT_STATUSES = {
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
};

export const PROJECT_STATUSES_TITLES = {
  COMPLETED: 'ЗАВЕРШЕН',
  FAILED: 'НЕ УДАЛСЯ',
  ACTIVE: 'В РАБОТЕ',
};

export const PROJECT_STATUSES_TITLES_FOR_STATISTICS_FILTER = {
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  ACTIVE: 'ACTIVE',
};

export const PROJECT_STATUSES_TITLES_FOR_STATISTICS_TABLE = {
  COMPLETED: {
    type: 'COMPLETED',
    titleLow: 'Завершён',
    color: PALETTE().gray,
  },
  FAILED: {
    type: 'FAILED',
    titleLow: 'Не удался',
    color: PALETTE().red,
  },
  ACTIVE: {
    type: 'ACTIVE',
    titleLow: 'В работе',
    color: PALETTE().green,
  },
};

export const USER_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const USER_STATUSES_TITLES = {
  ACTIVE: 'Активирован',
  INACTIVE: 'Не активирован',
};

export const USER_ROLES = {
  ORGANIZATION: 'ROLE_ORGANIZATION',
  USER: 'ROLE_USER',
  ADMIN: 'ROLE_ADMIN',
};

export const USER_TYPES = {
  STUDENT: 'STUDENT',
  EMPLOYEE: 'EMPLOYEE',
};

export const USER_TYPE_TITLES = {
  STUDENT: 'Ученик',
  EMPLOYEE: 'Сотрудник',
};

export const IMAGE_UPLOAD_PRESETS = {
  avatar: { width: 256, height: 256, aspectRatio: 1 },
  template: { aspectRatio: 1.5, autoCropArea: 1, maxWidth: 600 },
};

export const DEFAULT_IMAGE = 'default-project-image_wlmgzh';

export const MAX_TEMPLATE_QUESTIONS_NUMBER = 5;

export const STRINGS = require('../strings.json');
export const TIPS = STRINGS.tips;

export type LocalDicKeys = 'weeks' | 'symbols' | 'days' | 'people';
export const LOCALE_DIC: { [key in LocalDicKeys]: string[] } = {
  weeks: ['неделя', 'недели', 'недель'],
  symbols: ['символ', 'символа', 'символов'],
  days: ['день', 'дня', 'дней'],
  people: ['человек', 'человека', 'человек'],
};

export const MIN_SYMBOLS_NUMBER = 16;
export const MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT = 255;
export const MAX_SYMBOLS_NUMBER_FOR_MIDDLE_TEXT = 1000;
export const MAX_SYMBOLS_NUMBER_FOR_LONG_TEXT = 2047;
export const KANBAN_CARD_START_WEIGHT = 1024;
export const LIST_PAGE_SIZE = 20;

export const CURRENT_PUBLIC_HOST = process.env.REACT_APP_PUBLIC_HOST;

export const BUILD_VERSION = process.env.REACT_APP_BUILD_VERSION || 'local';

export const WIKI_LINK = process.env.REACT_APP_WIKI_LINK;

export const PAID_FEATURES_LINK = process.env.REACT_APP_PAID_FEATURES_LINK;

export const VK_AUTH_LINK = process.env.REACT_APP_VK_AUTH_LINK;

export const FB_AUTH_LINK = process.env.REACT_APP_FB_AUTH_LINK;

export const LANDING_PAGE_LINK = process.env.REACT_APP_LANDING_PAGE_LINK;
export const GLOBAL_ORGANIZATION_ID = process.env.REACT_APP_GLOBAL_ORGANIZATION;
export const GLOBAL_ORGANIZATION_REGISTRATION_KEY =
  process.env.REACT_APP_GLOBAL_ORGANIZATION_REGISTRATION_KEY;

export type PlanTaskStatuses = 'NOT_CONNECTED' | 'TODO' | 'IN_PROGRESS' | 'DONE';
export const PLAN_TASK_STATUSES: { [key in PlanTaskStatuses]: string } = {
  NOT_CONNECTED: 'нет связи с задачей на доске',
  TODO: 'задача не взята в работу',
  IN_PROGRESS: 'задача выполняется',
  DONE: 'задача выполнена',
};

export const PLAN_TASK_STATUSES_COLORS: { [key in PlanTaskStatuses]: string } = {
  NOT_CONNECTED: PALETTE().gray,
  TODO: PALETTE().red,
  IN_PROGRESS: PALETTE().orange,
  DONE: PALETTE().green,
};

export const EMPTY_USER: User = {
  id: '',
  firstName: '',
  lastName: '',
  middleName: '',
  organization: {
    id: '',
    name: '',
    shortName: '',
    type: '',
    ownerId: '',
    avatar: '',
    location: '',
    siteUrl: '',
  },
  email: '',
  avatar: '',
  type: '',
  role: '',
  lastActivity: '',
};

export const EMPTY_FULL_ORGANIZATION = {
  id: '',
  name: '',
  shortName: '',
  type: '',
  ownerId: '',
  owner: {
    id: '',
    status: '',
    type: null,
    role: '',
    firstName: '',
    middleName: '',
    lastName: '',
    avatar: '',
  },
  description: '',
  location: '',
  siteUrl: '',
  avatar: '',
  settings: {
    maxActiveProjects: 0,
    maxOrganizationUsers: 0,
    moderationEnabled: true,
    hasAccessToStatistics: false,
    maxProjectTeamMembers: 0,
  },
  publicStats: {
    activeProjectsCount: 0,
    usersCount: 0,
  },
};

export const EMPTY_PROJECT: Project = {
  id: '',
  name: '',
  goal: '',
  about: '',
  duration: 1,
  startDate: '',
  endDate: null,
  status: '',
  teamSize: 0,
  isMentor: false,
  ideaId: '',
  recruitingId: '',
  team: [],
  mentor: EMPTY_USER,
  images: [],
  attachments: [],
  organizationOnly: true,
  organizationId: '',
};

export const EVENTS_STORAGE = {
  name: 'event_storage',
  events: {
    askForNotification: 'askForNotification',
  },
};

export const VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;

export const WORKSPACE_TABS = {
  description: 0,
  charter: 1,
  plan: 2,
  project_office: 3,
  chat: 4,
  kanban: 5,
};

export const NOTIFICATION_UPDATE_DELAY = 60000;
export const PUSH_AUTO_CLOSE = 10 * 1000;
export const MAX_NUMBER_OF_PUSHES_ON_SCREEN = 5;
export const SHOW_PUSH_DELAY = PUSH_AUTO_CLOSE / MAX_NUMBER_OF_PUSHES_ON_SCREEN;

export const UPLOAD_FILE_TYPES = [
  '3ds',
  'aac',
  'ai',
  'avi',
  'bmp',
  'cad',
  'cdr',
  'css',
  'dat',
  'dll',
  'dmg',
  'doc',
  'eps',
  'fla',
  'flv',
  'gif',
  'html',
  'indd',
  'iso',
  'jpg',
  'js',
  'midi',
  'mov',
  'mp3',
  'mpg',
  'pdf',
  'php',
  'png',
  'ppt',
  'psd',
  'ps',
  'raw',
  'sql',
  'svg',
  'tif',
  'txt',
  'wmv',
  'xls',
  'xml',
  'zip',
  'docx',
  'xlsx',
];
