import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

interface TextBlockProps {
  label: string;
  body?: string | number;
  className?: string;
  html?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  bodyBox: {
    overflow: 'auto',
    marginTop: 10,
    paddingRight: 10,
    wordBreak: 'break-word',
    '& div': {
      '& p': {
        margin: 0,
      },
    },
  },
}));

function InfoBlock({ className, label, body, html, children }: PropsWithChildren<TextBlockProps>) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, !!className && className)}>
      <Typography variant="subtitle2" color="textSecondary" gutterBottom>
        {label}
      </Typography>
      {html ? (
        <div className={classes.bodyBox}>
          <Typography component="div" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      ) : (
        children || <Typography>{body}</Typography>
      )}
    </div>
  );
}

export default InfoBlock;
