import { ComponentStates } from './types';

export const COMPONENT_STATES: ComponentStates = {
  LOADING: 'LOADING',
  CONTENT: 'CONTENT',
  ERROR: 'ERROR',
  EMPTY: 'EMPTY',
};

export const DashboardComponentState = {
  EMPTY: 'EMPTY',
  TEMPLATES: 'TEMPLATES',
  RECRUITING: 'RECRUITING',
  PROJECTS: 'PROJECTS',
};
