import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KanbanSmallCard from './KanbanSmallCard';

import { SmallKanbanCard } from '../../../../utils/types';
import { PALETTE } from '../../../../utils/palette';
import { TOURS_CLASS_NAMES } from '../../../../components/tour/classNames';

interface KanbanColumnProps {
  column: { id: string; name: string; cards: SmallKanbanCard[] };
  index: number;
  onClickCard: (column, cardId: string) => void;
}

const {
  toDoList,
  inProgressList,
  toCheckList,
  doneList,
  fineTuningList,
} = TOURS_CLASS_NAMES.kanbanBoard;

const columnTours = {
  'Надо сделать': toDoList,
  'В работе': inProgressList,
  'На проверку': toCheckList,
  Сделано: doneList,
  'На доработку': fineTuningList,
};

const useStyles = makeStyles(() => ({
  paper: {
    background: PALETTE(0.1).gray,
    padding: '16px 8px',
    width: 300,
    minHeight: 600,
    marginRight: 12,
    borderRadius: 16,
  },
  paperDraggingOver: {
    background: PALETTE(0.18).gray,
  },
  title: {
    marginLeft: 4,
  },
  addButton: {
    display: 'flex',
    justifyContent: 'center',
    color: PALETTE().gray,
    '& button': {
      textTransform: 'none',
      fontWeight: 400,
    },
  },
}));

function KanbanColumn(props: KanbanColumnProps) {
  const { column, index, onClickCard } = props;
  const classes = useStyles();

  return (
    <Droppable droppableId={`${index}`}>
      {(provided, snapshot) => {
        return (
          <Paper
            className={clsx(classes.paper, snapshot.isDraggingOver && classes.paperDraggingOver)}
            elevation={0}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <Typography
              className={clsx(classes.title, columnTours[column.name])}
              variant="subtitle2"
              color="textSecondary"
              paragraph
            >
              {column.name}
            </Typography>
            {column.cards.map((card, cardIndex) => (
              <KanbanSmallCard
                className={clsx(cardIndex === 0 && TOURS_CLASS_NAMES.kanbanBoard.firstCard)}
                key={card.id}
                card={card}
                index={cardIndex}
                onClickCard={() => onClickCard(column, card.id)}
              />
            ))}
            {provided.placeholder}
            <div className={classes.addButton}>
              <Button
                color="inherit"
                startIcon={<AddIcon />}
                onClick={() => onClickCard(column, 'new')}
              >
                Добавить карточку
              </Button>
            </div>
          </Paper>
        );
      }}
    </Droppable>
  );
}

export default KanbanColumn;
