import { put, takeEvery, call } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { COMPONENT_STATES } from '../../../../utils/componentState';
import { errorHandler } from '../../../../utils/fetchUtils';
import { ComponentState, Template, User } from '../../../../utils/types';
import { EMPTY_USER, LIST_PAGE_SIZE } from '../../../../utils/constants';
// eslint-disable-next-line max-len
import { getPersonalProjectsTemplatesList } from '../../../project-templates/personal-templates-list/domain';
import { getUser } from '../domain';

// dal

// saga
function* publicIdeasRequest(action) {
  try {
    const { userId, currentPage } = action.payload;
    const { data } = yield call(getPersonalProjectsTemplatesList, userId, {
      page: currentPage,
      pageSize: LIST_PAGE_SIZE,
    });
    const { data: userData } = yield call(getUser, userId);
    yield put(fetchPublicUserIdeasSuccess({ data, userData }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchPublicUserIdeasFail());
  }
}

export function* watchLoadingPublicUserIdeas() {
  yield takeEvery(fetchPublicUserIdeas.type, publicIdeasRequest);
}

export interface PublicUserIdeasState {
  componentState: ComponentState;
  ideas: Template[];
  user: User;
  currentPage: number;
  isLastPage: boolean;
}

// reducer
const initialState: PublicUserIdeasState = {
  componentState: COMPONENT_STATES.LOADING,
  ideas: [],
  user: EMPTY_USER,
  currentPage: 1,
  isLastPage: false,
};

const publicUserIdeasSlice = createSlice({
  name: 'publicUserIdeas',
  initialState,
  reducers: {
    fetchPublicUserIdeas(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
      if (action.payload.isFirstLoading) {
        state.currentPage = 1;
        state.ideas = [];
      }
      action.payload.currentPage = state.currentPage;
    },
    fetchPublicUserIdeasSuccess(state, action) {
      const { data, userData } = action.payload;
      state.user = userData;
      if (data.items?.length === 0) {
        state.componentState = COMPONENT_STATES.EMPTY;
      } else {
        state.componentState = COMPONENT_STATES.CONTENT;
        state.ideas = [...state.ideas, ...data.items];
        state.currentPage += 1;
        state.isLastPage = data.last;
      }
    },
    fetchPublicUserIdeasFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const {
  fetchPublicUserIdeas,
  fetchPublicUserIdeasSuccess,
  fetchPublicUserIdeasFail,
} = publicUserIdeasSlice.actions;

export default publicUserIdeasSlice.reducer;
