import * as Yup from 'yup';

export const maxTaskSymbols = 64;
const required = 'Обязательное поле';

export const TaskItemSchema = Yup.object().shape({
  name: Yup.string()
    .max(maxTaskSymbols, `Не больше ${maxTaskSymbols} символов`)
    .required(required),
});

export const TaskFirstItemSchema = Yup.object().shape({
  name: Yup.string().max(maxTaskSymbols, `Не больше ${maxTaskSymbols} символов`),
});
