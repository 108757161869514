import { canvasToBlob } from './utils';
import { uploadFile } from '../components/files-uploader/domain';

export { uploadFile };

export function* uploadCanvasToBlobImage(canvasImage, name) {
  const { blobImage, blobImageName } = yield canvasToBlob(canvasImage, name);
  const { data } = yield uploadFile(blobImage, blobImageName);
  return data;
}
