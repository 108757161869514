import * as Yup from 'yup';
import { MAX_SYMBOLS_NUMBER_FOR_MIDDLE_TEXT } from '../../../utils/constants';

export const CancelRecruitingSchema = Yup.object().shape({
  message: Yup.string()
    .trim()
    .max(
      MAX_SYMBOLS_NUMBER_FOR_MIDDLE_TEXT,
      `Причина не может быть длиннее ${MAX_SYMBOLS_NUMBER_FOR_MIDDLE_TEXT} символов`,
    ),
});
