import { DefaultNodeModel } from '@projectstorm/react-diagrams';

export class TaskNodeModel extends DefaultNodeModel {
  constructor(options = {}) {
    super({
      ...options,
      type: 'js-custom-node',
      estimate: options.estimate,
      cardId: options.cardId,
      projectId: options.projectId,
    });
  }
}
