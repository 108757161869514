/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SortIcon from '@material-ui/icons/Sort';
import CommitSkeleton from '../Skeletons/CommentSkeleton';

import Comment from './Comment';
import { Comment as CommentType } from '../../utils/types';

import { fetchComments, fetchUpdateComment, fetchPostComment, fetchRemoveComment } from './domain';
import { PALETTE } from '../../utils/palette';
import { COMPONENT_STATES } from '../../utils/componentState';

interface CommentsProps {
  className?: string;
  commentsConfig: {
    url: string;
    pageSize: number;
  };
}

interface StyleProps {
  oldFirst: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 236,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 22,
  },
  sortIcon: {
    color: PALETTE().gray,
    transform: (props: StyleProps) => (props.oldFirst ? 'scale(1, 1)' : 'scale(1, -1)'),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  nameField: {
    marginBottom: 20,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 25,
    marginTop: 'auto',
    '& button': {
      marginRight: 24,
      width: 256,
    },
  },
  showCommentsBox: {
    position: 'relative',
    width: '42%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 20,
  },
  circularProgress: {
    position: 'absolute',
    left: 220,
  },
}));

function Comments({ className, commentsConfig }: CommentsProps) {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.app);
  const {
    componentState,
    comments,
    commentsLoading,
    totalComments,
    currentPage,
    commentsTreeWasChanged,
  } = useSelector((state: any) => state.comments);
  const notLoadedYetCommentsNumber = totalComments - comments.length;
  const canLoadCommentsNumber =
    notLoadedYetCommentsNumber > commentsConfig.pageSize
      ? commentsConfig.pageSize
      : notLoadedYetCommentsNumber;
  const canLoadMoreComments = comments.length < totalComments && !commentsTreeWasChanged;
  const [oldFirst, setOldFirst] = useState(false);
  const classes = useStyles({ oldFirst });

  const newComment: CommentType = {
    author: user,
    text: '',
    id: '',
    createdAt: '',
    updatedAt: '',
    edited: false,
  };

  const loadFirstPage = () =>
    dispatch(fetchComments({ ...commentsConfig, currentPage: 0, oldFirst }));

  useEffect(
    () => {
      loadFirstPage();
    },
    // eslint-disable-next-line
    [oldFirst],
  );

  const handleLoadMoreComments = () => {
    if (commentsTreeWasChanged) {
      loadFirstPage();
    } else {
      dispatch(fetchComments({ ...commentsConfig, currentPage, oldFirst }));
    }
  };

  const handlePostComment = (comment) => {
    if (oldFirst) {
      setOldFirst((prevState) => !prevState);
    }
    dispatch(fetchPostComment({ ...commentsConfig, comment }));
  };

  const handleUpdateComment = (index, comment) => {
    dispatch(fetchUpdateComment({ index, comment }));
  };

  const handleRemoveComment = (index, id) => {
    dispatch(fetchRemoveComment({ index, id }));
  };

  return (
    <div className={className}>
      <div className={classes.title}>
        <Typography variant="h4">Комментарии</Typography>
        <Tooltip
          arrow
          title={`Показать ${oldFirst ? 'новые' : 'старые'} комментарии наверху`}
          placement="top"
        >
          <IconButton onClick={() => setOldFirst((prevState) => !prevState)}>
            <SortIcon className={classes.sortIcon} />
          </IconButton>
        </Tooltip>
      </div>
      <Comment comment={newComment} onSave={handlePostComment} isFirstInput />
      {componentState === COMPONENT_STATES.CONTENT ? (
        <>
          {comments.map((comment, index) => (
            <Comment
              key={comment.id}
              comment={comment}
              onSave={(comment) => handleUpdateComment(index, comment)}
              onRemove={() => handleRemoveComment(index, comment.id)}
            />
          ))}
          {(canLoadMoreComments || commentsTreeWasChanged) && (
            <div className={classes.showCommentsBox}>
              <Link
                component="button"
                variant="subtitle2"
                color="textSecondary"
                onClick={handleLoadMoreComments}
              >
                {commentsTreeWasChanged ? 'Обновить комментарии' : 'Показать другие комментарии'}
              </Link>
              {commentsLoading && (
                <CircularProgress size={10} className={classes.circularProgress} />
              )}
              {!commentsTreeWasChanged && (
                <Typography variant="subtitle2" color="textSecondary">
                  {canLoadCommentsNumber} из {notLoadedYetCommentsNumber}
                </Typography>
              )}
            </div>
          )}
        </>
      ) : (
        <CommitSkeleton />
      )}
    </div>
  );
}

export default Comments;
