import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import { markTaskForRemoving, unMarkTaskForRemoving } from '../../domain';
import { Task } from '../PlanList';
import { maxTaskSymbols, TaskItemSchema } from './validationSchema';
import { PALETTE } from '../../../../../utils/palette';

interface TaskItemProps {
  task: Task;
  onChange: (task: Task) => void;
}

const ErrorTooltip = withStyles(() => ({
  arrow: {
    color: PALETTE().red,
  },
  tooltip: {
    backgroundColor: PALETTE().red,
  },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: 600,
  },
  name: {
    width: 450,
  },
  days: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
  },
  daysTitle: {
    paddingRight: 5,
  },
  daysInput: {
    width: 50,
    paddingTop: 5,
  },
}));

function TaskItemForm(props: TaskItemProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { task, onChange } = props;
  const [isMarkedToRemove, setIsMarkedToRemove] = useState(false);
  const { request } = useSelector((state: any) => state.plan);

  useEffect(() => setIsMarkedToRemove(false), [request]);

  const completeEditing = (values) => {
    onChange(values);
  };

  const onPressEnter = (e, handleSubmit, dirty) => {
    if (e.which === 13 && !e.shiftKey && dirty) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const onBlur = (e, handleSubmit, dirty) => {
    e.preventDefault();
    if (dirty) {
      handleSubmit();
    }
  };

  const handleMarkTaskForRemoving = () => {
    const payload = { taskId: task.id };
    setIsMarkedToRemove((prevState) => !prevState);
    if (isMarkedToRemove) {
      dispatch(unMarkTaskForRemoving(payload));
    } else {
      dispatch(markTaskForRemoving(payload));
    }
  };

  return (
    <Formik
      initialValues={task}
      validationSchema={TaskItemSchema}
      onSubmit={completeEditing}
      enableReinitialize
    >
      {({ values, handleChange, errors, setFieldValue, handleSubmit, dirty }) => {
        return (
          <Form className={classes.root}>
            <Checkbox
              id="shouldRemoveTask"
              checked={isMarkedToRemove}
              onChange={handleMarkTaskForRemoving}
              color="primary"
              disabled={request}
            />
            <div>
              <ErrorTooltip arrow open={!!errors.name} title={errors.name || ''} placement="top">
                <div />
              </ErrorTooltip>
              <TextField
                className={classes.name}
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={(e) => onBlur(e, handleSubmit, dirty)}
                onKeyPress={(e) => onPressEnter(e, handleSubmit, dirty)}
                size="small"
                rowsMax={2}
                variant="standard"
                placeholder="Наименование работы"
                InputProps={{ disableUnderline: true }}
                disabled={request}
                inputProps={{
                  maxLength: maxTaskSymbols,
                }}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default TaskItemForm;
