import React, { PropsWithChildren } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ImageGallery from 'react-image-gallery';

import Typography from '@material-ui/core/Typography';
import { Editor } from 'react-draft-wysiwyg';
import { mdToDraftjs } from 'draftjs-md-converter';
import UserInfoBlock from './UserInfoBlock';
import InfoBlock from './InfoBlock';
import OrganizationBanner from './OrganizationBanner';

import { makeGalleryImages, localNaming } from '../utils/utils';

import { User, Organization } from '../utils/types';
import { PALETTE } from '../utils/palette';
import { TOURS_CLASS_NAMES } from './tour/classNames';

import 'react-image-gallery/styles/css/image-gallery.css';

export interface CommonProjectData {
  name: string;
  status?: string;
  goal: string;
  about: string;
  duration: number;
  images: any[];
  attachments: any[];
  user?: User;
  isOwner?: boolean;
  ownerTitle?: string;
}

export interface CommonProjectViewProps {
  data: CommonProjectData;
  organization?: Organization;
  statusComponent?: JSX.Element;
  userLabel?: string;
  organizationLabel?: string;
  additionalFields?: { label: string; body?: string | number }[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 1016,
    display: 'flex',
    // для joyride, что бы корректно работала подсветка элемента в хроме
    backgroundColor: PALETTE().white,
  },
  contentLeft: {
    width: 508,
  },
  contentRight: {
    width: 446,
    marginLeft: 'calc(508px - 446px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  status: {
    marginBottom: 12,
  },
  ownerInfo: {
    marginBottom: 12,
  },
  gallery: {
    width: 'inherit',
  },
  description: {
    height: '50%',
  },
  actionBlock: {
    marginTop: 16,
    width: '100%',
  },
  leftInfoBlock: {
    marginTop: 24,
  },
}));

function CommonProjectView(props: PropsWithChildren<CommonProjectViewProps>) {
  const classes = useStyles();
  const {
    data,
    children,
    statusComponent,
    organization,
    userLabel = '',
    organizationLabel = '',
    additionalFields,
  } = props;

  const {
    name,
    status,
    goal,
    about,
    duration,
    images,
    // eslint-disable-next-line
    attachments,
    user,
    isOwner,
    ownerTitle,
  } = data;

  const improvedAbout = mdToDraftjs(about);

  const hasImages = !!images.length;
  const galleryImages = makeGalleryImages(images);

  return (
    <div className={classes.root}>
      <div className={classes.contentLeft}>
        <Typography variant="h4" gutterBottom>
          {name}
        </Typography>
        {!!status && <div className={classes.status}>{statusComponent}</div>}
        {!!user && (
          <InfoBlock className={classes.ownerInfo} label={userLabel}>
            <UserInfoBlock user={user} showTitle={isOwner} title={ownerTitle} />
          </InfoBlock>
        )}
        {!!organization && (
          <InfoBlock
            className={TOURS_CLASS_NAMES.projectInfo.organizationBanner}
            label={organizationLabel}
          >
            <OrganizationBanner
              id={organization.id}
              name={organization.shortName}
              avatarUrl={organization.avatar}
            />
          </InfoBlock>
        )}
        {!!additionalFields &&
          additionalFields.map(({ label, body }) => <InfoBlock label={label} body={body} />)}
        <InfoBlock className={classes.leftInfoBlock} label="Цель" body={goal || 'Отсутствует'} />
        <InfoBlock
          className={classes.leftInfoBlock}
          label="Ожидаемая длительность проекта"
          body={`${duration} ${localNaming(duration, 'weeks')}`}
        />
        <div className={classes.leftInfoBlock}>
          <Typography variant="subtitle2" color="textSecondary">
            Описание
          </Typography>
          <Editor toolbarHidden readOnly initialContentState={improvedAbout} />
        </div>
      </div>
      <div className={classes.contentRight}>
        <div className={classes.gallery}>
          <ImageGallery
            items={galleryImages}
            showNav={false}
            showPlayButton={false}
            showThumbnails={hasImages}
            showFullscreenButton={hasImages}
          />
        </div>
        <div className={classes.actionBlock}>{children}</div>
      </div>
    </div>
  );
}

export default CommonProjectView;
