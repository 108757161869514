import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import clsx from 'clsx';
import SlimBoxTip, { SlimBoxTipProps } from '../../../components/tips/SlimBoxTip';
import ColoredAvatar from '../../../components/ColoredAvatar';
import ViewSelectState from '../../../components/select-state/ViewSelectState';
import CharterTextView from './components/CharterTextView';

import Variants from './charter-states/Variants';
import CharterStepper from './charter-states/Stepper';
import Edit from './charter-states/Edit';
import Draft from './charter-states/Draft';
import Approved from './charter-states/Approved';

import { ChapterMakingState } from './utils/types';
import { fetchWorkspaceCharter, setComponentLoading } from './domain';
import { TIPS } from '../../../utils/constants';
import { TOURS_CLASS_NAMES } from '../../../components/tour/classNames';
import { PROJECT_CHAPTER_STATUSES } from './utils/constants';
import { setTourPage } from '../../globalDomain';
import { TOUR_PAGES } from '../../../components/tour/tourSteps';

interface Charter {
  className?: string;
}

type CharterState = {
  [key in ChapterMakingState]: { component: JSX.Element; title: string; tip?: SlimBoxTipProps };
};

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 800,
  },
  radio: {
    display: 'flex',
    marginBottom: 15,
  },
  buttonsBox: {
    marginTop: 10,
  },
  acceptors: {
    marginTop: 30,
  },
}));

function ProjectCharter({ className }: Charter) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    projectData: { id: projectId },
    isProjectMember,
  } = useSelector((state: any) => state.workspaceProjectInfo);
  const {
    user: { id: userId },
  } = useSelector((state: any) => state.app);

  const {
    charterComponentState,
    charterData: { status, acceptors, text },
    charterState,
  } = useSelector((state: any) => state.workspaceProjectCharter);

  useEffect(() => {
    dispatch(fetchWorkspaceCharter({ projectId, userId, isProjectMember }));
    if (isProjectMember) {
      dispatch(setTourPage({ pages: [TOUR_PAGES.charter] }));
    }
    return function() {
      dispatch(setComponentLoading());
      dispatch(setTourPage({ pages: [] }));
    };
  }, [dispatch, projectId, userId, isProjectMember]);

  const charterStates: CharterState = {
    variant: {
      component: <Variants />,
      title: 'Выберите вариант текста',
      tip: TIPS.projectCharterNoCharter,
    },
    steps: { component: <CharterStepper />, title: 'Дополните текст необходимой информацией' },
    edit: { component: <Edit />, title: 'Отредактируйте текст если необходимо' },
    draft: { component: <Draft />, title: 'Черновик устава', tip: TIPS.projectCharterWithCharter },
    approved: { component: <Approved />, title: 'Устав проекта' },
  };

  const content = () =>
    isProjectMember ? (
      <div className={clsx(TOURS_CLASS_NAMES.charter.center, TOURS_CLASS_NAMES.charter.center2)}>
        {!!charterStates[charterState].tip && <SlimBoxTip {...charterStates[charterState].tip} />}
        <Typography variant="h6" color="textSecondary" paragraph>
          {charterStates[charterState].title}
        </Typography>
        {charterStates[charterState].component}
        {status === PROJECT_CHAPTER_STATUSES.FORMED && (
          <div className={classes.acceptors}>
            <Typography variant="h6" color="textSecondary">
              {acceptors.length
                ? 'Участники проекта уже одобрившие устав:'
                : 'Еще никто не одобрил устав проекта'}
            </Typography>
            <AvatarGroup>
              {acceptors.map((teamMember) => {
                return <ColoredAvatar key={teamMember.id} user={teamMember} />;
              })}
            </AvatarGroup>
          </div>
        )}
      </div>
    ) : (
      <div>
        {status === PROJECT_CHAPTER_STATUSES.APPROVED ? (
          <div>
            <Typography variant="h6" color="textSecondary" paragraph>
              Устав проекта
            </Typography>
            <CharterTextView charter={text} />
          </div>
        ) : (
          <SlimBoxTip mainText="Команда еще не сформировала устав проекта" />
        )}
      </div>
    );

  return (
    <div className={clsx(classes.paper, className)}>
      <ViewSelectState state={charterComponentState} content={content} />
    </div>
  );
}

export default ProjectCharter;
