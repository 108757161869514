import React, { PropsWithChildren } from 'react';
import ErrorDataLoading from './ErrorDataLoading';
import { ComponentState } from '../../utils/types';
import { COMPONENT_STATES } from '../../utils/componentState';

interface WithErrorPageWrapper {
  action: () => void;
  errorData: any;
  componentState: ComponentState;
}

function WithErrorPageWrapper(props: PropsWithChildren<WithErrorPageWrapper>) {
  const { action, errorData, componentState, children } = props;
  return componentState === COMPONENT_STATES.ERROR ? (
    <ErrorDataLoading errorData={errorData} action={action} />
  ) : (
    <>{children}</>
  );
}

export default WithErrorPageWrapper;
