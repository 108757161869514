import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import ColoredAvatar from '../../../../components/ColoredAvatar';

import { User } from '../../../../utils/types';

interface AcceptedUsersProps {
  users: User[];
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: 4,
  },
}));

function AcceptedUsers(props: AcceptedUsersProps) {
  const classes = useStyles();
  const { className, users } = props;

  return (
    <div className={clsx(classes.root, !!className && className)}>
      {!!users.length ? (
        users.map((student) => {
          return (
            <div key={student.id}>
              <ColoredAvatar className={classes.avatar} user={student} />
            </div>
          );
        })
      ) : (
        <Typography align="justify" color="textSecondary">
          УЧАСТНИКИ НЕ ВЫБРАНЫ
        </Typography>
      )}
    </div>
  );
}

export default AcceptedUsers;
