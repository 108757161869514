import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import React from 'react';

import { LANDING_PAGE_LINK } from '../utils/constants';

export function Copyright(): JSX.Element {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href={LANDING_PAGE_LINK}>
        ООО Эдутим
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
