import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { COMPONENT_STATES } from '../../../utils/componentState';
import { errorHandler } from '../../../utils/fetchUtils';
import { getPersonalProjectsTemplatesList } from '../personal-templates-list/domain';
import { LIST_PAGE_SIZE } from '../../../utils/constants';
import UrlsV2 from '../../../utils/endpointsV2';

// dal
export const getCommonProjectTemplatesList = (page = 1, pageSize = LIST_PAGE_SIZE) => {
  const params = { page, pageSize };
  return axios.get(UrlsV2.COMMON_PROJECTS_IDEAS, { params });
};

// saga
function* commonProjectTemplateListRequest(action) {
  try {
    const { currentPage, userId } = action.payload;
    let personalTemplates;
    if (userId) {
      const { data } = yield call(getPersonalProjectsTemplatesList, userId, {
        page: 1,
        pageSize: 2,
      });
      personalTemplates = data;
    }
    const { data: commonTemplates } = yield call(() => getCommonProjectTemplatesList(currentPage));
    yield put(fetchCommonProjectTemplatesListSuccess({ personalTemplates, commonTemplates }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchCommonProjectTemplatesListFail());
  }
}

export function* watchLoadingCommonProjectTemplateList() {
  yield takeEvery(fetchCommonProjectTemplatesList.type, commonProjectTemplateListRequest);
}

// reducer
const initialState = {
  componentState: COMPONENT_STATES.LOADING,
  commonTemplates: [] as any[],
  personalTemplates: [] as any[],
  personalTemplatesCounter: 0,
  currentPage: 1,
  isLastPage: false,
};

const commonProjectTemplatesListSlice = createSlice({
  name: 'commonProjectTemplateList',
  initialState,
  reducers: {
    fetchCommonProjectTemplatesList(state, action) {
      if (action.payload.isFirstLoading) {
        state.componentState = COMPONENT_STATES.LOADING;
        state.currentPage = 1;
        state.commonTemplates = [];
      }
      action.payload.currentPage = state.currentPage;
    },
    fetchCommonProjectTemplatesListSuccess(state, action) {
      const { personalTemplates, commonTemplates } = action.payload;
      const isEmpty =
        !personalTemplates?.items?.length &&
        !commonTemplates?.items?.length &&
        !state.commonTemplates.length;

      if (isEmpty) {
        state.componentState = COMPONENT_STATES.EMPTY;
      } else {
        state.componentState = COMPONENT_STATES.CONTENT;
        state.commonTemplates = [...state.commonTemplates, ...commonTemplates.items];
        state.currentPage += 1;
        state.isLastPage = commonTemplates.last;
        if (personalTemplates) {
          state.personalTemplates = personalTemplates.items;
          state.personalTemplatesCounter = personalTemplates.totalElements;
        }
      }
    },
    fetchCommonProjectTemplatesListFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const {
  fetchCommonProjectTemplatesList,
  fetchCommonProjectTemplatesListSuccess,
  fetchCommonProjectTemplatesListFail,
} = commonProjectTemplatesListSlice.actions;

export default commonProjectTemplatesListSlice.reducer;
