import { createSlice } from '@reduxjs/toolkit';
import { COMPONENT_STATES } from '../../../utils/componentState';
import { ComponentState, Notification } from '../../../utils/types';
import { NotificationResponseData } from './dal';

export interface NotificationState {
  componentState: ComponentState;
  tableData: Omit<NotificationResponseData, 'content'>;
  notifications: Notification[];
  errorData: any;
  request: boolean;
}

const initialState: NotificationState = {
  componentState: COMPONENT_STATES.LOADING,
  tableData: {
    page: 1,
    pageSize: 0,
    totalPages: 0,
    last: false,
    totalElements: 0,
  },
  notifications: [],
  errorData: {},
  request: false,
};

const notificationsListSlice = createSlice({
  name: 'notificationsList',
  initialState,
  reducers: {
    fetchNotificationsList(state, action) {
      const { isFirstLoading } = action.payload;
      if (isFirstLoading) {
        state.componentState = COMPONENT_STATES.LOADING;
        state.notifications = [];
      }
      state.request = true;
    },
    fetchNotificationsListSuccess(state, action) {
      const {
        tableData: { content, ...data },
      } = action.payload;
      state.tableData = data;
      state.notifications = [...state.notifications, ...content];
      state.componentState = state.notifications.length
        ? COMPONENT_STATES.CONTENT
        : COMPONENT_STATES.EMPTY;
      state.request = false;
    },
    fetchNotificationsListFail(state, action) {
      state.componentState = COMPONENT_STATES.ERROR;
      state.errorData = action.payload;
      state.request = false;
    },
    fetchNotificationRead(state, action) {
      const { index } = action.payload;
      state.notifications[index].read = true;
    },
    goNotificationPath(state, action) {},
    fetchNotificationsReadAll(state) {},
    fetchNotificationsReadAllSuccess(state) {
      state.notifications.forEach((n) => {
        n.read = true;
      });
    },
  },
});

export const {
  fetchNotificationsList,
  fetchNotificationsListSuccess,
  fetchNotificationsListFail,
  fetchNotificationRead,
  goNotificationPath,
  fetchNotificationsReadAll,
  fetchNotificationsReadAllSuccess,
} = notificationsListSlice.actions;

export default notificationsListSlice.reducer;
