import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { configureStore } from '@reduxjs/toolkit';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ruRU } from '@material-ui/core/locale';
import * as Sentry from '@sentry/react';
import { YMInitializer } from 'react-yandex-metrika';

import reducer from './store/rootReducer';
import './index.css';
import Routes from './router';
import rootSaga from './store/rootSaga';
import * as serviceWorker from './serviceWorker';
import { isLoggedIn, clearToken } from './utils/auth';
import { CUSTOM_SHADOWS, PALETTE } from './utils/palette';
import { closeChatChannel } from './app/workspace/chat/domain';

import 'react-toastify/dist/ReactToastify.css';

const HOST = process.env.REACT_APP_HOST;

Sentry.init({
  dsn: 'https://df8082778c1b476c939d0792fd2c798e@o443434.ingest.sentry.io/5417160',
});

// Настраиваем axios, чтобы передавать токен авторизации в запросе
axios.interceptors.request.use((config) => {
  const token = isLoggedIn();

  if (token) {
    if (config.url?.includes('v2/')) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      config.headers.Authorization = `Token ${token}`;
    }
  }

  return {
    ...config,
    baseURL: HOST,
  };
});

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer,
  middleware: [sagaMiddleware],
});
sagaMiddleware.run(rootSaga);

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.data.status === 401) {
      clearToken();
      store.dispatch(closeChatChannel());
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

const theme = createMuiTheme(
  {
    palette: {
      background: {
        default: '#fff',
      },
      primary: {
        main: PALETTE().blue,
        contrastText: '#fff',
      },
      secondary: {
        main: PALETTE().red,
      },
      warning: {
        main: PALETTE().orange,
      },
      text: {
        primary: PALETTE().black,
        secondary: PALETTE().gray,
      },
      success: {
        main: PALETTE().green,
      },
    },
    typography: {
      h1: {
        fontSize: 36,
        fontWeight: 400,
        lineHeight: 0.8,
      },
      h2: {
        fontSize: 36,
        fontWeight: 300,
        lineHeight: 1.5,
      },
      h3: {
        fontSize: 24,
        fontWeight: 400,
      },
      h4: {
        fontSize: 24,
        fontWeight: 500,
      },
      h5: {
        fontSize: 24,
        fontWeight: 300,
      },
      h6: {
        fontSize: 20,
        fontWeight: 600,
      },
      subtitle2: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.37,
      },
    },
    overrides: {
      MuiButton: {
        sizeLarge: {
          height: 48,
          padding: '8px 14px',
          '& span': {
            fontSize: 14,
          },
        },
      },
      MuiPaper: {
        elevation3: {
          boxShadow: CUSTOM_SHADOWS.gray.elevation3,
        },
        elevation5: {
          boxShadow: CUSTOM_SHADOWS.gray.elevation5,
        },
        elevation10: {
          boxShadow: CUSTOM_SHADOWS.gray.elevation10,
        },
      },
    },
  },
  ruRU,
);

ReactDOM.render(
  <Provider store={store}>
    {process.env.REACT_APP_MODE === 'development' && (
      <div className="testmode">
        <p>Портал работает в тестовом режиме</p>
      </div>
    )}
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
    <ToastContainer autoClose={5000} pauseOnFocusLoss />
    {process.env.NODE_ENV === 'production' && (
      <YMInitializer accounts={[67289980]} options={{ webvisor: true }} version="2" />
    )}
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
