import * as Yup from 'yup';
import { MAX_SYMBOLS_NUMBER_FOR_MIDDLE_TEXT } from '../../../../utils/constants';

const required = 'Обязательное поле';

export const maxSymbols = MAX_SYMBOLS_NUMBER_FOR_MIDDLE_TEXT;

export const RejectReasonSchema = Yup.object().shape({
  rejectMessage: Yup.string()
    .max(maxSymbols, `Не более ${maxSymbols} символов`)
    .required(required),
});
