import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { isLoggedIn, setLogIn } from '../../utils/auth';
import { errorHandler } from '../../utils/fetchUtils';
import Paths from '../../utils/route-paths';
import Urls from '../../utils/endpoints';
import UrlsV2 from '../../utils/endpointsV2';
import { unsetUser } from '../globalDomain';
import { closeChatChannel } from '../workspace/chat/domain';
import { deviceRegistration } from '../dashboard/domain';

// dal
export const login = (payload) => axios.post(`${Urls.ROOTS.users}/sign-in`, payload);
export const socialLogin = (socialNet, code) =>
  axios.post(`${Urls.ROOTS.login}/social/token/`, { provider: socialNet, code });
export const logout = () => axios.post(UrlsV2.AUTH_LOGOUT);

// saga

function* loginRequest(action) {
  try {
    const { pathname, history } = action.payload;
    const {
      data: { token },
    } = yield call(() => login(action.payload.values));

    if (token) {
      setLogIn(token);
      yield put(loginFinish());
      const path = pathname === Paths.LOGIN ? '/' : pathname;
      history.replace(path);
      // регистрируем браузер для пушей на бэке если есть разрешение от пользователя
      if (Notification.permission === 'granted') {
        yield deviceRegistration();
      }
    }
  } catch (e) {
    errorHandler(e);
    yield put(loginFinish());
  }
}

function* socialLoginRequest(action) {
  try {
    const { code, state } = action.payload;

    let provider;
    if (state != null) {
      provider = 'facebook';
    } else {
      provider = 'vk-oauth2';
    }

    const {
      data: { token },
    } = yield call(socialLogin, provider, code);

    if (token) {
      setLogIn(token);
      yield put(loginFinish());
      window.opener.postMessage({ success: true });
      window.close();
      if (Notification.permission === 'granted') {
        yield deviceRegistration();
      }
    }
  } catch (e) {
    errorHandler(e);
    yield put(loginFinish());
    window.opener.postMessage({ success: false });
    window.close();
  }
}

function* checkLogin(action) {
  try {
    if (isLoggedIn()) {
      yield call(() => action.payload.history.replace('/'));
    }
  } catch (e) {
    errorHandler(e);
  }
}

function* logoutRequest(action) {
  try {
    const { history } = action.payload;
    yield call(logout);
    yield put(logoutSuccess());
    yield put(unsetUser({ history }));
    yield put(closeChatChannel());
    history.push('/login');
  } catch (e) {
    errorHandler(e);
    yield put(logoutFail());
  }
}

export function* watchLogin() {
  yield takeEvery(tryLogin.type, loginRequest);
  yield takeEvery(trySocialLogin.type, socialLoginRequest);
  yield takeEvery(checkLoginStatus.type, checkLogin);
  yield takeEvery(tryLogout.type, logoutRequest);
}

// reducer
const initialState = {
  request: false,
  initialPath: '/',
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    tryLogin(state, action) {
      state.request = true;
    },
    trySocialLogin(state, action) {
      state.request = true;
    },
    loginFinish(state) {
      state.request = false;
    },
    checkLoginStatus(state, action) {
      state.request = false;
    },
    rememberPath(state, action) {
      state.initialPath = action.payload;
    },
    tryLogout(state, action) {
      state.request = true;
    },
    logoutSuccess(state) {
      state.request = false;
      state.initialPath = '/';
    },
    logoutFail(state) {
      state.request = false;
    },
  },
});

export const {
  tryLogin,
  loginFinish,
  trySocialLogin,
  checkLoginStatus,
  rememberPath,
  tryLogout,
  logoutSuccess,
  logoutFail,
} = loginSlice.actions;

export default loginSlice.reducer;
