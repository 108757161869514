import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { COMPONENT_STATES } from '../../../utils/componentState';
import { errorHandler } from '../../../utils/fetchUtils';
import Urls from '../../../utils/endpoints';
import { chapterInitialState } from './utils/constants';
import {
  CharterTemplate,
  ChapterData,
  CharterReduxState,
  CharterStatePayload,
} from './utils/types';

// dal
const getChapterTemplates = () => axios.get(Urls.CHARTER_TEMPLATES);
const makeCharterText = (charterTemplateId, payload) =>
  axios.post(`${Urls.CHARTER_TEMPLATES}/${charterTemplateId}`, payload);

const getChapter = (projectId) => axios.get(Urls.CHARTER(projectId));
const updateChapter = (id, projectId, payload) =>
  axios.patch(`${Urls.CHARTER(projectId)}/${id}`, payload);
const approveChapter = (projectId) => axios.post(Urls.CHARTER(projectId));
const removeChapter = (id, projectId) => axios.delete(`${Urls.CHARTER(projectId)}/${id}`);

// saga
function* requestGetChapter(action) {
  try {
    const { projectId, userId, isProjectMember = true } = action.payload;
    const { data: charter } = yield call(getChapter, projectId);
    let templates = [];
    if (isProjectMember) {
      const { data } = yield call(getChapterTemplates);
      templates = data;
    }
    yield put(fetchWorkspaceCharterSuccess({ charter, templates, userId }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchCharterFail());
  }
}

function* requestUpdateChapter(action) {
  try {
    const { projectId, text, id } = action.payload;
    const { data } = yield call(updateChapter, id, projectId, { text });
    yield put(fetchUpdateCharterSuccess({ data }));
    yield put(setCharterState({ current: chapterInitialState[data.status] }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchCharterFail());
  }
}

function* requestApproveChapter(action) {
  try {
    const { projectId } = action.payload;
    const { data } = yield call(approveChapter, projectId);
    yield put(fetchApproveCharterSuccess({ data }));
    yield put(setCharterState({ current: chapterInitialState[data.status] }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchCharterFail());
  }
}

function* requestRemoveChapter(action) {
  try {
    const { projectId, id } = action.payload;
    const { data } = yield call(removeChapter, id, projectId);
    yield put(fetchRemoveCharterSuccess({ data }));
    yield put(setCharterState({ current: chapterInitialState[data.status] }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchCharterFail());
  }
}

function* requestMakeCharter(action) {
  try {
    const { charterTemplateId, values, previousState } = action.payload;
    const {
      data: { text },
    } = yield call(makeCharterText, charterTemplateId, values);
    yield put(makeCharterSuccess({ text }));
    yield put(setCharterState({ current: 'edit', previous: previousState }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchCharterFail());
  }
}

export function* watchRequestingProjectChapter() {
  yield takeLatest(fetchWorkspaceCharter.type, requestGetChapter);
  yield takeLatest(fetchUpdateCharter.type, requestUpdateChapter);
  yield takeLatest(fetchApproveCharter.type, requestApproveChapter);
  yield takeLatest(fetchRemoveCharter.type, requestRemoveChapter);
  yield takeLatest(fetchMakeCharter.type, requestMakeCharter);
}

const initialChapterData: ChapterData = {
  id: '',
  status: 'NOT_FORMED',
  text: '',
  acceptors: [],
};

// reducer
const initialState: CharterReduxState = {
  charterComponentState: COMPONENT_STATES.LOADING,
  charterData: initialChapterData,
  request: false,
  charterTemplates: [],
  workingTemplateVersion: {
    id: '',
    name: '',
    body: '',
    example: '',
    questions: [
      {
        id: '',
        number: 1,
        title: '',
        text: '',
      },
    ],
  },
  charterState: 'variant',
  previousState: 'variant',
  charterText: '',
  approvedByMe: false,
};

const projectSlice = createSlice({
  name: 'workspaceProjectCharter',
  initialState,
  reducers: {
    fetchWorkspaceCharter(state, action) {},
    fetchWorkspaceCharterSuccess(
      state,
      action: PayloadAction<{ charter: ChapterData; templates: CharterTemplate[]; userId: string }>,
    ) {
      const { charter, templates, userId } = action.payload;
      state.charterComponentState = COMPONENT_STATES.CONTENT;
      state.charterState = chapterInitialState[charter.status];
      state.charterData = charter;
      state.charterTemplates = templates;
      state.workingTemplateVersion = templates[0];
      const acceptorsIds = charter.acceptors.map((a) => a.id);
      state.approvedByMe = acceptorsIds.includes(userId);
    },
    // update
    fetchUpdateCharter(state, action) {
      state.request = true;
    },
    fetchUpdateCharterSuccess(state, action) {
      const { data } = action.payload;
      state.request = false;
      state.charterData = data;
      state.approvedByMe = false;
    },
    // approve
    fetchApproveCharter(state, action) {
      state.request = true;
    },
    fetchApproveCharterSuccess(state, action) {
      const { data } = action.payload;
      state.charterData = data;
      state.request = false;
      state.approvedByMe = true;
    },
    // remove
    fetchRemoveCharter(state, action) {
      state.request = true;
    },
    fetchRemoveCharterSuccess(state, action) {
      const { data } = action.payload;
      state.charterData = data;
      state.request = false;
      state.approvedByMe = false;
    },
    // fail
    fetchCharterFail(state) {
      state.request = false;
    },
    // settings
    setCharter(state, action) {
      state.charterData.text = action.payload;
    },
    setWorkingTemplateVersion(state, action: PayloadAction<CharterTemplate>) {
      state.workingTemplateVersion = action.payload;
    },
    setCharterState(state, action: PayloadAction<CharterStatePayload>) {
      const { current, previous = 'variant' } = action.payload;
      state.charterState = current;
      state.previousState = previous;
    },
    fetchMakeCharter(state, action) {
      state.request = true;
    },
    makeCharterSuccess(state, action) {
      const { text } = action.payload;
      state.charterData.text = text;
      state.request = false;
    },
    setComponentLoading(state) {
      state.charterComponentState = COMPONENT_STATES.LOADING;
    },
  },
});

export const {
  fetchWorkspaceCharter,
  fetchWorkspaceCharterSuccess,
  fetchUpdateCharter,
  fetchUpdateCharterSuccess,
  fetchApproveCharter,
  fetchApproveCharterSuccess,
  fetchRemoveCharter,
  fetchRemoveCharterSuccess,
  fetchCharterFail,
  setComponentLoading,
  setCharter,
  setCharterState,
  setWorkingTemplateVersion,
  fetchMakeCharter,
  makeCharterSuccess,
} = projectSlice.actions;

export default projectSlice.reducer;
