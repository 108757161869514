import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const WIDTH = 300;
const TEXT_HEIGHT = 30;

function ProjectTemplateSkeleton() {
  return (
    <>
      <Skeleton variant="text" width={WIDTH} height={TEXT_HEIGHT} animation="wave" />
      <Skeleton variant="text" width={WIDTH} height={TEXT_HEIGHT} animation="wave" />
      <Skeleton variant="text" width={WIDTH} height={TEXT_HEIGHT} animation="wave" />
      <Skeleton
        style={{ marginTop: 20 }}
        variant="rect"
        width={WIDTH}
        height={80}
        animation="wave"
      />
    </>
  );
}

export default ProjectTemplateSkeleton;
