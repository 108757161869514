import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import Urls from '../../utils/endpoints';
import { COMPONENT_STATES } from '../../utils/componentState';

// dal

export const getComments = (listUrl, page, pageSize, oldFirst) => {
  const url = oldFirst ? listUrl : `${listUrl}?ordering=-created_at`;
  return axios.get(url, {
    params: { page, pageSize },
  });
};

export const postComment = (postCommentUrl, payload) => axios.post(postCommentUrl, payload);

export const updateComment = (commentId, payload) =>
  axios.patch(`${Urls.COMMENTS(commentId)}`, payload);

export const removeComment = (commentId) => axios.delete(`${Urls.COMMENTS(commentId)}`);

// saga
function* requestFetchComments(action) {
  try {
    const { url, pageSize, currentPage, oldFirst } = action.payload;
    const nextPage = currentPage + 1;
    const { data: commentsData } = yield call(getComments, url, nextPage, pageSize, oldFirst);
    yield put(fetchCommentsSuccess({ commentsData, nextPage }));
  } catch (e) {
    toast.error('Не удалось загрузить комментарии');
    yield put(fetchCommentsFail());
  }
}

function* requestPostComment(action) {
  try {
    const { comment, url } = action.payload;
    const { data } = yield call(postComment, url, comment);
    yield put(postCommentSuccess({ data }));
  } catch (e) {
    toast.error('Не удалось опубликовать комментарий');
    put(postCommentFail());
  }
}

function* requestUpdateComment(action) {
  try {
    const { comment, index } = action.payload;
    const { data } = yield call(updateComment, comment.id, comment);
    yield put(updateCommentSuccess({ data, index }));
  } catch (e) {
    toast.error('Не удалось отредактировать комментарии');
    yield put(updateCommentFail());
  }
}

function* requestRemoveComment(action) {
  try {
    const { index, id } = action.payload;
    yield call(removeComment, id);
    yield put(removeCommentSuccess({ index }));
  } catch (e) {
    toast.error('Не удалось отредактировать комментарии');
    yield put(removeCommentFail());
  }
}

export function* watchLoadingComments() {
  yield takeLatest(fetchPostComment.type, requestPostComment);
  yield takeLatest(fetchComments.type, requestFetchComments);
  yield takeLatest(fetchUpdateComment.type, requestUpdateComment);
  yield takeLatest(fetchRemoveComment.type, requestRemoveComment);
}

//reducer
const initialState = {
  componentState: COMPONENT_STATES.LOADING,
  comments: [] as Comment[],
  commentsLoading: true,
  currentPage: 0,
  totalComments: 0,
  commentsTreeWasChanged: false,
};

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    // Загрузка комментариев
    fetchComments(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
      state.commentsLoading = true;
      state.commentsTreeWasChanged = false;
    },
    fetchCommentsSuccess(state, action) {
      const { commentsData, nextPage } = action.payload;
      state.componentState = COMPONENT_STATES.CONTENT;
      state.currentPage = nextPage;
      state.comments =
        state.currentPage === 1
          ? commentsData.content
          : [...state.comments, ...commentsData.content];
      state.commentsLoading = false;
      state.totalComments = commentsData.totalElements;
    },
    fetchCommentsFail(state) {
      state.commentsLoading = false;
    },

    // Публикация комментария
    fetchPostComment(state, action) {},
    postCommentSuccess(state, action) {
      const { data } = action.payload;
      state.comments = [data, ...state.comments];
      state.commentsTreeWasChanged = true;
    },
    postCommentFail(state) {},

    // Редактирование комментария
    fetchUpdateComment(state, action) {},
    updateCommentSuccess(state, action) {
      const { data, index } = action.payload;
      state.comments.splice(index, 1, data);
    },
    updateCommentFail(state) {},

    // Удаление комментария
    fetchRemoveComment(state, action) {},
    removeCommentSuccess(state, action) {
      const { index } = action.payload;
      state.totalComments = state.totalComments - 1;
      state.comments.splice(index, 1);
      state.commentsTreeWasChanged = true;
    },
    removeCommentFail(state) {},
  },
});

export const {
  fetchPostComment,
  postCommentSuccess,
  postCommentFail,
  fetchComments,
  fetchCommentsSuccess,
  fetchCommentsFail,
  fetchUpdateComment,
  updateCommentSuccess,
  updateCommentFail,
  fetchRemoveComment,
  removeCommentSuccess,
  removeCommentFail,
} = commentsSlice.actions;

export default commentsSlice.reducer;
