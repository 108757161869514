import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import ViewSelectState from '../../../../components/select-state/ViewSelectState';
import PageWrapper from '../../../../components/Wrapper';
import ProjectFullCard from '../../../projects/components/ProjectFullCard';
import TilesGridWrapper from '../../../../components/TilesGridWrapper';

import { fetchOrgPublicProjects, OrgPublicProjectsState } from './domain';
import { PublicOrganizationState } from '../domain';

interface OrgPublicProjects {}

function OrgPublicProjects(props: OrgPublicProjects) {
  const dispatch = useDispatch();
  // @ts-ignore
  const { id } = useParams();
  const { componentState, projects, isLastPage }: OrgPublicProjectsState = useSelector(
    (state: any) => state.orgPublicProjects,
  );

  const { organization }: PublicOrganizationState = useSelector(
    (state: any) => state.publicOrganization,
  );

  useEffect(() => {
    dispatch(fetchOrgPublicProjects({ isFirstLoading: true, orgId: id }));
  }, [dispatch, id]);

  const handleLoadMore = () => {
    dispatch(fetchOrgPublicProjects({ orgId: id }));
  };

  const content = () => {
    return (
      <div>
        <TilesGridWrapper isLastPage={isLastPage} loadMore={handleLoadMore}>
          {projects.map((p: any) => (
            <Grid key={p.id} item>
              <ProjectFullCard project={p} />
            </Grid>
          ))}
        </TilesGridWrapper>
      </div>
    );
  };

  return (
    <PageWrapper header={`Проекты организации ${organization.shortName}`}>
      <ViewSelectState state={componentState} content={content} />
    </PageWrapper>
  );
}

export default OrgPublicProjects;
