import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import Urls from '../../../utils/endpoints';
import { COMPONENT_STATES } from '../../../utils/componentState';
import { errorHandler } from '../../../utils/fetchUtils';
import { KanbanColumnType } from '../../../utils/types';
import { setKanbanCardOpen } from './components/kanban-card/domain';
import { fetchTaskTree } from '../plan/domain';

// dal
export const getKanbanBoard = (projectId) => axios.get(Urls.KANBAN_BOARD(projectId));
export const addCard = (projectId, payload) => axios.post(Urls.KANBAN_BOARD(projectId), payload);
export const updateCard = (projectId, cardId, payload) =>
  axios.patch(Urls.KANBAN_BOARD_CARDS(projectId, cardId), payload);
export const removeCard = (projectId, cardId) =>
  axios.delete(Urls.KANBAN_BOARD_CARDS(projectId, cardId));
export const moveCardInOtherColumn = (projectId, cardId, payload) =>
  axios.patch(Urls.KANBAN_BOARD_CARDS(projectId, cardId), payload);

// saga
function* requestKanbanBoard(action) {
  try {
    const { projectId } = action.payload;
    const { data } = yield call(getKanbanBoard, projectId);
    yield put(fetchKanbanBoardSuccess(data));
  } catch (e) {
    errorHandler(e);
    yield put(fetchKanbanBoardFail());
  }
}

export function* watchLoadingKanbanBoard() {
  yield takeLatest(fetchKanbanBoard.type, requestKanbanBoard);
}

function* requestRemoveTask(action) {
  try {
    const { projectId, cardId } = action.payload;
    yield call(removeCard, projectId, cardId);
    yield put(setKanbanCardOpen(false));
    yield put(fetchKanbanBoard({ projectId }));
    yield put(fetchTaskTree({ projectId }));
  } catch (e) {
    errorHandler(e);
    yield put(cardOperationFail());
  }
}

function* requestMoveCard(action) {
  try {
    const { projectId, cardId, columnId, weight } = action.payload;
    const payload = columnId ? { columnId, weight } : { weight };
    yield call(moveCardInOtherColumn, projectId, cardId, payload);
    yield put(fetchKanbanBoard({ projectId }));
  } catch (e) {
    errorHandler(e);
    yield put(cardOperationFail());
  }
}

export function* watchWorkingWithCards() {
  yield takeLatest(fetchRemoveCard.type, requestRemoveTask);
  yield takeLatest(fetchMoveCard.type, requestMoveCard);
}

// reducer
const initialState = {
  componentState: COMPONENT_STATES.LOADING,
  kanbanBoardData: [] as KanbanColumnType[],
};

const kanbanSlice = createSlice({
  name: 'workspaceKanban',
  initialState,
  reducers: {
    fetchKanbanBoard(state, action) {},
    fetchKanbanBoardSuccess(state, action) {
      state.componentState = COMPONENT_STATES.CONTENT;
      state.kanbanBoardData = action.payload;
    },
    fetchKanbanBoardFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
    fetchMoveCard(state, action) {
      const { newData } = action.payload;
      state.kanbanBoardData = newData;
    },
    fetchRemoveCard(state, action) {},
    cardOperationFail(state) {
      state.componentState = COMPONENT_STATES.CONTENT;
    },
  },
});

export const {
  fetchKanbanBoard,
  fetchKanbanBoardSuccess,
  fetchKanbanBoardFail,
  fetchRemoveCard,
  cardOperationFail,
  fetchMoveCard,
} = kanbanSlice.actions;

export default kanbanSlice.reducer;
