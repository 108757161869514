import { call, put, takeLatest } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { COMPONENT_STATES } from '../../../../utils/componentState';
import { errorHandler } from '../../../../utils/fetchUtils';
import { ComponentState, TablePaginationType } from '../../../../utils/types';
import axios from 'axios';
import Urls from '../../../../utils/endpoints';

// dal
const getUserRecruitings = (userId: string, pagination: TablePaginationType, filters) => {
  const params = { ...pagination, ...filters };
  return axios.get(Urls.PERSONAL_RECRUITING_LIST(userId), { params });
};

// saga
function* requestUserStatRecruitings(action) {
  try {
    const { userId, pagination, filters } = action.payload;
    const { data } = yield call(getUserRecruitings, userId, pagination, filters);
    yield put(fetchUserStatRecruitingsSuccess({ data }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchUserStatRecruitingsFail());
  }
}

export function* watchLoadingUserStatRecruitings() {
  yield takeLatest(fetchUserStatRecruitings.type, requestUserStatRecruitings);
}

export interface UserStatRecruitingsType {
  componentState: ComponentState;
  recruitings: any[];
  totalElements: number;
  request: boolean;
}

// reducer
const initialState: UserStatRecruitingsType = {
  componentState: COMPONENT_STATES.LOADING,
  recruitings: [],
  totalElements: 0,
  request: true,
};

const userStatRecruitingsSlice = createSlice({
  name: 'userStatRecruitings',
  initialState,
  reducers: {
    fetchUserStatRecruitings(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
    },
    fetchUserStatRecruitingsSuccess(state, action) {
      const { data } = action.payload;
      if (!data.content.length) {
        state.componentState = COMPONENT_STATES.EMPTY;
      } else {
        state.recruitings = data.content;
        state.totalElements = data.totalElements;
        state.componentState = COMPONENT_STATES.CONTENT;
      }
    },
    fetchUserStatRecruitingsFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const {
  fetchUserStatRecruitings,
  fetchUserStatRecruitingsSuccess,
  fetchUserStatRecruitingsFail,
} = userStatRecruitingsSlice.actions;

export default userStatRecruitingsSlice.reducer;
