import React from 'react';
import { useHistory } from 'react-router-dom';

import ProjectCard from '../../../dashboard/screens/projects/ProjectCard';
import CardBlockWrapper from '../../../dashboard/components/CardBlockWrapper';

import Paths from '../../../../utils/route-paths';
import { Project } from '../../../../utils/types';

interface PublicProjectsBlockProps {
  userId?: string;
  counter?: number;
  onClickMore?: () => void;
  data: Project[];
}

function PublicProjectsBlock(props: PublicProjectsBlockProps): JSX.Element | null {
  const history = useHistory();
  const { data, userId, onClickMore, counter } = props;

  const handleOnClickMore = () => {
    if (onClickMore) {
      onClickMore();
    } else {
      history.push(`${Paths.PUBLIC_USER_PROJECTS}/${userId}`);
    }
  };

  if (!data?.length) {
    return null;
  }

  return (
    <CardBlockWrapper
      counter={counter || data.length}
      title="Проекты"
      titleLinkOnClick={handleOnClickMore}
      counterEnable
    >
      {data.slice(0, 2).map((item) => (
        <ProjectCard key={item.id} data={item} />
      ))}
    </CardBlockWrapper>
  );
}

export default PublicProjectsBlock;
