/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ColoredAvatar from '../../../components/ColoredAvatar';
import RemoveMemberButton from './RemoveMemberButton';

import { User } from '../../../utils/types';
import { TOURS_CLASS_NAMES } from '../../../components/tour/classNames';

interface ProjectTeam {
  users: User[];
  isMentor: boolean;
  onKickMember: (payload) => void;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 26,
  },
  title: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 8,
  },
  label: {
    marginRight: 32,
  },
  team: {
    display: 'flex',
    alignItems: 'center',
  },
  member: {
    position: 'relative',
    width: 'min-content',
    transition: theme.transitions.create(['margin-right'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
  },
  teamEdit: {
    marginRight: 12,
  },
  teamView: {
    marginRight: 6,
  },
  closeIconButton: {
    position: 'absolute',
    left: 32,
    top: -10,
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
  },
  closeIconButtonEdit: {
    opacity: 1,
  },
  closeIconButtonView: {
    opacity: 0,
  },
  avatar: {},
}));

function ProjectTeam(props: ProjectTeam) {
  const { className, users, onKickMember, isMentor } = props;
  const [editTeam, setEditTeam] = useState<boolean>(false);
  const classes = useStyles({ editTeam });

  const handleToggleEditMode = () => {
    setEditTeam((prevState) => !prevState);
  };

  const handleRemoveMember = (userId) => (kickReason) => {
    if (editTeam) {
      onKickMember({ user: userId, kickReason });
      setEditTeam(false);
    }
  };

  return (
    <div className={clsx(classes.root, !!className && className)}>
      <div className={classes.title}>
        <Typography className={classes.label} variant="subtitle2" color="textSecondary">
          Команда проекта
        </Typography>
        {isMentor && (
          <Link
            className={clsx(!editTeam && TOURS_CLASS_NAMES.projectInfo.kickOffButton)}
            component="button"
            onClick={handleToggleEditMode}
          >
            <Typography variant="subtitle2" color="primary">
              {editTeam ? 'Отменить' : 'Исключение участников'}
            </Typography>
          </Link>
        )}
      </div>
      <div className={classes.team}>
        {users.map((student) => {
          return (
            <div
              className={clsx(classes.member, {
                [classes.teamEdit]: editTeam,
                [classes.teamView]: !editTeam,
              })}
              key={student.id}
            >
              {
                <RemoveMemberButton
                  className={clsx(classes.closeIconButton, {
                    [classes.closeIconButtonEdit]: editTeam,
                    [classes.closeIconButtonView]: !editTeam,
                  })}
                  size="small"
                  submitRemoving={handleRemoveMember(student.id)}
                  user={student}
                  isEdit={editTeam}
                >
                  <CloseIcon fontSize="small" />
                </RemoveMemberButton>
              }
              <ColoredAvatar className={classes.avatar} user={student} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ProjectTeam;
