import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import Urls from '../../../../utils/endpoints';
import { COMPONENT_STATES } from '../../../../utils/componentState';
import { errorHandler } from '../../../../utils/fetchUtils';

// dal
export const addApplication = (projectId, payload) =>
  axios.post(Urls.RECRUITING_APPLICATIONS(projectId), payload);

// saga
function* addApplicationRequest(action) {
  try {
    const { id, values, onClose, history } = action.payload;
    yield call(() => addApplication(id, values));
    toast.success('Заявка успешно отправлена');
    onClose();
    history.go();
    yield put(addingNewApplicationSuccess());
  } catch (e) {
    errorHandler(e);
    yield put(addingNewApplicationFail());
  }
}

export function* watchAddingNewApplication() {
  yield takeEvery(addNewApplication.type, addApplicationRequest);
}

// reducer
const initialState = {
  componentState: COMPONENT_STATES.CONTENT,
};

const addNewApplicationSlice = createSlice({
  name: 'newApplication',
  initialState,
  reducers: {
    addNewApplication(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
    },
    addingNewApplicationSuccess(state) {
      state.componentState = COMPONENT_STATES.CONTENT;
    },
    addingNewApplicationFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const {
  addNewApplication,
  addingNewApplicationSuccess,
  addingNewApplicationFail,
} = addNewApplicationSlice.actions;

export default addNewApplicationSlice.reducer;
