import React from 'react';
import { useDispatch } from 'react-redux';

import { setCharterState } from '../domain';
import CustomButton from '../../../../components/CustomButton';

function RemakeCharterButton(props) {
  const dispatch = useDispatch();

  const handleReStartCharterMaster = () => {
    dispatch(setCharterState({ current: 'variant' }));
  };

  return (
    <CustomButton
      withConfirm
      popoverProps={{ secondaryText: 'Все изменения будут утеряны' }}
      variant="outlined"
      color="primary"
      onClick={handleReStartCharterMaster}
      {...props}
    >
      Создать устав заново
    </CustomButton>
  );
}

export default RemakeCharterButton;
