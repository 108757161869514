import axios from 'axios';
import Urls from '../../../utils/endpoints';
import { call, put, takeEvery } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { COMPONENT_STATES } from '../../../utils/componentState';
import { errorHandler } from '../../../utils/fetchUtils';
import { TablePaginationType } from '../../../utils/types';

// dal
export const getUserList = (orgId: string, pagination: TablePaginationType, filters = {}) => {
  const params = { ...pagination, ...filters };
  return axios.get(Urls.ORGANIZATION_USER(orgId), { params });
};

const getOrganizationToken = (orgId) => axios.get(Urls.ORGANIZATION_TOKEN(orgId));

// saga
function* userListRequest(action) {
  try {
    const { orgId, pagination, filters } = action.payload;
    const { data: tableData } = yield call(getUserList, orgId, pagination, filters);
    const {
      data: { token },
    } = yield call(getOrganizationToken, orgId);
    yield put(fetchUserListSuccess({ tableData, token }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchUserListFail(e.response));
  }
}

export function* watchLoadingOrganizationUserList() {
  yield takeEvery(fetchUserList.type, userListRequest);
}

// reducer
const initialState = {
  componentState: COMPONENT_STATES.LOADING,
  users: [] as any[],
  tableData: {},
  orgToken: null,
  errorData: {},
};

const organizationUserListSlice = createSlice({
  name: 'organizationUserList',
  initialState,
  reducers: {
    fetchUserList(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
    },
    fetchUserListSuccess(state, action) {
      const { tableData, token } = action.payload;
      const users = tableData.content;
      state.users = users;
      state.tableData = tableData;
      state.orgToken = token;
      state.componentState = users.length ? COMPONENT_STATES.CONTENT : COMPONENT_STATES.EMPTY;
    },
    fetchUserListFail(state, action) {
      state.componentState = COMPONENT_STATES.ERROR;
      state.errorData = action.payload;
    },
  },
});

export const {
  fetchUserList,
  fetchUserListSuccess,
  fetchUserListFail,
} = organizationUserListSlice.actions;

export default organizationUserListSlice.reducer;
