import React from 'react';

import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import StandardDialog from '../../../../components/StandardDialog';
import Urls from '../../../../utils/endpoints';
import { PAID_FEATURES_LINK } from '../../../../utils/constants';

interface QuotaOverrunDialog {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  dialog: {
    width: 1000,
    height: 300,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    paddingLeft: 8,
    paddingTop: 0,
  },
  actionBox: {
    height: 200,
  },
  textVariant: {
    width: 450,
    paddingLeft: 80,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  image: {
    height: 300,
  },
  button: {
    width: 256,
  },
}));

function QuotaOverrunDialog({ open, onClose }: QuotaOverrunDialog): JSX.Element {
  const classes = useStyles();

  const handleToKnowClick = (): void => {
    window.open(PAID_FEATURES_LINK, '_blank', 'noopener, noreferrer');
  };

  return (
    <StandardDialog className={classes.dialog} open={open} onClose={onClose}>
      <img className={classes.image} src={Urls.ASSET_IMAGE('mentor_hbupfm')} alt="mentor_img" />
      <div className={clsx(classes.actionBox, classes.textVariant)}>
        <Typography variant="h4" gutterBottom>
          Превышение квоты
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          Количество разрешенных проектов исчерпано для вашей организации. Нажмите на кнопку что бы
          узнать о преимуществах платной версии
        </Typography>
        <Button
          className={classes.button}
          size="large"
          variant="contained"
          color="secondary"
          onClick={handleToKnowClick}
        >
          Узнать
        </Button>
      </div>
    </StandardDialog>
  );
}

export default QuotaOverrunDialog;
