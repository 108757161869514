import React, { useEffect, useState } from 'react';
import { createMuiTheme, makeStyles, Theme, ThemeProvider } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import CommunityIcon from '@material-ui/icons/Forum';
import SvgIcon from '@material-ui/core/SvgIcon';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { ReactComponent as Tips } from '../../resources/tips.svg';
import UserCard from './UserCard';
import SideMenuItem from './MenuItem';
import Tour from '../../components/tour/Tour';

import { BUILD_VERSION, USER_ROLES, WIKI_LINK } from '../../utils/constants';
import Urls from '../../utils/endpoints';
import { CUSTOM_SHADOWS, PALETTE } from '../../utils/palette';
import Paths from '../../utils/route-paths';
import {
  fetchNotificationCount,
  setPath,
  setTourStart,
  toggleRightPanelView,
} from '../globalDomain';
import { tryLogout } from '../auth/domain';
import { RootState } from '../../store/rootReducer';

import { TOURS_CLASS_NAMES } from '../../components/tour/tourSteps';

export const drawerWidth = 280;

const drawerTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#78909C',
    },
    secondary: {
      main: PALETTE().red,
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    text: {
      primary: '#78909C',
      secondary: '#212121',
    },
    action: {
      hover: 'rgba(120,144,156,0.1)',
      selected: 'rgba(120,144,156,0.2)',
    },
    divider: 'rgba(120,144,156,0.25)',
  },
  typography: {
    body1: {
      fontSize: 14,
    },
    h4: {
      fontSize: 24,
      fontWeight: 500,
    },
  },
  overrides: {
    MuiPaper: {
      elevation5: {
        boxShadow: CUSTOM_SHADOWS.gray.elevation5,
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    scroll: 'no',
    flexShrink: 0,
    color: 'white',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1,
    },
    overflowX: 'hidden',
  },
  footerDivider: {
    marginTop: 'auto',
  },
  logoBox: {
    display: 'flex',
    marginBottom: 16,
  },
  logoImg: {
    width: 32,
    height: 32,
    transition: theme.transitions.create(['margin-left', 'margin-right'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
  },
  logoImgOpen: {
    marginLeft: 65,
    marginRight: 8,
  },
  logoImgClose: {
    marginLeft: 16,
    marginRight: 30,
  },
  ideaIcon: {
    transform: 'rotate(180deg)',
  },
  pulse: {
    color: PALETTE().red,
    animation: '$pulse 15s infinite',
  },
  '@keyframes pulse': {
    '0%': {
      'box-shadow': '0 0 0 0 rgba(235,87,87, 0.4)',
    },
    '10%': {
      'box-shadow': '0 0 0 10px rgba(235,87,87, 0)',
    },
    '20%': {
      'box-shadow': '0 0 0 0 rgba(235,87,87, 0)',
    },
  },
  paper: {
    overflow: 'hidden',
  },
}));

function MainMenu(): JSX.Element {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(true);
  const { user, path, notificationsCount, tourPages } = useSelector(
    (state: RootState) => state.app,
  );
  const { sideMenu } = TOURS_CLASS_NAMES;

  useEffect(() => {
    dispatch(setPath({ path: pathname }));
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(tryLogout({ history }));
  };

  const handleMenuClick = (menuPath) => {
    history.push(menuPath);
  };

  const handleHelpClick = () => {
    window.open(WIKI_LINK, '_blank', 'noopener, noreferrer');
  };

  const handleRunTour = () => {
    // e.preventDefault();
    dispatch(setTourStart());
  };

  const handleNotificationsClick = () => {
    dispatch(toggleRightPanelView({ rightPanelName: 'notifications' }));
    dispatch(fetchNotificationCount());
  };

  return (
    <ThemeProvider theme={drawerTheme}>
      <Drawer
        PaperProps={{ elevation: 5, classes: { root: classes.paper } }}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        variant="permanent"
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <UserCard open={open} logout={handleLogout} />
        <Divider className={sideMenu.center} />
        <List>
          <SideMenuItem
            className={sideMenu.dashboard}
            key="dashboard"
            text="Обзор"
            isSelected={path === '/'}
            onClick={() => handleMenuClick('/')}
            Icon={<HomeIcon color="primary" />}
          />
          <SideMenuItem
            className={sideMenu.projects}
            key="projects"
            text="Проекты"
            isSelected={path === Paths.PERSONAL_PROJECTS}
            onClick={() => handleMenuClick(Paths.PERSONAL_PROJECTS)}
            Icon={<SchoolIcon color="primary" />}
          />
          <SideMenuItem
            className={sideMenu.ideas}
            key="common-projects-templates"
            text="Идеи проектов"
            isSelected={path === Paths.COMMON_IDEAS || path === Paths.PERSONAL_IDEAS}
            onClick={() => handleMenuClick(Paths.COMMON_IDEAS)}
            Icon={<WbIncandescentIcon className={classes.ideaIcon} color="primary" />}
          />
          <SideMenuItem
            className={sideMenu.recruiting}
            key="recruiting-projects"
            text="Рекрутинг"
            isSelected={
              path === Paths.COMMON_RECRUITING_PROJECTS ||
              path === Paths.PERSONAL_RECRUITING_PROJECTS
            }
            onClick={() => handleMenuClick(Paths.COMMON_RECRUITING_PROJECTS)}
            Icon={<HowToRegIcon color="primary" />}
          />
          {user && user.role === USER_ROLES.ORGANIZATION && (
            <SideMenuItem
              key="organization-management"
              text="Управление организацией"
              isSelected={path === Paths.ORGANIZATION}
              onClick={() => handleMenuClick(Paths.ORGANIZATION)}
              Icon={<GroupWorkIcon color="primary" />}
            />
          )}
          <SideMenuItem
            key="notifications"
            text="Уведомления"
            Icon={<NotificationsIcon color="primary" />}
            onClick={handleNotificationsClick}
            withBadge
            badgeProps={{ badgeContent: notificationsCount.unread }}
          />
        </List>
        <Divider />
        <List>
          <SideMenuItem
            className={sideMenu.community}
            key="community"
            text="Сообщество EDUTEAM"
            isSelected={path === Paths.COMMUNITY}
            onClick={() => handleMenuClick(Paths.COMMUNITY)}
            Icon={<CommunityIcon color="primary" />}
          />
          <SideMenuItem
            className={sideMenu.help}
            key="wiki"
            text="Справка"
            Icon={<MenuBookIcon color="primary" />}
            onClick={handleHelpClick}
          />
          {!!tourPages.length && (
            <SideMenuItem
              className={clsx(classes.pulse, sideMenu.tour)}
              key="tour"
              text="Помощь и обучение"
              isSelected={false}
              onClick={handleRunTour}
              Icon={
                <SvgIcon>
                  <Tips />
                </SvgIcon>
              }
            />
          )}
        </List>
        <div className={classes.footerDivider} />
        <div className={classes.logoBox}>
          <img
            className={clsx(classes.logoImg, {
              [classes.logoImgOpen]: open,
              [classes.logoImgClose]: !open,
            })}
            src={Urls.ASSET_IMAGE('logo_tfgtoc')}
            alt="logo"
          />
          <div>
            <Typography variant="h4" color="textSecondary">
              EDUTEAM
            </Typography>
            <Typography>v.{BUILD_VERSION}</Typography>
          </div>
        </div>
        <Divider />
        <List>
          <SideMenuItem
            key="menu-collapse"
            text="свернуть меню"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            Icon={open ? <ChevronLeftIcon color="primary" /> : <ChevronRightIcon color="primary" />}
          />
        </List>
        <Tour />
      </Drawer>
    </ThemeProvider>
  );
}

export default MainMenu;
