import React from 'react';
import { Form, Formik } from 'formik';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import { maxTaskSymbols, TaskFirstItemSchema } from './validationSchema';

interface FirstTaskInputProps {
  onChange: (task) => void;
  onCancelChange: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginTop: 20,
  },
  input: {
    width: 600,
    padding: 16,
    border: '2px dotted',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 16,
  },
  saveButton: {
    // color: PALETTE().green,
  },
  cancelButton: {
    // color: PALETTE().gray,
    marginLeft: 16,
  },
}));

function FirstTaskInput(props: FirstTaskInputProps) {
  const classes = useStyles();
  const { onChange, onCancelChange } = props;
  const { request } = useSelector((state: any) => state.plan);

  const completeEditing = (values) => {
    onChange(values);
  };

  return (
    <Formik
      initialValues={{ name: '', daysToComplete: 1 }}
      validationSchema={TaskFirstItemSchema}
      onSubmit={completeEditing}
      enableReinitialize
    >
      {({ values, handleChange, isValid, errors, dirty }) => {
        return (
          <Form className={classes.root}>
            <div className={classes.input}>
              <TextField
                name="name"
                value={values.name}
                onChange={handleChange}
                fullWidth
                size="small"
                variant="standard"
                placeholder="Наименование работы"
                InputProps={{ disableUnderline: true }}
                autoFocus
                disabled={request}
                inputProps={{
                  maxLength: maxTaskSymbols,
                }}
              />
            </div>
            <div className={classes.buttons}>
              <Button
                className={classes.saveButton}
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isValid || !dirty || request}
              >
                Сохранить
              </Button>
              <Button className={classes.cancelButton} onClick={onCancelChange} variant="contained">
                Отмена
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default FirstTaskInput;
