import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import RemakeCharterButton from '../components/RamakeCharterButton';
import CharterTextView from '../components/CharterTextView';
import CustomButton from '../../../../components/CustomButton';

import { fetchRemoveCharter, setCharterState } from '../domain';
import { CharterReduxState } from '../utils/types';

const useStyles = makeStyles((theme) => ({
  buttonsBox: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: 16,
    marginTop: 10,
  },
}));

function Approved() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    charterData: { text: charter, id },
    request,
    charterState,
  }: CharterReduxState = useSelector((state: any) => state.workspaceProjectCharter);
  const {
    projectData: { isMentor, id: projectId },
  } = useSelector((state: any) => state.workspaceProjectInfo);

  const handleRemoveDraft = () => {
    dispatch(fetchRemoveCharter({ projectId, id }));
  };

  const startEditText = () => {
    dispatch(setCharterState({ current: 'edit', previous: charterState }));
  };

  return (
    <div>
      <CharterTextView charter={charter} />
      {isMentor && (
        <div className={classes.buttonsBox}>
          <CustomButton
            style={{ marginRight: 10 }}
            variant="outlined"
            color="primary"
            disabled={request}
            onClick={startEditText}
          >
            Редактировать устав
          </CustomButton>
          <RemakeCharterButton disabled={request} />
          <CustomButton
            withConfirm
            style={{ marginLeft: 10 }}
            variant="outlined"
            color="secondary"
            disabled={request}
            onClick={handleRemoveDraft}
          >
            Удалить устав
          </CustomButton>
        </div>
      )}
    </div>
  );
}

export default Approved;
