import * as Yup from 'yup';
import {
  MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT,
  MAX_SYMBOLS_NUMBER_FOR_LONG_TEXT,
} from '../../../../../utils/constants';

const required = 'Обязательное поле';

const CardSchema = Yup.object().shape({
  name: Yup.string()
    .max(
      MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT,
      `Название задачи не может быть длиннее ${MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT} символов`,
    )
    .required(required),
  description: Yup.string()
    .transform((value) => {
      return value;
    })
    .max(
      MAX_SYMBOLS_NUMBER_FOR_LONG_TEXT,
      `Описание не может быть длиннее ${MAX_SYMBOLS_NUMBER_FOR_LONG_TEXT} символов`,
    ),
});

export default CardSchema;
