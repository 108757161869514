import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const TASK_HEIGHT = 75;

const useStyles = makeStyles(() => ({
  root: {},
  header: {
    width: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  taskBox: {
    paddingLeft: 44,
  },
  task: {
    marginTop: -10,
  },
}));

function WorkspacePlan() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.taskBox}>
        <Skeleton
          className={classes.task}
          variant="text"
          width={'60%'}
          height={TASK_HEIGHT}
          animation="wave"
        />
        <Skeleton
          className={classes.task}
          variant="text"
          width={'60%'}
          height={TASK_HEIGHT}
          animation="wave"
        />
        <Skeleton
          className={classes.task}
          variant="text"
          width={'60%'}
          height={TASK_HEIGHT}
          animation="wave"
        />
      </div>
    </div>
  );
}

export default WorkspacePlan;
