import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ViewSelectState from '../../../components/select-state/ViewSelectState';
import PageWrapper from '../../../components/Wrapper';
import GridWrapperWithTitle from '../../../components/GridWrapperWithTitle';
import ProjectsGrid from '../components/ProjectsGrid';

import { fetchPersonalProjectsList, fetchOrganizationProjectsList, ProjectsState } from './domain';
import Paths from '../../../utils/route-paths';
import { setTourPage } from '../../globalDomain';
import { TOUR_PAGES } from '../../../components/tour/tourSteps';

function PersonalProjectsTiles() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state: any) => state.app);
  const { myProjects, orgProjects }: ProjectsState = useSelector(
    (state: any) => state.personalProjectsList,
  );

  useEffect(() => {
    if (user) {
      dispatch(fetchPersonalProjectsList({ isFirstLoading: true, profileId: user.id }));
      if (user.organization.id) {
        dispatch(
          fetchOrganizationProjectsList({ isFirstLoading: true, orgId: user.organization.id }),
        );
      }
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user) {
      dispatch(setTourPage({ pages: [TOUR_PAGES.sideMenu] }));
    }
    return function cleanUp() {
      dispatch(setTourPage({ pages: [] }));
    };
  }, [dispatch, user]);

  const handleLoadMoreMyProjects = () => {
    dispatch(fetchPersonalProjectsList({ isFirstLoading: false, profileId: user.id }));
  };

  const handleLoadMoreOrgProjects = () => {
    dispatch(fetchOrganizationProjectsList({ isFirstLoading: false, orgId: user.organization.id }));
  };

  const myProjectsContent = () => (
    <ProjectsGrid
      loadMore={handleLoadMoreMyProjects}
      isLastPage={myProjects.isLastPage}
      data={myProjects.content}
    />
  );

  const orgProjectsContent = () => (
    <ProjectsGrid
      loadMore={handleLoadMoreOrgProjects}
      isLastPage={orgProjects.isLastPage}
      data={orgProjects.content}
    />
  );

  return (
    <PageWrapper header="Проекты">
      <GridWrapperWithTitle title="Мои проекты">
        <ViewSelectState
          state={myProjects.componentState}
          content={myProjectsContent}
          noDataText={'У вас еще нет запущенных проектов'}
          noDataButtonText={'Найти проект'}
          noDataButtonAction={() => history.push(Paths.COMMON_RECRUITING_PROJECTS)}
        />
      </GridWrapperWithTitle>
      {!!user?.organization.id && (
        <GridWrapperWithTitle title="Проекты в моей организации">
          <ViewSelectState
            state={orgProjects.componentState}
            content={orgProjectsContent}
            noDataText={'У организации еще нет запущенных проектов'}
          />
        </GridWrapperWithTitle>
      )}
    </PageWrapper>
  );
}

export default PersonalProjectsTiles;
