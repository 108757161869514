import axios, { AxiosResponse } from 'axios';
import { Notification, TablePaginationType } from '../../../utils/types';
import UrlsV2 from '../../../utils/endpointsV2';

export interface NotificationResponseData {
  page: number;
  pageSize: number;
  totalPages: number;
  last: boolean;
  totalElements: number;
  content: Notification[];
}

export const getNotificationsList = (
  pagination: TablePaginationType,
  filters = {},
): Promise<AxiosResponse<NotificationResponseData>> => {
  const params = { ...pagination, ...filters };
  return axios.get(UrlsV2.NOTIFICATIONS_LIST, { params });
};

export const setNotificationRead = (id) => axios.post(UrlsV2.NOTIFICATIONS_READ(id));
export const setNotificationReadAll = () => axios.post(UrlsV2.NOTIFICATIONS_READ_ALL);
