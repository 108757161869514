import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import TextFieldWithCounter from '../../../components/TextFieldWithCounter';
import ViewSelectState from '../../../components/select-state/ViewSelectState';
import MainPaper from '../../../components/MainPaper';
import SelectField from '../../../components/SelectField';
import ProfileAvatar from '../../../components/ProfileAvatar';
import CustomButton from '../../../components/CustomButton';

import UserSchema from './validationSchemaForUpdate';
import { fetchUser, startUpdatingUser, startRemovingUser } from './domain';
import { USER_TYPES, USER_TYPE_TITLES, USER_ROLES } from '../../../utils/constants';
import { makeFullName } from '../../../utils/utils';
import Urls from '../../../utils/endpoints';
import Dates from '../../../utils/dates';
import { TOURS_CLASS_NAMES } from '../../../components/tour/tourSteps';
import clsx from 'clsx';

interface OrganizationUserProps {
  id: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  info: {
    display: 'flex',
  },
  form: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  formRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'flex-end',
    '& button': {
      width: 160,
      height: 48,
    },
  },
  avatar: {
    marginRight: 24,
  },
  inputField: {
    width: 280,
  },
  textInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  typeText: {
    fontWeight: 600,
    fontSize: 18,
    color: '#78909C',
  },
  nameText: {
    fontSize: 36,
    fontWeight: 500,
    lineHeight: 1.2,
  },
  orgText: {
    fontSize: 24,
    fontWeight: 300,
    lineHeight: 2.8,
  },
  emailText: {
    fontSize: 18,
    fontWeight: 500,
    color: '#78909C',
  },
  button: {
    width: 160,
  },
  removeIcon: {
    position: 'absolute',
    top: -16,
    right: 8,
  },
  avatarProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  avatarContainer: {
    position: 'relative',
  },
}));

function OrganizationUser({ id }: OrganizationUserProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { componentState, user, requesting } = useSelector((state: any) => state.organizationUser);
  const { user: appUser } = useSelector((state: any) => state.app);

  useEffect(() => {
    if (appUser) {
      dispatch(fetchUser({ orgId: appUser.organization.id, userId: id }));
    }
  }, [id, dispatch, appUser]);

  const handleSubmit = (values, { resetForm }) => {
    dispatch(startUpdatingUser({ orgId: appUser.organization.id, userId: id, values }));
    resetForm({ values });
  };

  const handleRemoveUser = () => {
    dispatch(startRemovingUser({ orgId: appUser.organization.id, userId: id, history }));
  };

  // На бэке не реализовано удаление аватара руководителем организации
  // const handleRemoveAvatar = () => {
  //   dispatch(startRemovingAvatar({ orgId: appUser.organization.id, userId: id }));
  // };

  const content = () => (
    <div>
      <div className={classes.root}>
        <div className={classes.info}>
          <ProfileAvatar
            userFirstName={user.firstName}
            userLastName={user.lastName}
            className={classes.avatar}
            avatarUrl={Urls.AVATAR(user.avatar)}
          />
          <div className={classes.textInfoBlock}>
            <Typography className={classes.typeText}>{USER_TYPE_TITLES[user.type]}</Typography>
            <Typography className={classes.nameText}>{makeFullName(user)}</Typography>
            <Typography className={classes.orgText}>{user.organization?.name}</Typography>
            {!!user.lastActivity && (
              <Typography
                className={clsx(classes.emailText, TOURS_CLASS_NAMES.userInfoStat.lastActivity)}
              >
                Последняя активность: {Dates.dateToLocalDateAndTime(new Date(user.lastActivity))}
              </Typography>
            )}
          </div>
        </div>
        <Formik
          validationSchema={UserSchema}
          initialValues={user}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, handleChange, isValid, errors, dirty }) => {
            return (
              <div>
                <Form className={classes.form}>
                  <div className={classes.formRow}>
                    <TextFieldWithCounter
                      className={classes.inputField}
                      name="lastName"
                      error={errors.lastName}
                      value={values.lastName}
                      onChange={handleChange}
                      label="Фамилия"
                    />
                    <TextFieldWithCounter
                      className={classes.inputField}
                      name="firstName"
                      error={errors.firstName}
                      value={values.firstName}
                      onChange={handleChange}
                      label="Имя"
                    />
                    <TextFieldWithCounter
                      className={classes.inputField}
                      name="middleName"
                      error={errors.middleName}
                      value={values.middleName}
                      onChange={handleChange}
                      label="Отчество"
                    />
                  </div>
                  <SelectField
                    className={classes.inputField}
                    name="type"
                    error={errors.type}
                    value={values.type || ''}
                    items={[
                      { value: USER_TYPES.STUDENT, name: USER_TYPE_TITLES.STUDENT },
                      { value: USER_TYPES.EMPLOYEE, name: USER_TYPE_TITLES.EMPLOYEE },
                    ]}
                    label="Тип пользователя"
                  />

                  <div className={classes.buttons}>
                    <CustomButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      horizontalMargin="right"
                      disabled={!(isValid && dirty) || requesting}
                    >
                      Сохранить
                    </CustomButton>

                    <CustomButton
                      variant="outlined"
                      color="primary"
                      disabled={!isValid}
                      onClick={() => history.goBack()}
                    >
                      Отмена
                    </CustomButton>
                    {user.role !== USER_ROLES.ORGANIZATION && (
                      <CustomButton
                        className={TOURS_CLASS_NAMES.userInfoStat.kickOffMenuItem}
                        variant="outlined"
                        color="secondary"
                        horizontalMargin="left"
                        withConfirm
                        popoverProps={{ mainText: 'Исключить пользователя из организации?' }}
                        disabled={!isValid || requesting}
                        onClick={handleRemoveUser}
                      >
                        Исключить
                      </CustomButton>
                    )}
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );

  return (
    <div>
      <MainPaper>
        <ViewSelectState state={componentState} content={content} />
      </MainPaper>
    </div>
  );
}

export default OrganizationUser;
