import * as Yup from 'yup';
import {
  MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT,
  MIN_SYMBOLS_NUMBER,
} from '../../../../../utils/constants';

export const CancelRecruitingSchema = Yup.object().shape({
  message: Yup.string()
    .trim()
    .min(MIN_SYMBOLS_NUMBER, `Причина не может быть короче ${MIN_SYMBOLS_NUMBER} символов`)
    .max(
      MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT,
      `Причина не может быть длиннее ${MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT} символов`,
    ),
});
