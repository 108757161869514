import { combineReducers } from 'redux';

// auth
import login from '../app/auth/domain';
import passwordResetRequest from '../app/auth/password-reset/domain';
import passwordResetConfirmRequest from '../app/auth/confirm-reset/domain';
import newUserRegistration from '../app/auth/registrations/domain';

// ideas
import personalProjectTemplate from '../app/project-templates/view-n-form/domain/reducer';
import defaultQuestions from '../app/project-templates/start-recruiting-form/domain';
import personalProjectTemplatesList from '../app/project-templates/personal-templates-list/domain';
import commonProjectTemplateList from '../app/project-templates/list/domain';

// recruiting
import recruitingProject from '../app/recruiting/view/domain';
import recruitingProjectsList from '../app/recruiting/list/domain';
import newApplication from '../app/recruiting/view/application-form/domain';
import personalRecruitingList from '../app/recruiting/personal-list/domain';
import applications from '../app/recruiting/project-start/domain';
import recruitingTemplate from '../app/project-templates/start-recruiting-form/edit-template/domain';

// project
import personalProjectsList from '../app/projects/list/domain';
import workspaceProjectInfo from '../app/workspace/domain/reducer';
import workspaceProjectCharter from '../app/workspace/project-charter/domain';
import chatInfo from '../app/workspace/chat/domain';
import kanbanBoard from '../app/workspace/kanban/domain';
import kanbanCard from '../app/workspace/kanban/components/kanban-card/domain';
import plan from '../app/workspace/plan/domain';
import graphTaskTooltip from '../app/workspace/plan/components/graph-task-tooltip/domain';
import newUserJoinToProject from '../app/workspace/join-new-member/domain';
import projectSettings from '../app/workspace/project-settings/domain/reducer';
import workspaceProjectOffice from '../app/workspace/project-office/domain';

// organization
import organizationUser from '../app/organization/user-forms/domain';
import organizationUserList from '../app/organization/user-list/domain';
import organizationCommonInfo from '../app/organization/common-info/domain/reducer';
import statIdeas from '../app/organization/statistics/ideas/domain';
import statProjects from '../app/organization/statistics/projects/domain';
import statRecruitings from '../app/organization/statistics/recruitings/domain';
import userStatIdeas from '../app/organization/user-statistics/ideas/domain';
import userStatRecruitings from '../app/organization/user-statistics/recruitings/domain';
import userStatProjects from '../app/organization/user-statistics/projects/domain';

// other
import app from '../app/globalDomain';
import selfUserProfile from '../app/user/domain';
import dashboard from '../app/dashboard/domain';
import comments from '../components/comments/domain';
import notificationsList from '../app/notifications/domain/reducer';

// public
import publicUserProfile from '../app/user/public-profile/domain';
import publicUserIdeas from '../app/user/public-profile/ideas/domain';
import publicUserProjects from '../app/user/public-profile/projects/domain';
import publicUserRecruiting from '../app/user/public-profile/recruiting/domain';
import publicOrganization from '../app/organization/public-page/domain';
import orgPublicIdeas from '../app/organization/public-page/ideas/domain';
import orgPublicProjects from '../app/organization/public-page/projects/domain';
import orgPublicRecruiting from '../app/organization/public-page/recruiting/domain';

const rootReducer = combineReducers({
  login,
  app,
  personalProjectTemplate,
  recruitingProject,
  defaultQuestions,
  recruitingProjectsList,
  personalProjectTemplatesList,
  commonProjectTemplateList,
  newApplication,
  personalProjectsList,
  passwordResetRequest,
  passwordResetConfirmRequest,
  workspaceProjectInfo,
  chatInfo,
  kanbanBoard,
  kanbanCard,
  organizationUser,
  organizationUserList,
  selfUserProfile,
  dashboard,
  personalRecruitingList,
  applications,
  recruitingTemplate,
  newUserRegistration,
  comments,
  workspaceProjectCharter,
  plan,
  graphTaskTooltip,
  organizationCommonInfo,
  statIdeas,
  statProjects,
  statRecruitings,
  userStatIdeas,
  userStatRecruitings,
  userStatProjects,
  publicUserProfile,
  publicUserIdeas,
  publicUserProjects,
  publicUserRecruiting,
  publicOrganization,
  orgPublicIdeas,
  orgPublicProjects,
  orgPublicRecruiting,
  newUserJoinToProject,
  projectSettings,
  notificationsList,
  workspaceProjectOffice,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
