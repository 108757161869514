import React from 'react';

import CommonProjectView from '../../../../components/CommonProjectView';
import RecruitingActionBlock from './RecruitingActionBlock';
import RecruitingStatusLabel from '../../components/RecruitingStatusLabel';

import { Recruiting } from '../../../../utils/types';
import { localNaming } from '../../../../utils/utils';

interface RecruitingProjectProps {
  recruitingProject: Recruiting;
  organization: any;
}

function RecruitingProjectView({ recruitingProject, organization }: RecruitingProjectProps) {
  const {
    name,
    goal,
    about,
    duration,
    images,
    attachments,
    mentor,
    isMentor,
    endDate,
    status,
    teamSize,
  } = recruitingProject;

  const data = {
    name,
    status,
    goal,
    about,
    duration,
    images,
    attachments,
    user: mentor,
    isOwner: isMentor,
    ownerTitle: 'Вы лидер проекта',
  };

  return (
    <CommonProjectView
      data={data}
      userLabel={!data.isOwner ? 'Лидер проекта' : ''}
      organization={organization}
      organizationLabel="Место выполнения проекта"
      statusComponent={<RecruitingStatusLabel endDate={endDate} status={status} />}
      additionalFields={[
        { label: 'Размер команды', body: `${teamSize} ${localNaming(teamSize, 'people')}` },
      ]}
    >
      <RecruitingActionBlock />
    </CommonProjectView>
  );
}

export default RecruitingProjectView;
