import React from 'react';
import { useHistory } from 'react-router-dom';

import CardBlockWrapper from '../../../dashboard/components/CardBlockWrapper';
import RecruitingCard from '../../../dashboard/screens/recruiting/RecruitingCard';

import Paths from '../../../../utils/route-paths';
import { Recruiting } from '../../../../utils/types';

interface PublicRecruitingsBlockProps {
  userId?: string;
  data: Recruiting[];
  counter?: number;
  onClickMore?: () => void;
}

function PublicRecruitingBlock(props: PublicRecruitingsBlockProps): JSX.Element | null {
  const history = useHistory();
  const { data, userId, onClickMore, counter } = props;

  const handleOnClickMore = () => {
    if (onClickMore) {
      onClickMore();
    } else {
      history.push(`${Paths.PUBLIC_USER_RECRUITING}/${userId}`);
    }
  };

  if (!data?.length) {
    return null;
  }

  return (
    <CardBlockWrapper
      title="Рекрутинг"
      counter={counter || data.length}
      titleLinkOnClick={handleOnClickMore}
      counterEnable
    >
      {data.slice(0, 2).map((item) => (
        <RecruitingCard key={item.id} data={item} isPersonal={false} />
      ))}
    </CardBlockWrapper>
  );
}

export default PublicRecruitingBlock;
