import React from 'react';
import { useHistory } from 'react-router-dom';

import ProjectCard from './ProjectCard';
import CardBlockWrapper from '../../components/CardBlockWrapper';

import Paths from '../../../../utils/route-paths';
import { Project } from '../../../../utils/types';

interface ProjectsBlockProps {
  data: Project[];
  counterEnable?: boolean;
}

function ProjectsBlock(props: ProjectsBlockProps): JSX.Element {
  const history = useHistory();
  const { data, counterEnable = false } = props;

  return (
    <CardBlockWrapper
      counter={data.length}
      title="Мои проекты"
      titleLinkOnClick={() => history.push(Paths.PERSONAL_PROJECTS)}
      counterEnable={counterEnable}
    >
      {data.slice(0, 2).map((item) => (
        <ProjectCard key={item.id} data={item} />
      ))}
    </CardBlockWrapper>
  );
}

export default ProjectsBlock;
