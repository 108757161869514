import axios from 'axios';
import { put, takeEvery, call } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { COMPONENT_STATES } from '../../../utils/componentState';
import { errorHandler } from '../../../utils/fetchUtils';
import Urls from '../../../utils/endpoints';
import { ComponentState, Project, Recruiting, Template, User } from '../../../utils/types';
import { EMPTY_USER } from '../../../utils/constants';
import { getDashboard } from '../../dashboard/domain';

// dal
export const getUser = (userId) => axios.get(Urls.USER_PROFILE(userId));

// saga
function* getPublicUserRequest(action) {
  try {
    const { userId } = action.payload;
    const { data: userData } = yield call(getUser, userId);
    const { data: userDashboard } = yield call(getDashboard, userId);
    yield put(fetchPublicUserSuccess({ userData, userDashboard }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchPublicUserFail());
  }
}

export function* watchProcessingPublicUserProfile() {
  yield takeEvery(fetchPublicUser.type, getPublicUserRequest);
}

export interface PublicUserProfileState {
  componentState: ComponentState;
  user: User;
  dashboard: {
    templates: Template[];
    recruitings: Recruiting[];
    projects: Project[];
  };
  requesting: boolean;
}

// reducer
const initialState: PublicUserProfileState = {
  componentState: COMPONENT_STATES.LOADING,
  user: EMPTY_USER,
  dashboard: {
    templates: [],
    recruitings: [],
    projects: [],
  },
  requesting: false,
};

const publicUserProfileSlice = createSlice({
  name: 'publicUserProfile',
  initialState,
  reducers: {
    fetchPublicUser(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
    },
    fetchPublicUserSuccess(state, action) {
      const { userData, userDashboard } = action.payload;
      state.user = userData;
      state.dashboard = userDashboard;
      state.componentState = COMPONENT_STATES.CONTENT;
    },
    fetchPublicUserFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const {
  fetchPublicUser,
  fetchPublicUserSuccess,
  fetchPublicUserFail,
} = publicUserProfileSlice.actions;

export default publicUserProfileSlice.reducer;
