import React from 'react';
import clsx from 'clsx';

import Urls from '../../utils/endpoints';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

interface StyleProps {
  height: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 1000,
    height: (props: StyleProps) => props.height,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    marginBottom: 34,
    paddingLeft: 30,
  },
  actionBox: {
    height: 200,
  },
  textVariant: {
    width: 450,
    paddingLeft: 80,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  image: {
    height: (props: StyleProps) => props.height,
  },
  button: {
    width: 256,
  },
}));

export interface SlimBoxTipProps {
  title?: string;
  mainText?: string;
  buttonText?: string;
  buttonAction?: () => void;
  imageName?: string;
  mainComponent?: JSX.Element;
  className?: string;
  height?: number;
}

function SlimBoxTip(props: SlimBoxTipProps) {
  const {
    title,
    mainText,
    buttonText,
    buttonAction,
    imageName = 'mentor_hbupfm',
    mainComponent,
    className,
    height = 220,
  } = props;
  const classes = useStyles({ height });

  return (
    <Paper className={clsx(classes.root, className)} elevation={10}>
      <img className={classes.image} src={Urls.ASSET_IMAGE(imageName)} alt="mentor_img" />
      {mainComponent ? (
        <div className={clsx(classes.actionBox)}>{mainComponent}</div>
      ) : (
        <div className={clsx(classes.actionBox, classes.textVariant)}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            {mainText}
          </Typography>
          {!!(buttonText && buttonAction) && (
            <Button
              className={classes.button}
              size="large"
              variant="contained"
              color="secondary"
              onClick={buttonAction}
            >
              {buttonText}
            </Button>
          )}
        </div>
      )}
    </Paper>
  );
}

export default SlimBoxTip;
