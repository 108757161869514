import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Paper from '@material-ui/core/Paper';

interface CommonSmallCard {
  onClick: () => void;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 380,
    height: 200,
    borderRadius: 16,
    padding: '16px 16px',
    marginRight: 24,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}));

function CommonSmallCard(props: PropsWithChildren<CommonSmallCard>) {
  const { onClick, children, className } = props;
  const classes = useStyles();

  return (
    <Paper className={clsx(classes.root, className)} elevation={10} onClick={onClick}>
      {children}
    </Paper>
  );
}

export default CommonSmallCard;
