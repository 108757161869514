import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import MessageView from './MessageView';
import ChatInput from './components/ChatInput';

import { MessageData, sendMessage } from './domain';
import { PALETTE } from '../../../utils/palette';
import { COMPONENT_STATES } from '../../../utils/componentState';
import { create_UUID } from '../../../utils/utils';

const useStyles = makeStyles((theme: Theme) => ({
  chatBox: {
    display: 'flex',
    flexDirection: 'column',
    width: 766,
    height: 'inherit',
    borderRightStyle: 'solid',
    borderWidth: 1,
    borderColor: PALETTE(0.1).gray,
  },
  chatSheet: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    height: 0,
    borderBottomStyle: 'solid',
    borderWidth: 1,
    borderColor: PALETTE(0.1).gray,
    padding: 20,
    overflowY: 'scroll',
    backgroundColor: PALETTE(0.15).gray,
  },
  spinner: {
    zIndex: theme.zIndex.drawer - 1,
    color: '#fff',
  },
}));

// закомментированый код относится к подгузке истории по скроллу

function Chat() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { messages, historyMessages, chatState /* isHistoryEnd, historyLoading */ } = useSelector(
    (state: any) => state.chatInfo,
  );
  const { projectInfoComponentState, projectData } = useSelector(
    (state: any) => state.workspaceProjectInfo,
  );
  const { user } = useSelector((state: any) => state.app);
  const teamWithMentor = projectData?.team.reduce(
    (team, member) => ({ ...team, [member.id]: member }),
    { [projectData?.mentor.id]: projectData?.mentor },
  );
  const isReadyToChat =
    chatState === COMPONENT_STATES.CONTENT &&
    projectInfoComponentState === COMPONENT_STATES.CONTENT;

  const [previousScrollHeight, setPreviousScrollHeight] = useState(0);
  const chatSheetRef: any = useRef();

  useEffect(() => {
    if (previousScrollHeight < chatSheetRef?.current?.scrollHeight) {
      if (chatSheetRef && chatSheetRef.current) {
        chatSheetRef.current.scrollTop = chatSheetRef.current.scrollHeight - previousScrollHeight;
      }
    }
  });
  /*
  const handleOnScrollList = (e) => {
    if (isHistoryEnd) {
      return;
    }
    if (e.target.scrollTop < 150 && !historyLoading) {
      const lastMessage = historyMessages.length ? historyMessages[0] : messages[0];
      const lastTimestamp = lastMessage.timestamp;
      setPreviousScrollHeight(e.target.scrollHeight);
      dispatch(loadMessageHistory({ projectId: projectData.id, timestamp: lastTimestamp - 1 }));
    }
  };
  */

  const onSubmit = (message, attachment) => {
    setPreviousScrollHeight(0);
    dispatch(
      sendMessage({
        message: message.trim(),
        attachment,
        code: create_UUID(),
        author_id: user.id,
        images: attachment?.image ? [{}] : [],
      }),
    );
  };

  return (
    <div className={classes.chatBox}>
      <div
        key={'qwerty'}
        className={classes.chatSheet}
        ref={chatSheetRef}
        /* onScroll={handleOnScrollList} */
      >
        {historyMessages.map((message: MessageData) => (
          <MessageView
            key={message.code}
            messageData={message}
            teamData={teamWithMentor}
            isMentor={projectData?.isMentor}
          />
        ))}
        {messages.map((message: MessageData) => (
          <MessageView
            key={message.code}
            messageData={message}
            teamData={teamWithMentor}
            isMentor={projectData?.isMentor}
          />
        ))}
      </div>
      <ChatInput onSubmit={onSubmit} disabled={!isReadyToChat} />
      <Backdrop className={classes.spinner} open={!isReadyToChat}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Chat;
