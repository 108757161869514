import { call, put, takeEvery } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { COMPONENT_STATES } from '../../../utils/componentState';
import {
  ComponentState,
  OrganizationFull,
  Project,
  Recruiting,
  TablePaginationType,
  Template,
} from '../../../utils/types';
import { errorHandler } from '../../../utils/fetchUtils';
import { getOrganization } from '../common-info/domain/dal';
import { getOrganizationActiveProjects } from '../../projects/list/domain';

import { EMPTY_FULL_ORGANIZATION } from '../../../utils/constants';
import axios from 'axios';
import Urls from '../../../utils/endpoints';

//dal
export const getOrganizationOpenIdeas = (
  orgId: string,
  pagination: TablePaginationType,
  filters = {},
) => {
  const params = { ...pagination, ...filters };
  return axios.get(`${Urls.ORGANIZATION_ORGANIZATION(orgId)}/ideas/open`, { params });
};

export const getOrganizationActiveRecruitings = (
  orgId: string,
  pagination: TablePaginationType,
  filters = {},
) => {
  const params = { ...pagination, ...filters };
  return axios.get(`${Urls.ORGANIZATION_ORGANIZATION(orgId)}/recruitings/active`, { params });
};

// saga
function* requestPublicOrganization(action) {
  try {
    const { orgId } = action.payload;
    const { data } = yield call(getOrganization, orgId);
    const { data: projects } = yield call(getOrganizationActiveProjects, orgId, {
      page: 1,
      pageSize: 2,
    });
    const { data: recruitings } = yield call(getOrganizationActiveRecruitings, orgId, {
      page: 1,
      pageSize: 2,
    });
    const { data: ideas } = yield call(getOrganizationOpenIdeas, orgId, { page: 1, pageSize: 2 });
    yield put(fetchPublicOrganizationSuccess({ data, projects, recruitings, ideas }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchPublicOrganizationFail());
  }
}

export function* watchPublicOrganization() {
  yield takeEvery(fetchPublicOrganization.type, requestPublicOrganization);
}

export interface PublicOrganizationState {
  componentState: ComponentState;
  organization: OrganizationFull;
  projects: Project[];
  projectsTotal: number;
  recruitings: Recruiting[];
  recruitingTotal: number;
  ideas: Template[];
  ideasTotal: number;
}

// reducer
const initialState: PublicOrganizationState = {
  componentState: COMPONENT_STATES.LOADING,
  organization: EMPTY_FULL_ORGANIZATION,
  projects: [],
  projectsTotal: 0,
  recruitings: [],
  recruitingTotal: 0,
  ideas: [],
  ideasTotal: 0,
};

const publicOrganizationSlice = createSlice({
  name: 'publicOrganization',
  initialState,
  reducers: {
    fetchPublicOrganization(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
    },
    fetchPublicOrganizationSuccess(state, action) {
      const { data, projects, recruitings, ideas } = action.payload;
      state.organization = data;
      state.projects = projects.content;
      state.projectsTotal = projects.totalElements;
      state.recruitings = recruitings.content;
      state.recruitingTotal = recruitings.totalElements;
      state.ideas = ideas.content;
      state.ideasTotal = ideas.totalElements;
      state.componentState = COMPONENT_STATES.CONTENT;
    },
    fetchPublicOrganizationFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const {
  fetchPublicOrganization,
  fetchPublicOrganizationSuccess,
  fetchPublicOrganizationFail,
} = publicOrganizationSlice.actions;

export default publicOrganizationSlice.reducer;
