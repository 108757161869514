const ROOTS = {
  notifications: 'v2/notifications',
  auth: 'v2/auth',
  users: 'v2/users',
  projectIdeas: 'v2/project_ideas',
};

const UrlsV2 = {
  ROOTS,

  // auth
  AUTH_LOGOUT: `${ROOTS.auth}/logout`,
  AUTH_ME: `${ROOTS.auth}/me`,

  // notifications
  NOTIFICATIONS_REGISTER: `${ROOTS.notifications}/register`,
  NOTIFICATIONS_LIST: `${ROOTS.notifications}/`,
  NOTIFICATIONS_READ: (id: string): string => `${ROOTS.notifications}/${id}/read`,
  NOTIFICATIONS_READ_ALL: `${ROOTS.notifications}/read_all`,
  NOTIFICATIONS_COUNT: `${ROOTS.notifications}/counts`,
  NOTIFICATION_SETTINGS: `${ROOTS.notifications}/settings`,

  // project templates
  COMMON_PROJECTS_IDEAS: `${ROOTS.projectIdeas}/`,
  PERSONAL_PROJECTS_IDEAS: (profileId: string, ideaId?: string): string => {
    const path = `${ROOTS.users}/${profileId}/project_ideas/`;
    return ideaId ? `${path}${ideaId}/` : path;
  },
  START_PROJECT_FROM_IDEA: (ideaId: string): string =>
    `${ROOTS.projectIdeas}/${ideaId}/start_project/`,
};

export default UrlsV2;
