import React, { useState, PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button, { ButtonProps } from '@material-ui/core/Button';
import SimplePopover, { OuterPopoverProps } from './Popover';
import clsx from 'clsx';

type ButtonLength = 'long' | 'short' | undefined;
type ButtonMargin = 'left' | 'right' | undefined;

interface CustomButtonProps extends ButtonProps {
  withConfirm?: boolean;
  popoverProps?: OuterPopoverProps;
  length?: ButtonLength;
  horizontalMargin?: ButtonMargin;
  className?: string;
}

interface StyleProps {
  length: ButtonLength;
  horizontalMargin: ButtonMargin;
}

const buttonOptions = {
  length: {
    long: 240,
    short: 160,
  },
  margin: {
    left: 34,
    right: 34,
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: ({ length }: StyleProps) => (length ? buttonOptions.length[length] : 'auto'),
    marginRight: ({ horizontalMargin }: StyleProps) =>
      horizontalMargin === 'right' ? 34 : 'unset',
    marginLeft: ({ horizontalMargin }: StyleProps) => (horizontalMargin === 'left' ? 34 : 'unset'),
  },
}));

function CustomButton(props: PropsWithChildren<CustomButtonProps>) {
  const {
    withConfirm = false,
    popoverProps,
    length,
    horizontalMargin,
    onClick,
    children,
    className,
    ...otherButtonProps
  } = props;
  const classes = useStyles({ length, horizontalMargin });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  return withConfirm ? (
    <>
      <Button
        className={clsx(classes.root, className)}
        size="large"
        onClick={handleOpenPopover}
        {...otherButtonProps}
      >
        {children}
      </Button>
      <SimplePopover
        mainHandle={onClick}
        onClose={handleClosePopover}
        anchorEl={anchorEl}
        {...popoverProps}
      />
    </>
  ) : (
    <>
      <Button className={classes.root} size="large" onClick={onClick} {...otherButtonProps}>
        {children}
      </Button>
    </>
  );
}

export default CustomButton;
