import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DoneIcon from '@material-ui/icons/Done';

import PlanDiagram from './components/PlanDiagram';
import PlanList from './components/PlanList';
import ViewSelectState from '../../../components/select-state/ViewSelectState';
import WorkspacePlanSkeleton from '../../../components/Skeletons/WorkspacePlan';
import SlimBoxTip from '../../../components/tips/SlimBoxTip';

import { fetchTaskTree } from './domain';
import { COMPONENT_STATES } from '../../../utils/componentState';
import { TIPS, PLAN_TASK_STATUSES_COLORS, PLAN_TASK_STATUSES } from '../../../utils/constants';
import { PALETTE } from '../../../utils/palette';
import { setTourPage } from '../../globalDomain';
import { TOUR_PAGES, TOURS_CLASS_NAMES } from '../../../components/tour/tourSteps';

interface Plan {
  className?: string;
}

type ViewMode = 'graph' | 'list';
type PlanViewVariants = {
  [key in ViewMode]: {
    title: string;
    buttonName: string;
  };
};
const planViewVariants: PlanViewVariants = {
  graph: { title: 'Диаграмма работ', buttonName: 'Показать список' },
  list: { title: 'Список работ', buttonName: 'Показать диаграмму' },
};

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  header: {
    display: 'flex',
    maxWidth: 600,
    justifyContent: 'space-between',
    alineItems: 'center',
    paddingTop: 10,
    paddingBottom: 20,
  },
  tipBox: {
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tipList: {
    width: 500,
  },
  tipGraph: {
    width: 500,
    paddingLeft: 80,
  },
  colorBox: {
    width: 34,
    height: 20,
    borderRadius: 2,
    marginRight: 10,
  },
}));

function Plan({ className }: Plan) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { projectData, isProjectMember } = useSelector((state: any) => state.workspaceProjectInfo);
  const { planComponentState, hasPlan } = useSelector((state: any) => state.plan);
  const [viewMode, setViewMode] = useState<ViewMode>(isProjectMember ? 'list' : 'graph');

  useEffect(() => {
    dispatch(fetchTaskTree({ projectId: projectData.id }));
    if (isProjectMember) {
      dispatch(setTourPage({ pages: [TOUR_PAGES.plan] }));
    }
    return function cleanUp() {
      dispatch(setTourPage({ pages: [] }));
    };
  }, [dispatch, projectData.id, isProjectMember]);

  const toggleView = () => {
    const nextView = { graph: 'list', list: 'graph' } as { [key in ViewMode]: ViewMode };
    setViewMode((prevState) => nextView[prevState]);
  };

  const content = () => (
    <>
      {viewMode === 'list' && <PlanList />}
      {viewMode === 'graph' && <PlanDiagram />}
    </>
  );

  const Tip = () => (
    <div className={clsx(classes.tipBox)}>
      {viewMode === 'list' ? (
        <List className={classes.tipList}>
          {TIPS.projectPlanList.map((text, i) => (
            <ListItem key={i} alignItems="center" dense>
              <ListItemIcon style={{ paddingLeft: 20 }}>
                <DoneIcon fontSize="small" style={{ color: PALETTE().gray }} />
              </ListItemIcon>
              <ListItemText
                primary={text}
                primaryTypographyProps={{ variant: 'subtitle2', color: 'textSecondary' }}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <List className={classes.tipGraph}>
          {Object.keys(PLAN_TASK_STATUSES).map((status, i) => (
            <ListItem key={i} alignItems="center" dense>
              <div
                className={classes.colorBox}
                style={{ backgroundColor: PLAN_TASK_STATUSES_COLORS[status] }}
              />
              <ListItemText
                primary={`- ${PLAN_TASK_STATUSES[status]}`}
                primaryTypographyProps={{ variant: 'body1', color: 'textSecondary' }}
              />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );

  return (
    <div className={clsx(classes.root, className)}>
      {hasPlan || isProjectMember ? (
        <div>
          <SlimBoxTip mainComponent={<Tip />} />
          <div className={classes.header}>
            <Typography variant="h6" color="textSecondary">
              {planViewVariants[viewMode].title}
            </Typography>
            {isProjectMember && (
              <Button
                className={TOURS_CLASS_NAMES.plan.visibilityToggle}
                variant="outlined"
                color="primary"
                size="large"
                onClick={toggleView}
                disabled={planComponentState !== COMPONENT_STATES.CONTENT}
              >
                {planViewVariants[viewMode].buttonName}
              </Button>
            )}
          </div>
          <ViewSelectState
            state={planComponentState}
            content={content}
            skeleton={<WorkspacePlanSkeleton />}
          />
        </div>
      ) : (
        <SlimBoxTip mainText="План работ еще не сформирован" />
      )}
    </div>
  );
}

export default Plan;
