import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import StandardDialog from '../../components/StandardDialog';

import Urls from '../../utils/endpoints';
import { EVENTS_STORAGE } from '../../utils/constants';
import { setIEventToStorage } from '../../utils/utils';
import { setPermissionForNotification } from '../globalDomain';

interface AskForNotificationTipDialog {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  dialog: {
    width: 1030,
    height: 320,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    paddingLeft: 8,
    paddingTop: 0,
  },
  image: {
    height: 310,
  },
  button: {
    width: 128,
    marginRight: 24,
    '&:last-child': {
      marginRight: 0,
    },
  },
  buttons: {
    display: 'flex',
  },
  tipBox: {
    width: 500,
    height: 290,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tipTitle: {
    fontWeight: 600,
    paddingBottom: 24,
  },
  tipDescription: {
    paddingLeft: 12,
    paddingBottom: 24,
  },
}));

function AskForNotificationTipDialog({ open, onClose }: AskForNotificationTipDialog): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleAcceptNotifications = () => {
    dispatch(setPermissionForNotification());
    onClose();
  };

  const handleDeclineNotifications = () => {
    setIEventToStorage(EVENTS_STORAGE.events.askForNotification);
    onClose();
  };

  return (
    <StandardDialog className={classes.dialog} open={open} onClose={onClose}>
      <img className={classes.image} src={Urls.ASSET_IMAGE('mentor_hbupfm')} alt="mentor_img" />
      <div className={classes.tipBox}>
        <Typography className={classes.tipTitle} align="center">
          Хотите получать push-уведомления от платформы EDUTEAM?
        </Typography>
        <Typography className={classes.tipDescription}>
          Мы будем уведомлять вас о новых идеях, рекрутингах, сообщениях и передвижении карточек в
          проекте. Вы всегда можете изменить настройки уведомлений в настройках своего профиля.
        </Typography>
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            size="large"
            variant="contained"
            color="secondary"
            onClick={handleAcceptNotifications}
          >
            Да!
          </Button>
          <Button
            className={classes.button}
            size="large"
            variant="outlined"
            color="secondary"
            onClick={handleDeclineNotifications}
          >
            Отказаться
          </Button>
          <Button
            className={classes.button}
            size="large"
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            Подумаю...
          </Button>
        </div>
      </div>
    </StandardDialog>
  );
}

export default AskForNotificationTipDialog;
