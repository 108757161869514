import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import ColoredAvatar from './ColoredAvatar';

import { User } from '../utils/types';

interface UserInfoBlockProps {
  user?: User | null;
  className?: string;
  showTitle?: boolean;
  title?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  textBlock: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
  },
  title: {
    color: theme.palette.success.main,
    fontWeight: 500,
  },
}));

function UserInfoBlock(props: UserInfoBlockProps) {
  const classes = useStyles();
  const { user, className, showTitle = false, title } = props;

  return (
    <div className={clsx(classes.root, !!className && className)}>
      {showTitle ? (
        <Typography className={classes.title}>{title?.toUpperCase()}</Typography>
      ) : (
        <>
          <ColoredAvatar user={user} />
          <div className={classes.textBlock}>
            <Typography variant="subtitle2">
              {user?.firstName} {user?.lastName}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {user?.organization?.shortName.toUpperCase()}
            </Typography>
          </div>
        </>
      )}
    </div>
  );
}

export default UserInfoBlock;
