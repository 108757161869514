import React, { useState, PropsWithChildren } from 'react';

import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import SimplePopover, { OuterPopoverProps } from './Popover';

interface CustomIconButtonProps extends IconButtonProps {
  withConfirm?: boolean;
  popoverProps?: OuterPopoverProps;
}

function CustomIconButton(props: PropsWithChildren<CustomIconButtonProps>) {
  const { withConfirm = false, popoverProps, onClick, children, ...otherButtonProps } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  return withConfirm ? (
    <>
      <IconButton onClick={handleOpenPopover} {...otherButtonProps}>
        {children}
      </IconButton>
      <SimplePopover
        mainHandle={onClick}
        onClose={handleClosePopover}
        anchorEl={anchorEl}
        {...popoverProps}
      />
    </>
  ) : (
    <>
      <IconButton onClick={onClick} {...otherButtonProps}>
        {children}
      </IconButton>
    </>
  );
}

export default CustomIconButton;
