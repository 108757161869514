import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { COMPONENT_STATES } from '../../../../utils/componentState';

export interface FetchAcceptAppActionPayload {
  projectId: string;
  appId: string;
  userId: string;
}

export interface FetchRejectAppActionPayload extends FetchAcceptAppActionPayload {
  rejectMessage: string;
}

const initialState = {
  requesting: false,
  menuItem: 0,
  appComponentState: COMPONENT_STATES.CONTENT,
};

const projectSettingsSlice = createSlice({
  name: 'projectSettings',
  initialState,
  reducers: {
    fetchAcceptApp(state, action: PayloadAction<FetchAcceptAppActionPayload>) {
      state.requesting = true;
    },
    fetchRejectApp(state, action: PayloadAction<FetchAcceptAppActionPayload>) {
      state.requesting = true;
    },
    finishProcessApp(state) {
      state.requesting = false;
    },
    setProjectSettingMenuItem(state, action) {
      state.menuItem = action.payload;
    },
    fetchApplicationForProject(state, action) {
      state.appComponentState = COMPONENT_STATES.LOADING;
    },
    fetchApplicationForProjectSuccess(state) {
      state.appComponentState = COMPONENT_STATES.CONTENT;
    },
    fetchApplicationForProjectFail(state) {
      state.appComponentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const {
  fetchAcceptApp,
  fetchRejectApp,
  finishProcessApp,
  setProjectSettingMenuItem,
  fetchApplicationForProject,
  fetchApplicationForProjectSuccess,
  fetchApplicationForProjectFail,
} = projectSettingsSlice.actions;

export default projectSettingsSlice.reducer;
