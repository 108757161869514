import React, { PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

interface WrapperProps {
  header: string;
  dontShowGoBack?: boolean;
  goBack?: () => void;
}

interface StyleProps {
  dontShowGoBack: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100vh',
    padding: 32,
  },
  content: {
    width: '100%',
    marginTop: 42,
    height: '80vh',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: (props: StyleProps) => (props.dontShowGoBack ? 16 : 0),
  },
  icon: {
    marginBottom: 0,
  },
}));

function PageWrapper(props: PropsWithChildren<WrapperProps>): JSX.Element {
  const { children, header, dontShowGoBack = false, goBack } = props;
  const classes = useStyles({ dontShowGoBack });
  const history = useHistory();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      history.goBack();
    }
  };

  return (
    <div className={classes.root}>
      {header && (
        <div className={classes.header}>
          {!dontShowGoBack && (
            <IconButton className={classes.icon} onClick={handleGoBack}>
              <ArrowBackIosRoundedIcon fontSize="large" color="primary" />
            </IconButton>
          )}
          <Typography variant="h1" color="textSecondary">
            {header}
          </Typography>
        </div>
      )}
      <div className={classes.content}>{children}</div>
    </div>
  );
}

export default PageWrapper;
