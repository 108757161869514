import * as Yup from 'yup';

const required: string = 'Обязательное поле';

const UserSchema = Yup.object().shape({
  type: Yup.string()
    .required(required)
    .nullable(),
  lastName: Yup.string()
    .max(255, 'Фамилия не может быть длиннее 255 символов')
    .required(required),
  firstName: Yup.string()
    .max(255, 'Имя не может быть длиннее 255 символов')
    .required(required),
  middleName: Yup.string().max(255, 'Отчество не может быть длиннее 255 символов'),
});

export default UserSchema;
