import axios from 'axios';
import { put, takeEvery, takeLatest, all, call } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { COMPONENT_STATES } from '../../../utils/componentState';
import { errorHandler } from '../../../utils/fetchUtils';
import Urls from '../../../utils/endpoints';
import { removeAvatar } from '../../user/domain';

interface User {
  type: string;
  email: string;
  lastName: string;
  firstName: string;
  middleName?: string;
}

const initialUser: User = {
  type: '',
  email: '',
  lastName: '',
  firstName: '',
  middleName: '',
};

// dal
export const getUser = (orgId, userId) => axios.get(Urls.ORGANIZATION_USER(orgId, userId));
export const addUser = (orgId, payload) => axios.post(Urls.ORGANIZATION_USER(orgId), payload);
export const updateUser = (orgId, userId, payload) =>
  axios.put(Urls.ORGANIZATION_USER(orgId, userId), payload);
export const removeUser = (orgId, userId) => axios.delete(Urls.ORGANIZATION_USER(orgId, userId));

// saga
function* getUserRequest(action) {
  try {
    const { orgId, userId } = action.payload;
    let values = initialUser;
    if (userId) {
      const { data } = yield call(getUser, orgId, userId);
      values = data;
    }
    yield put(fetchUserSuccess(values));
  } catch (e) {
    errorHandler(e);
    yield put(fetchUserFail());
  }
}

function* addUserRequest(action) {
  try {
    const { orgId, values, history } = action.payload;
    yield call(addUser, orgId, values);
    yield put(finishUserProcessing());
    toast.success('Пользователь добавлен');
    history.goBack();
  } catch (e) {
    errorHandler(e);
    yield put(finishUserProcessing());
  }
}

function* updateUserRequest(action) {
  try {
    const { orgId, userId, values } = action.payload;
    yield call(updateUser, orgId, userId, values);
    toast.success('Изменения сохранены');
    yield put(finishUserProcessing());
  } catch (e) {
    errorHandler(e);
    yield put(finishUserProcessing());
  }
}

function* removeAvatarRequest(action) {
  try {
    const { userId } = action.payload;
    yield call(removeAvatar, userId);
    toast.success('Аватар успешно удален');
    yield put(removingAvatarSuccess());
  } catch (e) {
    errorHandler(e);
    yield put(finishUserProcessing());
  }
}

function* removeUserRequest(action) {
  try {
    const { orgId, userId, history } = action.payload;
    yield call(removeUser, orgId, userId);
    toast.success('Пользователь исключен');
    yield put(finishUserProcessing());
    history.goBack();
  } catch (e) {
    errorHandler(e);
    yield put(finishUserProcessing());
  }
}

export function* watchProcessingOrganizationUser() {
  yield all([
    yield takeEvery(fetchUser.type, getUserRequest),
    yield takeLatest(startAddingUser.type, addUserRequest),
    yield takeLatest(startUpdatingUser.type, updateUserRequest),
    yield takeLatest(startRemovingUser.type, removeUserRequest),
    yield takeLatest(startRemovingAvatar.type, removeAvatarRequest),
  ]);
}

// reducer
const initialState = {
  componentState: COMPONENT_STATES.LOADING,
  user: null,
  requesting: false,
  avatarUploading: false,
};

const organizationUserSlice = createSlice({
  name: 'organizationUser',
  initialState,
  reducers: {
    fetchUser(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
    },
    fetchUserSuccess(state, action) {
      state.componentState = COMPONENT_STATES.CONTENT;
      state.user = action.payload;
    },
    fetchUserFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
    startAddingUser(state, action) {
      state.requesting = true;
    },
    startUpdatingUser(state, action) {
      state.requesting = true;
    },
    startRemovingUser(state, action) {
      state.requesting = true;
    },
    startRemovingAvatar(state, action) {
      state.avatarUploading = true;
    },
    removingAvatarSuccess(state) {
      state.avatarUploading = false;
      if (state.user) {
        // @ts-ignore
        state.user.avatar = null;
      }
    },
    finishUserProcessing(state) {
      state.requesting = false;
      state.avatarUploading = false;
    },
  },
});

export const {
  fetchUser,
  fetchUserSuccess,
  fetchUserFail,
  startAddingUser,
  startUpdatingUser,
  startRemovingUser,
  finishUserProcessing,
  startRemovingAvatar,
  removingAvatarSuccess,
} = organizationUserSlice.actions;

export default organizationUserSlice.reducer;
