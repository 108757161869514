import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';
import { Task } from '../PlanList';
import Dates from '../../../../../utils/dates';
import { PALETTE } from '../../../../../utils/palette';

interface TaskItemProps {
  task: Task;
  onTaskClicked: (cardId: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 600,
  },
  name: {
    width: 450,
  },
  openButton: {
    alignSelf: 'end',
  },
}));

function TaskItemView(props: TaskItemProps) {
  const classes = useStyles();
  const { task, onTaskClicked } = props;

  const handleOpenCard = () => {
    onTaskClicked(task.cardId);
  };

  return (
    <div className={classes.root} onClick={handleOpenCard}>
      <Typography className={classes.name} noWrap>
        {task.name}
      </Typography>
      {task.taskEndDate && (
        <Typography align={'right'} variant={'subtitle2'} color="textSecondary">
          до {Dates.dateStringToLocalString(task.taskEndDate)}
        </Typography>
      )}
      <Tooltip style={{ color: PALETTE().gray }} title="Открыть задачу" arrow placement="top">
        <IconButton className={classes.openButton} onClick={handleOpenCard}>
          <LaunchIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export default TaskItemView;
