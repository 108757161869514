import { makeStyles, Theme } from '@material-ui/core/styles';
import { PALETTE } from '../../../../../utils/palette';

export const useCardStyles = makeStyles((theme: Theme) => ({
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 18,
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  dateAndExecutorBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  executorWrapper: {
    width: 392,
  },
  executorField: {
    width: '100%',
    display: 'flex',
  },
  executorItem: {
    display: 'flex',
    alignItems: 'center',
  },
  executorAvatar: {
    width: 30,
    height: 30,
  },
  executorSelectBox: {
    width: '100%',
    height: 14,
    paddingTop: 6,
    paddingBottom: 20,
  },
  executorClearIcon: {
    marginTop: 4,
    marginLeft: 2,
  },
  dateBox: {
    display: 'flex',
    width: 158,
  },
  dateClearIcon: {
    marginTop: 4,
    marginLeft: 2,
  },
  description: {
    padding: 10,
    border: '1px solid',
    borderColor: PALETTE(0.1).gray,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    paddingLeft: 36,
    paddingRight: 36,
    '& button': {
      marginBottom: 22,
      width: 160,
    },
  },
  closeIcon: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  divider: {
    marginTop: 20,
  },
  comments: {
    marginTop: 20,
  },
  attachmentsBox: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  attachmentItem: {
    maxWidth: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    marginRight: 8,
    marginBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 8,
    paddingTop: 6,
    borderRadius: 16,
    backgroundColor: PALETTE(0.05).gray,
  },
  addAttachmentBtn: {
    marginTop: 16,
  },
  fileIcon: {
    height: 40,
    marginRight: 4,
  },
}));
