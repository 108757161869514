import React, { useEffect, useState } from 'react';
import SortableTree, { changeNodeAtPath, getNodeAtPath } from 'react-sortable-tree';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TaskItemForm from './task-iteam/TaskItemForm';
import FirstTaskInput from './task-iteam/FirstTaskInput';

import { PALETTE } from '../../../../utils/palette';
import {
  fetchAddTask,
  fetchTaskTree,
  fetchUpdateTask,
  removeTasks,
  setIsExpandedNode,
} from '../domain';
import { PlanTaskStatuses } from '../../../../utils/constants';
import { getNodeKey } from '../utils';

// This only needs to be imported once in your app
import 'react-sortable-tree/style.css';
import TaskItemView from './task-iteam/TaskItemView';
import Card from '../../kanban/components/kanban-card/Card';
import {
  fetchExistedKanbanCard,
  fetchUploadCard,
  setKanbanCardOpen,
} from '../../kanban/components/kanban-card/domain';
import { fetchRemoveCard } from '../../kanban/domain';

const getNodePosition = (tree, path: number[], treeIndex, maxPosition) => {
  const prevNodePath = [...path];
  const nextNodePath = [...path];
  prevNodePath.splice(path.length - 1, 1, treeIndex - 1);
  nextNodePath.splice(path.length - 1, 1, treeIndex + 1);

  const prevNode = getNodeAtPath({ treeData: tree, path: prevNodePath, getNodeKey });
  const nextNode = getNodeAtPath({ treeData: tree, path: nextNodePath, getNodeKey });

  switch (true) {
    case !!prevNode && !!nextNode:
      // нода в серединке
      return (
        (parseInt(prevNode.node.task.position, 10) + parseInt(nextNode.node.task.position, 10)) / 2
      );

    case !!prevNode:
      // последняя нода
      return parseInt(prevNode.node.task.position, 10) * 2;

    case !!nextNode:
      // первая нода
      return parseInt(nextNode.node?.task?.position, 10) / 2;
    default:
      // единственная нода или последняя нода выше уровнем
      return maxPosition * 2;
  }
};

export interface Task {
  id: string;
  name: string;
  daysToComplete: number;
  cardId: string;
  projectId: string;
  status: PlanTaskStatuses;
  position: number;
  taskEndDate: string;
}

export interface TreeItem {
  task: Task;
  expanded?: boolean;
  children?: TreeItem[];
}

export const X_OFFSET = 20;
export const GRAPH_SHEET_WIDTH = 1200;
export const Y_STEP = 30;

const useStyles = makeStyles(() => ({
  addButton: {
    marginLeft: 44,
    marginTop: 10,
    color: PALETTE().gray,
    '& button': {
      textTransform: 'none',
      fontWeight: 400,
    },
  },
}));

function PlanList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { taskTree: backEndTree, rootNode, tasksForRemoving, request, maxPosition } = useSelector(
    (state: any) => state.plan,
  );
  const [taskTree, setTaskTree] = useState<TreeItem[]>([]);
  const [addingTask, setAddingTask] = useState(false);
  const { projectData } = useSelector((state: any) => state.workspaceProjectInfo);

  useEffect(() => {
    setTaskTree(backEndTree);
  }, [backEndTree]);

  const startAddingTask = () => {
    setAddingTask(true);
  };

  const addTask = (values) => {
    setAddingTask(false);
    if (values.name) {
      dispatch(
        fetchAddTask({
          values,
          parentId: rootNode ? rootNode.id : null,
          projectId: projectData.id,
          position: maxPosition * 2,
        }),
      );
    }
  };

  const finishTaskChanging = (treeData) => {
    setTaskTree(treeData);
  };

  const handleChangeNode = (parentNode, values) => {
    const parentNodeId = parentNode ? parentNode.task.id : rootNode.id;

    // оставляем открытой ветку с измененной нодой
    dispatch(setIsExpandedNode({ nodeId: parentNode, isExpanded: true }));

    dispatch(
      fetchUpdateTask({
        values,
        parentId: parentNodeId,
        projectId: projectData.id,
      }),
    );
  };

  const handleMoveNode = (parentNode, values, treeIndex, tree, path) => {
    const currentPosition = getNodePosition(tree, path, treeIndex, maxPosition);
    const nodeId = parentNode ? parentNode.task.id : rootNode.id;

    // оставляем открытой ветку с перемещенной нодой
    dispatch(setIsExpandedNode({ nodeId, isExpanded: true }));

    dispatch(
      fetchUpdateTask({
        values,
        parentId: nodeId,
        projectId: projectData.id,
        position: currentPosition,
      }),
    );
  };

  const handleRemoveTasks = () => {
    dispatch(removeTasks({ tasksForRemoving, projectId: projectData.id }));
  };

  const handleTaskClicked = (cardId) => {
    dispatch(
      fetchExistedKanbanCard({
        projectId: projectData.id,
        cardId,
      }),
    );
  };

  const nodePropsGenerator = ({ node, path, parentNode, treeIndex }) => {
    if (node.task.cardId != null) {
      return {
        title: <TaskItemView task={node.task} onTaskClicked={handleTaskClicked} />,
        style: { height: 55 },
      };
    }
    return {
      title: (
        <TaskItemForm
          task={node.task}
          onChange={(values) => {
            handleChangeNode(parentNode, values);
            setTaskTree((prevState) =>
              changeNodeAtPath({
                treeData: prevState,
                path,
                getNodeKey,
                newNode: { ...node, task: values },
              }),
            );
          }}
        />
      ),
      style: { height: 55 },
    };
  };

  const handleCloseCardModal = () => {
    dispatch(setKanbanCardOpen(false));
    dispatch(fetchTaskTree({ projectId: projectData.id }));
  };

  const handleSaveCard = (card) => {
    dispatch(fetchUploadCard({ projectId: projectData.id, card }));
  };

  const handleDeleteCard = (cardId) => {
    dispatch(fetchRemoveCard({ projectId: projectData.id, cardId }));
    dispatch(fetchTaskTree({ projectId: projectData.id }));
  };

  return (
    <div>
      <SortableTree
        rowHeight={65}
        treeData={taskTree}
        onChange={finishTaskChanging}
        onMoveNode={(data) => {
          const { nextParentNode, node, nextTreeIndex, nextPath, treeData } = data;
          handleMoveNode(nextParentNode, node.task, nextTreeIndex, treeData, nextPath);
        }}
        onVisibilityToggle={({ expanded, node }) => {
          dispatch(setIsExpandedNode({ nodeId: node.task.id, isExpanded: expanded }));
        }}
        generateNodeProps={nodePropsGenerator}
        isVirtualized={false}
      />
      <div className={classes.addButton}>
        {addingTask ? (
          <FirstTaskInput onChange={addTask} onCancelChange={() => setAddingTask(false)} />
        ) : (
          <div>
            <Button
              color="inherit"
              startIcon={<AddIcon />}
              onClick={startAddingTask}
              disabled={request}
            >
              Добавить работу
            </Button>
            {!!tasksForRemoving.length && (
              <Button
                color="inherit"
                startIcon={<DeleteForeverIcon />}
                onClick={handleRemoveTasks}
                disabled={request}
              >
                Удалить {tasksForRemoving.length > 1 ? 'работы' : 'работу'}
              </Button>
            )}
          </div>
        )}
      </div>
      <Card
        onClose={handleCloseCardModal}
        onSave={handleSaveCard}
        onDelete={handleDeleteCard}
        canDelete
      />
    </div>
  );
}

export default PlanList;
