import React, { useState } from 'react';
import { Form, Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ImageGallery from 'react-image-gallery';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ClearIcon from '@material-ui/icons/Clear';
import CircularProgress from '@material-ui/core/CircularProgress';

import FileUploader from '../../../../components/files-uploader/FileLoader';
import AttachmentsView from '../../../../components/AttachmentsView';
import InfoBlock from '../../../../components/InfoBlock';
import PageWrapper from '../../../../components/Wrapper';
import ImageLoader from '../../../../components/ImageLoader';
import RichTextEditor from '../../../../components/RichTextEditor';
import TextFieldWithCounter from '../../../../components/TextFieldWithCounter';
import StartRecruitingForm from '../index';

import { startUploadingRecruitingTemplateImage, startUploadRecruitingTemplate } from './domain';
import TemplateSchema from './validationSchema';
import { IMAGE_UPLOAD_PRESETS } from '../../../../utils/constants';
import { PALETTE } from '../../../../utils/palette';
import { makeGalleryImages } from '../../../../utils/utils';

import 'react-image-gallery/styles/css/image-gallery.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 1016,
    height: 634,
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 356,
  },
  form: {
    width: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& div:first-child': {
      marginTop: 0,
    },
  },
  gallery: {
    height: '55%',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& button': {
      marginBottom: 16,
      width: 256,
    },
    marginTop: 32,
  },
  imageContainer: {
    position: 'relative',
    width: 356,
  },
  image: {
    width: 356,
  },
  photoButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    position: 'absolute',
    bottom: -28,
    right: 146,
    borderRadius: '50%',
    zIndex: 2,
  },
  iconButton: {
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
    padding: 7,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  imageLoadingProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 88,
    left: 146,
    zIndex: 1,
  },
  deleteButton: {
    right: 0,
    top: 0,
    padding: 20,
  },
  about: {
    padding: 10,
    border: '1px solid',
    borderColor: PALETTE(0.1).gray,
  },
  blockType: {
    width: 180,
  },
  addAttachmentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '95%',
    marginTop: 8,
  },
  addAttachmentBtn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 256,
    marginTop: 16,
  },
  attachmentsLabel: {
    marginTop: 16,
  },
  attachmentsBox: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  attachmentItem: {
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 16,
    backgroundColor: PALETTE(0.05).gray,
  },
}));

function RecruitingTemplateForm(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [openImageUpload, setOpenImageUpload] = useState(false);
  const { requesting, projectTemplateData } = useSelector(
    (state: any) => state.personalProjectTemplate,
  );
  const { recruitingTemplateData } = useSelector((state: any) => state.recruitingTemplate);

  const handleOpenUpload = () => {
    setOpenImageUpload(true);
  };

  const handleCancelUploadImage = () => {
    setOpenImageUpload(false);
  };

  const handleUploadImage = (croppedImage, setFieldValue, images, imageName) => {
    dispatch(
      startUploadingRecruitingTemplateImage({
        image: croppedImage,
        setFieldValue,
        images,
        imageName,
      }),
    );
  };

  const submitForm = (_values) => {
    const values = { ..._values };
    dispatch(
      startUploadRecruitingTemplate({
        values,
        history,
        handleOpenDialog,
      }),
    );
  };

  const handleCancelEdit = () => {
    history.goBack();
  };

  const handleImageDelete = (setFieldValue, values, galleryRef) => {
    const images = [...values.images];
    const currentImageIndex = galleryRef.getCurrentIndex();
    images.splice(currentImageIndex, 1);
    setFieldValue('images', images, false);
  };

  const handleRemoveAttachment = (setFieldValue, field) => (ids, index) => {
    const newValue = [...field.value];
    newValue.splice(index, 1);
    setFieldValue(field.name, newValue);
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => setOpenDialog(false);
  const handleOpenDialog = () => setOpenDialog(true);

  // что бы при обновлении страницы не было ошибки
  if (!projectTemplateData.name) {
    history.push('/');
  }

  const [isFileUploaderOpened, setIsFileUploaderOpened] = useState(false);

  return (
    <PageWrapper header={'Подготовка рекрутинга'}>
      <Formik
        validationSchema={TemplateSchema}
        initialValues={projectTemplateData}
        validateOnBlur
        onSubmit={submitForm}
        enableReinitialize={true}
      >
        {({ values, handleChange, isValid, errors, setFieldValue, dirty }) => {
          const hasImages = !!values.images.length;
          return (
            <Form className={classes.root}>
              <div className={classes.form}>
                <TextFieldWithCounter
                  name="name"
                  label="Название"
                  value={values.name}
                  onChange={handleChange}
                  error={errors.name}
                />
                <TextFieldWithCounter
                  name="goal"
                  label="Цель"
                  error={errors.goal}
                  value={values.goal}
                  onChange={handleChange}
                />
                <TextFieldWithCounter
                  name="duration"
                  label="Продолжительность (в неделях)"
                  error={errors.duration}
                  value={values.duration}
                  onChange={handleChange}
                  hasCounter={false}
                  multiline={false}
                />
                <RichTextEditor
                  label="Описание"
                  name="about"
                  value={values.about}
                  onChange={handleChange}
                  error={errors.about}
                />
              </div>

              {/* Правая часть */}
              <div className={classes.contentRight}>
                <div className={classes.imageContainer}>
                  <Field name="images">
                    {({ field }) => {
                      let galleryRef;
                      return (
                        <>
                          <ImageGallery
                            ref={(el) => (galleryRef = el)}
                            items={makeGalleryImages(field.value)}
                            showNav={false}
                            showPlayButton={false}
                            showThumbnails={hasImages}
                            showFullscreenButton={hasImages}
                            renderCustomControls={() =>
                              hasImages ? (
                                <button
                                  type="button"
                                  className={clsx(classes.deleteButton, 'image-gallery-icon')}
                                  onClick={() =>
                                    handleImageDelete(setFieldValue, values, galleryRef)
                                  }
                                >
                                  <ClearIcon />
                                </button>
                              ) : null
                            }
                          />
                          <Paper className={classes.photoButtonContainer} elevation={0}>
                            <IconButton
                              className={classes.iconButton}
                              onClick={() => handleOpenUpload()}
                            >
                              <CameraAltIcon fontSize="large" />
                            </IconButton>
                          </Paper>
                          {requesting && (
                            <CircularProgress size={60} className={classes.imageLoadingProgress} />
                          )}
                        </>
                      );
                    }}
                  </Field>
                </div>
                <div className={classes.buttons}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={requesting}
                  >
                    Начать рекрутинг
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    disabled={requesting}
                    onClick={handleCancelEdit}
                  >
                    Отмена
                  </Button>
                </div>
                <div className={classes.addAttachmentContainer}>
                  <Field name="attachments">
                    {({ field }) => (
                      <>
                        <Button
                          className={classes.addAttachmentBtn}
                          variant="outlined"
                          color="primary"
                          size="large"
                          disabled={requesting}
                          onClick={() => setIsFileUploaderOpened(true)}
                        >
                          Добавить документ
                        </Button>
                        <FileUploader
                          open={isFileUploaderOpened}
                          onSave={(data) => setFieldValue(field.name, [...field.value, ...data])}
                          onClose={() => setIsFileUploaderOpened(false)}
                        />
                        {field.value.length > 0 && (
                          <>
                            <InfoBlock
                              label="Документы идеи:"
                              className={classes.attachmentsLabel}
                            />
                            <div className={classes.attachmentsBox}>
                              <AttachmentsView
                                files={field.value}
                                onRemoveFile={handleRemoveAttachment(setFieldValue, field)}
                                className={classes.attachmentItem}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </Field>
                </div>
              </div>
              <ImageLoader
                onSave={(v, imageName) =>
                  handleUploadImage(v, setFieldValue, values.images, imageName)
                }
                onClose={handleCancelUploadImage}
                open={openImageUpload}
                presets={IMAGE_UPLOAD_PRESETS.template}
              />
              <StartRecruitingForm
                open={openDialog}
                onClose={handleCloseDialog}
                templateData={recruitingTemplateData}
                isTemplateEdited={true}
              />
            </Form>
          );
        }}
      </Formik>
    </PageWrapper>
  );
}

export default RecruitingTemplateForm;
