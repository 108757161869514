import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import TextFieldWithCounter from '../../components/TextFieldWithCounter';
import PageWrapper from '../../components/Wrapper';
import ViewSelectState from '../../components/select-state/ViewSelectState';
import ImageLoader from '../../components/ImageLoader';
import MainPaper from '../../components/MainPaper';
import ProfileAvatar from '../../components/ProfileAvatar';
import OrganizationBanner from '../../components/OrganizationBanner';

import UserSchema from './validationSchema';
import { USER_TYPE_TITLES } from '../../utils/constants';
import { makeFullName } from '../../utils/utils';
import { fetchUser, startUpdatingUser, startUpdatingAvatar, startRemovingAvatar } from './domain';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  info: {
    display: 'flex',
  },
  form: {
    paddingTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
  },
  formRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    marginTop: 140,
    width: 352,
    '& button': {
      width: 160,
    },
  },
  avatarContainer: {
    position: 'relative',
  },
  avatar: {
    marginRight: 24,
  },
  inputField: {
    width: 280,
  },
  textInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  typeText: {
    fontWeight: 600,
    fontSize: 18,
    color: '#78909C',
  },
  nameText: {
    fontSize: 36,
    fontWeight: 500,
    lineHeight: 1.2,
  },
  orgText: {
    fontSize: 24,
    fontWeight: 300,
    lineHeight: 2.8,
  },
  emailText: {
    fontSize: 18,
    fontWeight: 500,
    color: '#78909C',
  },
  photoButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    position: 'absolute',
    bottom: -28,
    left: 100,
    borderRadius: '50%',
    zIndex: 2,
  },
  iconButton: {
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
    padding: 7,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  removeIcon: {
    position: 'absolute',
    top: -16,
    right: 8,
  },
  avatarProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  orgBanner: {
    marginTop: 16,
  },
}));

function UserProfile() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user: appUser, avatarUrl } = useSelector((state: any) => state.app);
  const { componentState, user, requesting, avatarUploading } = useSelector(
    (state: any) => state.selfUserProfile,
  );
  const [openImageUpload, setOpenImageUpload] = useState(false);

  useEffect(() => {
    if (appUser) {
      dispatch(fetchUser({ userId: appUser.id }));
    }
  }, [dispatch, appUser]);

  const handleSubmit = (values, { resetForm }) => {
    dispatch(startUpdatingUser({ userId: appUser.id, values, resetForm }));
  };

  const handleUploadImage = () => {
    setOpenImageUpload(true);
  };

  const handleCancelUploadImage = () => {
    setOpenImageUpload(false);
  };

  const handleUploadAvatar = (croppedImage, imageName) => {
    dispatch(startUpdatingAvatar({ userId: appUser.id, image: croppedImage, imageName }));
  };
  const handleRemoveAvatar = () => {
    dispatch(startRemovingAvatar({ userId: appUser.id }));
  };

  const content = () => (
    <MainPaper>
      <div className={classes.root}>
        <div className={classes.info}>
          <ProfileAvatar
            className={classes.avatar}
            avatarUrl={avatarUrl}
            upload={handleUploadImage}
            remove={handleRemoveAvatar}
            avatarUploading={avatarUploading}
            userFirstName={appUser.firstName}
            userLastName={appUser.lastName}
          />
          <div className={classes.textInfoBlock}>
            <Typography className={classes.typeText}>{USER_TYPE_TITLES[user.type]}</Typography>
            <Typography className={classes.nameText}>{makeFullName(user)}</Typography>
            <Typography className={classes.emailText}>{user.email}</Typography>
            {!!user.organization && (
              <OrganizationBanner
                className={classes.orgBanner}
                id={user.organization.id}
                name={user.organization.name}
                avatarUrl={user.organization.avatar}
              />
            )}
          </div>
        </div>
        <Formik
          validationSchema={UserSchema}
          initialValues={user}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, handleChange, isValid, errors, dirty }) => {
            return (
              <div>
                <Form className={classes.form}>
                  <div className={classes.formRow}>
                    <TextFieldWithCounter
                      className={classes.inputField}
                      name="lastName"
                      error={errors.lastName}
                      value={values.lastName}
                      onChange={handleChange}
                      label="Фамилия"
                    />
                    <TextFieldWithCounter
                      className={classes.inputField}
                      name="firstName"
                      error={errors.firstName}
                      value={values.firstName}
                      onChange={handleChange}
                      label="Имя"
                    />
                    <TextFieldWithCounter
                      className={classes.inputField}
                      name="middleName"
                      error={errors.middleName}
                      value={values.middleName || ''}
                      onChange={handleChange}
                      label="Отчество"
                    />
                  </div>
                  <div className={classes.buttons}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={!(isValid && dirty) || requesting}
                    >
                      Сохранить
                    </Button>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
        <ImageLoader
          onSave={handleUploadAvatar}
          onClose={handleCancelUploadImage}
          open={openImageUpload}
        />
      </div>
    </MainPaper>
  );

  return (
    <PageWrapper header={'Профиль'}>
      <ViewSelectState state={componentState} content={content} />
    </PageWrapper>
  );
}

export default UserProfile;
