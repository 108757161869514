import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import MainPaper from '../MainPaper';
import Urls from '../../utils/endpoints';

interface ErrorDataLoadingProps {
  errorData?: any;
  action?: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    objectFit: 'contain',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 400,
  },
  title: {
    fontSize: 36,
    fontWeight: 300,
  },
  text: {
    fontSize: 20,
    textAlign: 'center',
  },
  errorCode: {
    fontSize: 26,
    fontWeight: 500,
  },
  button: {
    marginTop: 20,
  },
  contactText: {
    ...theme.typography.button,
    color: theme.palette.primary.main,
    fontSize: 20,
  },
}));

function ErrorDataLoading(props: ErrorDataLoadingProps) {
  const { errorData, action } = props;
  let statusCode;
  if (errorData) {
    statusCode = errorData.data?.status || errorData.status;
  }
  const classes = useStyles();
  return (
    <MainPaper>
      <div className={classes.root}>
        <div className={classes.imageContainer}>
          <img className={classes.image} src={Urls.ASSET_IMAGE('error_dz8luv')} alt="error_image" />
        </div>
        <div className={classes.textContainer}>
          <p className={classes.title}>Ой, что-то пошло не так!</p>
          <span className={classes.text}>
            Во время загрузки данных произошла ошибка.
            {action ? (
              <div>
                <br /> Попробуйте повторить операцию <br /> или обновите страницу
              </div>
            ) : (
              <div>Попробуйте обновить страницу</div>
            )}
          </span>
          {!!action && (
            <div className={classes.button}>
              <Button color="primary" variant="outlined" onClick={action}>
                Повторить
              </Button>
            </div>
          )}
          {statusCode && <p className={classes.errorCode}>Код ошибки: {`${statusCode}`}</p>}
          <p className={classes.text}>
            Если проблема сохраняется, то <strong>срочно</strong> <br /> обращайтесь в техническую
            поддержку
          </p>
          <span className={classes.contactText}>support@eduteam.ru</span>
        </div>
      </div>
    </MainPaper>
  );
}

export default ErrorDataLoading;
