import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';

import ViewSelectState from '../../components/select-state/ViewSelectState';
import SettingSwitch from './components/SettingSwitch';

import { AppState } from '../globalDomain';
import { NotificationSettings } from '../../utils/types';
import { fetchUpdateNotificationSettings } from './domain';

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
}));

function OrgSettings() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user, componentState, requestUser }: AppState = useSelector((state: any) => state.app);
  const { requesting } = useSelector((state: any) => state.selfUserProfile);

  const [settings, setSettings] = useState<NotificationSettings>({
    receiveEmail: false,
    receivePush: false,
    enableGlobal: false,
    enableOrganization: false,
    enableProject: false,
    enablePersonal: false,
  });

  useEffect(
    () => {
      if (user) {
        setSettings(user.notificationSettings);
      }
    },
    // если апдейт успешный установятся актуальные настройки, если нет предыдущие
    [user, requesting],
  );

  const handleToggleSetting = (settingName: keyof NotificationSettings) => {
    setSettings((prevState) => {
      if (!prevState) {
        return prevState;
      }
      return { ...prevState, [settingName]: !prevState[settingName] };
    });

    if (settingName === 'receivePush' && !user?.notificationSettings.receivePush) {
      if (Notification.permission === 'default') {
        Notification.requestPermission();
      } else if (Notification.permission === 'denied') {
        toast.error(
          'Вы запретили push-уведомления в этом браузере. Снимите запрет что бы их получать.',
        );
      }
    }

    dispatch(
      fetchUpdateNotificationSettings({
        newSettings: { [settingName]: !user?.notificationSettings[settingName] },
      }),
    );
  };

  const content = () => (
    <div className={classes.root}>
      <SettingSwitch
        gridRow={1}
        title="Получать email-уведомления"
        description="Уведомления будут отправляться на электронную почту"
        onChange={() => handleToggleSetting('receiveEmail')}
        checked={settings.receiveEmail}
        disabled={requestUser}
      />
      <SettingSwitch
        gridRow={2}
        title="Получать push-уведомления"
        description="Уведомления будут показываться в браузере и на рабочем столе"
        onChange={() => handleToggleSetting('receivePush')}
        checked={settings.receivePush}
        disabled={requestUser}
      />
      <SettingSwitch
        gridRow={3}
        title="Получать уведомления о событиях на платформе"
        description="Например о рекрутингах для всех пользователей платформы"
        onChange={() => handleToggleSetting('enableGlobal')}
        checked={settings.enableGlobal}
        disabled={requestUser}
      />
      <SettingSwitch
        gridRow={4}
        title="Получать уведомления о событиях в организации"
        description="Например о новых идеях, рекрутингах и проектах для вашей организации"
        onChange={() => handleToggleSetting('enableOrganization')}
        checked={settings.enableOrganization}
        disabled={requestUser}
      />
      <SettingSwitch
        gridRow={5}
        title="Получать уведомления о событиях в ваших проектах"
        description="Это уведомления о новых сообщениях и обновлении доски-задач"
        onChange={() => handleToggleSetting('enableProject')}
        checked={settings.enableProject}
        disabled={requestUser}
      />
      <SettingSwitch
        gridRow={6}
        title="Получать персональные уведомления"
        description="Это уведомления о результатах модерации и заявок на участие в рекрутинге"
        onChange={() => handleToggleSetting('enablePersonal')}
        checked={settings.enablePersonal}
        disabled={requestUser}
      />
    </div>
  );

  return <ViewSelectState state={componentState} content={content} />;
}

export default OrgSettings;
