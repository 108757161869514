import React, { useEffect, useState } from 'react';
import Joyride, { STATUS, EVENTS } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';

import { AppState, setTourStart, setTourStop } from '../../app/globalDomain';
import { AUTO_TOUR_START_DELAY, makeTour } from './tourSteps';
import { isNeedToShowFirstTour } from '../../utils/auth';
import { PALETTE } from '../../utils/palette';

function Tour() {
  const dispatch = useDispatch();
  const { runTour, tourPages, tourScrolling }: AppState = useSelector((state: any) => state.app);
  const [helpers, setHelpers] = useState();
  const [currentTourPageIndex, setCurrentTourPageIndex] = useState(0);
  const currentTourPage = tourPages.length ? tourPages[currentTourPageIndex] : null;
  const tourSteps = makeTour(currentTourPage);

  useEffect(
    () => {
      if (isNeedToShowFirstTour(currentTourPage)) {
        setTimeout(() => {
          dispatch(setTourStart());
        }, AUTO_TOUR_START_DELAY);
      }
      setCurrentTourPageIndex(0);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, tourPages],
  );

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    if (status === STATUS.PAUSED) {
      if (helpers) {
        // @ts-ignore
        helpers.reset();
      }
    }
    const stopTour =
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      [EVENTS.BEACON, EVENTS.ERROR].includes(type);

    if (stopTour && currentTourPage) {
      dispatch(setTourStop(currentTourPage));
      const nextIndex = currentTourPageIndex + 1;
      if (nextIndex < tourPages.length) {
        setCurrentTourPageIndex(nextIndex);
        setTimeout(() => {
          dispatch(setTourStart());
        }, 700);
      } else {
        setCurrentTourPageIndex(0);
      }
    }
  };

  return tourPages.length ? (
    <Joyride
      // не удалять следующую стоку иначе будут проблемы с инъекцией overflow: initial
      disableScrollParentFix
      disableScrolling={!tourScrolling}
      // @ts-ignore
      getHelpers={(StoreHelpers) => setHelpers(StoreHelpers)}
      run={runTour}
      callback={handleJoyrideCallback}
      continuous
      showSkipButton
      // showProgress
      steps={tourSteps}
      styles={{ options: { zIndex: 9999, backgroundColor: PALETTE().white } }}
      locale={{
        back: 'Назад',
        close: 'Закрыть',
        last: 'Закрыть',
        next: 'Далее',
        skip: 'Пропустить',
      }}
    />
  ) : null;
}

export default Tour;
