import { createSlice } from '@reduxjs/toolkit';
import { ComponentState, OrganizationFull, Statistics } from '../../../../utils/types';
import { COMPONENT_STATES } from '../../../../utils/componentState';
import { EMPTY_FULL_ORGANIZATION } from '../../../../utils/constants';

export interface OrganizationCommonInfo {
  componentState: ComponentState;
  organization: OrganizationFull;
  statistics: Statistics;
  hasAccessToStatistics: boolean;
  menuItem: number;
  userStatMenuItem: number;
  moderationEnabled: boolean;
  request: boolean;
  avatarRequest: boolean;
}

// reducer
const initialState: OrganizationCommonInfo = {
  componentState: COMPONENT_STATES.LOADING,
  organization: EMPTY_FULL_ORGANIZATION,
  hasAccessToStatistics: false,
  menuItem: 0,
  userStatMenuItem: 0,
  moderationEnabled: true,
  statistics: {
    usersCount: 0,
    activeProjectsCount: 0,
    ideasCount: 0,
    activeRecruitingsCount: 0,
    waitingRecruitingsCount: 0,
  },
  request: true,
  avatarRequest: false,
};

const organizationCommonInfoSlice = createSlice({
  name: 'organizationCommonInfo',
  initialState,
  reducers: {
    fetchInfo(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
      state.request = true;
    },
    fetchInfoSuccess(state, action) {
      const { organization, statistics } = action.payload;
      state.organization = organization;
      state.hasAccessToStatistics = organization.settings.hasAccessToStatistics;
      if (statistics) {
        state.statistics = statistics;
      }
      state.moderationEnabled = organization.settings.moderationEnabled;
      state.componentState = COMPONENT_STATES.CONTENT;
      state.request = false;
    },
    fetchInfoFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
      state.request = false;
    },
    fetchUpdateOrgInfo(state, action) {
      state.request = true;
    },
    fetchUpdateOrgInfoSuccess(state, action) {
      const {
        data: { siteUrl, description, location },
      } = action.payload;
      state.organization.siteUrl = siteUrl;
      state.organization.description = description;
      state.organization.location = location;
      state.request = false;
    },
    fetchUpdateOrgInfoFail(state) {
      state.request = false;
    },
    fetchChangeAvatar(state, action) {
      state.avatarRequest = true;
    },
    fetchChangeAvatarSuccess(state, action) {
      const { url } = action.payload;
      state.organization.avatar = url;
      state.avatarRequest = false;
    },
    fetchChangeAvatarFail(state) {
      state.avatarRequest = false;
    },
    setStatisticsMenuItem(state, action) {
      state.menuItem = action.payload;
    },
    setUserStatisticsMenuItem(state, action) {
      state.userStatMenuItem = action.payload;
    },
    fetchToggleModeration(state, action) {
      state.request = true;
    },
    fetchToggleModerationSuccess(state, action) {
      const {
        data: { moderationEnabled },
      } = action.payload;
      state.moderationEnabled = moderationEnabled;
      state.request = false;
    },
    fetchToggleModerationFail(state) {
      state.request = false;
    },
  },
});

export const {
  fetchInfo,
  fetchInfoSuccess,
  fetchInfoFail,
  setStatisticsMenuItem,
  setUserStatisticsMenuItem,
  fetchToggleModeration,
  fetchToggleModerationSuccess,
  fetchToggleModerationFail,
  fetchUpdateOrgInfo,
  fetchUpdateOrgInfoSuccess,
  fetchUpdateOrgInfoFail,
  fetchChangeAvatar,
  fetchChangeAvatarSuccess,
  fetchChangeAvatarFail,
} = organizationCommonInfoSlice.actions;

export default organizationCommonInfoSlice.reducer;
