import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { Column } from '../table/CustomTable';

interface TableSkeletonProps {
  rows?: number;
  cells?: number;
  columns?: Column[];
  showColumnNumber?: boolean;
}

const fakeArray = (length = 5) => {
  const arr = new Array(length);
  return arr.fill(0);
};

const aliment = {
  center: 'center',
  right: 'flex-end',
};

export default (props: TableSkeletonProps) => {
  const { cells = 1, rows = 3, columns = {}, showColumnNumber = true } = props;
  return (
    <>
      {fakeArray(rows).map((v, i) => (
        <TableRow key={`row-${i}`}>
          {showColumnNumber && (
            <TableCell size="small">
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Skeleton variant="text" width={10} height={20} animation="wave" />
              </div>
            </TableCell>
          )}
          {fakeArray(cells).map((v, i) => (
            <TableCell key={`cell-${i}`}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: aliment[columns[i]?.props?.align] || 'flex-start',
                }}
              >
                <Skeleton variant="text" width={'50%'} height={20} animation="wave" />
              </div>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};
