import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { saveAs } from 'file-saver';

import InfoBlock from '../../../components/InfoBlock';
import UserInfoBlock from '../../../components/UserInfoBlock';
import CustomButton from '../../../components/CustomButton';
import ProjectTeam from './ProjectTeam';
import SendAppToJoinProject from '../send-app-to-join-project';
import AttachmentsView from '../../../components/AttachmentsView';

import { setTextToClipboard } from '../../../utils/utils';
import {
  fetchKickMember,
  fetchProjectJoinToken,
  fetchSendAppToJoinProject,
} from '../domain/reducer';
import { PALETTE } from '../../../utils/palette';
import { downloadProjectPassport, downloadProjectReport } from '../domain/dal';

const useStyles = makeStyles(() => ({
  button: {
    marginBottom: 12,
    marginTop: 8,
  },
  appSendText: {
    color: PALETTE().green,
  },
  applicationBox: {
    marginBottom: 26,
  },
  generateLinkLabel: {
    marginTop: 32,
  },
  generateLinkTypography: {
    marginBottom: 0,
  },
  attachmentsLabel: {
    marginTop: 32,
  },
  projectLeaderBlock: {
    marginBottom: 26,
  },
  projectTeamLabel: {
    marginTop: 32,
  },
  printformsLabel: {
    marginTop: 32,
  },
}));

function ProjectInfoActionBlock() {
  const { projectData, projectJoinLink, hasApplication, isProjectMember } = useSelector(
    (state: any) => state.workspaceProjectInfo,
  );
  const { mentor, team, isMentor, attachments } = projectData;
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleGenerateJoinLink = () => {
    dispatch(fetchProjectJoinToken({ projectId: projectData.id }));
  };

  const handleKickMember = (payload) => {
    dispatch(fetchKickMember({ projectId: projectData.id, payload }));
  };

  const [openSendAppModal, setSendAppModal] = React.useState(false);
  const handleOpenSendAppModal = () => setSendAppModal(true);
  const handleCloseSendAppModal = () => setSendAppModal(false);

  const handleSendApp = ({ message }) => {
    dispatch(fetchSendAppToJoinProject({ projectId: projectData.id, message }));
    handleCloseSendAppModal();
  };

  const handleDownloadProjectPassport = () => {
    downloadProjectPassport(projectData.id).then((response) => {
      const file = new Blob([response.data], { type: 'application/pdf;charset=utf-8' });

      saveAs(file, 'passport.pdf');
    });
  };

  const handleDownloadProjectReport = () => {
    downloadProjectReport(projectData.id).then((response) => {
      const file = new Blob([response.data], { type: 'application/pdf;charset=utf-8' });

      saveAs(file, 'report.pdf');
    });
  };

  return (
    <div>
      <InfoBlock label="Лидер проекта">
        <UserInfoBlock user={mentor} className={classes.projectLeaderBlock} />
      </InfoBlock>
      {team.length !== 0 && (
        <ProjectTeam
          users={team}
          onKickMember={handleKickMember}
          isMentor={isMentor}
          className={classes.projectTeamLabel}
        />
      )}
      {isMentor && (
        <InfoBlock
          label="Ссылка для добавления участника в проект"
          className={classes.generateLinkLabel}
        >
          <Typography
            variant="subtitle2"
            color="primary"
            className={classes.generateLinkTypography}
            paragraph
          >
            {projectJoinLink}
          </Typography>
          {!!projectJoinLink && (
            <Button
              className={classes.button}
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => setTextToClipboard(projectJoinLink)}
            >
              Скопировать ссылку в буфер обмена
            </Button>
          )}
          <CustomButton
            variant="outlined"
            color="primary"
            size="medium"
            onClick={handleGenerateJoinLink}
          >
            {`Сгенерировать ${projectJoinLink && 'новую '}ссылку `}
          </CustomButton>
        </InfoBlock>
      )}
      {!isProjectMember && (
        <div className={classes.applicationBox}>
          {hasApplication ? (
            <Typography className={classes.appSendText}>ЗАЯВКА НА УЧАСТИЕ ОТПРАВЛЕНА</Typography>
          ) : (
            <>
              <CustomButton
                variant="outlined"
                color="primary"
                size="medium"
                onClick={handleOpenSendAppModal}
              >
                Принять участие
              </CustomButton>
              <SendAppToJoinProject
                open={openSendAppModal}
                onClose={handleCloseSendAppModal}
                onConfirm={handleSendApp}
              />
            </>
          )}
        </div>
      )}
      {attachments.length > 0 && (
        <InfoBlock label="Документы проекта" className={classes.attachmentsLabel}>
          <AttachmentsView files={projectData.attachments} />
        </InfoBlock>
      )}
      {isProjectMember && (
        <InfoBlock label="Паспорт и отчеты" className={classes.printformsLabel}>
          <CustomButton
            variant="outlined"
            color="primary"
            size="medium"
            onClick={handleDownloadProjectPassport}
          >
            Скачать паспорт проекта
          </CustomButton>
          <div style={{ marginTop: 8 }} />
          <CustomButton
            variant="outlined"
            color="primary"
            size="medium"
            onClick={handleDownloadProjectReport}
          >
            Скачать отчет проекта
          </CustomButton>
        </InfoBlock>
      )}
    </div>
  );
}

export default ProjectInfoActionBlock;
