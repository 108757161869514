import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import AvatarGroup from '@material-ui/lab/AvatarGroup';
import CommonCardFull from '../../../components/CommonCardFull';
import ColoredAvatar from '../../../components/ColoredAvatar';
import ProjectStatusLabel from './ProjectStatusLabel';

import Paths from '../../../utils/route-paths';
import { Project } from '../../../utils/types';

interface TemplateCardProps {
  project: Project;
}

const useStyles = makeStyles((theme) => ({
  label: {
    marginTop: 10,
  },
}));

function ProjectFullCard({ project }: TemplateCardProps) {
  const history = useHistory();
  const classes = useStyles();

  const handleMoreInfoAction = useCallback(
    (e) => {
      const { id } = e.currentTarget.dataset;
      history.push({
        pathname: `${Paths.PROJECT_WORKSPACE}/${id}`,
      });
    },
    [history],
  );

  return (
    <CommonCardFull
      // у проекта свой айди, данные о проекте из рекрутинга
      data={{ ...project, id: project.id }}
      user={project.mentor}
      moreAction={handleMoreInfoAction}
      isPersonal
    >
      <AvatarGroup max={5}>
        {project.team.map((teamMember) => {
          return <ColoredAvatar key={teamMember.id} user={teamMember} />;
        })}
      </AvatarGroup>
      <ProjectStatusLabel
        className={classes.label}
        status={project.status}
        startDate={project.startDate}
        organizationOnly={project.organizationOnly}
      />
    </CommonCardFull>
  );
}

export default ProjectFullCard;
