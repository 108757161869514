import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StandardDialog from '../../../../components/StandardDialog';
import InfoBlock from '../../../../components/InfoBlock';
import UserInfoBlock from '../../../../components/UserInfoBlock';

import { processApplication } from '../domain';
import { setHeader } from '../../../globalDomain';
import { RecruitingApplication } from '../../../../utils/types';

interface ApplicationViewProps {
  application: RecruitingApplication | null;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: 48,
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 550,
    overflowX: 'hidden',
  },
  userInfo: {
    marginBottom: 20,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 25,
    marginTop: 'auto',
    '& button': {
      width: 160,
    },
    '& button:first-child': {
      marginRight: 24,
    },
  },
}));

function ApplicationView(props: ApplicationViewProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { application, open, onClose } = props;
  const { acceptedApplications } = useSelector((state: any) => state.applications);

  useEffect(() => {
    dispatch(setHeader({ title: 'Заявка на проект' }));
  }, [dispatch]);

  const handleCheck = () => {
    dispatch(processApplication({ app: application }));
    onClose();
  };

  return (
    <StandardDialog open={open} onClose={onClose}>
      {!!application && (
        <div className={classes.root}>
          <div className={classes.main}>
            <Typography variant="h3" color="textSecondary" paragraph>
              Анкета участника
            </Typography>
            <UserInfoBlock className={classes.userInfo} user={application.student} />
            <div className={classes.content}>
              {application.answers.map((a, i) => (
                <InfoBlock key={a.id} label={`${i + 1}. ${a.question}`} body={a.text} />
              ))}
            </div>
          </div>
          <div className={classes.buttons}>
            <Button size="large" variant="contained" color="primary" onClick={handleCheck}>
              {acceptedApplications.includes(application.id) ? 'Исключить' : 'Принять'}
            </Button>
            <Button size="large" variant="outlined" color="primary" onClick={onClose}>
              Отмена
            </Button>
          </div>
        </div>
      )}
    </StandardDialog>
  );
}

export default ApplicationView;
