import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Link from '@material-ui/core/Link';

import { PALETTE } from '../../../utils/palette';

interface CardBlockWrapperProps {
  title: string;
  titleLinkOnClick: () => void;
  addButtonText?: string;
  addButtonOnClick?: () => void;
  counter: number;
  counterEnable: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 34,
    width: 1000,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 370,
    marginBottom: 15,
  },
  title: {
    position: 'relative',
    display: 'flex',
  },
  counter: {
    position: 'absolute',
    color: PALETTE().red,
    fontWeight: 600,
    left: 110,
  },
  addIconBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    opacity: 0.5,
    marginLeft: 52,
  },
  addIcon: {
    fontSize: 100,
    color: theme.palette.text.secondary,
  },
  addText: {
    position: 'absolute',
    top: 110,
  },
  cards: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function CardBlockWrapper(props: PropsWithChildren<CardBlockWrapperProps>): JSX.Element {
  const classes = useStyles();
  const {
    title,
    titleLinkOnClick,
    addButtonText,
    addButtonOnClick,
    children,
    counter,
    counterEnable,
  } = props;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h3">{title}</Typography>
        <div className={classes.title}>
          <Link component="button" variant="body1" color="primary" onClick={titleLinkOnClick}>
            смотреть все
          </Link>
          {counterEnable && counter > 2 && (
            <Typography className={classes.counter}>+{counter - 2}</Typography>
          )}
        </div>
      </div>
      <div className={classes.cards}>
        {children}
        {!!addButtonText && (
          <div className={classes.addIconBox}>
            <IconButton onClick={addButtonOnClick}>
              <AddBoxIcon className={classes.addIcon} />
            </IconButton>
            <Typography
              className={classes.addText}
              variant="subtitle2"
              color="textSecondary"
              noWrap
            >
              {addButtonText}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default CardBlockWrapper;
