import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import { PALETTE } from '../../utils/palette';

const WIDTH = 300;
const HEIGHT = 600;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  item: {
    marginRight: 12,
    borderRadius: 16,
    backgroundColor: PALETTE(0.1).gray,
  },
}));

function KanbanSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Skeleton
        className={classes.item}
        variant="rect"
        width={WIDTH}
        height={HEIGHT}
        animation="wave"
      />
      <Skeleton
        className={classes.item}
        variant="rect"
        width={WIDTH}
        height={HEIGHT}
        animation="wave"
      />
      <Skeleton
        className={classes.item}
        variant="rect"
        width={WIDTH}
        height={HEIGHT}
        animation="wave"
      />
    </div>
  );
}

export default KanbanSkeleton;
