import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import TilesGridWrapper from '../../../components/TilesGridWrapper';
import RecruitingCardFull from '../components/RecruitingFullCard';

import { fetchRecruitingProjectsList } from './domain';

function CommonRecruitingTiles(props) {
  const dispatch = useDispatch();
  const { commonRecruitingProjects, isLastPage } = useSelector(
    (state: any) => state.recruitingProjectsList,
  );

  const handleLoadMore = () => {
    dispatch(fetchRecruitingProjectsList({ isFirstLoading: false }));
  };

  return (
    <TilesGridWrapper isLastPage={isLastPage} loadMore={handleLoadMore} title="Все рекрутинги">
      {commonRecruitingProjects.map((p) => (
        <Grid key={p.id} item>
          <RecruitingCardFull recruiting={p} />
        </Grid>
      ))}
    </TilesGridWrapper>
  );
}

export default CommonRecruitingTiles;
