import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import Typography from '@material-ui/core/Typography';

interface TableEmtyDataProps {
  columnsNumber?: number;
}

function TableEmptyData({ columnsNumber = 1 }: TableEmtyDataProps) {
  return (
    <>
      <TableRow>
        <TableCell colSpan={columnsNumber} align="center">
          <CancelPresentationIcon color="primary" fontSize="large" />{' '}
          <Typography color="textSecondary">Нет данных</Typography>
        </TableCell>
      </TableRow>
    </>
  );
}

export default TableEmptyData;
