import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import CommonCardFull from '../../../components/CommonCardFull';
import RecruitingStatusLabel from './RecruitingStatusLabel';
import RecruitingApplicationsView from './RecruitingApplicationsView';

import Paths from '../../../utils/route-paths';
import { Recruiting } from '../../../utils/types';

interface RecruitingCardProps {
  recruiting: Recruiting;
  isPersonal?: boolean;
}

const useStyles = makeStyles((theme) => ({
  applications: {
    marginBottom: 16,
  },
}));

function RecruitingCardFull(props: RecruitingCardProps) {
  const { recruiting, isPersonal = false } = props;
  const history = useHistory();
  const classes = useStyles();

  const handleMoreInfo = useCallback(
    (e) => {
      const { id } = e.currentTarget.dataset;
      history.push({
        pathname: `${Paths.COMMON_RECRUITING_PROJECTS}/${id}`,
        state: { isPersonal },
      });
    },
    [history, isPersonal],
  );

  return (
    <CommonCardFull
      data={recruiting}
      user={recruiting.mentor}
      moreAction={handleMoreInfo}
      isPersonal={isPersonal}
      isMentor={recruiting.isMentor}
    >
      {isPersonal && recruiting?.applications && (
        <RecruitingApplicationsView
          className={classes.applications}
          applications={recruiting.applications}
          isMentor={recruiting.isMentor}
        />
      )}
      <RecruitingStatusLabel endDate={recruiting.endDate} status={recruiting.status} />
    </CommonCardFull>
  );
}

export default RecruitingCardFull;
