import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CustomButton from '../../../components/CustomButton';
import ViewSelectState from '../../../components/select-state/ViewSelectState';
import PageWrapper from '../../../components/Wrapper';
import InfoBlock from '../../../components/InfoBlock';
import UserInfoBlock from '../../../components/UserInfoBlock';

import { fetchCheckToken, fetchJoinProject, JoinNewMemberState } from './domain';
import { CUSTOM_SHADOWS } from '../../../utils/palette';
import Paths from '../../../utils/route-paths';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  info: {
    maxWidth: 600,
    padding: 24,
    marginBottom: 24,
    boxShadow: CUSTOM_SHADOWS.gray.elevation3,
  },
  buttons: {
    display: 'flex',
  },
}));

function JoinNewMember() {
  // @ts-ignore
  const { token } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const {
    componentState,
    projectData,
    fullMentor,
    accepted,
    request,
  }: JoinNewMemberState = useSelector((state: any) => state.newUserJoinToProject);

  const { name, goal } = projectData;

  useEffect(() => {
    dispatch(fetchCheckToken({ token }));
  }, [dispatch, token]);

  useEffect(
    () => {
      if (accepted) {
        history.replace(`${Paths.PROJECT_WORKSPACE}/${projectData.id}`);
      }
    },
    // eslint-disable-next-line
    [accepted],
  );

  const handleJoinProject = () => {
    dispatch(fetchJoinProject({ token }));
  };

  const content = () => {
    return (
      <div className={classes.root}>
        <Paper className={classes.info}>
          <Typography variant="h4" paragraph>
            {name}
          </Typography>
          <InfoBlock label="Цель проекта">{goal}</InfoBlock>
          <InfoBlock label="Лидер проекта">
            <UserInfoBlock user={fullMentor} />
          </InfoBlock>
        </Paper>
        <div>
          <CustomButton
            withConfirm
            horizontalMargin="right"
            length="short"
            color="primary"
            variant="contained"
            disabled={request}
            onClick={handleJoinProject}
          >
            Вступить
          </CustomButton>
          <CustomButton
            withConfirm
            color="primary"
            length="short"
            variant="outlined"
            disabled={request}
            onClick={() => history.push('/')}
          >
            Отказаться
          </CustomButton>
        </div>
      </div>
    );
  };

  return (
    <PageWrapper dontShowGoBack header="Вступление в проект">
      <ViewSelectState state={componentState} content={content} />
    </PageWrapper>
  );
}

export default JoinNewMember;
