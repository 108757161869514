import { call, put, takeEvery } from 'redux-saga/effects';
import { errorHandler } from '../../../utils/fetchUtils';
import { getNotificationsList, setNotificationRead, setNotificationReadAll } from './dal';
import {
  fetchNotificationsList,
  fetchNotificationsListSuccess,
  fetchNotificationsListFail,
  fetchNotificationRead,
  goNotificationPath,
  fetchNotificationsReadAll,
  fetchNotificationsReadAllSuccess,
} from './reducer';
import { fetchNotificationCount } from '../../globalDomain';
import { makePathFromNotification } from '../../../utils/utils';
import { setSelectedTab, fetchWorkspaceProject } from '../../workspace/domain/reducer';
import { WORKSPACE_TABS } from '../../../utils/constants';
import {
  fetchExistedKanbanCard,
  setKanbanCardOpen,
} from '../../workspace/kanban/components/kanban-card/domain';
import { setProjectSettingMenuItem } from '../../workspace/project-settings/domain/reducer';

function* notificationsListRequest(action) {
  try {
    const { pagination, filters } = action.payload;
    const { data: tableData } = yield call(getNotificationsList, pagination, filters);
    yield put(fetchNotificationsListSuccess({ tableData }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchNotificationsListFail(e.response));
  }
}

function* notificationReadRequest(action) {
  try {
    const { id } = action.payload;
    yield call(setNotificationRead, id);
    yield put(fetchNotificationCount());
  } catch (e) {
    errorHandler(e);
  }
}

function* notificationReadAllRequest(action) {
  try {
    yield call(setNotificationReadAll);
    yield put(fetchNotificationCount());
    yield put(fetchNotificationsReadAllSuccess());
  } catch (e) {
    errorHandler(e);
  }
}

function* goNotificationPathHandler(action) {
  const {
    data: { ids, entities },
    history,
    userId,
  } = action.payload;
  const { path, pathKey } = makePathFromNotification(ids, entities);
  if (pathKey === 'PROJECT') {
    const firstAction = entities[1];
    switch (firstAction) {
      case 'PROJECT_CARD':
        yield put(setSelectedTab(WORKSPACE_TABS.kanban));
        if (ids[1]) {
          yield put(
            fetchExistedKanbanCard({
              projectId: ids[0],
              cardId: ids[1],
            }),
          );
          yield put(setKanbanCardOpen(true));
        }
        break;
      case 'PROJECT_TEAM':
        yield put(setSelectedTab(WORKSPACE_TABS.description));
        break;
      case 'PROJECT_CHARTER':
        yield put(setSelectedTab(WORKSPACE_TABS.charter));
        break;
      default:
        break;
    }
  }
  if (pathKey === 'APPLICATION') {
    yield fetchWorkspaceProject({ userId, projectId: ids[0] });
    yield put(setProjectSettingMenuItem(0));
  }
  yield call(() =>
    history.push({
      pathname: path,
    }),
  );
}

export function* watchLoadingNotificationsList() {
  yield takeEvery(fetchNotificationsList.type, notificationsListRequest);
  yield takeEvery(fetchNotificationRead, notificationReadRequest);
  yield takeEvery(goNotificationPath.type, goNotificationPathHandler);
  yield takeEvery(fetchNotificationsReadAll.type, notificationReadAllRequest);
}
