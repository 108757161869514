import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import throttle from 'lodash.throttle';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import InputAdornment from '@material-ui/core/InputAdornment';

import CustomTable, { Column } from '../../../components/table/CustomTable';
import WithErrorPageWrapper from '../../../components/errors/WithErrorPageWrapper';

import Paths from '../../../utils/route-paths';
import { fetchUserList } from './domain';
import {
  CURRENT_PUBLIC_HOST,
  USER_STATUSES_TITLES,
  USER_TYPE_TITLES,
} from '../../../utils/constants';
import { makeFullName, setTextToClipboard } from '../../../utils/utils';
import Dates from '../../../utils/dates';
import { TOURS_CLASS_NAMES, TOUR_PAGES } from '../../../components/tour/tourSteps';
import { setTourPage } from '../../globalDomain';

const columns: Column[] = [
  { title: 'Имя пользователя', fieldName: 'firstName', handler: (v, row) => makeFullName(row) },
  {
    title: 'Тип пользователя',
    fieldName: 'type',
    handler: (v) => USER_TYPE_TITLES[v],
    props: { align: 'right' },
  },
  {
    title: 'Статус',
    fieldName: 'status',
    handler: (v) => USER_STATUSES_TITLES[v],
    props: { align: 'right' },
  },
  {
    title: 'Последняя активность',
    fieldName: 'lastActivity',
    handler: (v) => (v ? Dates.dateToLocalDateAndTime(new Date(v)) : '-'),
    props: { align: 'right' },
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: '1px solid #A6A6A6',
    borderRadius: 5,
  },
  main: {
    width: 800,
    height: 'min-content',
    minHeight: '60%',
    paddingBottom: 50,
    background: '#fff',
  },
  table: {
    minWidth: 500,
  },
  hover: {
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(3),
  },
  headerText: {
    fontSize: 26,
    fontWeight: 300,
  },
  filtersBox: {
    marginBottom: 10,
    display: 'flex',
  },
  filter: {
    marginLeft: 10,
    width: 410,
  },
}));

function OrganizationUserList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { componentState, users, tableData, errorData, orgToken } = useSelector(
    (state: any) => state.organizationUserList,
  );
  const { user } = useSelector((state: any) => state.app);

  const [filters, setFilters] = useState({
    name: '',
  });
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const registrationLink = `${CURRENT_PUBLIC_HOST}${Paths.USER_REGISTRATION_PATH}/${orgToken}`;

  const fetchData = () => {
    if (user) {
      dispatch(fetchUserList({ orgId: user.organization.id, pagination, filters }));
      dispatch(setTourPage({ pages: [TOUR_PAGES.orgManageUsers], scrolling: true }));
    }
    return function cleanUp() {
      dispatch(setTourPage({ pages: [] }));
    };
  };

  useEffect(fetchData, [user, pagination]);

  const handleOnRowClick = useCallback(
    (e) => {
      const { id } = e.currentTarget.dataset;
      history.push(`${Paths.ORGANIZATION_USER}/${id}`, { fromStat: true });
    },
    [history],
  );

  const throttledSearch = useCallback(
    throttle(
      (filters) => dispatch(fetchUserList({ orgId: user.organization.id, pagination, filters })),
      1000,
    ),
    [],
  );

  const handleChangeFilter = (event) => {
    const { value } = event.target;
    setFilters((prevState) => {
      const newState = { ...prevState, name: value };
      throttledSearch(newState);
      return newState;
    });
  };

  const handleClearSearch = () => {
    setFilters((prevState) => {
      const newState = { ...prevState, name: '' };
      throttledSearch(newState);
      return newState;
    });
  };

  return (
    <WithErrorPageWrapper componentState={componentState} errorData={errorData} action={fetchData}>
      <div className={classes.main}>
        <div className={classes.header}>
          <div>
            <Typography className={classes.headerText}>
              {'Пользователей в организации:'} {tableData.totalElements}
            </Typography>
          </div>
          <Button
            className={TOURS_CLASS_NAMES.orgManageUsers.addUserBtn}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => history.push(Paths.ORGANIZATION_USER)}
          >
            Добавить пользователя
          </Button>
        </div>
        <div className={classes.filtersBox}>
          <TextField
            className={classes.filter}
            variant="outlined"
            value={filters.name}
            label="Ф.И.О. пользователя"
            helperText="Отфильтруйте пользователей по имени, фамилии или отчеству"
            onChange={handleChangeFilter}
            InputProps={{
              endAdornment: filters.name ? (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearch}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
        </div>
        <CustomTable
          columns={columns}
          tableData={users}
          componentState={componentState}
          totalElements={tableData.totalElements}
          pagination={pagination}
          setPagination={setPagination}
          onRowClick={handleOnRowClick}
          firstRowClassName={TOURS_CLASS_NAMES.orgManageUsers.userTableFirsRow}
        />
        {!!orgToken && (
          <div style={{ background: '#fff' }}>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              Ссылка для регистрации нового пользователя:
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" paragraph>
              {registrationLink}
            </Typography>
            <Button
              className={TOURS_CLASS_NAMES.orgManageUsers.copyLink}
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => setTextToClipboard(registrationLink)}
            >
              Скопировать ссылку в буфер обмена
            </Button>
          </div>
        )}
      </div>
    </WithErrorPageWrapper>
  );
}

export default OrganizationUserList;
