import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import RecruitingApplicationsView from '../../components/RecruitingApplicationsView';
import ApplicationForm from '../application-form';
import CancelRecruiting from './cancel-recruiting';

import AttachmentsView from '../../../../components/AttachmentsView';
import InfoBlock from '../../../../components/InfoBlock';

import Paths from '../../../../utils/route-paths';
import { fetchCancelRecruiting } from '../domain';
import { RECRUITING_MODERATION_STATUSES } from '../../../../utils/constants';
import { PALETTE } from '../../../../utils/palette';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& button': {
      marginBottom: 16,
      width: 256,
    },
    '& button:last-child': {
      marginBottom: 0,
    },
  },
  applications: {
    alignSelf: 'flex-start',
    marginBottom: 34,
  },
  applicationSentText: {
    color: PALETTE().green,
  },
  attachmentsBox: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  attachmentItem: {
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 16,
  },
  attachmentsLabel: {
    marginTop: 16,
  },
}));

function RecruitingActionBlock() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { recruitingProjectData, requesting } = useSelector(
    (state: any) => state.recruitingProject,
  );
  const { isMentor, applications, canSendApplication, status } = recruitingProjectData;

  const showApplications =
    status === RECRUITING_MODERATION_STATUSES.ACTIVE ||
    status === RECRUITING_MODERATION_STATUSES.WAITING;
  const showCancelButton = status !== RECRUITING_MODERATION_STATUSES.DECLINED;

  const [openDialog, setOpenDialog] = useState(false);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleBeginProject = () => history.push(Paths.START_NEW_PROJECT);

  const [openCancelModal, setOpenCancelModal] = React.useState(false);
  const handleOpenCancelDialog = () => setOpenCancelModal(true);
  const handleCloseCancelDialog = () => setOpenCancelModal(false);

  const handleCancelRecruiting = (values) => {
    dispatch(fetchCancelRecruiting({ projectId: recruitingProjectData.id, values, history }));
    handleCloseCancelDialog();
  };

  return (
    <div className={classes.root}>
      {isMentor ? (
        <>
          {showApplications && (
            <>
              <div className={classes.applications}>
                <Typography variant="subtitle2" color="textSecondary" paragraph>
                  Заявки на проект
                </Typography>
                <RecruitingApplicationsView applications={applications} isMentor={isMentor} />
              </div>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleBeginProject}
                disabled={requesting}
              >
                Смотреть заявки
              </Button>
            </>
          )}
          {showCancelButton && (
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={handleOpenCancelDialog}
              disabled={requesting}
            >
              Отменить рекрутинг
            </Button>
          )}
          {recruitingProjectData.attachments.length > 0 && (
            <>
              <InfoBlock label="Документы идеи:" className={classes.attachmentsLabel} />
              <div className={classes.attachmentsBox}>
                <AttachmentsView
                  files={recruitingProjectData.attachments}
                  className={classes.attachmentItem}
                />
              </div>
            </>
          )}
        </>
      ) : canSendApplication ? (
        <Button variant="contained" color="primary" size="large" onClick={handleOpenDialog}>
          Отправить заявку
        </Button>
      ) : (
        <Typography className={classes.applicationSentText}>ЗАЯВКА ОТПРАВЛЕНА</Typography>
      )}
      <ApplicationForm
        open={openDialog}
        onClose={handleCloseDialog}
        recruitingData={recruitingProjectData}
      />
      <CancelRecruiting
        open={openCancelModal}
        onClose={handleCloseCancelDialog}
        onConfirm={handleCancelRecruiting}
      />
    </div>
  );
}

export default RecruitingActionBlock;
