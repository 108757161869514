import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

function UserCardSkeleton() {
  return (
    <div style={{ display: 'flex' }}>
      <Skeleton variant="circle" width={45} height={45} />
    </div>
  );
}

export default UserCardSkeleton;
