import { makeStyles } from '@material-ui/core/styles';

export const usePublicProfileStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 40,
    paddingTop: 40,
  },
  info: {
    display: 'flex',
  },
  avatar: {
    marginRight: 24,
  },
  textInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  typeText: {
    fontWeight: 600,
    fontSize: 18,
    color: '#78909C',
  },
  nameText: {
    fontSize: 36,
    fontWeight: 500,
    lineHeight: 1.2,
  },
  orgBanner: {
    marginTop: 8,
  },
  lastActivityText: {
    marginTop: 16,
    fontSize: 18,
    fontWeight: 500,
    color: '#78909C',
  },
  dashboard: {
    paddingTop: 60,
  },
}));
