import React, { PropsWithChildren } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

interface GridWrapperWithTitle {
  title: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 40,
  },
}));

function GridWrapperWithTitle({ children, title }: PropsWithChildren<GridWrapperWithTitle>) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h3" color="textSecondary" paragraph>
        {title}
      </Typography>
      {children}
    </div>
  );
}

export default GridWrapperWithTitle;
