import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { COMPONENT_STATES } from '../../../utils/componentState';
import { errorHandler } from '../../../utils/fetchUtils';
import { LIST_PAGE_SIZE } from '../../../utils/constants';
import UrlsV2 from '../../../utils/endpointsV2';

// dal
export const getPersonalProjectsTemplatesList = (profileId, params) =>
  axios.get(UrlsV2.PERSONAL_PROJECTS_IDEAS(profileId), { params });

// saga
function* personalProjectTemplatesRequest(action) {
  try {
    const { userId, currentPage } = action.payload;
    const { data } = yield call(getPersonalProjectsTemplatesList, userId, {
      page: currentPage,
      pageSize: LIST_PAGE_SIZE,
    });
    yield put(fetchPersonalProjectsTemplatesListSuccess(data));
  } catch (e) {
    errorHandler(e);
    yield put(fetchPersonalProjectsTemplatesListFail());
  }
}

export function* watchLoadingPersonalProjectTemplatesList() {
  yield takeEvery(fetchPersonalProjectsTemplatesList.type, personalProjectTemplatesRequest);
}

// reducer
const initialState = {
  componentState: COMPONENT_STATES.LOADING,
  projectTemplates: [] as any[],
  currentPage: 1,
  isLastPage: false,
};

const personalProjectTemplatesListSlice = createSlice({
  name: 'personalProjectTemplatesList',
  initialState,
  reducers: {
    fetchPersonalProjectsTemplatesList(state, action) {
      if (action.payload.isFirstLoading) {
        state.componentState = COMPONENT_STATES.LOADING;
        state.currentPage = 1;
        state.projectTemplates = [];
      }
      action.payload.currentPage = state.currentPage;
    },
    fetchPersonalProjectsTemplatesListSuccess(state, action) {
      if (action.payload.items?.length === 0) {
        state.componentState = COMPONENT_STATES.EMPTY;
      } else {
        state.componentState = COMPONENT_STATES.CONTENT;
        state.projectTemplates = [...state.projectTemplates, ...action.payload.items];
        state.currentPage += 1;
        state.isLastPage = action.payload.last;
      }
    },
    fetchPersonalProjectsTemplatesListFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const {
  fetchPersonalProjectsTemplatesList,
  fetchPersonalProjectsTemplatesListSuccess,
  fetchPersonalProjectsTemplatesListFail,
} = personalProjectTemplatesListSlice.actions;

export default personalProjectTemplatesListSlice.reducer;
