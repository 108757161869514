import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import ViewSelectState from '../../../../components/select-state/ViewSelectState';
import PageWrapper from '../../../../components/Wrapper';
import RecruitingCardFull from '../../../recruiting/components/RecruitingFullCard';
import TilesGridWrapper from '../../../../components/TilesGridWrapper';

import { fetchPublicUserRecruiting, PublicUserRecruitingState } from './domain';
import { makeShortName } from '../../../../utils/utils';

interface PublicUserRecruitingProps {}

function PublicUserRecruiting(props: PublicUserRecruitingProps) {
  const dispatch = useDispatch();
  // @ts-ignore
  const { id } = useParams();
  const { componentState, recruiting, isLastPage, user }: PublicUserRecruitingState = useSelector(
    (state: any) => state.publicUserRecruiting,
  );

  useEffect(() => {
    dispatch(fetchPublicUserRecruiting({ isFirstLoading: true, userId: id }));
  }, [dispatch, id]);

  const handleLoadMore = () => {
    dispatch(fetchPublicUserRecruiting({ userId: id }));
  };

  const content = () => {
    return (
      <div>
        <TilesGridWrapper isLastPage={isLastPage} loadMore={handleLoadMore}>
          {recruiting.map((r: any) => (
            <Grid key={r.id} item>
              <RecruitingCardFull recruiting={r} isPersonal />
            </Grid>
          ))}
        </TilesGridWrapper>
      </div>
    );
  };

  return (
    <PageWrapper header={`Рекрутинг пользователя ${makeShortName(user)}`}>
      <ViewSelectState state={componentState} content={content} />
    </PageWrapper>
  );
}

export default PublicUserRecruiting;
