import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import ViewSelectState from '../../../components/select-state/ViewSelectState';

import { OrganizationCommonInfo, fetchToggleModeration } from './domain/reducer';
import { TOUR_PAGES, TOURS_CLASS_NAMES } from '../../../components/tour/tourSteps';
import { setTourPage } from '../../globalDomain';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '70px 90px 130px 0.5fr',
  },
  settings: {
    gridColumn: '1 / end',
    justifySelf: 'center',
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 16,
  },
  setting_1: {
    gridRow: 1,
  },
  setting_2: {
    gridRow: 2,
  },
}));

function OrgSettings() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    organization,
    componentState,
    moderationEnabled,
    request,
  }: OrganizationCommonInfo = useSelector((state: any) => state.organizationCommonInfo);

  useEffect(() => {
    dispatch(setTourPage({ pages: [TOUR_PAGES.orgManageSetting] }));
    return function cleanUp() {
      dispatch(setTourPage({ pages: [] }));
    };
  }, [dispatch]);

  const handleToggleModeration = () => {
    if (organization) {
      dispatch(fetchToggleModeration({ orgId: organization.id }));
    }
  };

  const content = () => (
    <div>
      <div className={classes.root}>
        {/* 1 row */}
        <div
          className={clsx(
            classes.settings,
            classes.setting_1,
            TOURS_CLASS_NAMES.orgManageSetting.moderation,
          )}
        >
          <div>
            <Typography>Модерация идей и рекрутингов командой платформы EDUTEAM</Typography>
            <Typography color="textSecondary" variant="subtitle2">
              Идеи и рекрутинги с активированным флагом "Только для организации" получат статус
              "Модерация" при публикации, пока не будут одобрены или отклонены.
            </Typography>
          </div>
          <Switch
            disabled={request}
            onChange={handleToggleModeration}
            checked={moderationEnabled}
            color="primary"
          />
        </div>

        {/* 2 row */}
        {/* <div className={clsx(classes.settings, classes.setting_2)}> */}
        {/*  <div> */}
        {/*    <Typography align="center">Еще одна настройка</Typography> */}
        {/*    <Typography color="textSecondary" variant="subtitle2"> */}
        {/*      Описание настройки */}
        {/*    </Typography> */}
        {/*  </div> */}
        {/*  <Switch onChange={() => {}} checked={true} color="primary" /> */}
        {/* </div> */}
      </div>
    </div>
  );

  return <ViewSelectState state={componentState} content={content} />;
}

export default OrgSettings;
