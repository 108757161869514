import * as Yup from 'yup';

import {
  MIN_SYMBOLS_NUMBER,
  MAX_SYMBOLS_NUMBER_FOR_LONG_TEXT,
  MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT,
} from '../../../../utils/constants';

const required = 'Обязательное поле';

const TemplateSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(MIN_SYMBOLS_NUMBER, `Название не может быть короче ${MIN_SYMBOLS_NUMBER} символов`)
    .max(
      MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT,
      `Название не может быть длиннее ${MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT} символов`,
    )
    .required(required),
  goal: Yup.string()
    .trim()
    .min(
      MIN_SYMBOLS_NUMBER,
      `Цель не может быть короче ${MIN_SYMBOLS_NUMBER} символов, но может оставаться незаполнненой`,
    )
    .max(
      MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT,
      `Цель не может быть длиннее ${MAX_SYMBOLS_NUMBER_FOR_SHORT_TEXT} символов`,
    ),
  duration: Yup.number()
    .typeError('Должно быть число')
    .integer('Должно быть целое число')
    .min(1, 'Продолжительность проекта не может быть менее 1 недели')
    .max(52, 'Продолжительность проекта не может быть длинее года')
    .required(required),
  about: Yup.string()
    .transform((value) => {
      return value;
    })
    .test(
      'about',
      `Описание не может быть короче ${MIN_SYMBOLS_NUMBER} символов, но может оставаться незаполненным`,
      (v) => v.length === 0 || v.length > MIN_SYMBOLS_NUMBER,
    )
    .max(
      MAX_SYMBOLS_NUMBER_FOR_LONG_TEXT,
      `Описание не может быть длиннее ${MAX_SYMBOLS_NUMBER_FOR_LONG_TEXT} символов`,
    ),
});

export default TemplateSchema;
