import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import UserInfoBlock from '../../../../components/UserInfoBlock';
import ApplicationView from './ApplicationView';

import { processApplication } from '../domain';
import { RecruitingApplication } from '../../../../utils/types';
import { PALETTE } from '../../../../utils/palette';

interface ApplicationsListProps {
  applications: RecruitingApplication[];
  project: any;
}

interface OpenDialog {
  status: boolean;
  app: RecruitingApplication | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 72,
    border: '1px solid',
    borderColor: PALETTE(0.25).gray,
    marginBottom: 4,
  },
}));

function ApplicationList(props: ApplicationsListProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { applications } = props;
  const { acceptedApplications } = useSelector((state: any) => state.applications);
  const [openDialog, setOpenDialog] = useState<OpenDialog>({ status: false, app: null });

  const handleCloseDialog = () => setOpenDialog({ status: false, app: null });
  const handleOpenDialog = (app) => setOpenDialog({ status: true, app });

  const handleItemCheck = (app) => {
    dispatch(processApplication({ app }));
  };

  return (
    <div>
      <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
        {applications.map((app, i) => {
          const { student } = app;
          return (
            <ListItem
              className={classes.item}
              button
              key={app.id}
              onClick={() => handleOpenDialog(app)}
            >
              <UserInfoBlock user={student} />
              <ListItemSecondaryAction>
                <Checkbox
                  onChange={() => handleItemCheck(app)}
                  checked={acceptedApplications.includes(app.id)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <ApplicationView
        application={openDialog.app}
        open={openDialog.status}
        onClose={handleCloseDialog}
      />
    </div>
  );
}

export default ApplicationList;
