import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import CustomButton from '../../../components/CustomButton';
import FileUploader from '../../../components/files-uploader/FileLoader';
import AttachmentsView from '../../../components/AttachmentsView';

import { fetchUpdateProject, fetchWorkspaceProject } from '../domain/reducer';
import { PALETTE } from '../../../utils/palette';
import ViewSelectState from '../../../components/select-state/ViewSelectState';

interface ProjectDocuments {
  projectId: string;
}

const useStyles = makeStyles(() => ({
  root: {
    width: 800,
    height: 634,
  },
  addAttachmentBtn: {
    marginTop: 16,
  },
  attachmentsBox: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fileIcon: {
    height: 40,
    marginRight: 4,
  },
  attachmentItem: {
    maxWidth: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    marginRight: 8,
    marginBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 8,
    paddingTop: 6,
    borderRadius: 16,
    backgroundColor: PALETTE(0.0).white,
  },
}));

function ProjectDocuments({ projectId }: ProjectDocuments) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { projectData, projectInfoComponentState } = useSelector(
    (state: any) => state.workspaceProjectInfo,
  );
  const { user } = useSelector((state: any) => state.app);

  const [isFileUploaderOpened, setIsFileUploaderOpened] = useState(false);

  const fetchWorkspaceProjectData = () => {
    if (user) {
      dispatch(
        fetchWorkspaceProject({
          projectId,
          userId: user.id,
        }),
      );
    }
  };

  useEffect(fetchWorkspaceProjectData, [projectId, user]);

  const handleOnUpload = (newAttachment) => {
    const attachmentIds = [...projectData.attachments, ...newAttachment].map((att) => att.id);
    dispatch(fetchUpdateProject({ projectId: projectData.id, values: { attachmentIds } }));
  };

  const handleOnRemove = (attachmentIds) => {
    dispatch(fetchUpdateProject({ projectId: projectData.id, values: { attachmentIds } }));
  };

  const content = () => (
    <div className={classes.root}>
      <div>
        {projectData.attachments.length > 0 && (
          <Typography color="textSecondary" variant="body1">
            Документы проекта
          </Typography>
        )}
        <AttachmentsView files={projectData.attachments} onRemoveFile={handleOnRemove} />
        <CustomButton
          className={classes.addAttachmentBtn}
          variant="outlined"
          color="primary"
          onClick={() => setIsFileUploaderOpened(true)}
        >
          Добавить документ
        </CustomButton>
        {/* Диалог загрузки вложения */}
        <FileUploader
          open={isFileUploaderOpened}
          onSave={handleOnUpload}
          onClose={() => setIsFileUploaderOpened(false)}
        />
      </div>
    </div>
  );

  return <ViewSelectState state={projectInfoComponentState} content={content} />;
}

export default ProjectDocuments;
