import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Paths from '../../../../utils/route-paths';
import TemplatesBlock from './TemplatesBlock';
import SlimBoxTip from '../../../../components/tips/SlimBoxTip';

import { Template } from '../../../../utils/types';
import { IDEA_STATUSES, STRINGS } from '../../../../utils/constants';

interface DashboardTemplatesOnlyProps {
  templates: Template[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

function DashboardTemplatesOnly(props: DashboardTemplatesOnlyProps): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const { templates } = props;
  const { dashboardTemplatesOnly, dashboardOneIdeaDraft, dashboardOneIdeaOpen } = STRINGS;

  let tipProps = {
    ...dashboardTemplatesOnly,
    buttonAction: () => history.push({ pathname: `${Paths.COMMON_IDEAS}/new`, state: {} }),
  };

  if (templates.length === 1) {
    const goToIdea = () =>
      history.push({
        pathname: `${Paths.COMMON_IDEAS}/${templates[0].id}`,
        state: { isPersonal: true },
      });
    if (templates[0].status === IDEA_STATUSES.DRAFT) {
      tipProps = {
        ...dashboardOneIdeaDraft,
        buttonAction: goToIdea,
      };
    } else if (templates[0].status === IDEA_STATUSES.OPEN) {
      tipProps = {
        ...dashboardOneIdeaOpen,
        buttonAction: goToIdea,
      };
    }
  }

  return (
    <div className={classes.root}>
      <SlimBoxTip {...tipProps} />
      <TemplatesBlock data={templates} counterEnable={false} />
    </div>
  );
}

export default DashboardTemplatesOnly;
