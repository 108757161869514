import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import ColoredAvatar from '../../../components/ColoredAvatar';

import { RecruitingApplication } from '../../../utils/types';
import { PALETTE } from '../../../utils/palette';

interface RecruitingApplicationViewProps {
  applications: RecruitingApplication[];
  isMentor: boolean;
  className?: string;
}

interface StyledProps {
  marginLeft: number;
  isMentor: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 40,
    marginLeft: (props: StyledProps) => props.marginLeft,
    display: 'flex',
    alignItems: 'center',
  },
  applicationText: {
    color: (props: StyledProps) => (props.isMentor ? PALETTE().gray : PALETTE().green),
  },
}));

function RecruitingApplicationsView(props: RecruitingApplicationViewProps) {
  const { applications, isMentor, className } = props;
  const styleProps = { marginLeft: !!applications.length ? 8 : 0, isMentor };
  const classes = useStyles(styleProps);

  return (
    <div className={clsx(classes.root, !!className && className)}>
      {!!applications.length ? (
        <AvatarGroup max={10}>
          {applications.map((app) => {
            return <ColoredAvatar key={app.student.id} user={app.student} />;
          })}
        </AvatarGroup>
      ) : (
        <Typography className={classes.applicationText} align="justify" color="textSecondary">
          {isMentor ? 'НЕТ ЗАЯВОК :(' : 'ЗАЯВКА ОТПРАВЛЕНА'}
        </Typography>
      )}
    </div>
  );
}

export default RecruitingApplicationsView;
