import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import CommonSmallCard from '../../../components/CommonSmallCard';

import { makeTextEllipsis } from '../../../utils/utils';

interface SmallCardWrapperProps {
  title: string;
  text: string;
  onClick: () => void;
  className?: string;
}

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
    marginBottom: 2,
  },
}));

function SmallCardWrapper(props: PropsWithChildren<SmallCardWrapperProps>): JSX.Element {
  const { title, text, onClick, children, className } = props;
  const classes = useStyles();

  return (
    <CommonSmallCard className={clsx(className)} onClick={onClick}>
      <Typography className={classes.title} variant="body1">
        {makeTextEllipsis(30, title)}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        {makeTextEllipsis(120, text)}
      </Typography>
      {children}
    </CommonSmallCard>
  );
}

export default SmallCardWrapper;
