import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import 'date-fns';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextFieldWithCounter from '../../../../components/TextFieldWithCounter';
import StandardDialog from '../../../../components/StandardDialog';

import { addNewApplication } from './domain';
import { Recruiting } from '../../../../utils/types';
import ApplicationSchema from './validationScema';
import { COMPONENT_STATES } from '../../../../utils/componentState';
import { getFontSizeForTitle } from '../../../../utils/utils';

interface ApplicationFormProps {
  recruitingData: Recruiting;
  open: boolean;
  onClose: () => void;
}

interface StyleProps {
  title: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
  },
  projectName: {
    fontWeight: 500,
    marginBottom: 40,
    marginTop: 16,
    fontSize: (props: StyleProps) => getFontSizeForTitle(props.title, 64, 128),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 25,
    marginTop: 'auto',
    '& button': {
      marginRight: 24,
      width: 160,
    },
  },
}));

function ApplicationForm(props: ApplicationFormProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { recruitingData, open, onClose } = props;
  const { componentState } = useSelector((state: any) => state.newApplication);
  const { id, questions, name } = recruitingData;
  const classes = useStyles({ title: name });

  const initialValues = questions.reduce(
    (answers, question) => ({ ...answers, [question.id]: '' }),
    {},
  );

  const onSubmit = (values) => {
    dispatch(addNewApplication({ id, values, onClose, history }));
  };

  return (
    <StandardDialog className={classes.root} open={open} onClose={onClose}>
      <Typography variant="h3" color="textSecondary">
        Анкета на участие в проекте
      </Typography>
      <Typography className={classes.projectName}>{name}</Typography>
      <Formik
        validationSchema={ApplicationSchema}
        initialValues={{ answers: initialValues }}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, isValid, errors, dirty }) => (
          <Form className={classes.form}>
            {questions.map((q, index) => {
              return (
                <div key={q.id} style={{ paddingBottom: 20 }}>
                  <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                    {index + 1}. {q.text}
                  </Typography>
                  <TextFieldWithCounter
                    margin="none"
                    fullWidth
                    id={`Ответ${q.id}`}
                    placeholder="Ваш ответ"
                    name={`Ответ${q.id}`}
                    error={errors.answers && errors.answers[q.id]}
                    value={values.answers[q.id]}
                    onChange={(e) => {
                      const newAnswers = { ...values.answers };
                      newAnswers[q.id] = e.target.value;
                      setFieldValue('answers', newAnswers);
                    }}
                  />
                </div>
              );
            })}
            <div className={classes.buttons}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={!dirty || !isValid || componentState === COMPONENT_STATES.LOADING}
              >
                Отправить
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={onClose}
                disabled={componentState === COMPONENT_STATES.LOADING}
              >
                Отмена
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </StandardDialog>
  );
}

export default ApplicationForm;
