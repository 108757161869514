import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Urls from '../utils/endpoints';
import Button from '@material-ui/core/Button';

interface NoDataProps {
  text?: string;
  buttonText?: string;
  buttonAction?: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    objectFit: 'contain',
    paddingTop: 30,
    paddingLeft: 70,
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 400,
  },
  actionBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 50,
  },
  image: {
    width: 400,
  },
  text: {
    textAlign: 'center',
    fontSize: 26,
    fontWeight: 300,
    lineHeight: 1.2,
  },
  button: {
    marginTop: 30,
    width: 256,
  },
}));

function NoData(props: NoDataProps) {
  const { text = 'Нет данных', buttonText, buttonAction } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <img
          className={classes.image}
          src={Urls.ASSET_IMAGE('no_data_jvjjix')}
          alt="no_data_image"
        />
      </div>
      <div className={classes.actionBlock}>
        <div className={classes.textContainer}>
          <Typography className={classes.text}>{text}</Typography>
        </div>
        {!!(buttonText && buttonAction) && (
          <Button
            className={classes.button}
            size="large"
            variant="contained"
            color="secondary"
            onClick={buttonAction}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
}

export default NoData;
