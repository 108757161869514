export const PALETTE = (transparency = 1) => ({
  gray: `rgba(120,144,156,${transparency})`, // #78909C
  green: `rgba(76,175,80,${transparency})`, // #4CAF50
  orange: `rgba(247,142,0,${transparency})`, // #F78E00
  black: `rgba(33,33,33,${transparency})`, // #212121
  red: `rgba(235,87,87,${transparency})`, // #EB5757
  blue: `rgba(45,170,226,${transparency})`, // #2DAAE2
  greenBlue: `rgba(13,105,116,${transparency})`, // #0D6974,
  white: `rgba(255,255,255,${transparency})`, // #fff
  fbBlue: `rgba(24,119,242,${transparency})`, // #1877F2
  vkBlue: `rgba(39,135,245,${transparency})`, // #2787f5
});

const { gray, green, orange, red, blue, black } = PALETTE();

export const PROJECT_TEMPLATE_STATUSES_BACK_PALETTE = {
  DRAFT: gray,
  MODERATION: orange,
  OPEN: green,
  HIDDEN: blue,
};

export const RECRUITING_STATUSES_PALETTE = {
  END: red,
  LESS_THEN_WEEK: orange,
  MORE_THEN_WEEK: green,
  MODERATION: orange,
  REJECTED: red,
  WAITING: blue,
  ACTIVE: green,
  DECLINED: red,
};

export const PROJECT_STATUSES_PALETTE = {
  COMPLETED: blue,
  FAILED: black,
  ACTIVE: green,
};

export const CUSTOM_SHADOWS = {
  blue: {
    elevation3: `0px 3px 3px -2px ${PALETTE(0.2).blue},0px 3px 4px 0px ${
      PALETTE(0.14).blue
    },0px 1px 8px 0px ${PALETTE(0.12).blue};`,
    elevation5: `0px 3px 5px -1px ${PALETTE(0.2).blue},0px 5px 8px 0px ${
      PALETTE(0.14).blue
    },0px 1px 14px 0px ${PALETTE(0.12).blue}`,
    elevation10: `0px 6px 6px -3px ${PALETTE(0.2).blue},0px 10px 14px 1px ${
      PALETTE(0.14).blue
    },0px 4px 18px 3px ${PALETTE(0.12).blue};`,
  },
  gray: {
    elevation3: `0px 3px 3px -2px ${PALETTE(0.2).gray},0px 3px 4px 0px ${
      PALETTE(0.14).gray
    },0px 1px 8px 0px ${PALETTE(0.12).gray};`,
    elevation5: `0px 3px 5px -1px ${PALETTE(0.2).gray},0px 5px 8px 0px ${
      PALETTE(0.14).gray
    },0px 1px 14px 0px ${PALETTE(0.12).gray}`,
    elevation10: `0px 6px 6px -3px ${PALETTE(0.2).gray},0px 10px 14px 1px ${
      PALETTE(0.14).gray
    },0px 4px 18px 3px ${PALETTE(0.12).gray};`,
  },
  green: {
    elevation10: `0px 6px 6px -3px ${PALETTE(0.2).green},0px 10px 14px 1px ${
      PALETTE(0.14).green
    },0px 4px 18px 3px ${PALETTE(0.19).green};`,
  },
};
