import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import CommonSmallCard from '../../../../components/CommonSmallCard';
import { PALETTE } from '../../../../utils/palette';

interface StatCard {
  title: string;
  subtitle: string;
  value: string | number;
  onClick: () => void;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 250,
    justifyContent: 'center',
    marginTop: 24,
  },
  title: {
    fontWeight: 500,
    marginBottom: 2,
  },
}));

function StatCard(props: PropsWithChildren<StatCard>) {
  const { title, subtitle, value, onClick, className } = props;
  const classes = useStyles();

  return (
    <CommonSmallCard className={clsx(classes.root, className)} onClick={onClick}>
      <Typography align="center" className={classes.title} variant="h2">
        {title}
      </Typography>
      <Typography align="center" color="textSecondary">
        {subtitle}
      </Typography>
      <Typography
        style={{ color: PALETTE().green }}
        align="center"
        variant="h2"
        color="textSecondary"
      >
        {value}
      </Typography>
    </CommonSmallCard>
  );
}

export default StatCard;
