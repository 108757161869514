import * as React from 'react';
import { DefaultLinkFactory } from '@projectstorm/react-diagrams';
import { ArrowLinkWidget } from './ArrowLinkWidget';
import { ArrowLinkModel } from './ArrowLinkModel';

export class ArrowLinkFactory extends DefaultLinkFactory {
  constructor() {
    super('advanced');
  }

  generateModel() {
    return new ArrowLinkModel();
  }

  generateReactWidget(event) {
    return <ArrowLinkWidget link={event.model} diagramEngine={this.engine} />;
  }
}
