import { StepOptions } from './types';

export const tourOptions: StepOptions = {
  sideMenu: {
    center: {
      placement: 'center',
    },
    support: {
      placement: 'left',
    },
  },
  ideaView: {
    startRecruitingButton: {
      disableBeacon: true,
    },
  },
  ideaViewDraft: {
    templateStatusLabel: {
      disableBeacon: true,
    },
  },
  ideaForm: {
    form: {
      disableBeacon: true,
      placement: 'right',
    },
  },
  startRecruitingForm: {
    settings: {
      disableBeacon: true,
    },
  },
  workspace: {
    center: {
      placement: 'center',
    },
    menu: {
      spotlightPadding: 6,
    },
    menuProjectInfo: {
      spotlightPadding: 0,
    },
    menuPlan: {
      spotlightPadding: 0,
    },
    menuChat: {
      spotlightPadding: 0,
    },
    menuKanban: {
      spotlightPadding: 0,
    },
  },
  kanbanBoard: {
    center: {
      placement: 'center',
      disableBeacon: true,
    },
    firstCard: {
      placement: 'right-start',
      spotlightPadding: 6,
    },
    center2: {
      placement: 'center',
    },
  },
  charter: {
    center: {
      placement: 'center',
      disableBeacon: true,
    },
    center2: {
      placement: 'center',
    },
  },
  kanbanCard: {
    center: {
      placement: 'center',
      disableBeacon: true,
    },
  },
  projectInfo: {
    visibilityLabel: {
      disableBeacon: true,
    },
  },
  plan: {
    visibilityToggle: {
      disableBeacon: true,
    },
  },
  orgManageInfo: {
    center: {
      placement: 'center',
      disableBeacon: true,
    },
  },
  orgManageUsers: {
    addUserBtn: {
      disableBeacon: true,
    },
  },
  addUserPage: {
    emailField: {
      disableBeacon: true,
    },
  },
  userInfoStat: {
    center: {
      placement: 'center',
      disableBeacon: true,
    },
  },
  orgManageStat: {
    center: {
      disableBeacon: true,
      placement: 'center',
    },
  },
  orgManageSetting: {
    moderation: {
      disableBeacon: true,
    },
  },
};
