import React, { useRef, useEffect } from 'react';
import { updateSvg } from 'jdenticon';

const Jdenticon = ({ value = 'test', size = '100%' }) => {
  const icon = useRef(null);
  useEffect(() => {
    if (icon.current) {
      // @ts-ignore
      updateSvg(icon.current, value);
    }
  }, [value]);

  return (
    <div>
      <svg data-jdenticon-value={value} height={size} ref={icon} width={size} />
    </div>
  );
};

export default Jdenticon;
