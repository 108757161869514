import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Typography, { TypographyProps } from '@material-ui/core/Typography';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

import { makeTextEllipsis } from '../utils/utils';
import { CUSTOM_SHADOWS, PALETTE } from '../utils/palette';

interface TypographyWithTooltipAndEllipsis {
  className?: string;
  title: string;
  symbolsNumber: number;
  typographyProps?: TypographyProps;
  toolTipProps?: TooltipProps;
}

interface StyleProps {
  disableTooltip: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  tooltip: {
    display: (props: StyleProps) => (props.disableTooltip ? 'none' : 'flex'),
    backgroundColor: PALETTE().white,
    color: PALETTE().black,
    maxWidth: 450,
    fontSize: 16,
    padding: 16,
    borderRadius: 16,
    boxShadow: CUSTOM_SHADOWS.gray.elevation10,
    whiteSpace: 'break-spaces',
    wordBreak: 'break-all',
  },
  arrow: {
    display: (props: StyleProps) => (props.disableTooltip ? 'none' : 'flex'),
    color: PALETTE().white,
  },
  title: {
    whiteSpace: 'break-spaces',
    wordBreak: 'break-word',
  },
}));

function TypographyWithTooltipAndEllipsis(
  props: PropsWithChildren<TypographyWithTooltipAndEllipsis>,
) {
  const { className, symbolsNumber, title, typographyProps, toolTipProps } = props;

  const disableTooltip = title.length < symbolsNumber;

  const classes = useStyles({ disableTooltip });

  return (
    <div className={clsx(classes.root, className)}>
      <Tooltip
        placement="top"
        arrow
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        title={title}
        {...toolTipProps}
      >
        <Typography className={classes.title} {...typographyProps}>
          {makeTextEllipsis(symbolsNumber, title)}
        </Typography>
      </Tooltip>
    </div>
  );
}

export default TypographyWithTooltipAndEllipsis;
