import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import StarsIcon from '@material-ui/icons/Stars';
import ChatSkeleton from '../../../components/Skeletons/ChatTeamSkeleton';
import UserInfoBlock from '../../../components/UserInfoBlock';

import { COMPONENT_STATES } from '../../../utils/componentState';
import { User } from '../../../utils/types';
import { PALETTE } from '../../../utils/palette';

const useStyles = makeStyles((theme: Theme) => ({
  teamPaper: {
    padding: 12,
  },
  mentorBlock: {
    display: 'flex',
  },
  mentorIcon: {
    marginLeft: 12,
    color: PALETTE().gray,
  },
  user: {
    marginBottom: 20,
  },
  warning: {
    fontWeight: 500,
    width: 350,
    marginTop: 100,
    color: PALETTE().red,
  },
}));

function ChatMembers() {
  const classes = useStyles();
  const { projectInfoComponentState, projectData } = useSelector(
    (state: any) => state.workspaceProjectInfo,
  );

  return (
    <div>
      <div className={classes.teamPaper}>
        <Typography variant="h6" paragraph>
          Участники:
        </Typography>
        {projectInfoComponentState === COMPONENT_STATES.CONTENT ? (
          <>
            <div className={clsx(classes.mentorBlock, classes.user)}>
              <UserInfoBlock user={projectData.mentor} />
              <StarsIcon className={classes.mentorIcon} />
            </div>
            {projectData.team.map((member: User) => (
              <UserInfoBlock className={classes.user} key={member.id} user={member} />
            ))}
          </>
        ) : (
          <ChatSkeleton />
        )}
        <Typography className={classes.warning} variant="subtitle2" color="textSecondary">
          Сообщения хранятся 72 часа с момента отправки, а затем удаляются. Храните важную
          информацию в карточках на доске-задач!
        </Typography>
      </div>
    </div>
  );
}

export default ChatMembers;
