import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import ViewSelectState from '../../../components/select-state/ViewSelectState';
import PageWrapper from '../../../components/Wrapper';
import SlimBoxTip from '../../../components/tips/SlimBoxTip';
import TemplateCardFull from '../components/TemplateFullCard';
import TilesGridWrapper from '../../../components/TilesGridWrapper';

import { fetchPersonalProjectsTemplatesList } from './domain';
import Paths from '../../../utils/route-paths';
import { STRINGS } from '../../../utils/constants';

function PersonalProjectsTemplatesTiles(props) {
  const { history } = props;
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.app);
  const { componentState, projectTemplates, isLastPage } = useSelector(
    (state: any) => state.personalProjectTemplatesList,
  );
  const { personalTemplates } = STRINGS;
  const makeNewIdea = () => history.push({ pathname: `${Paths.COMMON_IDEAS}/new`, state: {} });

  const tipProps = {
    ...personalTemplates,
    buttonAction: makeNewIdea,
  };

  useEffect(() => {
    if (user) {
      dispatch(fetchPersonalProjectsTemplatesList({ isFirstLoading: true, userId: user.id }));
    }
  }, [dispatch, user]);

  const handleLoadMore = () => {
    dispatch(fetchPersonalProjectsTemplatesList({ iisFirstLoading: false, userId: user.id }));
  };

  const content = () => (
    <div>
      <SlimBoxTip {...tipProps} />
      <TilesGridWrapper isLastPage={isLastPage} loadMore={handleLoadMore}>
        {projectTemplates.map((pt: any) => (
          <Grid key={pt.id} item>
            <TemplateCardFull template={pt} isPersonal={true} />
          </Grid>
        ))}
      </TilesGridWrapper>
    </div>
  );

  return (
    <PageWrapper header="Мои идеи проектов">
      <ViewSelectState
        state={componentState}
        content={content}
        noDataText={'У вас еще нет идей для проекта'}
        noDataButtonText={'Создать идею'}
        noDataButtonAction={makeNewIdea}
      />
    </PageWrapper>
  );
}

export default PersonalProjectsTemplatesTiles;
