const USER_REGISTRATION_PATH = '/new-user-registration';

const Paths = {
  PERSONAL_IDEAS: '/personal-ideas',
  COMMON_IDEAS: '/common-ideas',
  LOGIN: '/login',
  START_NEW_PROJECT: '/start-new-project',
  PERSONAL_PROJECTS: '/my-projects',
  COMMON_RECRUITING_PROJECTS: '/common-recruiting-projects',
  PERSONAL_RECRUITING_PROJECTS: '/personal-recruiting-projects',
  APPLICATION_TO_JOIN_PROJECT: '/application-to-join-project',
  APPLICATION_VIEW: '/application-view',
  RESET_PASSWORD: '/auth/reset-password',
  RESET_PASSWORD_CONFIRM: '/auth/reset-password-confirm/:uid/:token',
  PROJECT_WORKSPACE: '/project-workspace',
  ORGANIZATION: '/organization',
  ORGANIZATION_USER: '/organization-user',
  ORGANIZATION_STATISTICS_IDEAS: '/organization-statistics-ideas',
  ORGANIZATION_STATISTICS_PROJECTS: '/organization-statistics-projects',
  ORGANIZATION_STATISTICS_RECRUITINGS: '/organization-statistics-recruitings',
  USER_PROFILE: '/my-profile',
  USER_PROFILE_FORM: '/my-profile/form',
  EDIT_IDEA_BEFORE_RECRUITING: '/edit-idea-before-recruiting',
  USER_REGISTRATION_PATH,
  NEW_USER_REGISTRATION: `${USER_REGISTRATION_PATH}/:key`,
  PUBLIC_USER_PROFILE: '/public-profile',
  PUBLIC_USER_PROJECTS: '/public-user-projects',
  PUBLIC_USER_IDEAS: '/public-user-ideas',
  PUBLIC_USER_RECRUITING: '/public-user-recruiting',
  SOCIAL_LOGIN: '/social_login',
  PUBLIC_ORGANIZATION: '/organization-page',
  PUBLIC_ORGANIZATION_IDEAS: '/public-organization-ideas',
  PUBLIC_ORGANIZATION_PROJECTS: '/public-organization-projects',
  PUBLIC_ORGANIZATION_RECRUITINGS: '/public-organization-recruitings',
  JOIN_PROJECT_PATH: '/join-project',
  COMMUNITY: '/community',
  PROJECT_SETTINGS: '/project-settings',
};

export default Paths;
