import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import ProjectApplicationsList from './ProjectApplicationsList';
import ViewSelectState from '../../../../components/select-state/ViewSelectState';
import SlimBoxTip from '../../../../components/tips/SlimBoxTip';
import { fetchWorkspaceProject } from '../../domain/reducer';
import { RootState } from '../../../../store/rootReducer';

interface ProjectApplications {
  projectId: string;
}

const useStyles = makeStyles(() => ({
  root: {
    width: 800,
    height: 634,
    display: 'flex',
  },
  contentLeft: {
    minWidth: 420,
    marginRight: 78,
  },
  noAppTip: {
    width: 660,
  },
}));

function ProjectApplications({ projectId }: ProjectApplications) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { applicationsData, projectInfoComponentState } = useSelector(
    (state: RootState) => state.workspaceProjectInfo,
  );
  const { user } = useSelector((state: any) => state.app);

  useEffect(() => {
    // тянем весь проект потому что эта информация нужна для работы с заявками
    if (user) {
      dispatch(
        fetchWorkspaceProject({
          userId: user.id,
          projectId,
        }),
      );
    }
  }, [dispatch, projectId, user]);

  const content = () => {
    return (
      <div className={classes.root}>
        <div className={classes.contentLeft}>
          {!applicationsData.content.length && (
            <SlimBoxTip
              className={classes.noAppTip}
              mainText="У вас нет заявок на вступление в проект"
            />
          )}
          <ProjectApplicationsList applications={applicationsData.content} />
        </div>
      </div>
    );
  };

  return <ViewSelectState state={projectInfoComponentState} content={content} />;
}

export default ProjectApplications;
