import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { COMPONENT_STATES } from '../../../utils/componentState';
import { errorHandler } from '../../../utils/fetchUtils';
import Urls from '../../../utils/endpoints';
import { ComponentState, User } from '../../../utils/types';
import { getUser } from '../../user/domain';
import { EMPTY_USER } from '../../../utils/constants';

interface CheckTokenResponse {
  id: string;
  name: string;
  goal: string;
  mentor: {
    id: string;
    status: string;
    type: string;
    role: string;
    firstName: string;
    middleName: string;
    lastName: string;
    avatar: string;
  };
}

// dal
const checkToken = (token) => axios.post(`${Urls.PROJECTS}/check-token`, { token });
const joinToProject = (token) => axios.post(`${Urls.PROJECTS}/join`, { token });

// saga
function* checkTokenRequest(action) {
  try {
    const { token } = action.payload;
    const { data } = yield call(checkToken, token);
    const { data: fullMentor } = yield call(getUser, data.mentor.id);
    yield put(fetchCheckTokenSuccess({ data, fullMentor }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchCheckTokenFail());
  }
}

function* projectJoinRequest(action) {
  try {
    const { token } = action.payload;
    const { data } = yield call(joinToProject, token);
    toast.success('Вы вступили в проект');
    yield put(fetchJoinProjectSuccess({ data }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchJoinProjectFail());
  }
}

export function* watchNewUserJoinToProject() {
  yield takeEvery(fetchCheckToken.type, checkTokenRequest);
  yield takeEvery(fetchJoinProject.type, projectJoinRequest);
}

// reducer
export interface JoinNewMemberState {
  componentState: ComponentState;
  projectData: CheckTokenResponse;
  fullMentor: User;
  accepted: boolean;
  request: boolean;
}

const initialState: JoinNewMemberState = {
  componentState: COMPONENT_STATES.LOADING,
  projectData: {
    id: '',
    name: '',
    goal: '',
    mentor: {
      id: '',
      status: '',
      type: '',
      role: '',
      firstName: '',
      middleName: '',
      lastName: '',
      avatar: '',
    },
  },
  fullMentor: EMPTY_USER,
  accepted: false,
  request: false,
};

const newUserJoinToProjectSlice = createSlice({
  name: 'newUserJoinToProject',
  initialState,
  reducers: {
    fetchCheckToken(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
    },
    fetchCheckTokenSuccess(state, action) {
      const { data, fullMentor } = action.payload;
      state.projectData = data;
      state.fullMentor = fullMentor;
      state.componentState = COMPONENT_STATES.CONTENT;
    },
    fetchCheckTokenFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
    fetchJoinProject(state, action) {
      state.request = true;
    },
    fetchJoinProjectSuccess(state, action) {
      state.accepted = true;
      state.request = false;
    },
    fetchJoinProjectFail(state) {
      state.request = false;
    },
  },
});

export const {
  fetchCheckToken,
  fetchCheckTokenSuccess,
  fetchCheckTokenFail,
  fetchJoinProject,
  fetchJoinProjectSuccess,
  fetchJoinProjectFail,
} = newUserJoinToProjectSlice.actions;

export default newUserJoinToProjectSlice.reducer;
