import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { COMPONENT_STATES } from '../../../utils/componentState';
import { CURRENT_PUBLIC_HOST, EMPTY_PROJECT, WORKSPACE_TABS } from '../../../utils/constants';
import Paths from '../../../utils/route-paths';
import { WorkspaceTabsIndexes } from '../../../utils/types';

const emptyApplicationData = {
  totalElements: 0,
  totalPages: 0,
  pageSize: 0,
  last: false,
  content: [],
};

// reducer
const initialState = {
  projectInfoComponentState: COMPONENT_STATES.LOADING,
  projectData: EMPTY_PROJECT,
  organization: null,
  isProjectMember: false,
  hasApplication: false,
  applicationsData: emptyApplicationData,
  acceptedApplications: [],
  projectJoinLink: '',
  request: false,
  errorData: {},
  requestSendApp: false,
  selectedTab: WORKSPACE_TABS.description,
};

const projectSlice = createSlice({
  name: 'workspaceProjectInfo',
  initialState,
  reducers: {
    fetchWorkspaceProject(state, action) {
      state.projectInfoComponentState = COMPONENT_STATES.LOADING;
      state.request = true;
    },
    fetchWorkspaceProjectSuccess(state, action) {
      const {
        data,
        organization,
        hasApplication,
        applicationsData,
        isProjectMember,
        fromPush,
      } = action.payload;
      if (!fromPush) {
        state.selectedTab = isProjectMember ? 5 : 0;
      }
      state.projectData = data;
      state.isProjectMember = isProjectMember;
      state.organization = organization;
      state.hasApplication = !!hasApplication;
      if (applicationsData) {
        state.applicationsData = applicationsData;
      }
      state.projectInfoComponentState = COMPONENT_STATES.CONTENT;
      state.request = false;
    },
    fetchWorkspaceProjectFail(state, action) {
      state.projectInfoComponentState = COMPONENT_STATES.ERROR;
      state.errorData = action.payload;
      state.request = false;
    },
    resetIsProjectMember(state) {
      state.isProjectMember = false;
    },
    fetchProjectJoinToken(state, action) {
      state.request = true;
    },
    fetchProjectJoinTokenSuccess(state, action) {
      const { token } = action.payload;
      state.projectJoinLink = `${CURRENT_PUBLIC_HOST}${Paths.JOIN_PROJECT_PATH}/${token}`;
      state.request = false;
    },
    fetchProjectJoinTokenFail(state) {
      state.request = false;
    },
    fetchKickMember(state, action) {
      state.request = true;
    },
    fetchKickMemberSuccess(state, action) {
      const { data } = action.payload;
      state.projectData = data;
      state.request = false;
    },
    fetchKickMemberFail(state) {
      state.request = false;
    },
    fetchSendAppToJoinProject(state, action) {
      state.requestSendApp = true;
    },
    fetchSendAppToJoinProjectSuccess(state) {
      state.hasApplication = true;
      state.requestSendApp = false;
    },
    fetchSendAppToJoinProjectFail(state) {
      state.requestSendApp = false;
    },
    setSelectedTab(state, action: PayloadAction<WorkspaceTabsIndexes>) {
      // стартовая вкладка:   0 - описание, 1 - устав, 2 - список работ, 3 - чат, 4 - канбан,
      state.selectedTab = action.payload;
    },
    fetchUpdateProject(state, action) {
      state.request = true;
    },
    fetchUpdateProjectSuccess(state, action) {
      const { data } = action.payload;
      state.projectData = data;
      state.request = false;
    },
    fetchUpdateProjectFail(state) {
      state.request = false;
    },
    setApplicationList(state, action) {
      const { data } = action.payload;
      state.applicationsData = data;
    },
  },
});

export const {
  fetchWorkspaceProject,
  resetIsProjectMember,
  fetchProjectJoinToken,
  fetchKickMember,
  fetchSendAppToJoinProject,
  fetchUpdateProject,

  fetchWorkspaceProjectSuccess,
  fetchWorkspaceProjectFail,
  fetchProjectJoinTokenSuccess,
  fetchProjectJoinTokenFail,
  fetchKickMemberSuccess,
  fetchKickMemberFail,
  fetchSendAppToJoinProjectSuccess,
  fetchSendAppToJoinProjectFail,
  setSelectedTab,
  fetchUpdateProjectSuccess,
  fetchUpdateProjectFail,
  setApplicationList,
} = projectSlice.actions;

export default projectSlice.reducer;
