import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DoneIcon from '@material-ui/icons/Done';
import ListItemText from '@material-ui/core/ListItemText';
import StandardDialog from '../../../../components/StandardDialog';

import { PALETTE } from '../../../../utils/palette';
import { TIPS } from '../../../../utils/constants';
import Urls from '../../../../utils/endpoints';

interface TeamRecruitingTipDialog {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  dialog: {
    width: 1030,
    height: 320,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    paddingLeft: 8,
    paddingTop: 0,
  },
  image: {
    height: 310,
  },
  button: {
    width: 256,
  },

  tipBox: {
    height: 290,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tipList: {
    width: 500,
  },
  tipTitle: {
    width: 500,
    fontWeight: 600,
  },
}));

function TeamRecruitingTipDialog({ open, onClose }: TeamRecruitingTipDialog): JSX.Element {
  const classes = useStyles();

  return (
    <StandardDialog className={classes.dialog} open={open} onClose={onClose}>
      <img className={classes.image} src={Urls.ASSET_IMAGE('mentor_hbupfm')} alt="mentor_img" />
      <div className={classes.tipBox}>
        <Typography className={classes.tipTitle} align="center">
          При формировании команд важно учитывать следующие критерии для обеспечения их
          эффективности:
        </Typography>
        <List className={classes.tipList}>
          {TIPS.projectStartTip.body.map((text, i) => (
            <ListItem key={i} alignItems="center" dense>
              <ListItemIcon style={{ paddingLeft: 20 }}>
                <DoneIcon fontSize="small" style={{ color: PALETTE().gray }} />
              </ListItemIcon>
              <ListItemText
                primary={text}
                primaryTypographyProps={{ variant: 'subtitle2', color: 'textSecondary' }}
              />
            </ListItem>
          ))}
        </List>
        <Button
          className={classes.button}
          size="large"
          variant="contained"
          color="secondary"
          onClick={onClose}
        >
          Закрыть
        </Button>
      </div>
    </StandardDialog>
  );
}

export default TeamRecruitingTipDialog;
