import { call, put, take, all, takeLatest, takeEvery } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { COMPONENT_STATES } from '../../../utils/componentState';
import { isLoggedIn } from '../../../utils/auth';
import { uploadCanvasToBlobImage } from '../../../utils/dal';
import { Image } from '../../../utils/types';
import { errorHandler } from '../../../utils/fetchUtils';

const SOCKET_HOST = process.env.REACT_APP_CHAT_SOCKET_HOST;

// закомментированый код относится к подгузке истории по скроллу

// dal
// const getHistory = (projectId, timestamp, size = 10) =>
//   axios.get(`app/projects/${projectId}/history`, { params: { size, timestamp } });

// saga

const getMessageChannel = channel();

function createSocketChannel() {
  return new Promise((resolve, reject) => {
    const token = isLoggedIn();
    const ws = new window.WebSocket(`${SOCKET_HOST}?token=${token}`);
    ws.onopen = () => resolve(ws);
  });
}

export function* watchGetMessageChanel() {
  while (true) {
    // ловим экшен с сообщением и передаем в ридакс
    const action = yield take(getMessageChannel);
    yield put(action);
  }
}

function joinChat(client, projectId) {
  return new Promise((resolve, reject) => {
    client.onmessage = ({ data }) => {
      const parsedData = JSON.parse(data);
      if (parsedData.join === projectId) {
        resolve(parsedData);
      }
    };
  });
}

function closeChannel(client) {
  client.close();
}

function* sendingMessage(client, projectId, action) {
  try {
    const { message, code, attachment } = action.payload;
    const msg = { command: 'send', room: projectId, message, code, image_ids: [] as string[] };
    if (attachment) {
      try {
        const { image, imageName } = attachment;
        const data = yield call(uploadCanvasToBlobImage, image, imageName);
        if (data) {
          msg.image_ids.push(data.id);
        } else {
          throw Error('Ошибка загрузки изображения');
        }
      } catch (e) {
        errorHandler(e);
      }
    }
    client.send(JSON.stringify(msg));
  } catch (e) {
    errorHandler(e);
  }
}

function* startChat(action) {
  try {
    const { projectId } = action.payload;
    const client = yield call(createSocketChannel);
    const join = JSON.stringify({ command: 'join', room: `${projectId}` });
    yield call(() => client.send(join));
    const { history } = yield call(joinChat, client, projectId);
    yield put(initConnectionSuccess({ history, projectId }));
    client.onmessage = ({ data }) => {
      const { message } = JSON.parse(data);
      // кладем экшен в канал, что бы поймать его в вотчере
      getMessageChannel.put(onGetMessage(message));
    };
    yield all([
      watchGetMessageChanel(),
      yield takeLatest(sendMessage.type, sendingMessage, client, projectId),
      yield takeEvery(closeChatChannel.type, closeChannel, client),
    ]);
  } catch (e) {
    errorHandler(e);
    yield put(initConnectionFail());
  }
}

export function* watchChatWorking() {
  yield all([yield takeLatest(initConnection.type, startChat)]);
}

export interface MessageData {
  id: string;
  message: string;
  timestamp: any;
  author_id: string;
  code: string;
  images: Image[];
}

interface SelfMessageKeys {
  [key: string]: number;
}

// reducer
const initialState = {
  chatState: COMPONENT_STATES.LOADING,
  messages: [] as MessageData[],
  selfMessageKeys: {} as SelfMessageKeys,
  historyMessages: [] as MessageData[],
  // historyLoading: false,
  // isHistoryEnd: false,
  projectId: null,
};

const chatSlice = createSlice({
  name: 'workspaceChat',
  initialState,
  reducers: {
    initConnection(state, action) {
      state.chatState = COMPONENT_STATES.LOADING;
      state.messages = [];
    },
    initConnectionSuccess(state, action) {
      const { history, projectId } = action.payload;
      state.messages = [...history];
      state.chatState = COMPONENT_STATES.CONTENT;
      state.projectId = projectId;
    },
    initConnectionFail(state) {
      state.chatState = COMPONENT_STATES.ERROR;
      state.projectId = null;
    },
    sendMessage(state, action) {
      const { code } = action.payload;
      const length = state.messages.push(action.payload);
      state.selfMessageKeys = { ...state.selfMessageKeys, [code]: length - 1 };
    },
    sendMessageFail(state) {},
    uploadImageFail(state) {},
    onGetMessage(state, action: PayloadAction<MessageData>) {
      const { code } = action.payload;
      const isSelfMessage = Object.keys(state.selfMessageKeys).includes(code);
      if (isSelfMessage) {
        const index = state.selfMessageKeys[code];
        state.messages[index] = action.payload;
        const { [code]: notNeedVariable, ...restKeys } = state.selfMessageKeys;
        state.selfMessageKeys = restKeys;
      } else {
        state.messages.push(action.payload);
      }
    },
    /*
    loadMessageHistory(state, action) {
      state.historyLoading = true;
    },
    loadMessageHistorySuccess(state, action) {
      const { history } = action.payload;
      if (history.length) {
        state.historyMessages = [...history, ...state.historyMessages];
        state.historyLoading = false;
      } else {
        state.isHistoryEnd = true;
      }
    },
    loadMessageHistoryFail(state) {
      state.historyLoading = false;
    },
    */
    closeChatChannel(state) {
      state = initialState;
    },
  },
});

export const {
  sendMessage,
  onGetMessage,
  initConnection,
  initConnectionSuccess,
  initConnectionFail,
  closeChatChannel,
  sendMessageFail,
  uploadImageFail,
} = chatSlice.actions;

export default chatSlice.reducer;
