import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const TEXT_HEIGHT = 25;

function QuestionsSkeleton() {
  return (
    <>
      <Skeleton
        style={{ marginTop: 20 }}
        variant="text"
        width={'70%'}
        height={TEXT_HEIGHT}
        animation="wave"
      />
      <Skeleton
        style={{ marginTop: 20 }}
        variant="text"
        width={'50%'}
        height={TEXT_HEIGHT}
        animation="wave"
      />
      <Skeleton
        style={{ marginTop: 20 }}
        variant="text"
        width={'70%'}
        height={TEXT_HEIGHT}
        animation="wave"
      />
    </>
  );
}

export default QuestionsSkeleton;
