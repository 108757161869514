import { put, takeEvery, call } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { COMPONENT_STATES } from '../../../../utils/componentState';
import { errorHandler } from '../../../../utils/fetchUtils';
import { ComponentState, Recruiting, User } from '../../../../utils/types';
import { EMPTY_USER, LIST_PAGE_SIZE } from '../../../../utils/constants';
import { getPersonalRecruitingList } from '../../../recruiting/personal-list/domain';
import { getUser } from '../domain';

// dal

// saga
function* publicRecruitingRequest(action) {
  try {
    const { userId, currentPage } = action.payload;
    const { data } = yield call(getPersonalRecruitingList, userId, {
      page: currentPage,
      pageSize: LIST_PAGE_SIZE,
    });
    const { data: userData } = yield call(getUser, userId);
    yield put(fetchPublicUserRecruitingSuccess({ data, userData }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchPublicUserRecruitingFail());
  }
}

export function* watchLoadingPublicUserRecruitings() {
  yield takeEvery(fetchPublicUserRecruiting.type, publicRecruitingRequest);
}

export interface PublicUserRecruitingState {
  componentState: ComponentState;
  recruiting: Recruiting[];
  user: User;
  currentPage: number;
  isLastPage: boolean;
}

// reducer
const initialState: PublicUserRecruitingState = {
  componentState: COMPONENT_STATES.LOADING,
  recruiting: [],
  user: EMPTY_USER,
  currentPage: 1,
  isLastPage: false,
};

const publicUserRecruitingSlice = createSlice({
  name: 'publicUserRecruiting',
  initialState,
  reducers: {
    fetchPublicUserRecruiting(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
      if (action.payload.isFirstLoading) {
        state.currentPage = 1;
        state.recruiting = [];
      }
      action.payload.currentPage = state.currentPage;
    },
    fetchPublicUserRecruitingSuccess(state, action) {
      const { data, userData } = action.payload;
      state.user = userData;
      if (data.content?.length === 0) {
        state.componentState = COMPONENT_STATES.EMPTY;
      } else {
        state.componentState = COMPONENT_STATES.CONTENT;
        state.recruiting = [...state.recruiting, ...data.content];
        state.currentPage += 1;
        state.isLastPage = data.last;
      }
    },
    fetchPublicUserRecruitingFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const {
  fetchPublicUserRecruiting,
  fetchPublicUserRecruitingSuccess,
  fetchPublicUserRecruitingFail,
} = publicUserRecruitingSlice.actions;

export default publicUserRecruitingSlice.reducer;
