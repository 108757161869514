import { Step } from 'react-joyride';
import { TourClassNamesAndContent, TourSteps, TourPages } from './types';
import tourStrings from '../../tourStrings.json';
import { TOURS_CLASS_NAMES } from './classNames';
import { tourOptions } from './options';

export const AUTO_TOUR_START_DELAY = 1000;
export { TOURS_CLASS_NAMES };

export const TOUR_PAGES: { [key in TourPages]: TourPages } = {
  sideMenu: 'sideMenu',
  ideaView: 'ideaView',
  ideaViewDraft: 'ideaViewDraft',
  ideaForm: 'ideaForm',
  startRecruitingForm: 'startRecruitingForm',
  workspace: 'workspace',
  kanbanBoard: 'kanbanBoard',
  projectInfo: 'projectInfo',
  charter: 'charter',
  plan: 'plan',
  kanbanCard: 'kanbanCard',
  orgManageInfo: 'orgManageInfo',
  orgManageUsers: 'orgManageUsers',
  addUserPage: 'addUserPage',
  userInfoStat: 'userInfoStat',
  orgManageStat: 'orgManageStat',
  orgManageSetting: 'orgManageSetting',
};

export const tourSteps: TourSteps = {
  sideMenu: [
    'center',
    'profile',
    'dashboard',
    'projects',
    'ideas',
    'recruiting',
    'community',
    'help',
    'tour',
    'support',
  ],
  ideaView: ['startRecruitingButton'],
  ideaViewDraft: ['templateStatusLabel', 'sendToModerationButton', 'editIdea'],
  ideaForm: ['form', 'images', 'saveButton'],
  startRecruitingForm: ['settings', 'organizationOnly', 'questions', 'startButton'],
  workspace: [
    'center',
    'menu',
    'menuProjectInfo',
    'menuCharter',
    'menuPlan',
    'menuChat',
    'menuKanban',
  ],
  kanbanBoard: [
    'center',
    'firstCard',
    'toDoList',
    'inProgressList',
    'toCheckList',
    'doneList',
    'fineTuningList',
    'center2',
  ],
  projectInfo: [
    'visibilityLabel',
    'timeCounter',
    'organizationBanner',
    'welcomeLincButton',
    'kickOffButton',
  ],
  charter: ['center', 'startMasterButton', 'templateList', 'approveCharterButton', 'center2'],
  plan: ['visibilityToggle', 'addToBoardButton'],
  kanbanCard: [
    'center',
    'needTodoField',
    'responsiblePerson',
    'taskEndDate',
    'expectedResult',
    'description',
    'addAttachment',
    'comments',
  ],
  orgManageInfo: ['center'],
  orgManageUsers: ['addUserBtn', 'copyLink', 'userTableFirsRow'],
  addUserPage: ['emailField'],
  userInfoStat: [
    'center',
    'lastActivity',
    'ideasMenuItem',
    'recruitingsMenuItem',
    'projectsMenuItem',
    'kickOffMenuItem',
  ],
  orgManageStat: ['center'],
  orgManageSetting: ['moderation'],
};

const typedTourContent: TourClassNamesAndContent = tourStrings;

export const makeTour = (tourPage: TourPages | null): Step[] => {
  if (!tourPage) {
    return [];
  }
  return tourSteps[tourPage].map(
    (step): Step => {
      // @ts-ignore
      const tourStepOptions = tourOptions[tourPage] ? tourOptions[tourPage][step] : undefined;
      const tourStep = {
        target: `.${TOURS_CLASS_NAMES[tourPage][step]}`,
        content: typedTourContent[tourPage] ? typedTourContent[tourPage][step] : '',
      };
      if (tourStepOptions) {
        return { ...tourStepOptions, ...tourStep };
      }
      return tourStep;
    },
  );
};
