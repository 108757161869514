import React, { Component } from 'react';
import Cropper from 'cropperjs';

import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import IconButton from '@material-ui/core/IconButton';

// это стили кроппера без них не будет работать
// eslint-disable-next-line
let cropperStyles = require('cropperjs/dist/cropper.css');

interface Presets {
  width?: number;
  height?: number;
  aspectRatio?: number;
  autoCropArea?: number;
  maxWidth?: number;
  guides?: boolean;
}

interface CropperProps {
  image: any;
  setCropData: any;
  presets: Presets;
}

class ImageCropper extends Component<CropperProps> {
  private cropper: any;
  private image: any;
  private readonly options: any;

  constructor(props) {
    super(props);
    const { presets } = props;
    this.options = {
      width: presets.width,
      height: presets.height,
      fillColor: '#fff',
    };
  }

  componentDidMount() {
    const { autoCropArea = 0.8, aspectRatio = 1 } = this.props.presets;
    this.cropper = new Cropper(this.image, {
      ...this.props.presets,
      viewMode: 0,
      aspectRatio,
      autoCropArea,
      ready: this.setCropped.bind(this),
      cropend: this.setCropped.bind(this),
    });
  }

  setCropped() {
    // @ts-ignore
    const { setCropData } = this.props;
    const cropData = this.cropper.getCroppedCanvas(this.options);
    setCropData(cropData);
  }

  componentWillUnmount() {
    this.cropper.destroy();
  }

  rotate = (direction) => {
    this.cropper.rotate(direction);

    // @ts-ignore
    const { setCropData } = this.props;
    const cropData = this.cropper.getCroppedCanvas(this.options);
    setCropData(cropData);
  };

  render() {
    const { maxWidth = 300 } = this.props.presets;
    return (
      <div>
        <img
          src={this.props.image}
          ref={(img) => (this.image = img)}
          style={{ maxWidth }}
          alt="cropped_image"
        />
        <div style={{ textAlign: 'center' }}>
          <IconButton
            style={{ marginTop: 10, marginLeft: 5 }}
            onClick={this.rotate.bind(this, -90)}
          >
            <RotateLeftIcon />
          </IconButton>
          <IconButton style={{ marginTop: 10, marginLeft: 5 }} onClick={this.rotate.bind(this, 90)}>
            <RotateRightIcon />
          </IconButton>
        </div>
      </div>
    );
  }
}

export default ImageCropper;
