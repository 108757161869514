import * as React from 'react';
import { PortWidget } from '@projectstorm/react-diagrams';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import GraphTaskTooltip from '../graph-task-tooltip/GraphTaskTooltip';

import { NODE_DIMENSIONS } from '../../utils';
import { CUSTOM_SHADOWS, PALETTE } from '../../../../../utils/palette';

const useStyles = makeStyles(() => ({
  root: {
    height: NODE_DIMENSIONS.height,
    width: NODE_DIMENSIONS.width,
    display: 'flex',
    backgroundColor: (p) => p.backgroundColor,
    borderRadius: 5,
    color: 'white',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: PALETTE().black,
    overflow: 'hidden',
    fontSize: 11,
  },
  textBox: {
    width: NODE_DIMENSIONS.width * 0.8,
    height: NODE_DIMENSIONS.height * 0.8,
    display: 'flex',
    margin: 'auto',
    flexDirection: 'column',
  },
  title: {
    margin: 'auto',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ports: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  port: {
    height: 10,
  },
  portIn: {
    marginLeft: 10,
  },
  portOut: {
    marginRight: 10,
  },
  tooltip: {
    display: (props) => (props.disableTooltip ? 'none' : 'flex'),
    backgroundColor: PALETTE().white,
    color: PALETTE().black,
    maxWidth: 450,
    borderRadius: 16,
    boxShadow: CUSTOM_SHADOWS.gray.elevation10,
  },
}));

export function TaskNodeWidget({ node, engine }) {
  const {
    options,
    options: { name, color },
  } = node;
  const classes = useStyles({ backgroundColor: color });

  return (
    <div className={classes.root}>
      <div className={classes.ports}>
        <div className={clsx(classes.port, classes.portIn)}>
          <PortWidget engine={engine} port={node.getPort('in')}>
            <div className="circle-port" />
          </PortWidget>
        </div>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          placement="top"
          disableTouchListener
          disableFocusListener
          title={<GraphTaskTooltip data={options} />}
        >
          <div className={classes.textBox}>
            <Typography component="div" className={classes.title} variant="caption">
              {name}
            </Typography>
          </div>
        </Tooltip>
        <div className={clsx(classes.port, classes.portOut)}>
          <PortWidget engine={engine} port={node.getPort('out')}>
            <div className="circle-port" />
          </PortWidget>
        </div>
      </div>
    </div>
  );
}
