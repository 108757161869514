import React from 'react';
import clsx from 'clsx';
import compareDesc from 'date-fns/compareDesc';
import addWeeks from 'date-fns/addWeeks';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import {
  RECRUITING_TIME_STATUSES,
  RECRUITING_MODERATION_STATUSES,
  RECRUITING_MODERATION_STATUSES_TITLES,
} from '../../../utils/constants';
import { RECRUITING_STATUSES_PALETTE } from '../../../utils/palette';

const ruLocale = require('date-fns/locale/ru/index.js');

const recruitingStatus = (endDate, status) => {
  if (status === RECRUITING_MODERATION_STATUSES.ACTIVE) {
    const distanceToNow = formatDistanceToNow(new Date(endDate), { locale: ruLocale });
    switch (true) {
      case compareDesc(new Date(endDate), new Date()) > 0:
        return { text: 'рекрутинг завершен', status: RECRUITING_TIME_STATUSES.END };
      case compareDesc(new Date(endDate), addWeeks(new Date(), 1)) > 0:
        return { text: distanceToNow, status: RECRUITING_TIME_STATUSES.LESS_THEN_WEEK };
      default:
        return { text: distanceToNow, status: RECRUITING_TIME_STATUSES.MORE_THEN_WEEK };
    }
  }
  return { text: RECRUITING_MODERATION_STATUSES_TITLES[status], status };
};

interface RecruitingTimeLabelProps {
  endDate: string;
  status: string;
  className?: any;
}

const useStyles = (status) =>
  makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    box: {
      height: 24,
      color: '#fff',
      borderRadius: 4,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: RECRUITING_STATUSES_PALETTE[status],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: 10,
      paddingLeft: 10,
    },
    info: {
      color: RECRUITING_STATUSES_PALETTE[status],
      lineHeight: 0.8,
    },
  }));

function RecruitingStatusLabel({ endDate, status, className }: RecruitingTimeLabelProps) {
  const currentStatus = recruitingStatus(endDate, status);
  const classes = useStyles(currentStatus.status)();

  return (
    <div className={clsx('recruiting-status-label', classes.root, !!className && className)}>
      <div className={classes.box}>
        <Typography variant={'caption'} className={classes.info} display="block">
          {currentStatus.text.toUpperCase()}
        </Typography>
      </div>
    </div>
  );
}

export default RecruitingStatusLabel;
