import React, { useEffect, useState } from 'react';
import { DropzoneDialog } from 'material-ui-dropzone';
import { makeStyles, Theme } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import SendIcon from '@material-ui/icons/Send';
import ClearIcon from '@material-ui/icons/Clear';
import ImageCropper from '../../../../components/ImageLoader/ImageCropper';

import { getBase64 } from '../../../../utils/utils';
import { PALETTE } from '../../../../utils/palette';

interface ChatImageUploaderProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (msg: string, attachment: any) => void;
  clearInput: () => void;
  message: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  sendMessageBlock: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 14,
    paddingLeft: 16,
    width: '97%',
  },
  actionRoot: {
    justifyContent: 'flex-start',
  },
  input: {
    flexGrow: 1,
    backgroundColor: PALETTE(0.1).gray,
    borderRadius: 8,
    paddingTop: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
  iconBox: {
    // display: 'flex',
    // alignItems: 'flex-end',
  },
  closeIcon: {
    position: 'absolute',
    right: 2,
    top: 2,
  },
}));

function ChatImageUploader(props: ChatImageUploaderProps) {
  const { onClose, open, onSubmit, message, clearInput } = props;
  const classes = useStyles();
  const [cropper, setCropper] = useState<any>({ image: null, open: false });
  const [croppedImage, setCroppedImage] = useState(null);
  const [currentMessage, setCurrentMessage] = useState<string>('');

  useEffect(() => {
    setCurrentMessage(message);
  }, [message]);

  const handleCloseUploader = () => {
    onClose();
  };

  const handleCloseImageCropper = () => {
    setCropper({
      image: null,
      open: false,
    });
  };

  const handleStartCropping = (files) => {
    getBase64(files[0]).then((image) =>
      setCropper({
        filename: files[0].name,
        image,
        open: true,
      }),
    );
    onClose();
  };

  const handleSetCroppedData = (data) => {
    setCroppedImage(data);
  };

  const handleChangeCurrentMessage = (e) => {
    setCurrentMessage(e.target.value);
  };

  const handleSubmit = () => {
    if (croppedImage) {
      onSubmit(currentMessage, { image: croppedImage, imageName: cropper.filename });
    }
    handleCloseImageCropper();
    setCurrentMessage('');
    clearInput();
  };

  const onPressEnter = (e) => {
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      if (currentMessage.trim()) {
        handleSubmit();
      }
    }
  };

  return (
    <div>
      <DropzoneDialog
        fileObjects={[]}
        filesLimit={1}
        open={open}
        onDrop={handleStartCropping}
        acceptedFiles={['image/jpeg', 'image/png']}
        showPreviews={false}
        maxFileSize={5000000}
        onClose={handleCloseUploader}
        dialogTitle="Загрузить изображение"
        cancelButtonText="Отмена"
        submitButtonText="Ok"
        dropzoneText="Перетащите изображение на эту область или кликните на неё."
      />
      <Dialog maxWidth="xl" onClose={handleCloseImageCropper} open={cropper.open}>
        <IconButton className={classes.closeIcon} onClick={handleCloseImageCropper}>
          <ClearIcon />
        </IconButton>
        <DialogTitle>Просмотр и редактирование</DialogTitle>
        <DialogContent>
          <ImageCropper
            presets={{ maxWidth: 300, aspectRatio: NaN, autoCropArea: 1, guides: false }}
            image={cropper.image}
            setCropData={handleSetCroppedData}
          />
        </DialogContent>
        <DialogActions classes={{ root: classes.actionRoot }}>
          <div className={classes.sendMessageBlock}>
            <InputBase
              className={classes.input}
              onKeyPress={onPressEnter}
              fullWidth
              id="messageText"
              name="messageText"
              value={currentMessage}
              onChange={handleChangeCurrentMessage}
              placeholder="Добавить подпись..."
              multiline
              rowsMax={6}
            />
            <div className={classes.iconBox}>
              <IconButton
                disabled={!currentMessage.trim()}
                color="primary"
                edge="end"
                onClick={handleSubmit}
              >
                <SendIcon />
              </IconButton>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ChatImageUploader;
