import { toast } from 'react-toastify';

export function extractErrorMessage(error) {
  console.log('Error', { ...error });
  console.log('Error', error);
  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data;
  } else if (error.request) {
    return { message: 'Ошибка соединения' };
  } else {
    return { message: 'Ошибка выполнения запроса' };
  }
}

interface ServerValidationError {
  fields: string[];
  type: string;
  message: string;
}

export function errorHandler(error) {
  if (process.env.NODE_ENV !== 'production') {
    console.log('Error', { ...error });
    console.log('Error', error);
  }

  if (error?.response?.data?.errors) {
    const { errors } = error.response.data;

    if (Array.isArray(errors)) {
      errors.forEach((error: ServerValidationError) => {
        toast.error(error.message);
      });
      return;
    }

    if (typeof errors === 'object' && Object.keys(errors).includes('non_field_errors')) {
      errors['non_field_errors'].forEach((err) => {
        toast.error(err);
      });
      return;
    }
  }

  let errorMessage = 'Ошибка выполнения запроса';
  if (error?.response?.data?.message) {
    errorMessage = error.response.data.message;
  } else if (error?.message) {
    errorMessage = error.message;
  } else if (error?.request) {
    errorMessage = 'Ошибка соединения';
  }
  toast.error(errorMessage);
}
