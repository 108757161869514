import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 1016,
    height: 'min-content',
    minHeight: '60%',
    maxHeight: '100%',
    padding: theme.spacing(5),
  },
}));

function MainPaper(props): JSX.Element {
  const classes = useStyles();
  const { children } = props;
  return (
    <Paper className={classes.paper} elevation={0}>
      {children}
    </Paper>
  );
}

export default MainPaper;
