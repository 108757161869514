import React, { PropsWithChildren } from 'react';

interface TabPanelProps {
  index: number;
  value: number;
}

function TabPanel(props: PropsWithChildren<TabPanelProps>) {
  const { children, value, index } = props;

  return <>{value === index && children}</>;
}

export default TabPanel;
