import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import ViewSelectState from '../../../../components/select-state/ViewSelectState';
import PageWrapper from '../../../../components/Wrapper';
import ProjectFullCard from '../../../projects/components/ProjectFullCard';
import TilesGridWrapper from '../../../../components/TilesGridWrapper';

import { fetchPublicUserProjects, PublicUserProjectsState } from './domain';
import { makeShortName } from '../../../../utils/utils';

interface PublicUserProjectsProps {}

function PublicUserProjects(props: PublicUserProjectsProps) {
  const dispatch = useDispatch();
  // @ts-ignore
  const { id } = useParams();
  const { componentState, projects, isLastPage, user }: PublicUserProjectsState = useSelector(
    (state: any) => state.publicUserProjects,
  );

  useEffect(() => {
    dispatch(fetchPublicUserProjects({ isFirstLoading: true, userId: id }));
  }, [dispatch, id]);

  const handleLoadMore = () => {
    dispatch(fetchPublicUserProjects({ userId: id }));
  };

  const content = () => {
    return (
      <div>
        <TilesGridWrapper isLastPage={isLastPage} loadMore={handleLoadMore}>
          {projects.map((p: any) => (
            <Grid key={p.id} item>
              <ProjectFullCard project={p} />
            </Grid>
          ))}
        </TilesGridWrapper>
      </div>
    );
  };

  return (
    <PageWrapper header={`Проекты пользователя ${makeShortName(user)}`}>
      <ViewSelectState state={componentState} content={content} />
    </PageWrapper>
  );
}

export default PublicUserProjects;
