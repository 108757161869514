import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';

import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { Copyright } from '../../../components/Copyright';

import { RegistrationSchema } from './validationSchema';
import Urls from '../../../utils/endpoints';
import { userRegistrationRequesting } from './domain';

const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  middleName: '',
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function NewUserRegistration(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  // @ts-ignore
  const { key } = useParams();
  const { request } = useSelector((state: any) => state.newUserRegistration);
  const [approved, setApproved] = useState(false);

  const onSubmit = (values) => {
    values.key = key;
    dispatch(userRegistrationRequesting({ values, history }));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={Urls.ASSET_IMAGE('logo_aw3bb5')} alt="logo" />
        <Typography component="h1" variant="h5">
          Регистрация
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={RegistrationSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, isValid, errors, dirty }) => {
            return (
              <Form>
                <TextField
                  error={!!errors.email}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                  helperText={errors.email}
                />
                <TextField
                  error={!!errors.firstName}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="firstName"
                  label="Имя"
                  id="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  helperText={errors.firstName}
                />
                <TextField
                  error={!!errors.lastName}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="lastName"
                  label="Фамилия"
                  id="firstName"
                  value={values.lastName}
                  onChange={handleChange}
                  helperText={errors.lastName}
                />
                <TextField
                  error={!!errors.middleName}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="middleName"
                  label="Отчество"
                  id="middleName"
                  value={values.middleName}
                  onChange={handleChange}
                  helperText={errors.middleName}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={request || !isValid || !approved || !dirty}
                >
                  Зарегистрироваться
                </Button>
                <Grid container>
                  <Grid item xs={2}>
                    <Checkbox
                      onChange={() => setApproved((prevState) => !prevState)}
                      checked={approved}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <div>
                      <Link href={'https://eduteam.ru/eula'} variant="body2">
                        Вы подтверждаете своё согласие с пользовательским соглашением
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default NewUserRegistration;
