import React, { PropsWithChildren } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';

interface FieldWrapperProps {
  className?: string;
  label: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  fieldWrapperRoot: {
    marginBottom: 20,
  },
}));

function FieldWrapper({ children, className, label }: PropsWithChildren<FieldWrapperProps>) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.fieldWrapperRoot, !!className && className)}>
      <Typography variant="subtitle2" color="textSecondary" gutterBottom>
        {label}
      </Typography>
      {children}
    </div>
  );
}

export default FieldWrapper;
