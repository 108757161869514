import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import ProjectsBlock from './ProjectsBlock';
import TemplatesBlock from '../ideas/TemplatesBlock';
import RecruitingBlock from '../recruiting/RecruitingBlock';
import SlimBoxTip from '../../../../components/tips/SlimBoxTip';

import { Project, Recruiting, Template } from '../../../../utils/types';
import Paths from '../../../../utils/route-paths';
import { STRINGS } from '../../../../utils/constants';

interface DashboardProjectsProps {
  templates: Template[];
  recruiting: Recruiting[];
  projects: Project[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

function DashboardProjects(props: DashboardProjectsProps): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const { templates, recruiting, projects } = props;
  const {
    dashboardProjectsOnly,
    dashboardOneRecruitingMentor,
    dashboardOneRecruitingApplicationSent,
  } = STRINGS;
  const goToNewIdea = () => history.push({ pathname: `${Paths.COMMON_IDEAS}/new`, state: {} });

  let tipProps = {
    ...dashboardProjectsOnly,
    buttonAction: () => history.push({ pathname: `${Paths.COMMON_IDEAS}/new`, state: {} }),
  };

  if (recruiting.length === 1) {
    if (recruiting[0].isMentor) {
      tipProps = {
        ...dashboardOneRecruitingMentor,
        buttonAction: () =>
          history.push({
            pathname: `${Paths.COMMON_RECRUITING_PROJECTS}/${recruiting[0].id}`,
            state: { isPersonal: true },
          }),
      };
    } else if (recruiting[0].applicationSent) {
      tipProps = {
        ...dashboardOneRecruitingApplicationSent,
        buttonAction: goToNewIdea,
      };
    }
  }

  return (
    <div className={classes.root}>
      <SlimBoxTip {...tipProps} />
      <ProjectsBlock data={projects} />
      <RecruitingBlock data={recruiting} />
      <TemplatesBlock data={templates} />
    </div>
  );
}

export default DashboardProjects;
