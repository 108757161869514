import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import InfoIcon from '@material-ui/icons/Info';
import TuneIcon from '@material-ui/icons/Tune';
import OrgMenuItem from '../../components/OrgMenuItem';
import PageWrapper from '../../components/Wrapper';

import UserCommonInfo from './UserCommonInfo';
import NotificationsSettings from './NotificationsSettings';

import { setUserMenuItem } from './domain';
import { PALETTE } from '../../utils/palette';

const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: 'solid',
  },
  divider: {
    width: '100%',
  },
  main: {
    display: 'flex',
    background: '#fff',
  },
  menuList: {
    height: '100%',
    borderRight: 'solid',
    borderRightColor: PALETTE(0.2).gray,
    borderWidth: 1,
  },
  content: {
    paddingTop: 40,
    paddingLeft: 40,
    width: '70%',
    background: '#fff',
  },
  icon: {
    color: PALETTE().gray,
  },
}));

function UserManagement() {
  const classes = useStyles();
  const { menuItem } = useSelector((state: any) => state.selfUserProfile);
  const dispatch = useDispatch();

  const handleClickMenuItem = (item) => {
    dispatch(setUserMenuItem(item));
  };

  return (
    <PageWrapper header="Управление профилем пользователя">
      <Divider className={classes.divider} />
      <div className={classes.main}>
        <div className={classes.menuList}>
          <List>
            <OrgMenuItem
              onClick={() => handleClickMenuItem(0)}
              Icon={<InfoIcon className={classes.icon} />}
              text="Основная информация"
              isSelected={menuItem === 0}
            />
            <OrgMenuItem
              onClick={() => handleClickMenuItem(1)}
              Icon={<TuneIcon className={classes.icon} />}
              text="Настройки уведомлений"
              isSelected={menuItem === 1}
            />
          </List>
        </div>
        <div className={classes.content}>
          {menuItem === 0 && <UserCommonInfo />}
          {menuItem === 1 && <NotificationsSettings />}
        </div>
      </div>
    </PageWrapper>
  );
}

export default UserManagement;
