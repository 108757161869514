import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import SendIcon from '@material-ui/icons/Send';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ChatImageUploader from './ChatImageUploader';

import { PALETTE } from '../../../../utils/palette';

interface ChatInputProps {
  onSubmit: (msg: string, attachment: any) => void;
  attachment?: any;
  message?: string;
  disabled: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  sendMessageBlock: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  input: {
    flexGrow: 1,
    backgroundColor: PALETTE(0.1).gray,
    borderRadius: 8,
    paddingTop: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
  iconBox: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

function ChatInput({ onSubmit, disabled }: ChatInputProps) {
  const classes = useStyles();
  const [currentMessage, setCurrentMessage] = useState<string>('');
  const [openImageUpload, setOpenImageUpload] = useState(false);

  const handleSubmit = () => {
    onSubmit(currentMessage, null);
    setCurrentMessage('');
  };

  const onPressEnter = (e) => {
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      if (currentMessage.trim()) {
        handleSubmit();
      }
    }
  };

  const handleChangeCurrentMessage = (e) => {
    setCurrentMessage(e.target.value);
  };

  return (
    <div className={classes.sendMessageBlock}>
      <div className={classes.iconBox}>
        <IconButton
          style={{ color: PALETTE().gray }}
          disabled={disabled}
          edge="start"
          onClick={() => setOpenImageUpload(true)}
        >
          <AttachFileIcon />
        </IconButton>
      </div>
      <InputBase
        onKeyPress={onPressEnter}
        className={classes.input}
        id="messageText"
        name="messageText"
        value={currentMessage}
        onChange={handleChangeCurrentMessage}
        multiline
        rowsMax={6}
      />
      <div className={classes.iconBox}>
        <IconButton
          disabled={disabled || !currentMessage.trim()}
          color="primary"
          edge="end"
          onClick={handleSubmit}
        >
          <SendIcon />
        </IconButton>
      </div>
      {/* Диалог загрузки вложения */}
      <ChatImageUploader
        open={openImageUpload}
        onClose={() => setOpenImageUpload(false)}
        onSubmit={onSubmit}
        clearInput={() => setCurrentMessage('')}
        message={currentMessage}
      />
    </div>
  );
}

export default ChatInput;
