import { createSlice } from '@reduxjs/toolkit';
import { COMPONENT_STATES } from '../../../../utils/componentState';
import { Attachment } from '../../../../utils/types';

const initialData = {
  id: '',
  name: '',
  goal: '',
  about: '',
  status: '',
  duration: 1,
  images: [] as string[],
  attachments: [] as Attachment[],
  organizationOnly: false,
  needModerate: true,
};

const initialState = {
  componentState: COMPONENT_STATES.LOADING,
  projectTemplateData: initialData,
  requesting: false,
  isEditForm: false,
  errorData: {},
};

const personalProjectTemplateSlice = createSlice({
  name: 'personalProjectTemplate',
  initialState,
  reducers: {
    fetchProjectTemplate(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
      state.isEditForm = false;
    },
    fetchProjectTemplateInitial(state) {
      state.componentState = COMPONENT_STATES.CONTENT;
      state.projectTemplateData = { ...initialState.projectTemplateData };
      state.isEditForm = true;
    },
    fetchProjectTemplateSuccess(state, action) {
      state.projectTemplateData = { ...initialState.projectTemplateData, ...action.payload };
      state.componentState = COMPONENT_STATES.CONTENT;
    },
    fetchProjectTemplateFail(state, action) {
      state.componentState = COMPONENT_STATES.ERROR;
      state.errorData = action.payload;
    },

    startUploadForModeration(state, action) {
      state.requesting = true;
    },

    startUploadingTemplateImage(state, action) {
      state.requesting = true;
    },

    startUploadProjectTemplate(state, action) {
      state.requesting = true;
    },

    uploadProjectTemplateSuccess(state) {
      state.requesting = false;
      state.isEditForm = false;
    },

    startRemoveProjectTemplate(state, action) {
      state.requesting = true;
    },
    toggleEditForm(state) {
      state.isEditForm = !state.isEditForm;
    },
    setLoadingState(state) {
      state.componentState = COMPONENT_STATES.LOADING;
    },
    fetchingSuccess(state) {
      state.requesting = false;
    },
    fetchingEnd(state) {
      state.requesting = false;
    },

    fetchStartProjectFromIdea(state, action) {
      state.requesting = true;
    },
  },
});

export const {
  fetchProjectTemplate,
  fetchProjectTemplateFail,
  fetchProjectTemplateInitial,
  fetchProjectTemplateSuccess,
  startUploadForModeration,
  startUploadingTemplateImage,
  startUploadProjectTemplate,
  uploadProjectTemplateSuccess,
  toggleEditForm,
  startRemoveProjectTemplate,
  setLoadingState,
  fetchingEnd,
  fetchingSuccess,
  fetchStartProjectFromIdea,
} = personalProjectTemplateSlice.actions;

export default personalProjectTemplateSlice.reducer;
