import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';
import { rememberPath } from './domain';
import Paths from '../../utils/route-paths';

function RememberPath(): JSX.Element {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(
    () => {
      dispatch(rememberPath(pathname));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return <Redirect to={{ pathname: Paths.LOGIN }} />;
}

export default RememberPath;
