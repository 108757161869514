/* eslint-disable no-console */
import axios from 'axios';
import {
  call,
  put,
  takeEvery,
  takeLatest,
  take,
  delay as sagaDelay,
  all,
  select,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { errorHandler } from '../utils/fetchUtils';
import { COMPONENT_STATES } from '../utils/componentState';
import Urls from '../utils/endpoints';
import { closeChatChannel } from './workspace/chat/domain';
import { UserV2, AppPlaces } from '../utils/types';
import { TourPages } from '../components/tour/types';
import { setTourShown, clearToken, isLoggedIn } from '../utils/auth';
import UrlsV2 from '../utils/endpointsV2';
import { delay } from './workspace/plan/utils';
import {
  makePathFromNotification,
  removeIEventFromStorage,
  setIEventToStorage,
} from '../utils/utils';
import { EVENTS_STORAGE, NOTIFICATION_UPDATE_DELAY, SHOW_PUSH_DELAY } from '../utils/constants';
import { messaging } from '../firebase';
import { deviceRegistration } from './dashboard/domain';
import { RootState } from '../store/rootReducer';

// dal
export const getUser = () => axios.get(UrlsV2.AUTH_ME);
export const getNotificationsCount = () => axios.get(UrlsV2.NOTIFICATIONS_COUNT);

// saga
function onGetPushMessage() {
  return eventChannel((emitter) => {
    if (messaging) {
      messaging.onMessage((payload) => {
        emitter(payload);
      });
    }
    // unsubscribe
    return () => {};
  });
}

function* processPushMessage(action) {
  const { toast } = action.payload;
  const pushQueue = [] as any[];
  function* putPushInQueue() {
    try {
      const chain = yield call(onGetPushMessage);
      while (true) {
        const payload = yield take(chain);
        pushQueue.push(payload);
        yield put(fetchNotificationCount());
      }
    } catch (e) {
      console.log('receive notification failed: ', e);
    }
  }

  function* showToast() {
    while (true) {
      if (pushQueue.length) {
        const payload = pushQueue.shift();
        const {
          currentPlace: { placeName, data },
        } = yield select((state: RootState) => state.app);
        const { pathKey, pathId, entities } = makePathFromNotification(
          payload.data.ids,
          payload.data.entities,
        );
        const dontShowChatPushForCurrentChat =
          placeName === 'workspaceChat' &&
          pathKey === 'PROJECT' &&
          pathId === data?.projectId &&
          entities.includes('MESSAGE');

        if (!dontShowChatPushForCurrentChat) {
          toast(payload);
        }
      }
      yield sagaDelay(SHOW_PUSH_DELAY);
    }
  }
  yield all([putPushInQueue(), showToast()]);
}

function* requestUser(action) {
  const { history } = action.payload;
  try {
    const { data } = yield call(getUser);
    if (data) {
      yield put(setUser(data));
    } else {
      yield put(unsetUser({ history }));
      history.push('/login');
      yield put(closeChatChannel());
    }
  } catch (e) {
    errorHandler(e);
    yield put(unsetUser({ history }));
    history.push('/login');
  }
}

function* requestNotificationCount(action) {
  try {
    if (isLoggedIn()) {
      const { data } = yield call(getNotificationsCount);
      yield put(updateNotificationsCount({ data }));
      yield call(delay, NOTIFICATION_UPDATE_DELAY);
      yield put(fetchNotificationCount());
    }
  } catch (e) {
    errorHandler(e);
  }
}

function* setPermissionForNotificationRequest() {
  try {
    yield deviceRegistration();
    setIEventToStorage(EVENTS_STORAGE.events.askForNotification);
  } catch (e) {
    console.log('An error occurred while retrieving token. ', e);
  }
}

export function* watchGetSelfUser() {
  yield takeEvery(getSelfUser.type, requestUser);
  yield takeLatest(fetchNotificationCount.type, requestNotificationCount);
  yield takeLatest(startPushListener.type, processPushMessage);
  yield takeEvery(setPermissionForNotification.type, setPermissionForNotificationRequest);
}

export interface AppState {
  componentState: string;
  user: UserV2 | null;
  requestUser: boolean;
  header: string;
  newAvatarId: string;
  avatarUrl: string;
  path: string;
  runTour: boolean;
  tourPages: TourPages[];
  tourScrolling: boolean;
  notificationsCount: { total: number; unread: number };
  rightPanelsState: {
    notifications: boolean;
  };
  currentPlace: AppPlaces;
}

// reducer
const initialState: AppState = {
  componentState: COMPONENT_STATES.LOADING,
  user: null,
  requestUser: false,
  header: '',
  newAvatarId: '',
  avatarUrl: '',
  path: '',
  runTour: false,
  tourPages: [],
  tourScrolling: false,
  notificationsCount: { total: 0, unread: 0 },
  rightPanelsState: {
    notifications: false,
  },
  currentPlace: { placeName: null },
};

const loginSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    getSelfUser(state, action) {
      const { firstLoading } = action.payload;
      if (firstLoading) {
        state.componentState = COMPONENT_STATES.LOADING;
        state.user = null;
      }
      state.requestUser = true;
    },
    setUser(state, action) {
      state.user = action.payload;
      state.avatarUrl = Urls.AVATAR(action.payload.avatar);
      state.componentState = COMPONENT_STATES.CONTENT;
      state.requestUser = false;
    },
    unsetUser(state, action) {
      clearToken();
      removeIEventFromStorage(EVENTS_STORAGE.events.askForNotification);
    },
    setHeader(state, action) {
      state.header = action.payload;
    },
    setAvatar(state, action) {
      state.avatarUrl = Urls.AVATAR(action.payload);
    },
    setPath(state, action) {
      const { path } = action.payload;
      state.path = path;
    },
    setTourPage(state, action: PayloadAction<{ pages: TourPages[]; scrolling?: boolean }>) {
      const { pages, scrolling } = action.payload;
      state.tourPages = pages;
      state.tourScrolling = !!scrolling;
    },
    setTourStart(state) {
      state.runTour = true;
    },
    setTourStop(state, action: PayloadAction<TourPages>) {
      state.runTour = false;
      state.tourScrolling = false;
      setTourShown(action.payload);
    },
    fetchNotificationCount() {},
    updateNotificationsCount(state, action) {
      const {
        data: { total, unread },
      } = action.payload;
      state.notificationsCount = { total, unread };
    },
    toggleRightPanelView(state, action) {
      const { rightPanelName } = action.payload;
      const [currentRightPanelName] = Object.keys(state.rightPanelsState).filter(
        (key) => state.rightPanelsState[key],
      );
      if (currentRightPanelName) {
        state.rightPanelsState[currentRightPanelName] = false;
        if (currentRightPanelName !== rightPanelName) {
          state.rightPanelsState[rightPanelName] = true;
        }
      } else {
        state.rightPanelsState[rightPanelName] = true;
      }
    },
    setNweNotificationSettings(state, action) {
      const { newSettings } = action.payload;
      if (state.user) {
        state.user.notificationSettings = newSettings;
      }
    },
    startPushListener(state, action) {},
    setPermissionForNotification(state) {},
    setCurrentPlace(state, action: PayloadAction<{ place: AppPlaces }>) {
      const { place } = action.payload;
      state.currentPlace = place;
    },
  },
});

export const {
  getSelfUser,
  setUser,
  unsetUser,
  setHeader,
  setAvatar,
  setPath,
  setTourStart,
  setTourStop,
  setTourPage,
  fetchNotificationCount,
  updateNotificationsCount,
  toggleRightPanelView,
  setNweNotificationSettings,
  startPushListener,
  setPermissionForNotification,
  setCurrentPlace,
} = loginSlice.actions;

export default loginSlice.reducer;
