import React, { useState, PropsWithChildren } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';

import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button, { ButtonProps } from '@material-ui/core/Button';

import TextFieldWithCounter from '../../../../components/TextFieldWithCounter';
import { RejectReasonSchema, maxSymbols } from './validationSchema';

interface RejectAppButton extends ButtonProps {
  submitRejecting: (v: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    width: 400,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function RejectAppButton(props: PropsWithChildren<RejectAppButton>) {
  const classes = useStyles();
  const { children, submitRejecting, ...otherButtonProps } = props;

  const { request } = useSelector((state: any) => state.workspaceProjectInfo);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const onSubmit = ({ rejectMessage }) => {
    submitRejecting(rejectMessage);
    handleClosePopover();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button onClick={handleOpenPopover} {...otherButtonProps}>
        {children}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className={classes.popover}>
          <Typography variant="h3" color="textSecondary" gutterBottom>
            Отклонить заявку
          </Typography>
          <Formik
            initialValues={{ rejectMessage: '' }}
            validationSchema={RejectReasonSchema}
            onSubmit={onSubmit}
            validateOnMount
          >
            {({ values, handleChange, isValid }) => (
              <Form id="rejectProjectApp" className={classes.form}>
                <TextFieldWithCounter
                  name="rejectMessage"
                  fullWidth
                  value={values.rejectMessage}
                  maxSymbols={maxSymbols}
                  onChange={handleChange}
                  placeholder={'Укажите причину отклонения заявки'}
                />
                <div style={{ paddingTop: 10 }}>
                  <Button
                    form="rejectProjectApp"
                    type="submit"
                    size="small"
                    variant="outlined"
                    color="primary"
                    disabled={request || !isValid}
                  >
                    Отклонить
                  </Button>
                  <Button
                    size="small"
                    style={{ marginLeft: 10 }}
                    variant="outlined"
                    color="primary"
                    onClick={handleClosePopover}
                  >
                    Отменить
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Popover>
    </>
  );
}

export default RejectAppButton;
