import React, { useState } from 'react';
import { convertFromRaw } from 'draft-js';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import { mdToDraftjs } from 'draftjs-md-converter';
import StartRecruitingForm from '../../start-recruiting-form';
import CustomButton from '../../../../components/CustomButton';
import AttachmentsView from '../../../../components/AttachmentsView';
import InfoBlock from '../../../../components/InfoBlock';

import { IDEA_STATUSES } from '../../../../utils/constants';
import {
  startRemoveProjectTemplate,
  startUploadForModeration,
  toggleEditForm,
  fetchStartProjectFromIdea,
} from '../domain/reducer';
import Paths from '../../../../utils/route-paths';
import { startUploadRecruitingTemplate } from '../../start-recruiting-form/edit-template/domain';
import { TOURS_CLASS_NAMES } from '../../../../components/tour/classNames';

import { OuterPopoverProps } from '../../../../components/Popover';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& button': {
      marginBottom: 16,
      width: 256,
    },
  },
  startProjectPopoverBody: {
    width: 400,
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
  },
  attachmentsBox: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  attachmentItem: {
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 16,
  },
  attachmentsLabel: {
    marginTop: 16,
  },
}));

function TemplateActionBlock() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { projectTemplateData, requesting } = useSelector(
    (state: any) => state.personalProjectTemplate,
  );
  const { user } = useSelector((state: any) => state.app);
  const {
    status,
    goal,
    about,
    id,
    owner: { id: ownerId },
    organizationOnly,
    needModerate,
  } = projectTemplateData;

  const dontNeedModeration = organizationOnly && !needModerate;

  const pureAbout = convertFromRaw(mdToDraftjs(about)).getPlainText();
  const isReadyForModeration = !!goal && !!pureAbout;

  const [openDialog, setOpenDialog] = useState(false);

  const [startProjectOrganizationOnly, setStartProjectOrganizationOnly] = useState(true);

  const handleCloseDialog = () => setOpenDialog(false);
  const handleOpenDialog = () => {
    dispatch(
      startUploadRecruitingTemplate({
        values: { ...projectTemplateData },
        history,
        handleOpenDialog: () => setOpenDialog(true),
      }),
    );
  };

  const handleToModeration = () => {
    dispatch(startUploadForModeration({ userId: user?.id, templateId: id, history }));
  };

  const handleRemove = () =>
    dispatch(startRemoveProjectTemplate({ userId: user?.id, templateId: id, history }));

  const handleEditBeforeStartRecruiting = () => {
    history.push(Paths.EDIT_IDEA_BEFORE_RECRUITING);
  };

  const handleEdit = () => dispatch(toggleEditForm());

  const handleStartProject = () => {
    dispatch(
      fetchStartProjectFromIdea({ organizationOnly: startProjectOrganizationOnly, id, history }),
    );
  };

  const StartProjectPopoverBody = () => (
    <div className={classes.startProjectPopoverBody}>
      <div>
        <Typography>Внутренний проект организации</Typography>
        <Typography color="textSecondary" variant="subtitle2">
          Если настройка не активирована, то любой пользователь платформы может посмотреть описание
          проекта и отправить заявку на участие
        </Typography>
      </div>
      <Switch
        onChange={() => setStartProjectOrganizationOnly((prevState) => !prevState)}
        checked={startProjectOrganizationOnly}
        color="primary"
      />
    </div>
  );

  const POPOVER: { [key: string]: OuterPopoverProps } = {
    MODERATION: {
      okButtonId: 'send-to-moderation-button',
      mainText: 'Модерация может занять некоторое время. Отправить на модерацию?',
      okButtonText: 'Отправить',
    },
    REMOVING: {
      okButtonId: 'remove-idea',
      mainText: 'Удалить идею?',
    },
    START_RECRUITING: {
      okButtonId: 'change-button',
      cancelButtonId: 'dont-change-button',
      mainText: 'Хотите изменить данные перед началом рекрутинга?',
      cancelButtonText: 'Нет',
      cancelHandle: handleOpenDialog,
    },
    START_PROJECT: {
      okButtonId: 'change-button',
      cancelButtonId: 'dont-change-button',
      mainText: 'Видимость проекта на платформе',
      okButtonText: 'Запустить',
      cancelButtonText: 'Отменить',
      bodyComponent: <StartProjectPopoverBody />,
      okButtonProps: { variant: 'contained' },
    },
  };

  return (
    <div className={classes.root}>
      {status === IDEA_STATUSES.OPEN && (
        <>
          <CustomButton
            className={TOURS_CLASS_NAMES.ideaView.startRecruitingButton}
            variant="contained"
            color="primary"
            size="large"
            withConfirm
            popoverProps={POPOVER.START_RECRUITING}
            onClick={handleEditBeforeStartRecruiting}
            disabled={requesting}
          >
            Начать рекрутинг
          </CustomButton>
          <CustomButton
            variant="contained"
            color="primary"
            size="large"
            withConfirm
            popoverProps={POPOVER.START_PROJECT}
            onClick={handleStartProject}
            disabled={requesting}
          >
            Запустить проект
          </CustomButton>
        </>
      )}
      {status === IDEA_STATUSES.DRAFT && (
        <CustomButton
          className={TOURS_CLASS_NAMES.ideaViewDraft.sendToModerationButton}
          variant="contained"
          color="primary"
          size="large"
          withConfirm={!dontNeedModeration}
          popoverProps={POPOVER.MODERATION}
          onClick={handleToModeration}
          disabled={requesting || !isReadyForModeration}
        >
          {dontNeedModeration ? 'Опубликовать идею' : 'Отправить на модерацию'}
        </CustomButton>
      )}
      {user?.id === ownerId && status !== IDEA_STATUSES.HIDDEN && (
        <Button
          className={TOURS_CLASS_NAMES.ideaViewDraft.editIdea}
          disabled={requesting}
          variant="outlined"
          color="primary"
          size="large"
          onClick={handleEdit}
        >
          Редактировать
        </Button>
      )}
      {user?.id === ownerId && (
        <CustomButton
          disabled={requesting}
          variant="outlined"
          color="secondary"
          size="large"
          withConfirm
          popoverProps={POPOVER.REMOVING}
          onClick={handleRemove}
        >
          Удалить
        </CustomButton>
      )}
      {projectTemplateData.attachments.length > 0 && (
        <>
          <InfoBlock label="Документы идеи:" className={classes.attachmentsLabel} />
          <div className={classes.attachmentsBox}>
            <AttachmentsView
              files={projectTemplateData.attachments}
              className={classes.attachmentItem}
            />
          </div>
        </>
      )}
      <StartRecruitingForm
        open={openDialog}
        onClose={handleCloseDialog}
        templateData={projectTemplateData}
      />
    </div>
  );
}

export default TemplateActionBlock;
