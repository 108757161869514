import { call, put, takeEvery } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { COMPONENT_STATES } from '../../../../utils/componentState';
import { errorHandler } from '../../../../utils/fetchUtils';
import { ComponentState, TablePaginationType } from '../../../../utils/types';
import axios from 'axios';
import Urls from '../../../../utils/endpoints';

// dal
export const getOrganizationProjects = (
  orgId: string,
  pagination: TablePaginationType,
  filters,
) => {
  const params = { ...pagination, ...filters };
  return axios.get(`${Urls.ORGANIZATION_ORGANIZATION(orgId)}/projects`, { params });
};

// saga
function* requestStatProjects(action) {
  try {
    const { orgId, pagination, filters } = action.payload;
    const { data } = yield call(getOrganizationProjects, orgId, pagination, filters);
    yield put(fetchStatProjectsSuccess({ data }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchStatProjectsFail());
  }
}

export function* watchLoadingStatProjects() {
  yield takeEvery(fetchStatProjects.type, requestStatProjects);
}

export interface StatProjectsType {
  componentState: ComponentState;
  projects: any[];
  totalElements: number;
  request: boolean;
}

// reducer
const initialState: StatProjectsType = {
  componentState: COMPONENT_STATES.LOADING,
  projects: [],
  totalElements: 0,
  request: true,
};

const statProjectsSlice = createSlice({
  name: 'statProjects',
  initialState,
  reducers: {
    fetchStatProjects(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
    },
    fetchStatProjectsSuccess(state, action) {
      const { data } = action.payload;
      state.projects = data.content;
      state.totalElements = data.totalElements;
      state.componentState = COMPONENT_STATES.CONTENT;
    },
    fetchStatProjectsFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const {
  fetchStatProjects,
  fetchStatProjectsSuccess,
  fetchStatProjectsFail,
} = statProjectsSlice.actions;

export default statProjectsSlice.reducer;
