import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { errorHandler } from '../../../../utils/fetchUtils';
import Paths from '../../../../utils/route-paths';
import { uploadCanvasToBlobImage } from '../../../../utils/dal';
import { IDEA_STATUSES } from '../../../../utils/constants';
import {
  getProjectTemplate,
  getCommonProjectTemplate,
  addProjectTemplate,
  removeProjectTemplate,
  updateProjectTemplate,
  updateProjectTemplateIfOpen,
  uploadForModeration,
  startProjectFromIdea,
} from './dal';
import {
  fetchProjectTemplate,
  fetchProjectTemplateFail,
  fetchProjectTemplateInitial,
  fetchProjectTemplateSuccess,
  fetchingSuccess,
  fetchingEnd,
  startRemoveProjectTemplate,
  startUploadProjectTemplate,
  uploadProjectTemplateSuccess,
  startUploadingTemplateImage,
  startUploadForModeration,
  fetchStartProjectFromIdea,
} from './reducer';

// saga
function* projectTemplateRequest(action) {
  const { templateId, userId } = action.payload;
  try {
    if (templateId === 'new') {
      yield put(fetchProjectTemplateInitial());
      return;
    }
    const fetchData = userId
      ? () => getProjectTemplate(userId, templateId)
      : () => getCommonProjectTemplate(templateId);
    const { data } = yield call(fetchData);
    yield put(fetchProjectTemplateSuccess(data));
  } catch (e) {
    errorHandler(e);
    yield put(fetchProjectTemplateFail(e.response));
  }
}

function* uploadForModerationRequest(action) {
  const { templateId, history } = action.payload;
  try {
    yield call(uploadForModeration, templateId);
    toast.success('Данные успешно отправлены');
    history.go();
  } catch (e) {
    errorHandler(e);
    yield put(fetchingEnd());
  }
}

function* updateImageRequest(action) {
  try {
    const { image, setFieldValue, images, imageName } = action.payload;
    const data = yield call(uploadCanvasToBlobImage, image, imageName);
    if (data) {
      const newImages = [data, ...images];
      setFieldValue('images', newImages, false);
      yield put(fetchingSuccess());
    } else {
      toast.error('Ошибка загрузки файла');
      yield put(fetchingEnd());
    }
  } catch (e) {
    errorHandler(e);
    yield put(fetchingEnd());
  }
}

function* uploadProjectTemplateRequest(action) {
  try {
    const { userId, templateId, values, history, status } = action.payload;
    values.imageIds = values.images.map((i) => i.id);
    values.attachmentIds = values.attachments.map((i) => i.id);
    if (!templateId) {
      const { data } = yield call(addProjectTemplate, values);
      history.push({
        pathname: `${Paths.COMMON_IDEAS}/${data.id}`,
        state: { isPersonal: true },
      });
    } else {
      if (status === IDEA_STATUSES.OPEN) {
        yield call(updateProjectTemplateIfOpen, templateId);
      }
      yield call(updateProjectTemplate, templateId, values);
      yield put(fetchProjectTemplate({ userId, templateId }));
    }
    toast.success('Данные успешно сохранены');
    yield put(uploadProjectTemplateSuccess());
  } catch (e) {
    errorHandler(e);
    yield put(fetchingEnd());
  }
}

function* removeProjectTemplateRequest(action) {
  try {
    const { templateId, history } = action.payload;
    yield call(removeProjectTemplate, templateId);
    yield put(fetchingEnd());
    history.push(Paths.COMMON_IDEAS);
  } catch (e) {
    errorHandler(e);
    yield put(fetchingEnd());
  }
}

function* startProjectRequest(action) {
  try {
    const { organizationOnly, id, history } = action.payload;
    const { data } = yield call(startProjectFromIdea, id, { organizationOnly });
    history.push(`${Paths.PROJECT_WORKSPACE}/${data.id}`);
  } catch (e) {
    errorHandler(e);
  } finally {
    yield put(fetchingEnd());
  }
}

export function* watchProjectTemplate() {
  yield takeEvery(fetchProjectTemplate.type, projectTemplateRequest);
  yield takeLatest(startUploadForModeration.type, uploadForModerationRequest);
  yield takeEvery(startUploadingTemplateImage.type, updateImageRequest);
  yield takeEvery(startUploadProjectTemplate.type, uploadProjectTemplateRequest);
  yield takeLatest(startRemoveProjectTemplate.type, removeProjectTemplateRequest);
  yield takeEvery(fetchStartProjectFromIdea.type, startProjectRequest);
}

// reducer
