import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@material-ui/core/styles';

import MuiButton from '@material-ui/core/Button';
import RemakeCharterButton from '../components/RamakeCharterButton';
import CharterTextView from '../components/CharterTextView';
import CustomButton from '../../../../components/CustomButton';

import { fetchApproveCharter, fetchRemoveCharter, setCharterState } from '../domain';
import { User } from '../../../../utils/types';
import { TOURS_CLASS_NAMES } from '../../../../components/tour/classNames';

const isApprovedByUser = (userId: string, approvedUsers: User[]) => {
  const approvedUsersIds = approvedUsers.map((user: User) => user.id);
  return approvedUsersIds.includes(userId);
};

const StyledButton = styled(MuiButton)({
  marginTop: 10,
  marginRight: 10,
  '&:last-child': {
    marginLeft: 0,
  },
});

function Draft() {
  const dispatch = useDispatch();
  const {
    projectData: { isMentor, id: projectId },
  } = useSelector((state: any) => state.workspaceProjectInfo);
  const {
    charterData,
    charterData: { text: charter, id },
    request,
    charterState,
  } = useSelector((state: any) => state.workspaceProjectCharter);
  const { user } = useSelector((state: any) => state.app);

  const isApprovedByMe = isApprovedByUser(user.id, charterData.acceptors);

  const startEditText = () => {
    dispatch(setCharterState({ current: 'edit', previous: charterState }));
  };

  const approveChapter = () => {
    dispatch(fetchApproveCharter({ projectId }));
  };

  const handleRemoveDraft = () => {
    dispatch(fetchRemoveCharter({ projectId, id }));
  };

  return (
    <div>
      <CharterTextView charter={charter} />
      <div>
        {!isApprovedByMe && (
          <StyledButton
            className={TOURS_CLASS_NAMES.charter.approveCharterButton}
            variant="contained"
            color="primary"
            size="large"
            disabled={request}
            onClick={approveChapter}
          >
            Одобрить устав
          </StyledButton>
        )}

        {((isMentor && isApprovedByMe) || !isApprovedByMe) && (
          <StyledButton
            variant="outlined"
            color="primary"
            size="large"
            disabled={request}
            onClick={startEditText}
          >
            Редактировать черновик
          </StyledButton>
        )}

        {isMentor && (
          <>
            <RemakeCharterButton style={{ marginRight: 10, marginTop: 10 }} disabled={request} />
            <CustomButton
              withConfirm
              style={{ marginTop: 10, marginRight: 10 }}
              variant="outlined"
              color="secondary"
              disabled={request}
              onClick={handleRemoveDraft}
            >
              Удалить черновик
            </CustomButton>
          </>
        )}
      </div>
    </div>
  );
}

export default Draft;
