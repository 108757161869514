import React, { useState } from 'react';
import { Form, Formik, FieldArray } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import StepContent from '@material-ui/core/StepContent';
import MuiButton from '@material-ui/core/Button';
import TextFieldWithCounter from '../../../../components/TextFieldWithCounter';

import { fetchMakeCharter } from '../domain';
import { CharterAnswer, CharterQuestion, CharterReduxState } from '../utils/types';
import { urlify } from '../../../../utils/xutils';

function CharterStepper() {
  const dispatch = useDispatch();
  const { workingTemplateVersion, charterState }: CharterReduxState = useSelector(
    (state: any) => state.workspaceProjectCharter,
  );

  const [activeStep, setActiveStep] = useState(0);
  const answers: CharterAnswer[] = workingTemplateVersion.questions.map(
    (question: CharterQuestion) => ({ questionNumber: question.number, answer: '' }),
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmitSteps = (values) => {
    dispatch(
      fetchMakeCharter({
        charterTemplateId: workingTemplateVersion.id,
        values,
        previousState: charterState,
      }),
    );
  };

  return (
    <div>
      {/* Степпер */}
      <Formik initialValues={{ answers }} onSubmit={handleSubmitSteps} enableReinitialize>
        {({ values, setFieldValue, isValid, errors, dirty, handleChange }) => {
          return (
            <Form>
              <FieldArray
                name="answers"
                render={(arrayHelpers) => (
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {workingTemplateVersion.questions.map((question: CharterQuestion, index) => (
                      <Step key={question.id}>
                        <StepLabel>
                          <Typography>{urlify(question.title)}</Typography>
                        </StepLabel>
                        <StepContent>
                          <Typography>{urlify(question.text)}</Typography>
                          <TextFieldWithCounter
                            name={`answers[${index}].answer`}
                            error={errors[index]}
                            value={values.answers[index].answer}
                            onChange={handleChange}
                            multiline
                            variant="standard"
                            fullWidth
                          />
                          <div>
                            {activeStep > 0 && (
                              <MuiButton
                                style={{ marginRight: 10 }}
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={handleBack}
                              >
                                Назад
                              </MuiButton>
                            )}
                            {answers.length === index + 1 ? (
                              <MuiButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="small"
                              >
                                Сформировать черновик
                              </MuiButton>
                            ) : (
                              <MuiButton
                                type="button"
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={handleNext}
                              >
                                Далее
                              </MuiButton>
                            )}
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                )}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default CharterStepper;
