import React from 'react';
import { useHistory } from 'react-router-dom';

import { COMPONENT_STATES } from '../../utils/componentState';
import ErrorDataLoading from '../errors/ErrorDataLoading';
import ProjectTemplateSkeleton from '../../components/Skeletons/ProjectTemplate';
import NoData from '../NoData';

interface ViewSelectStateProps {
  state: string;
  content: () => JSX.Element | null;
  skeleton?: JSX.Element;
  noDataComponent?: JSX.Element;
  noDataText?: string;
  noDataButtonText?: string;
  noDataButtonAction?: () => void;
  errorData?: object;
  errorAction?: (() => void) | undefined;
  locationState?: { state: any };
  requiredData?: { state: any };
}

const defaultSkeleton = <ProjectTemplateSkeleton />;

function ViewSelectState(props: ViewSelectStateProps) {
  const history = useHistory();
  const {
    locationState,
    state,
    skeleton = defaultSkeleton,
    noDataComponent,
    noDataText,
    noDataButtonText,
    noDataButtonAction,
    content,
    errorAction,
    errorData,
    requiredData,
  } = props;

  if (requiredData) {
    if (!requiredData.state) {
      history.push('/');
      return null;
    }
  }

  if (locationState) {
    if (!locationState.state) {
      history.push('/');
      return null;
    }
  }

  switch (state) {
    case COMPONENT_STATES.LOADING:
      return skeleton;
    case COMPONENT_STATES.CONTENT:
      return content();
    case COMPONENT_STATES.ERROR:
      return <ErrorDataLoading errorData={errorData} action={errorAction} />;
    case COMPONENT_STATES.EMPTY:
      return (
        noDataComponent || (
          <NoData
            text={noDataText}
            buttonText={noDataButtonText}
            buttonAction={noDataButtonAction}
          />
        )
      );
    default:
      return null;
  }
}

export default ViewSelectState;
