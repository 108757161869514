import React, { useEffect, FunctionComponent } from 'react';
import { Form, Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { Copyright } from 'components/Copyright';
import IconVK from 'components/icons/IconVK';
import { VK_AUTH_LINK, GLOBAL_ORGANIZATION_REGISTRATION_KEY } from 'utils/constants';
import { PALETTE } from 'utils/palette';
import Paths from 'utils/route-paths';
import Urls from 'utils/endpoints';
import { RootState } from 'store/rootReducer';

import LoginSchema from './validationSchema';
import { checkLoginStatus, tryLogin } from './domain';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    marginBottom: 16,
  },
  enterButton: {
    marginTop: 16,
  },
  vkButton: {
    backgroundColor: PALETTE().vkBlue,
    color: PALETTE().white,
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: PALETTE(0.85).vkBlue,
    },
  },
  vkIcon: {
    marginRight: 6,
  },
}));

let windowObjectReference: Window | null = null;
let previousUrl = null;

const receiveMessage = (event, path) => {
  const { data } = event;

  if (data.success) {
    window.location.pathname = path;
  } else {
    toast.error('Не удалось авторизоваться через соц. сети. Воспользуйтесь формой входа.');
  }
};

const openSignInWindow = (url, name, path) => {
  window.removeEventListener('message', (e) => receiveMessage(e, path), true);

  const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

  if (windowObjectReference === null || windowObjectReference.closed) {
    windowObjectReference = window.open(url, name, strWindowFeatures);
  } else if (previousUrl !== url) {
    windowObjectReference = window.open(url, name, strWindowFeatures);
    if (windowObjectReference) {
      windowObjectReference.focus();
    }
  } else {
    windowObjectReference.focus();
  }

  window.addEventListener('message', (e) => receiveMessage(e, path), true);
  previousUrl = url;
};

const Login: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { initialPath, request } = useSelector((state: RootState) => state.login);

  const initialValues = {
    username: '',
    password: '',
  };

  const onSubmit = (values) => {
    const form = {
      username: values.username.trim(),
      password: values.password.trim(),
    };
    dispatch(tryLogin({ values: form, history, pathname: initialPath }));
  };

  const handleVKClick = () => {
    openSignInWindow(VK_AUTH_LINK, 'VK', initialPath);
  };

  useEffect(() => {
    dispatch(checkLoginStatus({ history }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, dispatch]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={Urls.ASSET_IMAGE('logo_aw3bb5')} alt="logo" />
        <Typography component="h1" variant="h5">
          Вход
        </Typography>
        <Formik initialValues={initialValues} validationSchema={LoginSchema} onSubmit={onSubmit}>
          {({ values, handleChange, isValid, errors }) => (
            <Form>
              <TextField
                error={!!errors.username}
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="username"
                autoComplete="email"
                value={values.username}
                onChange={handleChange}
                helperText={errors.username}
              />
              <TextField
                error={!!errors.password}
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Пароль"
                type="password"
                id="password"
                autoComplete="current-password"
                value={values.password}
                onChange={handleChange}
                helperText={errors.password}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={clsx(classes.enterButton, classes.button)}
                disabled={request || !isValid}
              >
                Войти
              </Button>
              <Button
                fullWidth
                className={clsx(classes.vkButton, classes.button)}
                disabled={request}
                startIcon={<IconVK className={classes.vkIcon} />}
                onClick={handleVKClick}
              >
                Войти через Вконтакте
              </Button>
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <Link href={Paths.RESET_PASSWORD} variant="body2">
                    Забыли пароль?
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    href={`${Paths.USER_REGISTRATION_PATH}/${GLOBAL_ORGANIZATION_REGISTRATION_KEY}`}
                    variant="body2"
                  >
                    Регистрация
                  </Link>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Login;
