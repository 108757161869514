import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import ViewSelectState from '../../../components/select-state/ViewSelectState';
import Card from './components/kanban-card/Card';
import KanbanColumn from './components/KanbanColumn';
import KanbanSkeleton from '../../../components/Skeletons/KanbanSkeleton';

import { fetchKanbanBoard, fetchRemoveCard, fetchMoveCard } from './domain';
import {
  fetchKanbanCard,
  fetchUploadCard,
  setKanbanCardOpen,
} from './components/kanban-card/domain';
import { TOURS_CLASS_NAMES, TOUR_PAGES } from '../../../components/tour/tourSteps';
import { setTourPage } from '../../globalDomain';

interface KanbanBoardProps {
  projectId: string;
  className?: string;
}

const getCardWeight = (cardsList, cardNewIndex) => {
  switch (true) {
    case cardNewIndex === 0:
      return cardsList[1] ? parseInt(cardsList[1].weight, 10) / 2 : 1024;
    case cardNewIndex === cardsList.length - 1:
      return parseInt(cardsList[cardsList.length - 2].weight, 10) * 2;
    default:
      return (
        (parseInt(cardsList[cardNewIndex - 1].weight, 10) +
          parseInt(cardsList[cardNewIndex + 1].weight, 10)) /
        2
      );
  }
};

const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  const newWeight = getCardWeight(result, endIndex);
  return [result, newWeight];
};

const move = (source, destination, sourceIndex, destIndex) => {
  const sourceClone = [...source];
  const destClone = [...destination];
  const [removed] = sourceClone.splice(sourceIndex, 1);
  destClone.splice(destIndex, 0, removed);
  const newWeight = getCardWeight(destClone, destIndex);
  return [sourceClone, destClone, newWeight];
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
  },
  paper: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

function Kanban({ projectId, className }: KanbanBoardProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { componentState, kanbanBoardData } = useSelector((state: any) => state.kanbanBoard);

  useEffect(() => {
    dispatch(fetchKanbanBoard({ projectId }));
    dispatch(setTourPage({ pages: [TOUR_PAGES.workspace, TOUR_PAGES.kanbanBoard] }));
    return function cleanUp() {
      dispatch(setTourPage({ pages: [] }));
    };
  }, [dispatch, projectId]);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    const newData = [...kanbanBoardData];
    // if dropped outside the list
    if (!destination) {
      return;
    }

    const droppableSourceCards = kanbanBoardData[source.droppableId].cards;

    if (source.droppableId === destination.droppableId) {
      const [newCards, weight] = reorder(droppableSourceCards, source.index, destination.index);
      newData[source.droppableId] = { ...newData[source.droppableId], cards: newCards };
      dispatch(fetchMoveCard({ projectId, cardId: result.draggableId, weight, newData }));
    } else {
      const droppableDestinationCards = kanbanBoardData[destination.droppableId].cards;
      const [sourceCards, destCards, weight] = move(
        droppableSourceCards,
        droppableDestinationCards,
        source.index,
        destination.index,
      );
      newData[source.droppableId] = { ...newData[source.droppableId], cards: sourceCards };
      newData[destination.droppableId] = { ...newData[destination.droppableId], cards: destCards };
      const columnId = newData[destination.droppableId].id;
      dispatch(fetchMoveCard({ projectId, cardId: result.draggableId, columnId, weight, newData }));
    }
  };

  const handleClickOpenCard = (column, cardId) => {
    dispatch(
      fetchKanbanCard({
        projectId,
        cardId,
        currentColumn: {
          id: column.id,
          name: column.name,
        },
        column,
      }),
    );
    dispatch(setTourPage({ pages: [TOUR_PAGES.kanbanCard], scrolling: true }));
  };

  const handleCloseCardModal = () => {
    dispatch(fetchKanbanBoard({ projectId }));
    dispatch(setKanbanCardOpen(false));
    dispatch(setTourPage({ pages: [TOUR_PAGES.workspace, TOUR_PAGES.kanbanBoard] }));
  };

  const handleSaveCard = (card) => {
    dispatch(fetchUploadCard({ projectId, card }));
  };

  const handleDeleteCard = (cardId) => {
    dispatch(fetchRemoveCard({ projectId, cardId }));
  };

  const content = () => {
    return (
      <div
        className={clsx(
          TOURS_CLASS_NAMES.kanbanBoard.center,
          TOURS_CLASS_NAMES.kanbanBoard.center2,
        )}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <div className={clsx(classes.paper)}>
            {kanbanBoardData.map((column, index) => (
              <KanbanColumn
                key={column.id}
                column={column}
                index={index}
                onClickCard={handleClickOpenCard}
              />
            ))}
          </div>
        </DragDropContext>
        <Card
          canDelete={false}
          onClose={handleCloseCardModal}
          onSave={handleSaveCard}
          onDelete={handleDeleteCard}
        />
      </div>
    );
  };

  return (
    <div className={clsx(classes.root, className)}>
      <ViewSelectState state={componentState} content={content} skeleton={<KanbanSkeleton />} />
    </div>
  );
}

export default Kanban;
