import axios from 'axios';
import { put, takeEvery, call } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { COMPONENT_STATES, DashboardComponentState } from '../../utils/componentState';
import { errorHandler } from '../../utils/fetchUtils';
import Urls from '../../utils/endpoints';
import UrlsV2 from '../../utils/endpointsV2';
import { Template, Recruiting, Project } from '../../utils/types';
import { VAPID_KEY } from '../../utils/constants';
import { messaging } from '../../firebase';

// dal
export const getDashboard = (userId) => axios.get(Urls.DASHBOARD(userId));
export const registerDeviceForNotifications = (token) =>
  axios.post(UrlsV2.NOTIFICATIONS_REGISTER, { token });

// saga
export function* deviceRegistration() {
  if (messaging) {
    const notifToken = yield messaging.getToken({
      vapidKey: VAPID_KEY,
    });
    yield call(registerDeviceForNotifications, notifToken);
  } else {
    yield;
  }
}
function* dashboardRequest(action) {
  try {
    const { userId } = action.payload;
    const { data } = yield call(() => getDashboard(userId));
    yield put(fetchDashboardSuccess({ data }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchDashboardFail(e.response));
  }
}

export function* watchDashboardRequest() {
  yield takeEvery(fetchDashboard.type, dashboardRequest);
}

// reducer

export interface DashboardState {
  componentState: string;
  dashboardState: string;
  templates: Template[];
  recruiting: Recruiting[];
  projects: Project[];
  errorData: any;
}

const initialState: DashboardState = {
  componentState: COMPONENT_STATES.LOADING,
  dashboardState: DashboardComponentState.EMPTY,
  templates: [],
  recruiting: [],
  projects: [],
  errorData: {},
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    fetchDashboard(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
    },
    fetchDashboardSuccess(state, action) {
      state.componentState = COMPONENT_STATES.CONTENT;
      const { templates, recruitings: recruiting, projects } = action.payload.data;
      if (templates.length) {
        state.dashboardState = DashboardComponentState.TEMPLATES;
        state.templates = templates;
      }
      if (recruiting.length) {
        state.dashboardState = DashboardComponentState.RECRUITING;
        state.recruiting = recruiting;
      }
      if (projects.length) {
        state.dashboardState = DashboardComponentState.PROJECTS;
        state.projects = projects;
      }
    },
    fetchDashboardFail(state, action) {
      state.componentState = COMPONENT_STATES.ERROR;
      state.errorData = action.payload;
    },
  },
});

export const { fetchDashboard, fetchDashboardSuccess, fetchDashboardFail } = dashboardSlice.actions;

export default dashboardSlice.reducer;
