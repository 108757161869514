import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { trySocialLogin } from './domain';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SocialLogin(): JSX.Element {
  const query = useQuery();
  const dispatch = useDispatch();
  const code = query.get('code');

  const state = query.get('state');

  useEffect(() => {
    dispatch(trySocialLogin({ code, state }));
  }, [code, state, dispatch]);

  return <div>Загрузка...</div>;
}

export default SocialLogin;
