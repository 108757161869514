import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ViewSelectState from '../../../components/select-state/ViewSelectState';
import PageWrapper from '../../../components/Wrapper';
import CommonRecruitingTiles from './Tiles';
import SlimBoxTip from '../../../components/tips/SlimBoxTip';
import RecruitingBlock from '../../dashboard/screens/recruiting/RecruitingBlock';

import { fetchRecruitingProjectsList } from './domain';
import { STRINGS } from '../../../utils/constants';
import Paths from '../../../utils/route-paths';
import { setTourPage } from '../../globalDomain';
import { TOUR_PAGES } from '../../../components/tour/tourSteps';

function CommonRecruiting() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    componentState,
    personalRecruitingProjects,
    personalRecruitingProjectsCounter,
  } = useSelector((state: any) => state.recruitingProjectsList);
  const { user } = useSelector((state: any) => state.app);
  const { recruitingWithUserRecruiting, recruitingNoUserRecruiting } = STRINGS;

  useEffect(() => {
    if (user) {
      dispatch(fetchRecruitingProjectsList({ isFirstLoading: true, userId: user.id }));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user) {
      dispatch(setTourPage({ pages: [TOUR_PAGES.sideMenu] }));
    }
    return function cleanUp() {
      dispatch(setTourPage({ pages: [] }));
    };
  }, [dispatch, user]);

  const hasRecruiting = personalRecruitingProjects.length !== 0;
  const tipStrings = hasRecruiting ? recruitingWithUserRecruiting : recruitingNoUserRecruiting;

  const tipProps = {
    ...tipStrings,
  };

  const content = () => (
    <div>
      <SlimBoxTip {...tipProps} />
      {hasRecruiting && (
        <RecruitingBlock
          data={personalRecruitingProjects}
          counter={personalRecruitingProjectsCounter}
        />
      )}
      <CommonRecruitingTiles />
    </div>
  );

  return (
    <PageWrapper header="Проекты на рекрутинге">
      <ViewSelectState
        state={componentState}
        content={content}
        noDataText={'Пока никто не запустил рекрутинг. Вы можете это сделать первыми!'}
        noDataButtonText={'Запустить рекрутинг'}
        noDataButtonAction={() => history.push(Paths.COMMON_IDEAS)}
      />
    </PageWrapper>
  );
}

export default CommonRecruiting;
