import { put, takeEvery, call } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import axios from 'axios';
import { COMPONENT_STATES } from '../../../../utils/componentState';
import { errorHandler } from '../../../../utils/fetchUtils';
import { ComponentState, TablePaginationType, Template } from '../../../../utils/types';
import { LIST_PAGE_SIZE } from '../../../../utils/constants';
import Urls from '../../../../utils/endpoints';

// dal
export const getPublicOrganizationIdeas = (
  orgId: string,
  pagination: TablePaginationType,
  filters = {},
) => {
  const params = { ...pagination, ...filters };
  return axios.get(`${Urls.ORGANIZATION_ORGANIZATION(orgId)}/ideas/open`, { params });
};

// saga
function* orgPublicIdeasRequest(action) {
  try {
    const { orgId, currentPage } = action.payload;
    const { data } = yield call(getPublicOrganizationIdeas, orgId, {
      page: currentPage,
      pageSize: LIST_PAGE_SIZE,
    });
    yield put(fetchOrgPublicIdeasSuccess({ data }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchOrgPublicIdeasFail());
  }
}

export function* watchLoadingOrgPublicIdeas() {
  yield takeEvery(fetchOrgPublicIdeas.type, orgPublicIdeasRequest);
}

export interface OrgPublicIdeasState {
  componentState: ComponentState;
  ideas: Template[];
  currentPage: number;
  isLastPage: boolean;
}

// reducer
const initialState: OrgPublicIdeasState = {
  componentState: COMPONENT_STATES.LOADING,
  ideas: [],
  currentPage: 1,
  isLastPage: false,
};

const orgPublicIdeasSlice = createSlice({
  name: 'orgPublicIdeas',
  initialState,
  reducers: {
    fetchOrgPublicIdeas(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
      if (action.payload.isFirstLoading) {
        state.currentPage = 1;
        state.ideas = [];
      }
      action.payload.currentPage = state.currentPage;
    },
    fetchOrgPublicIdeasSuccess(state, action) {
      const { data } = action.payload;
      if (data.content?.length === 0) {
        state.componentState = COMPONENT_STATES.EMPTY;
      } else {
        state.componentState = COMPONENT_STATES.CONTENT;
        state.ideas = [...state.ideas, ...data.content];
        state.currentPage += 1;
        state.isLastPage = data.last;
      }
    },
    fetchOrgPublicIdeasFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const {
  fetchOrgPublicIdeas,
  fetchOrgPublicIdeasSuccess,
  fetchOrgPublicIdeasFail,
} = orgPublicIdeasSlice.actions;

export default orgPublicIdeasSlice.reducer;
