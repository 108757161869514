import React from 'react';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { Copyright } from '../../../components/Copyright';
import { RequestSchema } from './validationSchema';
import { passwordResetRequesting } from './domain';
import Paths from '../../../utils/route-paths';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface FormValues {
  email: string;
}

function PasswordReset(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { request } = useSelector((state: any) => state.passwordResetRequest);

  const initialValues: FormValues = {
    email: '',
  };

  const onSubmit = (values) => {
    dispatch(passwordResetRequesting({ values, history }));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Сброс пароля
        </Typography>
        <Formik initialValues={initialValues} validationSchema={RequestSchema} onSubmit={onSubmit}>
          {({ values, handleChange, isValid, errors }) => (
            <Form>
              <TextField
                error={!!errors.email}
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={values.email}
                onChange={handleChange}
                helperText={errors.email}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={request || !isValid}
              >
                Сбросить пароль
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href={Paths.LOGIN} variant="body2">
                    Вход
                  </Link>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default PasswordReset;
