import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import TilesGridWrapper from '../../../components/TilesGridWrapper';
import RecruitingCardFull from '../components/RecruitingFullCard';
import PageWrapper from '../../../components/Wrapper';
import ViewSelectState from '../../../components/select-state/ViewSelectState';

import { fetchPersonalRecruitingList } from './domain';
import Paths from '../../../utils/route-paths';

function PersonalRecruiting() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state: any) => state.app);
  const { componentState, recruitingProjects, isLastPage } = useSelector(
    (state: any) => state.personalRecruitingList,
  );

  useEffect(() => {
    if (user) {
      dispatch(fetchPersonalRecruitingList({ isFirstLoading: true, userId: user.id }));
    }
  }, [dispatch, user]);

  const handleLoadMore = () => {
    dispatch(fetchPersonalRecruitingList({ isFirstLoading: false, userId: user.id }));
  };

  const content = () => (
    <TilesGridWrapper isLastPage={isLastPage} loadMore={handleLoadMore}>
      {recruitingProjects.map((p) => (
        <Grid key={p.id} item>
          <RecruitingCardFull recruiting={p} isPersonal />
        </Grid>
      ))}
    </TilesGridWrapper>
  );

  return (
    <PageWrapper header="Мой рекрутинг">
      <ViewSelectState
        state={componentState}
        content={content}
        noDataText={'Пока никто не запустил рекрутинг. Вы можете это сделать первыми!'}
        noDataButtonText={'Запустить рекрутинг'}
        noDataButtonAction={() => history.push(Paths.COMMON_IDEAS)}
      />
    </PageWrapper>
  );
}

export default PersonalRecruiting;
