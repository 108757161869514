import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomIconButton from './CustomIconButton';
import Jdenticon from './icons/Jdenticon';

import { PALETTE } from '../utils/palette';
import { makeAvatarColor } from '../utils/utils';

interface ProfileAvatar extends AvatarProps {
  className?: string;
  avatarClassName?: string;
  avatarUrl: string;
  avatarUploading?: boolean;
  remove?: () => void;
  upload?: () => void;
  square?: boolean;
  orgName?: string;
  userFirstName?: string;
  userLastName?: string;
}

interface StyleProps {
  backgroundColor?: string;
}

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    maxWidth: 'min-content',
    position: 'relative',
  },
  avatar: {
    color: ({ backgroundColor }: StyleProps) =>
      backgroundColor ? theme.palette.getContrastText(backgroundColor) : undefined,
    backgroundColor: ({ backgroundColor }: StyleProps) => backgroundColor,
    width: 256,
    height: 256,
  },
  initials: {
    fontSize: 100,
  },
  removeBoxForRound: {
    position: 'absolute',
    top: -16,
    right: 8,
    zIndex: 3,
    borderRadius: '50%',
  },
  removeBoxForSquare: {
    position: 'absolute',
    top: 4,
    right: 4,
    zIndex: 3,
    backgroundColor: PALETTE(0.5).white,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: PALETTE(0.75).white,
    },
  },
  removeButton: {},
  removeIcon: {},
  avatarProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  photoButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    position: 'absolute',
    top: 224,
    left: 100,
    borderRadius: '50%',
    zIndex: 2,
  },
  iconButton: {
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
    padding: 7,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

function ProfileAvatar({
  avatarUrl,
  avatarUploading,
  remove,
  upload,
  className,
  square,
  avatarClassName,
  orgName,
  userFirstName,
  userLastName,

  ...avatarProps
}: ProfileAvatar) {
  const backgroundColor =
    userFirstName && userLastName
      ? makeAvatarColor(userFirstName, userLastName)
      : 'rgba(255,255,255)';
  const classes = useStyles({ backgroundColor });

  return (
    <div className={clsx(classes.avatarContainer, className)}>
      {!!avatarUrl && !!remove && (
        <div
          className={clsx({
            [classes.removeBoxForSquare]: square,
            [classes.removeBoxForRound]: !square,
          })}
        >
          <CustomIconButton
            size={square ? 'small' : 'medium'}
            className={classes.removeIcon}
            withConfirm
            onClick={remove}
          >
            <ClearIcon fontSize="large" />
          </CustomIconButton>
        </div>
      )}
      <Avatar className={clsx(classes.avatar, avatarClassName)} src={avatarUrl} {...avatarProps}>
        {userFirstName && userLastName ? (
          <span className={classes.initials}>
            {userFirstName[0]}
            {userLastName[0]}
          </span>
        ) : (
          <Jdenticon value={orgName} />
        )}
      </Avatar>
      {!!upload && (
        <Paper className={classes.photoButtonContainer} elevation={0}>
          <IconButton className={classes.iconButton} onClick={upload}>
            <CameraAltIcon fontSize="large" />
          </IconButton>
        </Paper>
      )}
      {avatarUploading && <CircularProgress size={256} className={classes.avatarProgress} />}
    </div>
  );
}

export default ProfileAvatar;
