import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import TypographyWithTooltipAndEllipsis from './TypographyWithTooltipAndEllipsis';
import Jdenticon from './icons/Jdenticon';

import Paths from '../utils/route-paths';
import { CUSTOM_SHADOWS } from '../utils/palette';

interface OrganizationBanner {
  className?: string;
  id: string;
  avatarUrl: string;
  name: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
    boxShadow: CUSTOM_SHADOWS.blue.elevation5,
  },
  avatar: {
    width: 50,
    height: 50,
    marginRight: 8,
    marginLeft: 8,
    backgroundColor: 'rgba(255,255,255)',
  },
}));

function OrganizationBanner(props: OrganizationBanner) {
  const { id, avatarUrl, name, className } = props;
  const history = useHistory();
  const classes = useStyles();

  return (
    <Paper
      className={clsx(classes.root, className)}
      onClick={() => history.push(`${Paths.PUBLIC_ORGANIZATION}/${id}`)}
    >
      <Avatar className={classes.avatar} variant="rounded" src={avatarUrl}>
        <Jdenticon value={name} />
      </Avatar>
      <TypographyWithTooltipAndEllipsis
        title={name || ''}
        symbolsNumber={80}
        typographyProps={{ color: 'primary', variant: 'subtitle2' }}
      />
    </Paper>
  );
}

export default OrganizationBanner;
