import React, { useEffect, PropsWithChildren, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';

import CssBaseline from '@material-ui/core/CssBaseline';
import MainMenu from './app-components/MainMenu';
import NotificationTost from '../components/NotificationTost';
import NotificationsPanel from './notifications/NotificationsPanel';
import AskForNotificationTipDialog from './app-components/AskForNotificationTipDialog';

import { isLoggedIn, isNeedToShowFirstTour } from '../utils/auth';
import { getSelfUser, fetchNotificationCount, startPushListener } from './globalDomain';
import { TOURS_CLASS_NAMES } from '../components/tour/tourSteps';

import { PALETTE } from '../utils/palette';
import { isFirebaseSupported } from '../firebase';
import { isEventStorageHasEvent } from '../utils/utils';
import { EVENTS_STORAGE, PUSH_AUTO_CLOSE } from '../utils/constants';
import { RootState } from '../store/rootReducer';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  content: {
    display: 'flex',
    flexGrow: 1,
  },
  support: {
    width: 42,
    height: 302,
    position: 'fixed',
    bottom: 20,
    right: 0,
    zIndex: 10,
    color: '#fff',
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  toastProgressBar: {
    background: PALETTE().blue,
  },
  toast: {
    width: 500,
  },
}));

function App({ children }: PropsWithChildren<any>) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLogged = isLoggedIn();
  const { user, runTour, tourPages } = useSelector((state: RootState) => state.app);
  const [showNotificationTip, setShowNotificationTip] = useState(false);

  useEffect(() => {
    if (isLogged) {
      dispatch(getSelfUser({ history, firstLoading: true }));
      dispatch(fetchNotificationCount());
      dispatch(
        startPushListener({
          toast: (payload) =>
            toast(<NotificationTost payload={payload} history={history} />, {
              position: toast.POSITION.TOP_CENTER,
              pauseOnFocusLoss: true,
              autoClose: PUSH_AUTO_CLOSE,
              className: classes.toast,
              progressClassName: classes.toastProgressBar,
            }),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  useEffect(() => {
    if (user && isFirebaseSupported) {
      const notNeedToShowPushRequestTip =
        isEventStorageHasEvent(EVENTS_STORAGE.events.askForNotification) ||
        isNeedToShowFirstTour(tourPages) ||
        (user?.notificationSettings.receivePush && Notification.permission === 'granted') ||
        runTour;

      if (notNeedToShowPushRequestTip) {
        return;
      }
      setShowNotificationTip(true);
    }
  }, [runTour, user, tourPages]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* Боковое меню */}
      <MainMenu />
      {/* Основной контент */}
      <main className={classes.content}>{children}</main>
      <NotificationsPanel />
      <div className={clsx(classes.support, TOURS_CLASS_NAMES.sideMenu.support)} />
      <AskForNotificationTipDialog
        open={showNotificationTip}
        onClose={() => setShowNotificationTip(false)}
      />
    </div>
  );
}

const AppRouted = withRouter(App);

export default AppRouted;
