import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ViewSelectState from '../../../components/select-state/ViewSelectState';
import PageWrapper from '../../../components/Wrapper';
import RecruitingProjectView from './components/RecruitingProjectView';

import { fetchRecruitingProject } from './domain';

function ProjectTemplate() {
  const dispatch = useDispatch();
  // @ts-ignore
  const { id } = useParams();
  const { state } = useLocation();
  const { componentState, recruitingProjectData, organization, errorData } = useSelector(
    (state: any) => state.recruitingProject,
  );

  const fetchData = () => {
    const payload = { projectId: id, userId: null };
    // @ts-ignore
    if (state?.userId) {
      // @ts-ignore
      payload.userId = state.userId;
    }
    dispatch(fetchRecruitingProject(payload));
  };

  useEffect(fetchData, [id, state, dispatch]);

  const content = (): JSX.Element => {
    return (
      <RecruitingProjectView
        recruitingProject={recruitingProjectData}
        organization={organization}
      />
    );
  };

  return (
    <PageWrapper header={'Рекрутинг'}>
      <ViewSelectState
        state={componentState}
        content={content}
        errorData={errorData}
        errorAction={fetchData}
      />
    </PageWrapper>
  );
}

export default ProjectTemplate;
