import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { COMPONENT_STATES } from '../../../utils/componentState';
import { errorHandler } from '../../../utils/fetchUtils';
import Urls from '../../../utils/endpoints';
import { LIST_PAGE_SIZE } from '../../../utils/constants';
import { Project, ComponentState, TablePaginationType } from '../../../utils/types';

// dal
export const getPersonalProjectsList = (profileId, params) =>
  axios.get(Urls.PERSONAL_PROJECTS_LIST(profileId), { params });

export const getOrganizationActiveProjects = (
  orgId: string,
  pagination: TablePaginationType,
  filters = {},
) => {
  const params = { ...pagination, ...filters };
  return axios.get(`${Urls.ORGANIZATION_ORGANIZATION(orgId)}/projects/active`, { params });
};

// saga
function* personalProjectsListRequest(action) {
  try {
    const { profileId, currentPage } = action.payload;
    const { data } = yield call(() =>
      getPersonalProjectsList(profileId, { page: currentPage, pageSize: LIST_PAGE_SIZE }),
    );
    yield put(fetchPersonalProjectsListSuccess(data));
  } catch (e) {
    errorHandler(e);
    yield put(fetchPersonalProjectsListFail());
  }
}

function* organizationProjectsListRequest(action) {
  try {
    const { orgId, currentPage } = action.payload;
    const { data } = yield call(() =>
      getOrganizationActiveProjects(orgId, { page: currentPage, pageSize: LIST_PAGE_SIZE }, {}),
    );
    yield put(fetchOrganizationProjectsListSuccess(data));
  } catch (e) {
    errorHandler(e);
    yield put(fetchOrganizationProjectsListFail());
  }
}

export function* watchLoadingPersonalProjectsList() {
  yield takeEvery(fetchPersonalProjectsList.type, personalProjectsListRequest);
  yield takeEvery(fetchOrganizationProjectsList.type, organizationProjectsListRequest);
}

export interface ProjectsState {
  myProjects: {
    componentState: ComponentState;
    content: Project[];
    currentPage: number;
    isLastPage: boolean;
  };
  orgProjects: {
    componentState: ComponentState;
    content: Project[];
    currentPage: number;
    isLastPage: boolean;
  };
}

// reducer
const initialState: ProjectsState = {
  myProjects: {
    componentState: COMPONENT_STATES.LOADING,
    content: [] as any[],
    currentPage: 1,
    isLastPage: false,
  },
  orgProjects: {
    componentState: COMPONENT_STATES.LOADING,
    content: [] as any[],
    currentPage: 1,
    isLastPage: false,
  },
};

const personalProjectsListSlice = createSlice({
  name: 'personalProjectsList',
  initialState,
  reducers: {
    // personal
    fetchPersonalProjectsList(state, action) {
      if (action.payload.isFirstLoading) {
        state.myProjects.componentState = COMPONENT_STATES.LOADING;
        state.myProjects.currentPage = 1;
        state.myProjects.content = [];
      }
      action.payload.currentPage = state.myProjects.currentPage;
    },
    fetchPersonalProjectsListSuccess(state, action) {
      if (action.payload.content.length === 0) {
        state.myProjects.componentState = COMPONENT_STATES.EMPTY;
      } else {
        state.myProjects.componentState = COMPONENT_STATES.CONTENT;
        state.myProjects.content = [...state.myProjects.content, ...action.payload.content];
        state.myProjects.currentPage += 1;
        state.myProjects.isLastPage = action.payload.last;
      }
    },
    fetchPersonalProjectsListFail(state) {
      state.orgProjects.componentState = COMPONENT_STATES.ERROR;
    },

    // organization
    fetchOrganizationProjectsList(state, action) {
      if (action.payload.isFirstLoading) {
        state.orgProjects.componentState = COMPONENT_STATES.LOADING;
        state.orgProjects.currentPage = 1;
        state.orgProjects.content = [];
      }
      action.payload.currentPage = state.orgProjects.currentPage;
    },
    fetchOrganizationProjectsListSuccess(state, action) {
      if (action.payload.content.length === 0) {
        state.orgProjects.componentState = COMPONENT_STATES.EMPTY;
      } else {
        state.orgProjects.componentState = COMPONENT_STATES.CONTENT;
        state.orgProjects.content = [...state.orgProjects.content, ...action.payload.content];
        state.orgProjects.currentPage += 1;
        state.orgProjects.isLastPage = action.payload.last;
      }
    },
    fetchOrganizationProjectsListFail(state) {
      state.orgProjects.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const {
  fetchPersonalProjectsList,
  fetchPersonalProjectsListSuccess,
  fetchPersonalProjectsListFail,

  fetchOrganizationProjectsList,
  fetchOrganizationProjectsListSuccess,
  fetchOrganizationProjectsListFail,
} = personalProjectsListSlice.actions;

export default personalProjectsListSlice.reducer;
