import React, { useState, useEffect } from 'react';
import { Form, Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ImageGallery from 'react-image-gallery';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ClearIcon from '@material-ui/icons/Clear';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';

import FileUploader from '../../../../components/files-uploader/FileLoader';
import AttachmentsView from '../../../../components/AttachmentsView';
import InfoBlock from '../../../../components/InfoBlock';

import ImageLoader from '../../../../components/ImageLoader';
import RichTextEditor from '../../../../components/RichTextEditor';
import TextFieldWithCounter from '../../../../components/TextFieldWithCounter';

import {
  startUploadingTemplateImage,
  toggleEditForm,
  startUploadProjectTemplate,
} from '../domain/reducer';
import TemplateSchema from '../validationSchema';
import { IMAGE_UPLOAD_PRESETS, IDEA_STATUSES } from '../../../../utils/constants';
import { PALETTE } from '../../../../utils/palette';
import { makeGalleryImages } from '../../../../utils/utils';
import { TOURS_CLASS_NAMES, TOUR_PAGES } from '../../../../components/tour/tourSteps';
import { setTourPage } from '../../../globalDomain';
import { RootState } from '../../../../store/rootReducer';

import 'react-image-gallery/styles/css/image-gallery.css';

interface IdeaForm {
  userId: string;
}

interface ParamsProps {
  id: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 1016,
    height: 634,
    display: 'flex',
    justifyContent: 'space-between',
    // для joyride, что бы корректно работала подсветка элемента в хроме
    backgroundColor: PALETTE().white,
  },
  contentRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 356,
  },
  form: {
    width: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& div:first-child': {
      marginTop: 0,
    },
  },
  gallery: {
    height: '55%',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& button': {
      marginBottom: 16,
      width: 256,
    },
    marginTop: 32,
  },
  imageContainer: {
    position: 'relative',
    width: 356,
  },
  image: {
    width: 356,
  },
  photoButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    position: 'absolute',
    bottom: -28,
    right: 146,
    borderRadius: '50%',
    zIndex: 2,
  },
  iconButton: {
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
    padding: 7,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  imageLoadingProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 88,
    left: 146,
    zIndex: 1,
  },
  deleteButton: {
    right: 0,
    top: 0,
    padding: 20,
  },
  about: {
    padding: 10,
    border: '1px solid',
    borderColor: PALETTE(0.1).gray,
  },
  blockType: {
    width: 180,
  },
  onlyOrgSwitch: {
    display: 'flex',
    paddingTop: 8,
    paddingBottom: 16,
  },
  addAttachmentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '95%',
    marginTop: 8,
  },
  addAttachmentBtn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 256,
    marginTop: 16,
  },
  attachmentsLabel: {
    marginTop: 16,
  },
  attachmentsBox: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  fileIcon: {
    height: 40,
    marginRight: 4,
  },
  attachmentItem: {
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 16,
    backgroundColor: PALETTE(0.0).white,
  },
}));

function ProjectTemplatesForm({ userId }: IdeaForm) {
  const dispatch = useDispatch();
  const { id } = useParams<ParamsProps>();
  const history = useHistory();
  const classes = useStyles();
  const [openImageUpload, setOpenImageUpload] = useState(false);
  const { requesting, projectTemplateData } = useSelector(
    (state: RootState) => state.personalProjectTemplate,
  );

  useEffect(() => {
    dispatch(setTourPage({ pages: [TOUR_PAGES.ideaForm] }));
    return function cleanUp() {
      dispatch(setTourPage({ pages: [] }));
    };
  }, [dispatch]);

  const handleOpenUploadImage = () => {
    setOpenImageUpload(true);
  };

  const handleCancelUploadImage = () => {
    setOpenImageUpload(false);
  };

  const handleUploadImage = (croppedImage, setFieldValue, images, imageName) => {
    dispatch(
      startUploadingTemplateImage({ image: croppedImage, setFieldValue, images, imageName }),
    );
  };

  const handleRemoveAttachment = (setFieldValue, field) => (ids, index) => {
    const newValue = [...field.value];
    newValue.splice(index, 1);
    setFieldValue(field.name, newValue);
  };

  const handleImageDelete = (setFieldValue, values, galleryRef) => {
    const images = [...values.images];
    const currentImageIndex = galleryRef.getCurrentIndex();
    images.splice(currentImageIndex, 1);
    setFieldValue('images', images, false);
  };

  const submitForm = (_values) => {
    const values = { ..._values };
    // временно
    values.status = 'DRAFT';
    values.ownerId = userId;
    dispatch(
      startUploadProjectTemplate({
        userId,
        templateId: projectTemplateData.id,
        values,
        history,
        status: projectTemplateData.status,
      }),
    );
  };

  const handleCancelEdit = () => {
    if (id === 'new') {
      history.goBack();
    }
    dispatch(toggleEditForm());
  };

  const [isFileUploaderOpened, setIsFileUploaderOpened] = useState(false);

  return (
    <Formik
      validationSchema={TemplateSchema}
      initialValues={projectTemplateData}
      validateOnBlur
      onSubmit={submitForm}
      enableReinitialize
    >
      {({ values, handleChange, isValid, errors, setFieldValue, dirty }) => {
        const hasImages = !!values.images.length;
        return (
          <Form className={clsx(classes.root)}>
            <div className={TOURS_CLASS_NAMES.ideaForm.form}>
              <div className={clsx(classes.form)}>
                {projectTemplateData.status === IDEA_STATUSES.OPEN && (
                  <Typography variant="subtitle2" color="secondary">
                    При сохранении изменений идея перестанет быть общедоступной и перейдет в статус
                    &quot;Черновик&quot;
                  </Typography>
                )}
                <TextFieldWithCounter
                  name="name"
                  label="Название"
                  value={values.name}
                  onChange={handleChange}
                  error={errors.name}
                />
                <TextFieldWithCounter
                  name="goal"
                  label="Цель"
                  error={errors.goal}
                  value={values.goal}
                  onChange={handleChange}
                />
                <TextFieldWithCounter
                  name="duration"
                  label="Продолжительность (в неделях)"
                  error={errors.duration}
                  value={values.duration}
                  onChange={handleChange}
                  hasCounter={false}
                  multiline={false}
                />
                <div className={classes.onlyOrgSwitch}>
                  <Switch
                    disabled={requesting}
                    onChange={(e) => setFieldValue('organizationOnly', e.target.checked)}
                    checked={values.organizationOnly}
                    color="primary"
                  />
                  <div>
                    <Typography>Доступно только для участников организации</Typography>
                    <Typography color="textSecondary" variant="subtitle2">
                      Если настройка не активирована, то идея будет доступна всем на платформе.
                    </Typography>
                  </div>
                </div>

                <RichTextEditor
                  label="Описание"
                  name="about"
                  value={values.about}
                  onChange={handleChange}
                  error={errors.about}
                />
              </div>
            </div>

            {/* Правая часть */}
            <div className={classes.contentRight}>
              <div className={classes.imageContainer}>
                <Field name="images">
                  {({ field }) => {
                    let galleryRef;
                    return (
                      <>
                        <ImageGallery
                          ref={(el) => {
                            galleryRef = el;
                          }}
                          items={makeGalleryImages(field.value)}
                          showNav={false}
                          showPlayButton={false}
                          showThumbnails={hasImages}
                          showFullscreenButton={hasImages}
                          renderCustomControls={() =>
                            hasImages ? (
                              <button
                                type="button"
                                className={clsx(classes.deleteButton, 'image-gallery-icon')}
                                onClick={() => handleImageDelete(setFieldValue, values, galleryRef)}
                              >
                                <ClearIcon />
                              </button>
                            ) : null
                          }
                        />
                        <Paper className={classes.photoButtonContainer} elevation={0}>
                          <IconButton
                            className={clsx(classes.iconButton, TOURS_CLASS_NAMES.ideaForm.images)}
                            onClick={() => handleOpenUploadImage()}
                          >
                            <CameraAltIcon fontSize="large" />
                          </IconButton>
                        </Paper>
                        {requesting && (
                          <CircularProgress size={60} className={classes.imageLoadingProgress} />
                        )}
                      </>
                    );
                  }}
                </Field>
              </div>
              <div className={classes.buttons}>
                <Button
                  className={TOURS_CLASS_NAMES.ideaForm.saveButton}
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={requesting || !(dirty && isValid)}
                >
                  Сохранить
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  disabled={requesting}
                  onClick={handleCancelEdit}
                >
                  Отмена
                </Button>
              </div>
              <div className={classes.addAttachmentContainer}>
                <Field name="attachments">
                  {({ field }) => (
                    <>
                      <Button
                        className={classes.addAttachmentBtn}
                        variant="outlined"
                        color="primary"
                        size="large"
                        disabled={requesting}
                        onClick={() => setIsFileUploaderOpened(true)}
                      >
                        Добавить документ
                      </Button>
                      <FileUploader
                        open={isFileUploaderOpened}
                        onSave={(data) => setFieldValue(field.name, [...field.value, ...data])}
                        onClose={() => setIsFileUploaderOpened(false)}
                      />
                      {field.value.length > 0 && (
                        <>
                          <InfoBlock label="Документы идеи:" className={classes.attachmentsLabel} />
                          <div className={classes.attachmentsBox}>
                            <AttachmentsView
                              files={field.value}
                              onRemoveFile={handleRemoveAttachment(setFieldValue, field)}
                              className={classes.attachmentItem}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </Field>
              </div>
            </div>
            <ImageLoader
              onSave={(v, imageName) =>
                handleUploadImage(v, setFieldValue, values.images, imageName)
              }
              onClose={handleCancelUploadImage}
              open={openImageUpload}
              presets={IMAGE_UPLOAD_PRESETS.template}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default ProjectTemplatesForm;
