import React from 'react';
import TilesGridWrapper from '../../../components/TilesGridWrapper';
import Grid from '@material-ui/core/Grid';
import ProjectFullCard from './ProjectFullCard';
import { Project } from '../../../utils/types';

interface ProjectsGridProps {
  loadMore: () => void;
  isLastPage: boolean;
  data: Project[];
}

function ProjectsGrid(props: ProjectsGridProps) {
  const { loadMore, isLastPage, data } = props;

  return (
    <div>
      <TilesGridWrapper isLastPage={isLastPage} loadMore={loadMore}>
        {data.map((p) => (
          <Grid key={p.id} item>
            <ProjectFullCard project={p} />
          </Grid>
        ))}
      </TilesGridWrapper>
    </div>
  );
}

export default ProjectsGrid;
