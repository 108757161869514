import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 20,
  },
  text: {
    paddingLeft: 10,
  },
}));

function CommitSkeleton() {
  const classes = useStyles();

  const Item = () => (
    <div className={classes.item}>
      <Skeleton variant="circle" width={30} height={30} animation="wave" />
      <div className={classes.text}>
        <Skeleton variant="text" width={100} animation="wave" />
        <Skeleton variant="text" width={70} animation="wave" />
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <Item />
      <Item />
      <Item />
    </div>
  );
}

export default CommitSkeleton;
