import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import Tooltip from '@material-ui/core/Tooltip';
import UserCardSkeleton from '../../components/Skeletons/UserCard';
import ViewSelectState from '../../components/select-state/ViewSelectState';
import ColoredAvatar from '../../components/ColoredAvatar';

import Paths from '../../utils/route-paths';
import { PALETTE } from '../../utils/palette';
import { TOURS_CLASS_NAMES } from '../../components/tour/tourSteps';

interface UserCardProps {
  logout: () => void;
  open: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    height: 100,
    transition: theme.transitions.create(['padding-left'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
  },
  rootOpen: {
    paddingLeft: 15,
  },
  rootClose: {
    paddingLeft: 8,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    height: '100%',
  },
  userInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  avatar: {
    width: 48,
    height: 48,
  },
  nameBlock: {
    width: 166,
    paddingLeft: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    fontSize: 20,
    fontWeight: 500,
    color: PALETTE().black,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  shortName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100%',
  },
}));

function UserCard({ logout, open }: UserCardProps): JSX.Element {
  const history = useHistory();
  const classes = useStyles({ open });
  const { componentState, user } = useSelector((state: any) => state.app);

  const content = () => {
    const { firstName, organization } = user;
    return (
      <div className={clsx(classes.main, TOURS_CLASS_NAMES.sideMenu.profile)}>
        <div className={classes.userInfo}>
          <ColoredAvatar
            className={classes.avatar}
            clickable={!open}
            onClick={() => history.push(Paths.USER_PROFILE)}
            disableTooltip
          />
          <div className={classes.nameBlock}>
            <Typography className={classes.name}>{firstName}</Typography>
            {organization && (
              <Typography className={classes.shortName}>
                {organization.shortName.toUpperCase()}
              </Typography>
            )}
          </div>
        </div>
        <div className={classes.actions}>
          <Tooltip arrow title="Открыть профиль пользователя">
            <IconButton size="small" onClick={() => history.push(Paths.USER_PROFILE)}>
              <SettingsIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Выйти из учетной записи">
            <IconButton size="small" onClick={logout}>
              <ExitToAppIcon color="primary" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootOpen]: open,
        [classes.rootClose]: !open,
      })}
    >
      <ViewSelectState state={componentState} content={content} skeleton={<UserCardSkeleton />} />
    </div>
  );
}

export default UserCard;
