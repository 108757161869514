import { ChapterInitialState } from './types';

export const PROJECT_CHAPTER_STATUSES = {
  NOT_FORMED: 'NOT_FORMED',
  FORMED: 'FORMED',
  APPROVED: 'APPROVED',
};

export const chapterInitialState: ChapterInitialState = {
  NOT_FORMED: 'variant',
  FORMED: 'draft',
  APPROVED: 'approved',
};
