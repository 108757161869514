import { call, put, takeEvery } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import { COMPONENT_STATES } from '../../../../utils/componentState';
import { errorHandler } from '../../../../utils/fetchUtils';
import { ComponentState, TablePaginationType } from '../../../../utils/types';
import axios from 'axios';
import Urls from '../../../../utils/endpoints';

// dal
export const getOrganizationIdeas = (
  orgId: string,
  pagination: TablePaginationType,
  filters = {},
) => {
  const params = { ...pagination, ...filters };
  return axios.get(`${Urls.ORGANIZATION_ORGANIZATION(orgId)}/ideas`, { params });
};

// saga
function* requestStatIdeas(action) {
  try {
    const { orgId, pagination, filters } = action.payload;
    const { data } = yield call(getOrganizationIdeas, orgId, pagination, filters);
    yield put(fetchStatIdeasSuccess({ data }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchStatIdeasFail());
  }
}

export function* watchLoadingStatIdeas() {
  yield takeEvery(fetchStatIdeas.type, requestStatIdeas);
}

export interface StatIdeasType {
  componentState: ComponentState;
  ideas: any[];
  totalElements: number;
  request: boolean;
}

// reducer
const initialState: StatIdeasType = {
  componentState: COMPONENT_STATES.LOADING,
  ideas: [],
  totalElements: 0,
  request: true,
};

const statIdeasSlice = createSlice({
  name: 'statIdeas',
  initialState,
  reducers: {
    fetchStatIdeas(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
    },
    fetchStatIdeasSuccess(state, action) {
      const { data } = action.payload;
      state.ideas = data.content;
      state.totalElements = data.totalElements;
      state.componentState = COMPONENT_STATES.CONTENT;
    },
    fetchStatIdeasFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const { fetchStatIdeas, fetchStatIdeasSuccess, fetchStatIdeasFail } = statIdeasSlice.actions;

export default statIdeasSlice.reducer;
