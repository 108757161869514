import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { createSlice } from '@reduxjs/toolkit';

import { errorHandler } from '../../../utils/fetchUtils';
import Urls from '../../../utils/endpoints';
import Paths from '../../../utils/route-paths';

// dal
export const passwordResetConfirm = (payload) => axios.post(Urls.CONFIRM_RESET_PASSWORD, payload);

// saga
function* passwordResetConfirmRequest(action) {
  try {
    const { values, history } = action.payload;
    yield call(() => passwordResetConfirm(values));
    toast.success('Вы успешно сменили пароль');
    history.push(Paths.LOGIN);
    yield put(passwordResetConfirmRequestingSuccess());
  } catch (e) {
    errorHandler(e);
    yield put(passwordResetConfirmRequestingFail());
  }
}

export function* watchPasswordResetConfirmRequest() {
  yield takeEvery(passwordResetConfirmRequesting.type, passwordResetConfirmRequest);
}

// reducer
const initialState = {
  request: false,
};

const PasswordResetConfirmSlice = createSlice({
  name: 'passwordReset',
  initialState,
  reducers: {
    passwordResetConfirmRequesting(state, action) {
      state.request = true;
    },
    passwordResetConfirmRequestingSuccess(state) {
      state.request = false;
    },
    passwordResetConfirmRequestingFail(state) {
      state.request = false;
    },
  },
});

export const {
  passwordResetConfirmRequesting,
  passwordResetConfirmRequestingSuccess,
  passwordResetConfirmRequestingFail,
} = PasswordResetConfirmSlice.actions;

export default PasswordResetConfirmSlice.reducer;
