import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MainPaper from '../../../components/MainPaper';

import Urls from '../../../utils/endpoints';
import Paths from '../../../utils/route-paths';
import { STRINGS } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {},
  cards: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 54,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    width: 256,
    marginTop: 40,
  },
}));

function DashboardFirst(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const {
    dashboardEmpty: {
      title,
      mainText,
      subTitle1,
      text1,
      buttonText1,
      subTitle2,
      text2,
      buttonText2,
    },
  } = STRINGS;

  return (
    <MainPaper>
      <div className={classes.root}>
        <Typography align="center" variant="h2">
          {title}
        </Typography>
        <Typography align="center" variant="h3" color="textSecondary">
          {mainText}
        </Typography>
        <div className={classes.cards}>
          <div className={classes.card}>
            <img
              style={{ height: 260 }}
              src={Urls.ASSET_IMAGE('student_pi4y3y')}
              alt="student_img"
            />
            <Typography align="center" variant="h4">
              {subTitle1}
            </Typography>
            <Typography align="center" variant="body1">
              {text1}
            </Typography>
            <Button
              onClick={() => history.push(Paths.COMMON_RECRUITING_PROJECTS)}
              size="large"
              color="primary"
              variant="contained"
              className={classes.button}
            >
              {buttonText1}
            </Button>
          </div>
          <div className={classes.card}>
            <img style={{ height: 260 }} src={Urls.ASSET_IMAGE('mentor_hbupfm')} alt="mentor_img" />
            <Typography align="center" variant="h4">
              {subTitle2}
            </Typography>
            <Typography align="center" variant="body1">
              {text2}
            </Typography>
            <Button
              onClick={() => history.push({ pathname: `${Paths.COMMON_IDEAS}/new`, state: {} })}
              size="large"
              color="secondary"
              variant="contained"
              className={classes.button}
            >
              {buttonText2}
            </Button>
          </div>
        </div>
      </div>
    </MainPaper>
  );
}

export default DashboardFirst;
