import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import InfoIcon from '@material-ui/icons/Info';
import PeopleIcon from '@material-ui/icons/People';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SettingsIcon from '@material-ui/icons/Settings';
import OrgMenuItem from '../../components/OrgMenuItem';
import PageWrapper from '../../components/Wrapper';

import OrgCommonInfo from './common-info/OrgCommoInfo';
import OrganizationUserList from './user-list';
import OrgStat from './statistics';
import OrgSettings from './common-info/OrgSettings';

import { PALETTE } from '../../utils/palette';
import {
  fetchInfo,
  OrganizationCommonInfo,
  setStatisticsMenuItem,
} from './common-info/domain/reducer';
import { AppState, setTourPage } from '../globalDomain';
import { COMPONENT_STATES } from '../../utils/componentState';
import { TOUR_PAGES } from '../../components/tour/tourSteps';

const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: 'solid',
  },
  divider: {
    width: '100%',
  },
  main: {
    display: 'flex',
    background: '#fff',
  },
  menuList: {
    height: '100%',
    borderRight: 'solid',
    borderRightColor: PALETTE(0.2).gray,
    borderWidth: 1,
  },
  content: {
    paddingTop: 40,
    paddingLeft: 40,
    width: '70%',
    background: '#fff',
  },
  icon: {
    color: PALETTE().gray,
  },
}));

function OrganizationManagement() {
  const classes = useStyles();
  const { user }: AppState = useSelector((state: any) => state.app);
  const {
    componentState: infoComponentState,
    menuItem,
    hasAccessToStatistics,
  }: OrganizationCommonInfo = useSelector((state: any) => state.organizationCommonInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && !hasAccessToStatistics) {
      dispatch(fetchInfo({ orgId: user.organization.id }));
    }
    dispatch(setTourPage({ pages: [TOUR_PAGES.orgManageInfo] }));
    return function cleanUp() {
      dispatch(setTourPage({ pages: [] }));
    };
  }, [dispatch, user, hasAccessToStatistics]);

  const handleClickMenuItem = (item) => {
    dispatch(setStatisticsMenuItem(item));
  };

  return (
    <PageWrapper header="Управление организацией">
      <Divider className={classes.divider} />
      <div className={classes.main}>
        <div className={classes.menuList}>
          <List>
            <OrgMenuItem
              onClick={() => handleClickMenuItem(0)}
              Icon={<InfoIcon className={classes.icon} />}
              text="Основная информация"
              isSelected={menuItem === 0}
            />
            <OrgMenuItem
              onClick={() => handleClickMenuItem(1)}
              Icon={<PeopleIcon className={classes.icon} />}
              text="Пользователи"
              isSelected={menuItem === 1}
            />
            <OrgMenuItem
              onClick={() => handleClickMenuItem(2)}
              Icon={<TrendingUpIcon className={classes.icon} />}
              text="Статистика"
              isSelected={menuItem === 2}
              disabled={infoComponentState !== COMPONENT_STATES.CONTENT}
            />
            <OrgMenuItem
              onClick={() => handleClickMenuItem(3)}
              Icon={<SettingsIcon className={classes.icon} />}
              text="Настройки организации"
              isSelected={menuItem === 3}
            />
          </List>
        </div>
        <div className={classes.content}>
          {menuItem === 0 && <OrgCommonInfo />}
          {menuItem === 1 && <OrganizationUserList />}
          {menuItem === 2 && <OrgStat />}
          {menuItem === 3 && <OrgSettings />}
        </div>
      </div>
    </PageWrapper>
  );
}

export default OrganizationManagement;
