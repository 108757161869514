import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';

import Urls from '../../../utils/endpoints';
import { COMPONENT_STATES } from '../../../utils/componentState';
import { errorHandler } from '../../../utils/fetchUtils';
import { getPersonalRecruitingList } from '../personal-list/domain';
import { LIST_PAGE_SIZE } from '../../../utils/constants';

// dal
export const getRecruitingProjectsList = (params) => axios.get(Urls.COMMON_RECRUITING, { params });

// saga
function* recruitingProjectsListRequest(action) {
  try {
    const { currentPage, userId } = action.payload;
    let personalRecruiting;
    if (userId) {
      const { data } = yield call(getPersonalRecruitingList, userId, {
        page: 1,
        pageSize: 2,
      });
      personalRecruiting = data;
    }
    const { data: commonRecruiting } = yield call(() =>
      getRecruitingProjectsList({ page: currentPage, pageSize: LIST_PAGE_SIZE }),
    );
    yield put(fetchRecruitingProjectsListSuccess({ personalRecruiting, commonRecruiting }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchRecruitingProjectsListFail());
  }
}

export function* watchLoadingRecruitingProjectsLis() {
  yield takeEvery(fetchRecruitingProjectsList.type, recruitingProjectsListRequest);
}

// reducer
const initialState = {
  componentState: COMPONENT_STATES.LOADING,
  commonRecruitingProjects: [] as any[],
  personalRecruitingProjects: [] as any[],
  personalRecruitingProjectsCounter: 0,
  currentPage: 1,
  isLastPage: false,
};

const recruitingProjectsListSlice = createSlice({
  name: 'recruitingProjectsList',
  initialState,
  reducers: {
    fetchRecruitingProjectsList(state, action) {
      if (action.payload.isFirstLoading) {
        state.componentState = COMPONENT_STATES.LOADING;
        state.currentPage = 1;
        state.commonRecruitingProjects = [];
      }
      action.payload.currentPage = state.currentPage;
    },
    fetchRecruitingProjectsListSuccess(state, action) {
      const { personalRecruiting, commonRecruiting } = action.payload;

      const isEmpty =
        !commonRecruiting?.content?.length &&
        !personalRecruiting?.content?.length &&
        !state.commonRecruitingProjects.length;

      if (isEmpty) {
        state.componentState = COMPONENT_STATES.EMPTY;
      } else {
        state.componentState = COMPONENT_STATES.CONTENT;
        state.commonRecruitingProjects = [
          ...state.commonRecruitingProjects,
          ...commonRecruiting.content,
        ];
        state.currentPage += 1;
        state.isLastPage = commonRecruiting.last;
        if (personalRecruiting) {
          state.personalRecruitingProjects = personalRecruiting.content;
          state.personalRecruitingProjectsCounter = personalRecruiting.totalElements;
        }
      }
    },
    fetchRecruitingProjectsListFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
  },
});

export const {
  fetchRecruitingProjectsList,
  fetchRecruitingProjectsListSuccess,
  fetchRecruitingProjectsListFail,
} = recruitingProjectsListSlice.actions;

export default recruitingProjectsListSlice.reducer;
