import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { call, put, takeEvery, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { COMPONENT_STATES } from '../../../utils/componentState';
import Urls from '../../../utils/endpoints';
import Paths from '../../../utils/route-paths';
import { errorHandler } from '../../../utils/fetchUtils';
import { RecruitingApplication } from '../../../utils/types';

// dal
export const startProject = (payload, projectId) =>
  axios.post(Urls.START_PROJECT(projectId), payload);
export const getApplications = (projectId) => axios.get(Urls.RECRUITING_APPLICATIONS(projectId));

// saga
function* ApplicationsRequest(action) {
  try {
    const { projectId } = action.payload;
    const { data: applicationsData } = yield call(() => getApplications(projectId));
    yield put(fetchApplicationsSuccess({ applicationsData }));
  } catch (e) {
    errorHandler(e);
    yield put(fetchApplicationsFail());
  }
}

function* requestProjectStart(action) {
  try {
    const { projectId, acceptedApplications, history } = action.payload;
    const {
      data: { id },
    } = yield call(startProject, { ids: acceptedApplications }, projectId);
    toast.success('Проект успешно запущен');
    history.push(`${Paths.PROJECT_WORKSPACE}/${id}`);
    yield put(endProjectStartingProcess());
  } catch (e) {
    if (e.response?.data?.message === 'Превышена квота количества проектов организации') {
      yield put(setQuotaOverrun(true));
    } else {
      errorHandler(e);
    }
    yield put(endProjectStartingProcess());
  }
}

export function* watchProjectStarting() {
  yield all([
    yield takeEvery(projectStarting.type, requestProjectStart),
    yield takeEvery(fetchApplications.type, ApplicationsRequest),
  ]);
}

// reducer
interface InitialState {
  componentState: string;
  applications: null | RecruitingApplication[];
  acceptedApplications: string[];
  acceptedUsers: RecruitingApplication[];
  requesting: boolean;
  quotaOverrun: boolean;
}

const initialState: InitialState = {
  componentState: COMPONENT_STATES.LOADING,
  applications: null,
  acceptedApplications: [],
  acceptedUsers: [],
  requesting: false,
  quotaOverrun: false,
};

const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    fetchApplications(state, action) {
      state.componentState = COMPONENT_STATES.LOADING;
    },
    fetchApplicationsSuccess(state, action) {
      state.componentState = COMPONENT_STATES.CONTENT;
      state.applications = action.payload.applicationsData;
      state.acceptedApplications = [];
    },
    fetchApplicationsFail(state) {
      state.componentState = COMPONENT_STATES.ERROR;
    },
    processApplication(state, action) {
      const {
        app: { id, student },
      } = action.payload;
      if (state.acceptedApplications.includes(id)) {
        state.acceptedApplications = state.acceptedApplications.filter((appId) => appId !== id);
        state.acceptedUsers = state.acceptedUsers.filter(
          (oldStudent) => student.id !== oldStudent.id,
        );
      } else {
        state.acceptedApplications.push(id);
        state.acceptedUsers.push(student);
      }
    },
    projectStarting(state, action) {
      state.requesting = true;
    },
    endProjectStartingProcess(state) {
      state.requesting = false;
    },
    setQuotaOverrun(state, action) {
      state.quotaOverrun = action.payload;
    },
  },
});

export const {
  fetchApplications,
  fetchApplicationsSuccess,
  fetchApplicationsFail,
  processApplication,
  projectStarting,
  endProjectStartingProcess,
  setQuotaOverrun,
} = applicationsSlice.actions;

export default applicationsSlice.reducer;
