import { DefaultLinkModel, DefaultPortModel } from '@projectstorm/react-diagrams';
import { PALETTE } from '../../../../../utils/palette';

export class ArrowLinkModel extends DefaultLinkModel {
  constructor() {
    super({
      type: 'advanced',
      width: 1,
      color: PALETTE(0.9).black,
    });
  }
}

export class ArrowPortModel extends DefaultPortModel {
  createLinkModel() {
    return new ArrowLinkModel();
  }
}
