import React, { useState } from 'react';
import { DropzoneDialog } from 'material-ui-dropzone';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import ImageCropper from './ImageCropper';

import { getBase64 } from '../../utils/utils';
import { IMAGE_UPLOAD_PRESETS } from '../../utils/constants';

function ImageLoader(props) {
  const { onClose, open, onSave, presets = IMAGE_UPLOAD_PRESETS.avatar } = props;
  const [cropper, setCropper] = useState<any>({ image: null, open: false });
  const [croppedImage, setCroppedImage] = useState(null);

  const handleCloseUploader = () => {
    onClose();
  };

  const handleCloseImageCropper = () => {
    setCropper({
      image: null,
      open: false,
    });
  };

  const handleStartCropping = (files) => {
    getBase64(files[0]).then((image) =>
      setCropper({
        filename: files[0].name,
        image,
        open: true,
      }),
    );
    onClose();
  };

  const handleSetCroppedData = (data) => {
    setCroppedImage(data);
  };

  const handleSaveImage = () => {
    if (croppedImage) {
      onSave(croppedImage, cropper.filename);
    }
    handleCloseImageCropper();
  };

  return (
    <div>
      <DropzoneDialog
        fileObjects={[]}
        filesLimit={1}
        open={open}
        onDrop={handleStartCropping}
        acceptedFiles={['image/jpeg', 'image/png']}
        showPreviews={false}
        maxFileSize={5000000}
        onClose={handleCloseUploader}
        dialogTitle="Загрузить изображение"
        cancelButtonText="Отмена"
        submitButtonText="Ok"
        dropzoneText="Перетащите изображение на эту область или кликните на неё."
      />
      <Dialog onClose={handleCloseImageCropper} open={cropper.open}>
        <DialogTitle>Выберите область</DialogTitle>
        <DialogContent>
          <ImageCropper
            presets={presets}
            image={cropper.image}
            setCropData={handleSetCroppedData}
          />
        </DialogContent>
        <DialogActions>
          <Button size="small" color="primary" variant="outlined" onClick={handleCloseImageCropper}>
            Отменить
          </Button>
          <Button size="small" color="primary" variant="contained" onClick={handleSaveImage}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ImageLoader;
