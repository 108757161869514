import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ImageGallery from 'react-image-gallery';

import ClearIcon from '@material-ui/icons/Clear';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import ImageLoader from '../../../components/ImageLoader';
import TextFieldWithCounter from '../../../components/TextFieldWithCounter';
import RichTextEditor from '../../../components/RichTextEditor';

import { RootState } from '../../../store/rootReducer';
import { startUploadingTemplateImage } from '../../project-templates/view-n-form/domain/reducer';
import { makeGalleryImages } from '../../../utils/utils';
import { IMAGE_UPLOAD_PRESETS } from '../../../utils/constants';
import TemplateSchema from '../../project-templates/view-n-form/validationSchema';
import { PALETTE } from '../../../utils/palette';
import ViewSelectState from '../../../components/select-state/ViewSelectState';
import { fetchUpdateProject, fetchWorkspaceProject } from '../domain/reducer';

interface ProjectEditForm {
  projectId: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1016,
    display: 'flex',
    justifyContent: 'space-between',
    // для joyride, что бы корректно работала подсветка элемента в хроме
    backgroundColor: PALETTE().white,
  },
  contentRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 50,
  },
  form: {
    width: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& div:first-child': {
      marginTop: 0,
    },
    marginBottom: 24,
  },
  gallery: {
    height: '55%',
  },
  buttons: {
    height: '45%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 64,
    alignItems: 'center',
    '& button': {
      marginBottom: 16,
      width: 256,
    },
  },
  imageContainer: {
    position: 'relative',
    width: 356,
  },
  image: {
    width: 356,
  },
  photoButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    position: 'absolute',
    bottom: -28,
    right: 146,
    borderRadius: '50%',
    zIndex: 2,
  },
  iconButton: {
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
    padding: 7,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  imageLoadingProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 88,
    left: 146,
    zIndex: 1,
  },
  deleteButton: {
    right: 0,
    top: 0,
    padding: 20,
  },
  about: {
    padding: 10,
    border: '1px solid',
    borderColor: PALETTE(0.1).gray,
  },
  blockType: {
    width: 180,
  },
  onlyOrgSwitch: {
    display: 'flex',
    paddingTop: 8,
    paddingBottom: 16,
  },
}));

function ProjectEditForm({ projectId }: ProjectEditForm) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [openImageUpload, setOpenImageUpload] = useState(false);
  const { request, projectData, projectInfoComponentState } = useSelector(
    (state: RootState) => state.workspaceProjectInfo,
  );
  const { user } = useSelector((state: any) => state.app);

  const fetchWorkspaceProjectData = () => {
    if (user) {
      dispatch(
        fetchWorkspaceProject({
          projectId,
          userId: user.id,
        }),
      );
    }
  };

  useEffect(fetchWorkspaceProjectData, [projectId, user]);

  const handleOpenUpload = () => {
    setOpenImageUpload(true);
  };

  const handleCancelUploadImage = () => {
    setOpenImageUpload(false);
  };

  const handleUploadImage = (croppedImage, setFieldValue, images, imageName) => {
    dispatch(
      startUploadingTemplateImage({ image: croppedImage, setFieldValue, images, imageName }),
    );
  };

  const submitForm = (_values) => {
    const { name, goal, about, duration, organizationOnly } = _values;
    const values = {
      name,
      goal,
      about,
      duration,
      organizationOnly,
      avatarIds: _values.images.map((i) => i.id),
    };
    dispatch(fetchUpdateProject({ projectId: projectData.id, values }));
  };

  const handleImageDelete = (setFieldValue, values, galleryRef) => {
    const images = [...values.images];
    const currentImageIndex = galleryRef.getCurrentIndex();
    images.splice(currentImageIndex, 1);
    setFieldValue('images', images, false);
  };

  const content = () => (
    <Formik
      validationSchema={TemplateSchema}
      initialValues={projectData}
      validateOnBlur
      onSubmit={submitForm}
      enableReinitialize
    >
      {({ values, handleChange, isValid, errors, setFieldValue, dirty }) => {
        const hasImages = !!values.images.length;
        return (
          <Form className={clsx(classes.root)}>
            <div className={clsx(classes.form)}>
              <TextFieldWithCounter
                name="name"
                label="Название"
                value={values.name}
                onChange={handleChange}
                error={errors.name}
              />
              <TextFieldWithCounter
                name="goal"
                label="Цель"
                error={errors.goal}
                value={values.goal}
                onChange={handleChange}
              />
              <TextFieldWithCounter
                name="duration"
                label="Продолжительность (в неделях)"
                error={errors.duration}
                value={values.duration}
                onChange={handleChange}
                hasCounter={false}
                multiline={false}
              />
              <div className={classes.onlyOrgSwitch}>
                <Switch
                  disabled={request}
                  onChange={(e) => setFieldValue('organizationOnly', e.target.checked)}
                  checked={values.organizationOnly}
                  color="primary"
                />
                <div>
                  <Typography>Доступно только для участников организации</Typography>
                  <Typography color="textSecondary" variant="subtitle2">
                    Если настройка не активирована, то проект будет доступен всем на платформе.
                  </Typography>
                </div>
              </div>

              <RichTextEditor
                label="Описание"
                name="about"
                value={values.about}
                onChange={handleChange}
                error={errors.about}
              />
            </div>

            {/* Правая часть */}
            <div className={classes.contentRight}>
              <div className={classes.imageContainer}>
                <Field name="images">
                  {({ field }) => {
                    let galleryRef;
                    return (
                      <>
                        <ImageGallery
                          ref={(el) => {
                            galleryRef = el;
                          }}
                          items={makeGalleryImages(field.value)}
                          showNav={false}
                          showPlayButton={false}
                          showThumbnails={hasImages}
                          showFullscreenButton={hasImages}
                          renderCustomControls={() =>
                            hasImages ? (
                              <button
                                type="button"
                                className={clsx(classes.deleteButton, 'image-gallery-icon')}
                                onClick={() => handleImageDelete(setFieldValue, values, galleryRef)}
                              >
                                <ClearIcon />
                              </button>
                            ) : null
                          }
                        />
                        <Paper className={classes.photoButtonContainer} elevation={0}>
                          <IconButton
                            className={clsx(classes.iconButton)}
                            onClick={() => handleOpenUpload()}
                          >
                            <CameraAltIcon fontSize="large" />
                          </IconButton>
                        </Paper>
                        {request && (
                          <CircularProgress size={60} className={classes.imageLoadingProgress} />
                        )}
                      </>
                    );
                  }}
                </Field>
              </div>
              <div className={classes.buttons}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={request || !(dirty && isValid)}
                >
                  Сохранить
                </Button>
              </div>
            </div>
            <ImageLoader
              onSave={(v, imageName) =>
                handleUploadImage(v, setFieldValue, values.images, imageName)
              }
              onClose={handleCancelUploadImage}
              open={openImageUpload}
              presets={IMAGE_UPLOAD_PRESETS.template}
            />
          </Form>
        );
      }}
    </Formik>
  );

  return <ViewSelectState state={projectInfoComponentState} content={content} />;
}

export default ProjectEditForm;
