import React from 'react';
import { Form, Formik } from 'formik';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StandardDialog from '../../../../../components/StandardDialog';
import TextFieldWithCounter from '../../../../../components/TextFieldWithCounter';

import { CancelRecruitingSchema } from './validationSchema';

export interface CancelRecruitingProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (card) => void;
}

const initialValues = {
  message: '',
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 236,
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 22,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  nameField: {
    marginBottom: 20,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 25,
    marginTop: 'auto',
    '& button': {
      marginRight: 24,
      width: 256,
    },
  },
}));

function CancelRecruiting(props: CancelRecruitingProps) {
  const classes = useStyles();
  const { onConfirm, onClose, open } = props;
  const onSubmit = (values) => {
    if (!values.message) {
      values.message = 'Причина не указана';
    }
    onConfirm(values);
  };

  return (
    <StandardDialog className={classes.root} open={open} onClose={onClose}>
      <div className={classes.title}>
        <Typography variant="h4">Укажите причину отмены рекрутинга</Typography>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={CancelRecruitingSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, isValid, errors, dirty, setFieldValue }) => {
          return (
            <Form className={classes.form}>
              <div className={classes.nameField}>
                <TextFieldWithCounter
                  name="message"
                  error={errors.message}
                  value={values.message}
                  onChange={handleChange}
                  margin="none"
                  size="small"
                  fullWidth
                  placeholder="Причина отмены"
                />
              </div>

              <div className={classes.buttons}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={!isValid}
                >
                  Подтвердить отмену
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  disabled={!isValid}
                  onClick={onClose}
                >
                  Отказаться от отмены
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </StandardDialog>
  );
}

export default CancelRecruiting;
