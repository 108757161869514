import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

interface ChapterTextViewProps {
  charter: string;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  charterBox: {
    maxWidth: '100%',
  },
  charterText: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
}));

function CharterTextView({ charter, className }: ChapterTextViewProps) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.charterBox, className)}>
      <Typography className={classes.charterText} component="pre" paragraph>
        {charter}
      </Typography>
    </div>
  );
}

export default CharterTextView;
