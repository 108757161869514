const ROOTS = {
  root: 'v1',
  login: 'v1/login',
  users: 'v1/users',
  recruitings: 'v1/recruitings',
  organizations: 'v1/organizations',
  projects: 'v1/projects',
  workspaces: 'v1/workspaces',
  projectOffice: 'v2/project_office',
};

// projects
const PERSONAL_PROJECTS_LIST = (profileId: string, templateId?: string) => {
  const path = `${ROOTS.users}/${profileId}/projects`;
  return templateId ? `${path}/${templateId}` : path;
};
const PERSONAL_PROJECT = (userId: string, projectId: string) =>
  `${ROOTS.users}/${userId}/projects/${projectId}`;
const KANBAN_BOARD = (projectId: string) => `${ROOTS.workspaces}/${projectId}/board`;
const KANBAN_BOARD_CARDS = (projectId: string, cardId: string) =>
  `${ROOTS.workspaces}/${projectId}/board/${cardId}`;
const CHARTER = (id) => `${ROOTS.workspaces}/${id}/charter`;

// project office
const PROJECT_RESOURCES = (id) => `${ROOTS.workspaces}/${id}/resources`;
const PROJECT_RESOURCE_ITEM = (projectId: string, resourceId: string) =>
  `${ROOTS.workspaces}/${projectId}/resources/${resourceId}`;
const PROJECT_RISKS = (id) => `${ROOTS.workspaces}/${id}/risks`;
const PROJECT_RISKS_ITEM = (projectId: string, riskId: string) =>
  `${ROOTS.workspaces}/${projectId}/risks/${riskId}`;
const PROJECT_STAKEHOLDERS = (id) => `${ROOTS.workspaces}/${id}/stakeholders`;
const PROJECT_STAKEHOLDERS_ITEM = (projectId: string, stakeholderId: string) =>
  `${ROOTS.workspaces}/${projectId}/stakeholders/${stakeholderId}`;

// recruiting
const START_PROJECT = (templateId: string): string => `${ROOTS.recruitings}/${templateId}/start`;
const PERSONAL_RECRUITING_LIST = (userID: string) => `${ROOTS.users}/${userID}/recruitings`;
const PERSONAL_RECRUITING_PROJECT = (userId: string, projectId: string) =>
  `${ROOTS.users}/${userId}/recruitings/${projectId}`;
const RECRUITING_APPLICATIONS = (projectId: string) =>
  `${ROOTS.recruitings}/${projectId}/applications`;
const CANCEL_RECRUITING = (projectId) => `${ROOTS.recruitings}/${projectId}/cancel`;

// organization
const ORGANIZATION_USER = (organizationId: string, userId?: string) => {
  const path = `${ROOTS.organizations}/${organizationId}/users`;
  return userId ? `${path}/${userId}` : path;
};
const ORGANIZATION_TOKEN = (orgId) => `${ROOTS.organizations}/${orgId}/token`;
const ORGANIZATION_ORGANIZATION = (orgId) => `${ROOTS.organizations}/${orgId}`;

// common
const USER_PROFILE = (userId) => `${ROOTS.users}/${userId}`;
const AVATAR = (avatarUrl: string | null): string => {
  if (!avatarUrl) {
    return '';
  }
  return avatarUrl;
};
const ASSET_IMAGE = (imgUrl: string) => `${process.env.REACT_APP_STATIC_IMAGE_HOST}/${imgUrl}.png`;
const DASHBOARD = (userId) => `${ROOTS.users}/${userId}/dashboard`;

const ATTACHMENTS = (filename) => `${ROOTS.root}/attachments/${filename}`;

const COMMENTS = (commentId) => `${ROOTS.workspaces}/comments/${commentId}`;

const PLAN = (projectId: string) => `${ROOTS.workspaces}/${projectId}/plan`;

const Urls = {
  // auth
  PASSWORD_RESET_REQUEST: `${ROOTS.users}/reset-password`,
  CONFIRM_RESET_PASSWORD: `${ROOTS.users}/confirm-reset-password`,

  // projects
  PROJECTS: `${ROOTS.projects}`,
  PROJECT_OFFICE: `${ROOTS.projectOffice}`,
  PERSONAL_PROJECTS_LIST,
  PERSONAL_PROJECT,
  KANBAN_BOARD,
  KANBAN_BOARD_CARDS,
  CHARTER,
  CHARTER_TEMPLATES: `${ROOTS.workspaces}/charter-templates`,
  PLAN,
  PROJECT_RESOURCES,
  PROJECT_RESOURCE_ITEM,
  PROJECT_RISKS,
  PROJECT_RISKS_ITEM,
  PROJECT_STAKEHOLDERS,
  PROJECT_STAKEHOLDERS_ITEM,

  // recruiting
  PERSONAL_RECRUITING_LIST,
  PERSONAL_RECRUITING_PROJECT,
  RECRUITING_APPLICATIONS,
  COMMON_RECRUITING: `${ROOTS.recruitings}/`,
  START_PROJECT,
  QUESTIONS: `${ROOTS.root}/questions/`,
  CANCEL_RECRUITING,

  // organization
  ORGANIZATION_USER,
  ORGANIZATION_TOKEN,
  ORGANIZATION_ORGANIZATION,
  ORGANIZATION: `${ROOTS.organizations}`,

  // common
  USER_PROFILE,
  AVATAR,
  ASSET_IMAGE,
  DASHBOARD,
  ATTACHMENTS,
  NEW_USER_REGISTRATION: `${ROOTS.users}/registration`,
  COMMENTS,
  ROOTS,
};

export default Urls;
