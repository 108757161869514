import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import RecruitingBlock from './RecruitingBlock';
import TemplatesBlock from '../ideas/TemplatesBlock';
import SlimBoxTip from '../../../../components/tips/SlimBoxTip';

import Paths from '../../../../utils/route-paths';
import { Recruiting, Template } from '../../../../utils/types';
import { STRINGS } from '../../../../utils/constants';

interface DashboardRecruitingProps {
  templates: Template[];
  recruiting: Recruiting[];
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    mentor: {
      width: 1000,
      height: 200,
      display: 'flex',
      justifyContent: 'center',
      borderRadius: 16,
    },
    actionBox: {
      width: 450,
      height: 200,
      paddingTop: 20,
      paddingLeft: 80,
    },
    image: {
      height: 200,
    },
    button: {
      width: 256,
      marginTop: 30,
    },
  };
});

function DashboardRecruiting(props: DashboardRecruitingProps): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const { templates, recruiting } = props;
  const {
    dashboardRecruitingOnly,
    dashboardOneRecruitingMentor,
    dashboardOneRecruitingApplicationSent,
  } = STRINGS;
  const goToNewIdea = () => history.push({ pathname: `${Paths.COMMON_IDEAS}/new`, state: {} });

  let tipProps = {
    ...dashboardRecruitingOnly,
    buttonAction: goToNewIdea,
  };

  if (recruiting.length === 1) {
    if (recruiting[0].isMentor) {
      tipProps = {
        ...dashboardOneRecruitingMentor,
        buttonAction: () =>
          history.push({
            pathname: `${Paths.COMMON_RECRUITING_PROJECTS}/${recruiting[0].id}`,
            state: { isPersonal: true },
          }),
      };
    } else if (recruiting[0].applicationSent) {
      tipProps = {
        ...dashboardOneRecruitingApplicationSent,
        buttonAction: goToNewIdea,
      };
    }
  }

  return (
    <div className={classes.root}>
      <SlimBoxTip {...tipProps} />
      <RecruitingBlock data={recruiting} />
      <TemplatesBlock data={templates} />
    </div>
  );
}

export default DashboardRecruiting;
