import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Paths from '../../../../utils/route-paths';
import SmallCardWrapper from '../../components/SmallCardWrapper';
import TemplateStatusLabel from '../../../project-templates/components/TemplateSatatusLable';
import UserInfoBlock from '../../../../components/UserInfoBlock';

import { Template } from '../../../../utils/types';

interface TemplateCardProps {
  data: Template;
  isPersonal?: boolean;
}

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: 'auto',
  },
  infoBlock: {
    marginBottom: 8,
  },
}));

function TemplateCard(props: TemplateCardProps): JSX.Element {
  const { data, isPersonal = true } = props;
  const history = useHistory();
  const classes = useStyles();

  const handleCardClick = () =>
    history.push({
      pathname: `${Paths.COMMON_IDEAS}/${data.id}`,
      state: { isPersonal },
    });

  return (
    <SmallCardWrapper title={data.name} text={data.goal} onClick={handleCardClick}>
      <div className={classes.divider} />
      {!isPersonal && <UserInfoBlock className={classes.infoBlock} user={data.owner} />}
      <TemplateStatusLabel status={data.status} organizationOnly={data.organizationOnly} />
    </SmallCardWrapper>
  );
}

export default TemplateCard;
