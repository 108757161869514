import * as Yup from 'yup';

const required = 'Обязательное поле';

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .email('введите корректный email')
    .max(255, 'E-mail не может быть длиннее 255 символов')
    .required(required),
  password: Yup.string()
    .min(8, 'Пароль не может быть короче 8 символов')
    .required(required),
});

export default LoginSchema;
