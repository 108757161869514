import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NotificationCard from './NotificationCard';

import { RootState } from '../../store/rootReducer';
import { toggleRightPanelView } from '../globalDomain';
import { fetchNotificationsList, fetchNotificationsReadAll } from './domain/reducer';
import { COMPONENT_STATES } from '../../utils/componentState';
import ProjectTemplateSkeleton from '../../components/Skeletons/ProjectTemplate';
import { PALETTE } from '../../utils/palette';

const useStyles = makeStyles(() => ({
  drawer: {
    zIndex: 1201,
  },
  paper: {
    overflow: 'hidden',
  },
  root: {
    width: 550,
    overflow: 'auto',
  },
  header: {
    padding: 24,
  },
  title: {
    display: 'flex',
  },
  loadMoreBox: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 16,
    paddingBottom: 16,
  },
}));

function NotificationsPanel(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { rightPanelsState, notificationsCount } = useSelector((state: RootState) => state.app);
  const { componentState, tableData, request, notifications } = useSelector(
    (state: RootState) => state.notificationsList,
  );

  const firstPage = { page: 1, pageSize: 10 };
  const [pagination, setPagination] = useState(firstPage);

  useEffect(
    () => {
      if (rightPanelsState.notifications) {
        dispatch(fetchNotificationsList({ pagination: firstPage, isFirstLoading: true }));
        setPagination(firstPage);
      }
    },
    // eslint-disable-next-line
    [dispatch, rightPanelsState.notifications],
  );

  useEffect(
    () => {
      if (!tableData.last && notifications.length) {
        dispatch(fetchNotificationsList({ pagination }));
      }
    },
    // eslint-disable-next-line
    [dispatch, pagination],
  );

  const handlePanelClose = () => {
    dispatch(toggleRightPanelView({ rightPanelName: 'notifications' }));
  };

  const handleReadAllNotifications = () => {
    dispatch(fetchNotificationsReadAll());
  };

  const handleLoadMore = () => {
    setPagination((prevState) => ({ ...prevState, page: prevState.page + 1 }));
  };

  return (
    <Drawer
      className={classes.drawer}
      PaperProps={{ elevation: 8 }}
      classes={{ paper: classes.paper }}
      anchor="right"
      // variant="persistent"
      open={rightPanelsState.notifications}
      onClose={handlePanelClose}
    >
      <div className={classes.header}>
        <div className={classes.title}>
          <Typography variant="h5" style={{ fontWeight: 100 }}>
            Уведомления
          </Typography>
          {!!notificationsCount.unread && (
            <Typography variant="h5" style={{ color: PALETTE().red, marginLeft: 16 }}>
              {notificationsCount.unread}
            </Typography>
          )}
        </div>
        <Link component="button" onClick={handleReadAllNotifications}>
          <Typography variant="subtitle2" color="primary">
            прочитать всё
          </Typography>
        </Link>
      </div>
      <Divider />
      <div className={classes.root}>
        {componentState === COMPONENT_STATES.LOADING && <ProjectTemplateSkeleton />}
        {componentState === COMPONENT_STATES.CONTENT && (
          <>
            {notifications.map((notification, index) => (
              <NotificationCard key={notification.id} notification={notification} index={index} />
            ))}
            {!tableData.last && (
              <div className={classes.loadMoreBox}>
                <Button color="primary" onClick={handleLoadMore} disabled={request}>
                  Загрузить еще
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </Drawer>
  );
}

export default NotificationsPanel;
