import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { put, takeLatest, all, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { walk, getFlatDataFromTree } from 'react-sortable-tree';

import { COMPONENT_STATES } from '../../../utils/componentState';
import { makeTree, makeGraphPlaneTree, getMaxPosition, getNodeKey, delay } from './utils';
import Urls from '../../../utils/endpoints';
import { errorHandler } from '../../../utils/fetchUtils';

// dal
const getTaskTree = (projectId) => axios.get(Urls.PLAN(projectId));
const addNewTask = (projectId, payload) => axios.post(Urls.PLAN(projectId), payload);
const updateTask = (projectId, taskId, payload) =>
  axios.patch(`${Urls.PLAN(projectId)}/${taskId}`, payload);
const removeTask = (projectId, taskId) => axios.delete(`${Urls.PLAN(projectId)}/${taskId}`);

// saga
function* requestTaskTree(action) {
  try {
    const { projectId } = action.payload;
    const { data } = yield call(getTaskTree, projectId);
    if (data[1]?.position === 0) {
      yield put(fetchUpdateAllPositions({ data, projectId }));
    } else {
      yield put(fetchTaskTreeSuccess({ data }));
    }
  } catch (e) {
    errorHandler(e);
    yield put(fetchTaskTreeFail());
  }
}

function* requestAddTask(action) {
  try {
    const { values, parentId, projectId, position } = action.payload;
    const taskData = {
      parentStepId: parentId,
      name: values.name,
      projectId,
      estimation: parseInt(values.daysToComplete, 10),
      position,
    };
    const { data } = yield addNewTask(projectId, taskData);
    yield put(addTaskSuccess({ data, position }));
  } catch (e) {
    errorHandler(e);
    yield put(addOrUpdateTaskFail());
  }
}

function* requestUpdateTask(action) {
  try {
    const { values, parentId, projectId, position } = action.payload;
    const taskData = {
      parentStepId: parentId,
      name: values.name,
      projectId,
      estimation: parseInt(values.daysToComplete, 10),
      position,
    };
    const finalTaskData = position ? { ...taskData, position } : taskData;
    const { data } = yield updateTask(projectId, values.id, finalTaskData);
    yield put(updateTaskSuccess({ data }));
  } catch (e) {
    errorHandler(e);
    yield put(addOrUpdateTaskFail());
  }
}

function* requestRemoveTasks(action) {
  try {
    const { tasksForRemoving, projectId } = action.payload;
    yield all(
      tasksForRemoving.map(function*(taskId, index) {
        yield call(delay, 300 * index);
        yield call(removeTask, projectId, taskId);
      }),
    );
    const { data } = yield call(getTaskTree, projectId);
    toast.success('Работы успешно удалены');
    yield put(removingTasksSuccess({ data }));
  } catch (e) {
    errorHandler(e);
    yield put(removingTasksFail());
  }
}

function* requestUpdateAllPositions(action) {
  try {
    const { data, projectId } = action.payload;
    const [rootNode, ...plainTree] = data;
    const taskTree = makeTree(plainTree, rootNode.id, []);
    let position = 512;
    walk({
      treeData: taskTree,
      getNodeKey,
      callback({ node, path, lowerSiblingCounts, treeIndex }) {
        if (node?.task) {
          node.task.position = position;
          position *= 2;
        }
        return node;
      },
      ignoreCollapsed: false,
    });
    const flatTree = getFlatDataFromTree({
      treeData: taskTree,
      getNodeKey,
      ignoreCollapsed: false,
    });
    yield all(
      flatTree.map(function({ node: { task } }, index) {
        const { projectId, id, position } = task;
        return (function*() {
          yield call(delay, 300 * index);
          yield call(updateTask, projectId, id, { position });
        })();
      }),
    );
    yield put(fetchTaskTree({ projectId }));
  } catch (e) {
    errorHandler(e);
  }
}

export function* watchPlan() {
  yield takeLatest(fetchTaskTree.type, requestTaskTree);
  yield takeLatest(fetchAddTask.type, requestAddTask);
  yield takeLatest(fetchUpdateTask.type, requestUpdateTask);
  yield takeLatest(removeTasks.type, requestRemoveTasks);
  yield takeLatest(fetchUpdateAllPositions.type, requestUpdateAllPositions);
}

// reducer
const initialState = {
  planComponentState: COMPONENT_STATES.LOADING,
  rootNode: null,
  taskTree: [],
  graphTree: [],
  dimensions: { height: 0, width: 0 },
  tasksForRemoving: [] as string[],
  expandedNodes: {} as { [key: string]: boolean },
  maxPosition: 512,
  hasPlan: false,
  request: false,
};

const projectSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    fetchTaskTree(state, action) {},
    fetchTaskTreeSuccess(state, action) {
      const { data } = action.payload;
      const [rootNode, ...plainTree] = data;
      state.hasPlan = !!plainTree?.length;
      state.maxPosition = getMaxPosition(plainTree);
      const taskTree = makeTree(plainTree, rootNode.id, state.expandedNodes);
      state.rootNode = rootNode;
      state.taskTree = taskTree;
      const { graphTree, dimensions } = makeGraphPlaneTree(taskTree);
      state.graphTree = graphTree;
      state.dimensions = dimensions;
      state.tasksForRemoving = [];
      state.planComponentState = COMPONENT_STATES.CONTENT;
    },
    fetchTaskTreeFail(state) {
      state.planComponentState = COMPONENT_STATES.ERROR;
    },

    fetchAddTask(state, action) {
      state.request = true;
    },
    addTaskSuccess(state, action) {
      const { data, position } = action.payload;
      state.maxPosition = position;
      const [rootNode, ...plainTree] = data;
      if (plainTree.length < 1) {
        state.maxPosition = 512;
      }
      const taskTree = makeTree(plainTree, rootNode.id, state.expandedNodes);
      state.taskTree = taskTree;
      const { graphTree, dimensions } = makeGraphPlaneTree(taskTree);
      state.graphTree = graphTree;
      state.dimensions = dimensions;
      state.request = false;
    },

    fetchUpdateTask(state, action) {
      state.request = true;
    },
    updateTaskSuccess(state, action) {
      const { data } = action.payload;
      const [rootNode, ...plainTree] = data;
      state.maxPosition = getMaxPosition(plainTree);
      const taskTree = makeTree(plainTree, rootNode.id, state.expandedNodes);
      state.taskTree = taskTree;
      const { graphTree, dimensions } = makeGraphPlaneTree(taskTree);
      state.graphTree = graphTree;
      state.dimensions = dimensions;
      state.request = false;
    },

    addOrUpdateTaskFail(state) {
      state.request = false;
    },
    markTaskForRemoving(state, action) {
      const { taskId } = action.payload;
      state.tasksForRemoving.push(taskId);
    },
    unMarkTaskForRemoving(state, action) {
      const { taskId } = action.payload;
      state.tasksForRemoving = state.tasksForRemoving.filter(
        (taskIdForRemoving) => taskIdForRemoving !== taskId,
      );
    },
    removeTasks(state, action) {
      state.request = true;
    },
    removingTasksSuccess(state, action) {
      state.request = false;
      const { data } = action.payload;
      const [rootNode, ...plainTree] = data;
      const taskTree = makeTree(plainTree, rootNode.id, state.expandedNodes);
      state.taskTree = taskTree;
      const { graphTree, dimensions } = makeGraphPlaneTree(taskTree);
      state.graphTree = graphTree;
      state.dimensions = dimensions;
      state.tasksForRemoving = [];
    },
    removingTasksFail(state) {
      state.request = false;
    },
    setIsExpandedNode(state, action) {
      const { nodeId, isExpanded } = action.payload;
      state.expandedNodes[nodeId] = isExpanded;
    },
    fetchSyncWithBoard(state, action) {
      state.request = true;
    },
    syncWithBoardSuccess(state) {
      state.request = false;
    },
    syncWithBoardFail(state) {
      state.request = false;
    },
    fetchUpdateAllPositions(state, action) {},
  },
});

export const {
  fetchTaskTree,
  fetchTaskTreeSuccess,
  fetchTaskTreeFail,
  fetchAddTask,
  addTaskSuccess,
  fetchUpdateTask,
  updateTaskSuccess,
  addOrUpdateTaskFail,
  removeTasks,
  removingTasksSuccess,
  removingTasksFail,
  setIsExpandedNode,
  markTaskForRemoving,
  unMarkTaskForRemoving,
  fetchUpdateAllPositions,
} = projectSlice.actions;

export default projectSlice.reducer;
