import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import ViewSelectState from '../../../../components/select-state/ViewSelectState';
import PageWrapper from '../../../../components/Wrapper';
import TemplateCardFull from '../../../project-templates/components/TemplateFullCard';
import TilesGridWrapper from '../../../../components/TilesGridWrapper';

import { fetchOrgPublicIdeas, OrgPublicIdeasState } from './domain';
import { PublicOrganizationState } from '../domain';

interface OrgPublicIdeas {}

function OrgPublicIdeas(props: OrgPublicIdeas) {
  const dispatch = useDispatch();
  // @ts-ignore
  const { id } = useParams();
  const { componentState, ideas, isLastPage }: OrgPublicIdeasState = useSelector(
    (state: any) => state.orgPublicIdeas,
  );

  const { organization }: PublicOrganizationState = useSelector(
    (state: any) => state.publicOrganization,
  );

  useEffect(() => {
    dispatch(fetchOrgPublicIdeas({ isFirstLoading: true, orgId: id }));
  }, [dispatch, id]);

  const handleLoadMore = () => {
    dispatch(fetchOrgPublicIdeas({ orgId: id }));
  };

  const content = () => {
    return (
      <div>
        <TilesGridWrapper isLastPage={isLastPage} loadMore={handleLoadMore}>
          {ideas.map((pt: any) => (
            <Grid key={pt.id} item>
              <TemplateCardFull template={pt} isPersonal={false} />
            </Grid>
          ))}
        </TilesGridWrapper>
      </div>
    );
  };

  return (
    <PageWrapper header={`Идеи организации ${organization.shortName}`}>
      <ViewSelectState state={componentState} content={content} />
    </PageWrapper>
  );
}

export default OrgPublicIdeas;
