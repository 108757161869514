import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import DescriptionIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import PageWrapper from '../../../components/Wrapper';
import ProjectApplications from './project-applications';
import OrgMenuItem from '../../../components/OrgMenuItem';
import ProjectDocuments from './ProjectDocuments';
import ProjectEditForm from './ProjectEditForm';

import { setProjectSettingMenuItem } from './domain/reducer';

import { PALETTE } from '../../../utils/palette';
import { RootState } from '../../../store/rootReducer';

interface ParamTypes {
  projectId: string;
}

const useStyles = makeStyles(() => ({
  header: {
    borderBottom: 'solid',
  },
  divider: {
    width: '100%',
  },
  main: {
    height: '100%',
    display: 'flex',
  },
  menuList: {
    height: '100%',
    borderRight: 'solid',
    borderRightColor: PALETTE(0.2).gray,
    borderWidth: 1,
  },
  content: {
    paddingTop: 40,
    paddingLeft: 40,
    width: '70%',
  },
  icon: {
    color: PALETTE().gray,
  },
}));

function ProjectSettings() {
  const classes = useStyles();
  const { projectId } = useParams<ParamTypes>();
  const dispatch = useDispatch();
  const { menuItem } = useSelector((state: RootState) => state.projectSettings);

  const handleClickMenuItem = (item) => {
    dispatch(setProjectSettingMenuItem(item));
  };

  return (
    <PageWrapper header="Настройки проекта">
      <Divider className={classes.divider} />
      <div className={classes.main}>
        <div className={classes.menuList}>
          <List>
            <OrgMenuItem
              onClick={() => handleClickMenuItem(0)}
              Icon={<EmojiPeopleIcon className={classes.icon} />}
              text="Заявки на вступление в проект"
              isSelected={menuItem === 0}
            />
            <OrgMenuItem
              onClick={() => handleClickMenuItem(1)}
              Icon={<EditIcon className={classes.icon} />}
              text="Редактирование проекта"
              isSelected={menuItem === 1}
            />
            <OrgMenuItem
              onClick={() => handleClickMenuItem(2)}
              Icon={<DescriptionIcon className={classes.icon} />}
              text="Документы проекта"
              isSelected={menuItem === 2}
            />
          </List>
        </div>
        <div className={classes.content}>
          {menuItem === 0 && <ProjectApplications projectId={projectId} />}
          {menuItem === 1 && <ProjectEditForm projectId={projectId} />}
          {menuItem === 2 && <ProjectDocuments projectId={projectId} />}
        </div>
      </div>
    </PageWrapper>
  );
}

export default ProjectSettings;
